import { APIResponse } from 'api/Requests';
import { Team } from 'api/types/Team';

export enum CreateTeamError {
  NAME_IS_NOT_CORRECT = 'NAME_IS_NOT_CORRECT',
  EXTERNALID_IS_NOT_CORRECT = 'EXTERNALID_IS_NOT_CORRECT',
  WAREHOUSEIDS_IS_NOT_CORRECT = 'WAREHOUSEIDS_IS_NOT_CORRECT'
}

export const CreateTeamErrorMessage: Record<CreateTeamError, string> = {
  [CreateTeamError.NAME_IS_NOT_CORRECT]: 'Name must have a length between 1 and 50',
  [CreateTeamError.EXTERNALID_IS_NOT_CORRECT]: 'There cannot be more than one Team in one Warehouse',
  [CreateTeamError.WAREHOUSEIDS_IS_NOT_CORRECT]: 'Impossible to assign Team to the selected Warehouse'
}

export type CreateTeamResponse = APIResponse<Team>;
