import { ExpandableHeader } from 'components/ExpandableHeader/ExpandableHeader';
import { useCallback, useEffect, useState } from 'react';
import { Slot } from './Slot';
import { dataStore } from 'stores/DataStore';
import { useTranslation } from 'react-i18next';
import { generateHtmlClasses } from 'utils/htmlClasses';

export const Slots = () => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const handleSetIsExpanded = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  useEffect(() => {
    if (!initialized && Object.keys(dataStore?.unassignedJobsSlots).length) {
      setIsExpanded(Object.keys(dataStore?.unassignedJobsSlots).length > 0);
      setInitialized(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataStore.activeTeam, dataStore?.unassignedJobsSlots, initialized]);

  return (
    <div
      className={generateHtmlClasses('slots', {
        __expanded: isExpanded,
      })}
    >
      <ExpandableHeader
        titleClass="slots-title"
        isExpanded={isExpanded}
        onClick={handleSetIsExpanded}
        title={t('phrases:ordersForLater')}
      />
      <div
        aria-expanded={isExpanded}
        className={generateHtmlClasses('slots-body', {
          __expanded: isExpanded,
        })}
      >
        {dataStore?.unassignedJobsSlots &&
          Object.keys(dataStore?.unassignedJobsSlots).map((key, index) => (
            <Slot
              index={index}
              key={index + key}
              date={key}
              job={dataStore?.unassignedJobsSlots[key]}
            />
          ))}
      </div>
    </div>
  );
};
