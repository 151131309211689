import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { MS_IN_SEC, TASK_COMPLETE_STATUSES_V2 } from 'config';
import { frameDetailStore } from './store';
import { ORDER_COMPLETE_STATUSES } from 'config';
import { dateTimeStore } from 'stores/DateTimeStore';
import { dataStore } from 'stores/DataStore';
import Feed from '../FrameDetail/Feed';
import OrderDetails from '../FrameDetail/OrderDetails';
import Switch from '../../components/Switch';
import { mainStore } from 'stores/MainStore';
import { useTranslation } from 'react-i18next';
import { generateHtmlClasses } from 'utils/htmlClasses';

export default observer(() => {
  const { t } = useTranslation();
  const switchStates = [t('switches:orderDetails'), t('switches:feed')];

  const [sla, setSla] = useState<string>('');
  const [eta, setEta] = useState<string>('');
  const [diff, setDiff] = useState<string>('');
  const [diffState, setDiffState] = useState<'SUPER_LATE' | 'LATE' | 'EARLIER' | 'IN-TIME'>();
  const [currentSwitchState, setCurrentSwitchState] = useState<string>(switchStates[0]);

  const handleHideFrame = () => {
    mainStore.setSelectedOrder(null);
    mainStore.setActiveDynamicFrame(mainStore.activeDynamicFrameSource);
  };
  const handleForceComplete = () => mainStore.setIsShowPopupComplete(true);

  const handleCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      error && console.error(error);
      mainStore.pushAlert('error', t('errorPhrases:failedCopyText'));
    }
  };

  const handleSwitchChange = (state: string) => setCurrentSwitchState(state);

  useEffect(() => {
    frameDetailStore.updateOrderInfo();
    //eslint-disable-next-line
  }, [mainStore.selectedOrder.id, dataStore.activeTeam]);

  useEffect(() => {
    if (!frameDetailStore.orderInfo) {
      handleHideFrame();
      return;
    }
    const eta =
      frameDetailStore.orderInfo.order.eta > 0
        ? new Date(frameDetailStore.orderInfo.order.eta * 1000)
        : null;
    const sla =
      frameDetailStore.orderInfo.order.deliverByMax > 0
        ? new Date(frameDetailStore.orderInfo.order.deliverByMax * 1000)
        : null;

    // diff in minutes
    const diff =
      sla && eta
        ? dateTimeStore.getDateDiffInMinutes(
            frameDetailStore.orderInfo.order.eta,
            frameDetailStore.orderInfo.order.deliverByMax,
          )
        : 0;

    let diffMin = diff;
    let diffHour = 0;
    if (Math.abs(diffMin) > 60) {
      diffMin = diffMin % 60;
      diffHour = Math.floor(Math.abs(diff) / 60);
    }

    let diffMinStr = '';
    const diffHrsStr = `${
      Math.abs(diffHour) === 1
        ? `${t('time:hr', { num: Math.abs(diffHour) })}, `
        : Math.abs(diffHour) > 1
        ? `${t('time:hrs', { num: Math.abs(diffHour) })}, `
        : ''
    }`;
    if (diffMin < 0) {
      diffMinStr = `(${t('time:minsEarlier', { num: diffHrsStr + Math.abs(diffMin) })})`;
      setDiffState('EARLIER');
    } else if (diffMin > 0) {
      diffMinStr = `(${t('time:minsLater', { num: diffHrsStr + Math.abs(diffMin) })}`;

      if (diffMin <= 2 && diffHour === 0) {
        setDiffState('LATE');
      } else {
        setDiffState('SUPER_LATE');
      }
    } else {
      setDiffState('IN-TIME');
    }

    setDiff(diffMinStr);

    let etaMonthDate = '';
    let slaMonthDate = '';
    const createdAtDate = new Date(frameDetailStore.orderInfo.order.createdAt * 1000);
    if (
      eta?.getDate() !== sla?.getDate() &&
      (eta?.getDate() !== dateTimeStore.today.getDate() ||
        (sla?.getDate() !== dateTimeStore.today.getDate() &&
          sla?.getDate() !== createdAtDate.getDate()))
    ) {
      etaMonthDate =
        dateTimeStore.getFormattedShortDate(frameDetailStore.orderInfo.order.eta * 1000) + ', ';
      slaMonthDate =
        dateTimeStore.getFormattedShortDate(frameDetailStore.orderInfo.order.deliverByMax * 1000) +
        ', ';
    }

    if (eta) {
      setEta(`${etaMonthDate}${dateTimeStore.getFormattedDayTime(eta.getTime())}`);
    } else {
      setEta(t('etaTime:willBeKnown'));
    }

    if (sla) {
      setSla(`${slaMonthDate}${dateTimeStore.getFormattedDayTime(sla.getTime())}`);
    } else {
      setSla(t('etaTime:unknown'));
    }
    //eslint-disable-next-line
  }, [frameDetailStore.orderInfo]);

  const order = frameDetailStore.orderInfo?.order;
  if (!order) return <></>;

  const returnTaskExists = !!frameDetailStore.tasks?.RETURN;
  const returnTaskCompleted =
    frameDetailStore.tasks?.RETURN &&
    TASK_COMPLETE_STATUSES_V2.includes(frameDetailStore.tasks.RETURN.task.state);
  const dropOffTaskCompleted =
    frameDetailStore.tasks?.DROP_OFF &&
    TASK_COMPLETE_STATUSES_V2.includes(frameDetailStore.tasks.DROP_OFF.task.state);
  const orderCompleted = order && ORDER_COMPLETE_STATUSES.includes(order.status);

  const isForceCompleteButtonEnabled =
    (returnTaskExists && !returnTaskCompleted && !orderCompleted) ||
    (!dropOffTaskCompleted && !orderCompleted);

  return (
    <div className="frame-dynamic-detail">
      <div className="frame-dynamic-header">
        <div className="frame-dynamic-title">
          <div>
            <div className="frame-dynamic-title__name">
              {t('common:order')}{' '}
              <div className="frame-dynamic-title__num">
                {order.externalId}
                <div
                  className="icon icon-copy"
                  onClick={() => handleCopy(order.externalId).catch(() => void 0)}
                />
              </div>
            </div>
            <div className="frame-detail-subtitle">
              <div className="frame-detail-subtitle__date">
                {dateTimeStore.getFormattedFullDate(order.createdAt * MS_IN_SEC)}
              </div>
              <div className="frame-detail-subtitle__dot" />
              <div className="frame-detail-subtitle__status">
                {t(`orderStatus:${order.status}`)}
              </div>
            </div>
          </div>
          <div className="frame-dynamic-title__close icon icon-close" onClick={handleHideFrame} />
        </div>
        <div className="frame-dynamic-time">
          <div className="frame-dynamic-time__item item _sla">
            <div className="item__label">{t('common:sla')}</div>
            <div className="item__value value">
              <span className="value__main">{sla}</span>
            </div>
          </div>
          <div
            className={generateHtmlClasses('frame-dynamic-time__item item _eta', {
              _late: diffState === 'LATE',
              _earlier: diffState === 'EARLIER',
              '_super-late': diffState === 'SUPER_LATE',
            })}
          >
            <div className="item__label">{t('common:eta')}</div>
            <div className="item__value value">
              <span className="value__main">{eta}</span>
              <span className="value__sub">{diff}</span>
            </div>
          </div>
        </div>
        {order.seller && (
          <div className="frame-dynamic-source">
            <div className="frame-dynamic-source__text">
              {t('time:from', { person: order.seller })}
            </div>
          </div>
        )}
        <div className="frame-dynamic-switch">
          <Switch
            states={switchStates}
            currentState={currentSwitchState}
            onChange={handleSwitchChange}
          />
        </div>
      </div>
      {dataStore.orderExistsLocally(order.id) && (
        <div className="frame-dynamic-footer">
          <div className="frame-dynamic-footer__buttons">
            <button
              disabled={!isForceCompleteButtonEnabled}
              className="button _primary"
              onClick={handleForceComplete}
            >
              {t('common:forceComplete')}
            </button>
          </div>
        </div>
      )}
      <div className="frame-dynamic-body">
        <OrderDetails active={currentSwitchState === switchStates[0]} />
        <Feed active={currentSwitchState === switchStates[1]} />
      </div>
    </div>
  );
});
