import { TaskWithRel } from 'api/response/GetJobListResponse';
import { Order } from 'api/types/Order';
import { observer } from 'mobx-react-lite';
import React from 'react';
import OrderBase from '../../../components/OrderBase';
import { searchStore } from 'components/Search/store';
import { mainStore } from 'stores/MainStore';
import useOrderProgress from '../../../hooks/useOrderProgress.hook';
import { generateHtmlClasses } from 'utils/htmlClasses';

interface OrderProps {
  order: Order;
  tasks: TaskWithRel[];
  className?: string;
  onClick?: () => void;
}

export default observer(({ order, tasks, className, onClick }: OrderProps) => {
  const isOrderFound = order.externalId.includes(searchStore.searchValue);
  const isOrderSelected = mainStore.selectedOrder.id === order.id;

  const progress = useOrderProgress(order);

  return (
    <OrderBase
      progress={progress}
      onClick={onClick}
      order={order}
      tasks={tasks}
      className={generateHtmlClasses(className, {
        _found: isOrderFound,
        _selected: isOrderSelected,
        _expired: !progress,
      })}
    />
  );
});
