import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { mainStore } from 'stores/MainStore';
import Popup from './Popup';
import { dataStore } from 'stores/DataStore';
import { useTranslation } from 'react-i18next';

export default observer(() => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const handlePopupDismiss = () => mainStore.setIsShowPopupLogoff(false);
  const handleForceLogoff = () => {
    if (!mainStore.selectedCourier.id) return;
    setIsLoading(true);
    dataStore
      .courierForceOffline(mainStore.selectedCourier.id)
      .catch(() => void 0)
      .finally(() => {
        setIsLoading(false);
        mainStore.setIsShowPopupLogoff(false);
      });
  };

  return (
    <Popup
      className="popup-complete"
      isShow={mainStore.isShowPopupLogoff && !!mainStore.selectedCourier.id}
      onBackdropDismiss={handlePopupDismiss}
      mobileCentered
    >
      <div className="popup-complete__sign">?</div>
      <div className="popup-complete__text">{t('phrases:areYouSure')}</div>
      <div className="popup-complete__buttons">
        <div className="button _primary _block" onClick={handlePopupDismiss}>
          {t('common:no')}
        </div>
        <button
          className="button _secondary _block"
          onClick={handleForceLogoff}
          disabled={isLoading}
        >
          {isLoading ? <span className="spinner" /> : t('common:yes')}
        </button>
      </div>
    </Popup>
  );
});
