import { observer } from 'mobx-react-lite';
import Popover from './Popover';
import { mainStore } from 'stores/MainStore';
import { authStore } from 'stores/AuthStore';
import { CMS_URL, MENU_LINKS, WMS_URL } from 'config';
import { useTranslation } from 'react-i18next';

export default observer(() => {
  const { t } = useTranslation();
  const logout = () => authStore.logout();

  return (
    <Popover className="popover-menu _in-top" isShow={mainStore.isShowPopoverMenu}>
      <a
        className="popover-menu__item"
        href={CMS_URL}
        target="_blank"
        rel="noreferrer"
      >
        <span className="icon icon-dashboard" />
        {t('common:adminPanel')}
      </a>
      <a
        className="popover-menu__item"
        href={WMS_URL}
        target="_blank"
        rel="noreferrer"
      >
        <span className="icon icon-home" />
        {t('common:warehouseManagment')}
      </a>
      <a
        className="popover-menu__item"
        href={MENU_LINKS[mainStore.environment].DELIVERY_ADMIN}
        target="_blank"
        rel="noreferrer"
      >
        <span className="icon icon-map" />
        {t('common:deliveryManager')}
      </a>
      <div className="popover-menu__line" />
      <div className="popover-menu__item" onClick={logout}>
        <span className="icon icon-exit" />
        {t('common:logout')}
      </div>
    </Popover>
  );
});
