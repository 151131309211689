import { MOBILE_WIDTH } from 'config';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { settingsStore } from './store';
import { dataStore } from 'stores/DataStore';
import { mainStore } from 'stores/MainStore';
import Select, { SelectItem } from '../../Select';
import Header from '../Header';
import TeamCard from './TeamCard';
import { useTranslation } from 'react-i18next';

export default observer(() => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH}px)` });

  const [selectedWarehouseItem, setSelectedWarehouseItem] = useState<SelectItem>({
    value: '',
    text: '',
    rawText: '',
  });

  const handleWarehouseChange = (item: SelectItem) => {
    if (item.value) {
      setSelectedWarehouseItem(item);
      settingsStore.fetchWarehouseWithTeams(item.value);
    } else {
      setSelectedWarehouseItem({ value: '', text: '', rawText: '' });
      settingsStore.setSelectedWarehouse(null);
    }
  };

  const handleToggleAddTeam = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    mainStore.setIsShowPopupSettings(false);

    if (isMobile) mainStore.setIsShowPopupAddTeam(true);
    else mainStore.setIsShowPopoverAddTeam(true);
  };

  useEffect(() => {
    if (!settingsStore.selectedWarehouse) return;
    const existingWarehouseItem = dataStore.warehouseList.find(
      (item) => item.value === settingsStore.selectedWarehouse?.warehouse.id,
    );
    if (existingWarehouseItem) setSelectedWarehouseItem(existingWarehouseItem);
    //eslint-disable-next-line
  }, [mainStore.isShowPopupSettings]);

  return (
    <div>
      <Header title={t('common:warehouse')} />
      <div className="settings-frame__body body">
        <div className="body__item item">
          <div className="item__top top">
            <span className="top__label">{t('phrases:selectWarehouse')}</span>
          </div>
          <Select
            initialValue={t('inputs:selectWarehouse')}
            className="item__select"
            data={dataStore.warehouseList}
            value={selectedWarehouseItem}
            onChange={handleWarehouseChange}
            withSearch
            searchPlaceholder={t('inputs:search')}
          />
          {settingsStore.selectedWarehouse && (
            <div className="item__info info">
              {settingsStore.selectedWarehouse.address.name && (
                <div className="info__prop prop">
                  <div className="prop__icon icon icon-pin-outline" />
                  <div className="prop__text text">
                    {settingsStore.selectedWarehouse.address.name}
                  </div>
                </div>
              )}
              <div className="info__prop prop">
                <div className="prop__icon icon icon-clock" />
                <div className="prop__text text">{t('time:fromTo', {from: '0:00 a.m.', to: '0:00 p.m.'})}</div>
              </div>
            </div>
          )}
        </div>
        {settingsStore.selectedWarehouse && (
          <div className="body__item item">
            <div className="item__top top">
              <span className="top__label">{t('common:teams')}</span>
              <div className="top__button button">
                <div className="button__icon icon icon-plus" />
                <div className="button__text" onClick={handleToggleAddTeam}>
                  {t('phrases:addTeam')}
                </div>
              </div>
            </div>
            <div className="item__team-cards cards team-cards">
              {settingsStore.selectedWarehouse.teams.map((team) => (
                <TeamCard key={team.id} teamId={team.id} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
