import { observer } from 'mobx-react-lite';
import React, { ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { generateHtmlClasses } from 'utils/htmlClasses';

type PopoverProps = {
  isShow: boolean;
  children: ReactNode;
  className?: string;
};

export default observer(({ isShow, children, className }: PopoverProps) => {
  const refPopover = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition
      in={isShow}
      timeout={{ enter: 350, exit: 250 }}
      mountOnEnter
      unmountOnExit
      classNames="popover"
      nodeRef={refPopover}
    >
      <div className={generateHtmlClasses('popover', className)} ref={refPopover}>
        {children}
      </div>
    </CSSTransition>
  );
});
