import { observer } from 'mobx-react-lite';
import { mainStore } from '../../stores/MainStore';
import { CourierStatus, VehicleType } from '../../api/types/Courier';
import { generateHtmlClasses } from 'utils/htmlClasses';

type CourierLabelProps = {
  id: string;
  userName: string;
  status: CourierStatus;
  vehicleType: VehicleType;
  clickable?: boolean;
};

export const CourierLabel = observer(
  ({ id, userName, status, vehicleType, clickable }: CourierLabelProps) => {
    const handleLabelClick = () => {
      if (!clickable) return;
      setTimeout(() => {
        mainStore.setSelectedCourier({ id, source: 'map' });
      }, 0);
    };

    const isActive = mainStore.selectedCourier.id === id;
    const vehicle = VehicleType[vehicleType]?.toLowerCase() || '';
    const statusName = CourierStatus[status]?.toLowerCase() || '';

    return (
      <div
        className={generateHtmlClasses('map-label', { _active: clickable && isActive })}
        onClick={handleLabelClick}
      >
        {clickable && <div className={`icon icon-${vehicle} courier-icon _${statusName}`} />}
        <div className="map-label__name">{userName}</div>
        {statusName.length > 0 && (
          <div className="map-label__status">{statusName.replace(/_/g, ' ')}</div>
        )}
      </div>
    );
  },
);
