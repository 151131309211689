import { observer } from 'mobx-react-lite';
import { dataStore } from 'stores/DataStore';
import { mainStore } from 'stores/MainStore';
import Popup from '../components/Popup';
import { useTranslation } from 'react-i18next';

interface PopupDeleteMemberProps {
  teamId: string;
  userId: string;
  onComplete?: () => void;
}

export default observer(({ teamId, userId, onComplete }: PopupDeleteMemberProps) => {
  const { t } = useTranslation();
  const handlePopupDismiss = () => mainStore.setIsShowPopupDeleteMember(false);
  const handlePopupSubmit = async () => {
    dataStore.deleteMemberFromTeam(teamId, userId).finally(() => {
      mainStore.setIsShowPopupDeleteMember(false);
      onComplete?.call(null);
    });
  };

  return (
    <Popup className='popup-delete-member'
           isShow={mainStore.isShowPopupDeleteMember}
           onBackdropDismiss={handlePopupDismiss}
           mobileCentered>
      <div className='popup-delete-member__sign'>?</div>
      <div className='popup-delete-member__text'>{t('phrases:doYouWantToDeleteMember')}</div>
      <div className='popup-delete-member__buttons'>
        <button className='button _primary _block' onClick={handlePopupDismiss}>{t('common:no')}</button>
        <button className='button _secondary _block' onClick={handlePopupSubmit}>{t('phrases:yesDelete')}</button>
      </div>
    </Popup>
  );
});
