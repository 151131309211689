import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { dataStore } from 'stores/DataStore';
import { Scrollbars } from 'react-custom-scrollbars';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Team from './Team';
import MobileEmptyBlock from './MobileEmptyBlock';

export default observer(() => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Promise.allSettled([
      dataStore.requestDashboardInfo(),
      dataStore.requestJobList(),
      dataStore.getWarehouseList(),
    ])
      .then(() => {
        process.env.REACT_APP_SLOTS && dataStore.requestSlotJobList();
      })
      .catch((error) => error && console.error(error))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Scrollbars className="team-list">
      {isLoading && dataStore.teams === null ? (
        <div className="team">
          <SkeletonTheme baseColor="#202934" highlightColor="#27303B">
            <Skeleton height={21} width={170} />
            <br />
            <Skeleton height={34} count={5} />
            <br />
            <Skeleton height={21} width={170} />
            <br />
            <Skeleton height={34} count={5} />
          </SkeletonTheme>
        </div>
      ) : (
        <div className="team-list__items-container">
          {dataStore.teams &&
            dataStore.teams.map(({ team, couriers }) => (
              <Team team={team} couriers={couriers} key={team.id} />
            ))}
        </div>
      )}
      <MobileEmptyBlock />
    </Scrollbars>
  );
});
