import React, { Suspense, useEffect, lazy } from 'react';
import './assets/styles/common.scss';
import { observer } from 'mobx-react-lite';
import { listenerOnClick } from './UI';
import { Routes, Route, Navigate } from 'react-router-dom';
import Main from './pages/Main';
import { authStore } from './stores/AuthStore';
import { mainStore } from './stores/MainStore';
import Alerts from './components/Alerts';
import { useLang } from 'hooks/useLang';

const Login = lazy(() => import('./pages/Login'));

export default observer(() => {
  useEffect(() => {
    if (!mainStore.isAllStoresSynchronized) return;
    listenerOnClick();
    mainStore.setSentryUser({
      id: authStore.personalData.id || '',
      name: authStore.fullName,
      phone: authStore.personalData.phone || '',
    });
    //eslint-disable-next-line
  }, [mainStore.isAllStoresSynchronized]);

  useEffect(() => {
    document.body.dir = mainStore.dir;
  }, []);

  useLang();

  if (!mainStore.isAllStoresSynchronized) return <></>;

  return (
    <Suspense fallback={<></>}>
      <Routes>
        <Route path="/" element={authStore.isAuthorized ? <Main /> : <Login />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Alerts />
    </Suspense>
  );
});
