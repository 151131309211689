import { MOBILE_WIDTH } from 'config';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';
import { mainStore } from 'stores/MainStore';
import React, { useEffect } from 'react';
import { DragDropContext, DragStart, DropResult } from '@hello-pangea/dnd';
import DynamicFrame from './DynamicFrame';
import Search from '../components/Search/index';
import TeamList from './TeamList';
import { dataStore } from 'stores/DataStore';
import { authStore } from '../stores/AuthStore';
import { generateHtmlClasses } from 'utils/htmlClasses';

export default observer(() => {
  const isLess1200px = useMediaQuery({ query: '(max-width: 1200px)' });
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH}px)` });

  useEffect(() => {
    if (!isLess1200px) {
      if (authStore.isFrameTeamsHidden !== null) authStore.setIsFrameTeamsHidden(isLess1200px);
    } else {
      if (authStore.isFrameTeamsHidden === null) authStore.setIsFrameTeamsHidden(isLess1200px);
    }
  }, [isLess1200px]);

  const onDragStart = ({ source, type }: DragStart) => {
    mainStore.setDragItem({ group: source.droppableId, type: type });
    mainStore.setIsDrag(true);
    if (isMobile) return;
  };
  const onDragEnd = ({ source, destination, type, draggableId }: DropResult) => {
    mainStore.setDragItem(null);
    mainStore.setIsDrag(false);
    const target = document.querySelector(
      `[data-rbd-drag-handle-draggable-id="${mainStore.draggableId}"]`,
    );
    target?.classList.remove('_isDraggable');
    mainStore.setDraggableId('');

    if (destination && destination.droppableId === source.droppableId && type === 'order') {
      const jobId = draggableId.split('_')[0];
      dataStore.reorderJobOrders(jobId, source.index, destination.index);
    }
    if (!destination) return;
    if (source.droppableId === destination.droppableId) return;
    switch (type) {
      case 'courier':
        if (destination.droppableId === 'teamless') {
          dataStore.courierUnassignTeam(draggableId).catch(() => void 0);
        } else {
          dataStore.courierAssignTeam(draggableId).catch(() => void 0);
        }
        break;
      case 'job':
        if (destination.droppableId === 'jobUnassigned') {
          dataStore
            .jobUnassignCourier({
              jobId: draggableId,
              courierId: source.droppableId.split('_')[1],
            })
            .catch(() => void 0);
        } else {
          dataStore
            .jobAssignCourier({
              jobId: draggableId,
              courierId: destination.droppableId.split('_')[1],
            })
            .catch(() => void 0);
        }
        break;
      case 'order':
        if (destination.droppableId === 'orderUnassigned') {
          dataStore.splitOrder(draggableId.split('_')[1]).catch(() => void 0);
        }
        break;
    }
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <div
        className={generateHtmlClasses('frame-teams', { _hidden: authStore.isFrameTeamsHidden })}
      >
        <Search />
        <TeamList />
        {!isMobile && <DynamicFrame />}
      </div>
      {isMobile && <DynamicFrame />}
    </DragDropContext>
  );
});
