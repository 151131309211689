import { ForceAction, OrderStatus } from 'api/types/Order';
import { capitalizeFirstLetter } from 'common/capitalize-word';
import MobileEmptyBlock from 'components/MobileEmptyBlock';
import { MS_IN_SEC, ORDER_BAD_ACTIONS, ORDER_BAD_STATUSES } from 'config';
import { observer } from 'mobx-react-lite';
import { dateTimeStore } from 'stores/DateTimeStore';
import { frameDetailStore } from '../store';
import { useTranslation } from 'react-i18next';
import { generateHtmlClasses } from 'utils/htmlClasses';

interface FeedProps {
  active: boolean;
}

export default observer(({ active }: FeedProps) => {
  const { t } = useTranslation();
  const order = frameDetailStore.orderInfo?.order;
  if (!order) return <></>;

  const pickupEtaMin = frameDetailStore.tasks?.PICK_UP
    ? dateTimeStore.getDateDiffInMinutes(
        frameDetailStore.tasks.PICK_UP.task.startedAt,
        order.createdAt,
      )
    : 0;

  const pickupActualMin = frameDetailStore.tasks?.PICK_UP
    ? dateTimeStore.getDateDiffInMinutes(
        frameDetailStore.tasks.PICK_UP.task.completedAt,
        frameDetailStore.tasks.PICK_UP.task.startedAt,
      )
    : 0;

  const pickupEtaConverted = dateTimeStore.convertMinutesToFullTime(pickupEtaMin);
  const pickupActualConverted = dateTimeStore.convertMinutesToFullTime(pickupActualMin);

  return (
    <div className={generateHtmlClasses('feed', { _active: active })}>
      <div className="feed__line" />
      <div className="feed__item item _created">
        <div className="item__line" />
        <div className="item__dot" />
        <div className="item__text">{t('common:orderCreated')}</div>
        <div className="item__time">
          {t('time:dateTime', {
            date: dateTimeStore.getFormattedFullDate(order.createdAt * MS_IN_SEC),
            time: dateTimeStore.getFormattedDayTime(order.createdAt * MS_IN_SEC),
          })}
        </div>
      </div>
      {!!frameDetailStore.tasks?.PICK_UP?.task.startedAt && (
        <div className="feed__item item _picking-up">
          <div className="item__line" />
          <div className="item__dot" />
          <div className="item__text">{t('common:pickingUp')}</div>
          <div className="item__time">
            {dateTimeStore.getFormattedDayTime(
              frameDetailStore.tasks.PICK_UP.task.startedAt * MS_IN_SEC,
            ) || '...'}{' '}
            –{' '}
            {dateTimeStore.getFormattedDayTime(
              frameDetailStore.tasks.PICK_UP.task.completedAt * MS_IN_SEC,
            ) || '...'}
          </div>
          {!!frameDetailStore.tasks.PICK_UP.task.startedAt &&
            !!frameDetailStore.tasks.PICK_UP.task.completedAt && (
              <div className="item__body">
                <div className="eta">
                  <strong>{t('common:eta')}</strong>{' '}
                  {dateTimeStore.fullTimeToString(pickupEtaConverted)}
                </div>
                <div className="actual-time">
                  <strong className="actual-time__text">{t('common:actualTime')}</strong>
                  <span
                    className={generateHtmlClasses('actual-time__value', {
                      _later: pickupActualMin > pickupEtaMin,
                      _earlier: pickupActualMin < pickupEtaMin,
                    })}
                  >
                    {dateTimeStore.fullTimeToString(pickupActualConverted)}
                  </span>
                </div>
              </div>
            )}
        </div>
      )}
      {!!frameDetailStore.tasks?.DROP_OFF?.task.startedAt && (
        <div className="feed__item item _in-delivery">
          <div className="item__line" />
          <div className="item__dot" />
          <div className="item__text">{t('taskStatus:inDelivery')}</div>
          <div className="item__time">
            {dateTimeStore.getFormattedDayTime(
              frameDetailStore.tasks.DROP_OFF.task.startedAt * MS_IN_SEC,
            )}
          </div>
        </div>
      )}
      {!!frameDetailStore.tasks?.DROP_OFF?.task.completedAt &&
        order.status === OrderStatus.COMPLETED && (
          <div className="feed__item item _delivered">
            <div className="item__line" />
            <div className="item__dot" />
            <div className="item__text">{t('taskStatus:delivered')}</div>
            <div className="item__time">
              {dateTimeStore.getFormattedDayTime(
                frameDetailStore.tasks.DROP_OFF.task.completedAt * MS_IN_SEC,
              )}
            </div>
          </div>
        )}
      {!!frameDetailStore.tasks?.RETURN?.task.startedAt && (
        <div className="feed__item item _returning">
          <div className="item__line" />
          <div className="item__dot" />
          <div className="item__text">{t('taskStatus:returning')}</div>
          <div className="item__time">
            {dateTimeStore.getFormattedDayTime(
              frameDetailStore.tasks.RETURN.task.startedAt * MS_IN_SEC,
            )}
          </div>
        </div>
      )}
      {!!frameDetailStore.tasks?.RETURN?.task.completedAt && (
        <div className="feed__item item _returned">
          <div className="item__line" />
          <div className="item__dot" />
          <div className="item__text">{t('taskStatus:returned')}</div>
          <div className="item__time">
            {dateTimeStore.getFormattedDayTime(
              frameDetailStore.tasks.RETURN.task.completedAt * MS_IN_SEC,
            )}
          </div>
        </div>
      )}
      {order.forceAction === ForceAction.COMPLETE && order.status === OrderStatus.COMPLETED && (
        <div className="feed__item item _delivered">
          <div className="item__line" />
          <div className="item__dot" />
          <div className="item__text">{t('taskStatus:forceCompleted')}</div>
          <div className="item__time">
            {t('time:dateTime', {
              date: dateTimeStore.getFormattedFullDate(order.updatedAt * MS_IN_SEC),
              time: dateTimeStore.getFormattedDayTime(order.updatedAt * MS_IN_SEC),
            })}
          </div>
        </div>
      )}
      {(ORDER_BAD_STATUSES.includes(order.status) ||
        (order.forceAction && ORDER_BAD_ACTIONS.includes(order.forceAction))) && (
        <div className="feed__item item _bad">
          <div className="item__line" />
          <div className="item__dot" />
          {!order.forceAction ? (
            <div className="item__text">{capitalizeFirstLetter(order.status, true)}</div>
          ) : (
            <div className="item__text">{t('taskStatus:forceFailed')}</div>
          )}
          <div className="item__time">
            {t('time:dateTime', {
              date: dateTimeStore.getFormattedFullDate(order.updatedAt * MS_IN_SEC),
              time: dateTimeStore.getFormattedDayTime(order.updatedAt * MS_IN_SEC),
            })}
          </div>
        </div>
      )}
      <MobileEmptyBlock />
    </div>
  );
});
