/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
;(function (global, factory) {
    /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd) define(['protobufjs/minimal'], factory)
    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require('protobufjs/minimal'))
})(this, function ($protobuf) {
    'use strict'

    // Common aliases
    var $Reader = $protobuf.Reader,
        $Writer = $protobuf.Writer,
        $util = $protobuf.util

    // Exported root namespace
    var $root = $protobuf.roots['default'] || ($protobuf.roots['default'] = {})

    $root.orders = (function () {
        /**
         * Namespace orders.
         * @exports orders
         * @namespace
         */
        var orders = {}

        orders.GetOrderListResponse = (function () {
            /**
             * Properties of a GetOrderListResponse.
             * @memberof orders
             * @interface IGetOrderListResponse
             * @property {Array.<orders.Order>|null} [orders] GetOrderListResponse orders
             */

            /**
             * Constructs a new GetOrderListResponse.
             * @memberof orders
             * @classdesc Represents a GetOrderListResponse.
             * @implements IGetOrderListResponse
             * @constructor
             * @param {orders.IGetOrderListResponse=} [properties] Properties to set
             */
            function GetOrderListResponse(properties) {
                this.orders = []
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * GetOrderListResponse orders.
             * @member {Array.<orders.Order>} orders
             * @memberof orders.GetOrderListResponse
             * @instance
             */
            GetOrderListResponse.prototype.orders = $util.emptyArray

            /**
             * Creates a new GetOrderListResponse instance using the specified properties.
             * @function create
             * @memberof orders.GetOrderListResponse
             * @static
             * @param {orders.IGetOrderListResponse=} [properties] Properties to set
             * @returns {orders.GetOrderListResponse} GetOrderListResponse instance
             */
            GetOrderListResponse.create = function create(properties) {
                return new GetOrderListResponse(properties)
            }

            /**
             * Encodes the specified GetOrderListResponse message. Does not implicitly {@link orders.GetOrderListResponse.verify|verify} messages.
             * @function encode
             * @memberof orders.GetOrderListResponse
             * @static
             * @param {orders.GetOrderListResponse} message GetOrderListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOrderListResponse.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                if (message.orders != null && message.orders.length)
                    for (var i = 0; i < message.orders.length; ++i)
                        $root.orders.Order.encode(
                            message.orders[i],
                            writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
                        ).ldelim()
                return writer
            }

            /**
             * Encodes the specified GetOrderListResponse message, length delimited. Does not implicitly {@link orders.GetOrderListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof orders.GetOrderListResponse
             * @static
             * @param {orders.GetOrderListResponse} message GetOrderListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOrderListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a GetOrderListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof orders.GetOrderListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {orders.GetOrderListResponse} GetOrderListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOrderListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.orders.GetOrderListResponse()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            if (!(message.orders && message.orders.length)) message.orders = []
                            message.orders.push($root.orders.Order.decode(reader, reader.uint32()))
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                return message
            }

            /**
             * Decodes a GetOrderListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof orders.GetOrderListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {orders.GetOrderListResponse} GetOrderListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOrderListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a GetOrderListResponse message.
             * @function verify
             * @memberof orders.GetOrderListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetOrderListResponse.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (message.orders != null && message.hasOwnProperty('orders')) {
                    if (!Array.isArray(message.orders)) return 'orders: array expected'
                    for (var i = 0; i < message.orders.length; ++i) {
                        var error = $root.orders.Order.verify(message.orders[i])
                        if (error) return 'orders.' + error
                    }
                }
                return null
            }

            /**
             * Creates a GetOrderListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof orders.GetOrderListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {orders.GetOrderListResponse} GetOrderListResponse
             */
            GetOrderListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.orders.GetOrderListResponse) return object
                var message = new $root.orders.GetOrderListResponse()
                if (object.orders) {
                    if (!Array.isArray(object.orders))
                        throw TypeError('.orders.GetOrderListResponse.orders: array expected')
                    message.orders = []
                    for (var i = 0; i < object.orders.length; ++i) {
                        if (typeof object.orders[i] !== 'object')
                            throw TypeError('.orders.GetOrderListResponse.orders: object expected')
                        message.orders[i] = $root.orders.Order.fromObject(object.orders[i])
                    }
                }
                return message
            }

            /**
             * Creates a plain object from a GetOrderListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof orders.GetOrderListResponse
             * @static
             * @param {orders.GetOrderListResponse} message GetOrderListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetOrderListResponse.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.arrays || options.defaults) object.orders = []
                if (message.orders && message.orders.length) {
                    object.orders = []
                    for (var j = 0; j < message.orders.length; ++j)
                        object.orders[j] = $root.orders.Order.toObject(message.orders[j], options)
                }
                return object
            }

            /**
             * Converts this GetOrderListResponse to JSON.
             * @function toJSON
             * @memberof orders.GetOrderListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetOrderListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for GetOrderListResponse
             * @function getTypeUrl
             * @memberof orders.GetOrderListResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetOrderListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/orders.GetOrderListResponse'
            }

            return GetOrderListResponse
        })()

        /**
         * Status enum.
         * @name orders.Status
         * @enum {number}
         * @property {number} NEW=0 NEW value
         * @property {number} BATCHED=1 BATCHED value
         * @property {number} IN_PROGRESS=2 IN_PROGRESS value
         * @property {number} COMPLETED=3 COMPLETED value
         * @property {number} CANCELED=4 CANCELED value
         * @property {number} FAILED=5 FAILED value
         */
        orders.Status = (function () {
            var valuesById = {},
                values = Object.create(valuesById)
            values[(valuesById[0] = 'NEW')] = 0
            values[(valuesById[1] = 'BATCHED')] = 1
            values[(valuesById[2] = 'IN_PROGRESS')] = 2
            values[(valuesById[3] = 'COMPLETED')] = 3
            values[(valuesById[4] = 'CANCELED')] = 4
            values[(valuesById[5] = 'FAILED')] = 5
            return values
        })()

        /**
         * OrderType enum.
         * @name orders.OrderType
         * @enum {number}
         * @property {number} GROCERY=0 GROCERY value
         * @property {number} FASHION=1 FASHION value
         */
        orders.OrderType = (function () {
            var valuesById = {},
                values = Object.create(valuesById)
            values[(valuesById[0] = 'GROCERY')] = 0
            values[(valuesById[1] = 'FASHION')] = 1
            return values
        })()

        orders.Order = (function () {
            /**
             * Properties of an Order.
             * @memberof orders
             * @interface IOrder
             * @property {string} id Order id
             * @property {string} externalId Order externalId
             * @property {string} contactId Order contactId
             * @property {number} startBy Order startBy
             * @property {number} deliverByMin Order deliverByMin
             * @property {number} deliverByMax Order deliverByMax
             * @property {string|null} [note] Order note
             * @property {orders.Status} status Order status
             * @property {number} createdAt Order createdAt
             * @property {number|null} [updatedAt] Order updatedAt
             * @property {number|null} [deletedAt] Order deletedAt
             * @property {number|null} [distance] Order distance
             * @property {number|null} [eta] Order eta
             * @property {string|null} [seller] Order seller
             * @property {addresses.Address|null} [address] Order address
             * @property {orders.OrderType} orderType Order orderType
             */

            /**
             * Constructs a new Order.
             * @memberof orders
             * @classdesc Represents an Order.
             * @implements IOrder
             * @constructor
             * @param {orders.IOrder=} [properties] Properties to set
             */
            function Order(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * Order id.
             * @member {string} id
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.id = ''

            /**
             * Order externalId.
             * @member {string} externalId
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.externalId = ''

            /**
             * Order contactId.
             * @member {string} contactId
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.contactId = ''

            /**
             * Order startBy.
             * @member {number} startBy
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.startBy = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Order deliverByMin.
             * @member {number} deliverByMin
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.deliverByMin = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Order deliverByMax.
             * @member {number} deliverByMax
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.deliverByMax = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Order note.
             * @member {string} note
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.note = ''

            /**
             * Order status.
             * @member {orders.Status} status
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.status = 0

            /**
             * Order createdAt.
             * @member {number} createdAt
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.createdAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Order updatedAt.
             * @member {number} updatedAt
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Order deletedAt.
             * @member {number} deletedAt
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Order distance.
             * @member {number} distance
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.distance = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Order eta.
             * @member {number} eta
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.eta = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Order seller.
             * @member {string} seller
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.seller = ''

            /**
             * Order address.
             * @member {addresses.Address|null|undefined} address
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.address = null

            /**
             * Order orderType.
             * @member {orders.OrderType} orderType
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.orderType = 0

            /**
             * Creates a new Order instance using the specified properties.
             * @function create
             * @memberof orders.Order
             * @static
             * @param {orders.IOrder=} [properties] Properties to set
             * @returns {orders.Order} Order instance
             */
            Order.create = function create(properties) {
                return new Order(properties)
            }

            /**
             * Encodes the specified Order message. Does not implicitly {@link orders.Order.verify|verify} messages.
             * @function encode
             * @memberof orders.Order
             * @static
             * @param {orders.Order} message Order message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Order.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
                writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.externalId)
                writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.contactId)
                writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.startBy)
                writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.deliverByMin)
                writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.deliverByMax)
                if (message.note != null && Object.hasOwnProperty.call(message, 'note'))
                    writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.note)
                writer.uint32(/* id 8, wireType 0 =*/ 64).int32(message.status)
                writer.uint32(/* id 9, wireType 0 =*/ 72).int64(message.createdAt)
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, 'updatedAt'))
                    writer.uint32(/* id 10, wireType 0 =*/ 80).int64(message.updatedAt)
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, 'deletedAt'))
                    writer.uint32(/* id 11, wireType 0 =*/ 88).int64(message.deletedAt)
                if (message.distance != null && Object.hasOwnProperty.call(message, 'distance'))
                    writer.uint32(/* id 12, wireType 0 =*/ 96).int64(message.distance)
                if (message.eta != null && Object.hasOwnProperty.call(message, 'eta'))
                    writer.uint32(/* id 13, wireType 0 =*/ 104).int64(message.eta)
                if (message.seller != null && Object.hasOwnProperty.call(message, 'seller'))
                    writer.uint32(/* id 14, wireType 2 =*/ 114).string(message.seller)
                if (message.address != null && Object.hasOwnProperty.call(message, 'address'))
                    $root.addresses.Address.encode(
                        message.address,
                        writer.uint32(/* id 15, wireType 2 =*/ 122).fork()
                    ).ldelim()
                writer.uint32(/* id 16, wireType 0 =*/ 128).int32(message.orderType)
                return writer
            }

            /**
             * Encodes the specified Order message, length delimited. Does not implicitly {@link orders.Order.verify|verify} messages.
             * @function encodeDelimited
             * @memberof orders.Order
             * @static
             * @param {orders.Order} message Order message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Order.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes an Order message from the specified reader or buffer.
             * @function decode
             * @memberof orders.Order
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {orders.Order} Order
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Order.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.orders.Order()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.id = reader.string()
                            break
                        }
                        case 2: {
                            message.externalId = reader.string()
                            break
                        }
                        case 3: {
                            message.contactId = reader.string()
                            break
                        }
                        case 4: {
                            message.startBy = reader.int64()
                            break
                        }
                        case 5: {
                            message.deliverByMin = reader.int64()
                            break
                        }
                        case 6: {
                            message.deliverByMax = reader.int64()
                            break
                        }
                        case 7: {
                            message.note = reader.string()
                            break
                        }
                        case 8: {
                            message.status = reader.int32()
                            break
                        }
                        case 9: {
                            message.createdAt = reader.int64()
                            break
                        }
                        case 10: {
                            message.updatedAt = reader.int64()
                            break
                        }
                        case 11: {
                            message.deletedAt = reader.int64()
                            break
                        }
                        case 12: {
                            message.distance = reader.int64()
                            break
                        }
                        case 13: {
                            message.eta = reader.int64()
                            break
                        }
                        case 14: {
                            message.seller = reader.string()
                            break
                        }
                        case 15: {
                            message.address = $root.addresses.Address.decode(reader, reader.uint32())
                            break
                        }
                        case 16: {
                            message.orderType = reader.int32()
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('id'))
                    throw $util.ProtocolError("missing required 'id'", { instance: message })
                if (!message.hasOwnProperty('externalId'))
                    throw $util.ProtocolError("missing required 'externalId'", { instance: message })
                if (!message.hasOwnProperty('contactId'))
                    throw $util.ProtocolError("missing required 'contactId'", { instance: message })
                if (!message.hasOwnProperty('startBy'))
                    throw $util.ProtocolError("missing required 'startBy'", { instance: message })
                if (!message.hasOwnProperty('deliverByMin'))
                    throw $util.ProtocolError("missing required 'deliverByMin'", { instance: message })
                if (!message.hasOwnProperty('deliverByMax'))
                    throw $util.ProtocolError("missing required 'deliverByMax'", { instance: message })
                if (!message.hasOwnProperty('status'))
                    throw $util.ProtocolError("missing required 'status'", { instance: message })
                if (!message.hasOwnProperty('createdAt'))
                    throw $util.ProtocolError("missing required 'createdAt'", { instance: message })
                if (!message.hasOwnProperty('orderType'))
                    throw $util.ProtocolError("missing required 'orderType'", { instance: message })
                return message
            }

            /**
             * Decodes an Order message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof orders.Order
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {orders.Order} Order
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Order.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies an Order message.
             * @function verify
             * @memberof orders.Order
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Order.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (!$util.isString(message.id)) return 'id: string expected'
                if (!$util.isString(message.externalId)) return 'externalId: string expected'
                if (!$util.isString(message.contactId)) return 'contactId: string expected'
                if (
                    !$util.isInteger(message.startBy) &&
                    !(message.startBy && $util.isInteger(message.startBy.low) && $util.isInteger(message.startBy.high))
                )
                    return 'startBy: integer|Long expected'
                if (
                    !$util.isInteger(message.deliverByMin) &&
                    !(
                        message.deliverByMin &&
                        $util.isInteger(message.deliverByMin.low) &&
                        $util.isInteger(message.deliverByMin.high)
                    )
                )
                    return 'deliverByMin: integer|Long expected'
                if (
                    !$util.isInteger(message.deliverByMax) &&
                    !(
                        message.deliverByMax &&
                        $util.isInteger(message.deliverByMax.low) &&
                        $util.isInteger(message.deliverByMax.high)
                    )
                )
                    return 'deliverByMax: integer|Long expected'
                if (message.note != null && message.hasOwnProperty('note'))
                    if (!$util.isString(message.note)) return 'note: string expected'
                switch (message.status) {
                    default:
                        return 'status: enum value expected'
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break
                }
                if (
                    !$util.isInteger(message.createdAt) &&
                    !(
                        message.createdAt &&
                        $util.isInteger(message.createdAt.low) &&
                        $util.isInteger(message.createdAt.high)
                    )
                )
                    return 'createdAt: integer|Long expected'
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (
                        !$util.isInteger(message.updatedAt) &&
                        !(
                            message.updatedAt &&
                            $util.isInteger(message.updatedAt.low) &&
                            $util.isInteger(message.updatedAt.high)
                        )
                    )
                        return 'updatedAt: integer|Long expected'
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (
                        !$util.isInteger(message.deletedAt) &&
                        !(
                            message.deletedAt &&
                            $util.isInteger(message.deletedAt.low) &&
                            $util.isInteger(message.deletedAt.high)
                        )
                    )
                        return 'deletedAt: integer|Long expected'
                if (message.distance != null && message.hasOwnProperty('distance'))
                    if (
                        !$util.isInteger(message.distance) &&
                        !(
                            message.distance &&
                            $util.isInteger(message.distance.low) &&
                            $util.isInteger(message.distance.high)
                        )
                    )
                        return 'distance: integer|Long expected'
                if (message.eta != null && message.hasOwnProperty('eta'))
                    if (
                        !$util.isInteger(message.eta) &&
                        !(message.eta && $util.isInteger(message.eta.low) && $util.isInteger(message.eta.high))
                    )
                        return 'eta: integer|Long expected'
                if (message.seller != null && message.hasOwnProperty('seller'))
                    if (!$util.isString(message.seller)) return 'seller: string expected'
                if (message.address != null && message.hasOwnProperty('address')) {
                    var error = $root.addresses.Address.verify(message.address)
                    if (error) return 'address.' + error
                }
                switch (message.orderType) {
                    default:
                        return 'orderType: enum value expected'
                    case 0:
                    case 1:
                        break
                }
                return null
            }

            /**
             * Creates an Order message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof orders.Order
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {orders.Order} Order
             */
            Order.fromObject = function fromObject(object) {
                if (object instanceof $root.orders.Order) return object
                var message = new $root.orders.Order()
                if (object.id != null) message.id = String(object.id)
                if (object.externalId != null) message.externalId = String(object.externalId)
                if (object.contactId != null) message.contactId = String(object.contactId)
                if (object.startBy != null)
                    if ($util.Long) (message.startBy = $util.Long.fromValue(object.startBy)).unsigned = false
                    else if (typeof object.startBy === 'string') message.startBy = parseInt(object.startBy, 10)
                    else if (typeof object.startBy === 'number') message.startBy = object.startBy
                    else if (typeof object.startBy === 'object')
                        message.startBy = new $util.LongBits(
                            object.startBy.low >>> 0,
                            object.startBy.high >>> 0
                        ).toNumber()
                if (object.deliverByMin != null)
                    if ($util.Long) (message.deliverByMin = $util.Long.fromValue(object.deliverByMin)).unsigned = false
                    else if (typeof object.deliverByMin === 'string')
                        message.deliverByMin = parseInt(object.deliverByMin, 10)
                    else if (typeof object.deliverByMin === 'number') message.deliverByMin = object.deliverByMin
                    else if (typeof object.deliverByMin === 'object')
                        message.deliverByMin = new $util.LongBits(
                            object.deliverByMin.low >>> 0,
                            object.deliverByMin.high >>> 0
                        ).toNumber()
                if (object.deliverByMax != null)
                    if ($util.Long) (message.deliverByMax = $util.Long.fromValue(object.deliverByMax)).unsigned = false
                    else if (typeof object.deliverByMax === 'string')
                        message.deliverByMax = parseInt(object.deliverByMax, 10)
                    else if (typeof object.deliverByMax === 'number') message.deliverByMax = object.deliverByMax
                    else if (typeof object.deliverByMax === 'object')
                        message.deliverByMax = new $util.LongBits(
                            object.deliverByMax.low >>> 0,
                            object.deliverByMax.high >>> 0
                        ).toNumber()
                if (object.note != null) message.note = String(object.note)
                switch (object.status) {
                    case 'NEW':
                    case 0:
                        message.status = 0
                        break
                    case 'BATCHED':
                    case 1:
                        message.status = 1
                        break
                    case 'IN_PROGRESS':
                    case 2:
                        message.status = 2
                        break
                    case 'COMPLETED':
                    case 3:
                        message.status = 3
                        break
                    case 'CANCELED':
                    case 4:
                        message.status = 4
                        break
                    case 'FAILED':
                    case 5:
                        message.status = 5
                        break
                }
                if (object.createdAt != null)
                    if ($util.Long) (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false
                    else if (typeof object.createdAt === 'string') message.createdAt = parseInt(object.createdAt, 10)
                    else if (typeof object.createdAt === 'number') message.createdAt = object.createdAt
                    else if (typeof object.createdAt === 'object')
                        message.createdAt = new $util.LongBits(
                            object.createdAt.low >>> 0,
                            object.createdAt.high >>> 0
                        ).toNumber()
                if (object.updatedAt != null)
                    if ($util.Long) (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false
                    else if (typeof object.updatedAt === 'string') message.updatedAt = parseInt(object.updatedAt, 10)
                    else if (typeof object.updatedAt === 'number') message.updatedAt = object.updatedAt
                    else if (typeof object.updatedAt === 'object')
                        message.updatedAt = new $util.LongBits(
                            object.updatedAt.low >>> 0,
                            object.updatedAt.high >>> 0
                        ).toNumber()
                if (object.deletedAt != null)
                    if ($util.Long) (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false
                    else if (typeof object.deletedAt === 'string') message.deletedAt = parseInt(object.deletedAt, 10)
                    else if (typeof object.deletedAt === 'number') message.deletedAt = object.deletedAt
                    else if (typeof object.deletedAt === 'object')
                        message.deletedAt = new $util.LongBits(
                            object.deletedAt.low >>> 0,
                            object.deletedAt.high >>> 0
                        ).toNumber()
                if (object.distance != null)
                    if ($util.Long) (message.distance = $util.Long.fromValue(object.distance)).unsigned = false
                    else if (typeof object.distance === 'string') message.distance = parseInt(object.distance, 10)
                    else if (typeof object.distance === 'number') message.distance = object.distance
                    else if (typeof object.distance === 'object')
                        message.distance = new $util.LongBits(
                            object.distance.low >>> 0,
                            object.distance.high >>> 0
                        ).toNumber()
                if (object.eta != null)
                    if ($util.Long) (message.eta = $util.Long.fromValue(object.eta)).unsigned = false
                    else if (typeof object.eta === 'string') message.eta = parseInt(object.eta, 10)
                    else if (typeof object.eta === 'number') message.eta = object.eta
                    else if (typeof object.eta === 'object')
                        message.eta = new $util.LongBits(object.eta.low >>> 0, object.eta.high >>> 0).toNumber()
                if (object.seller != null) message.seller = String(object.seller)
                if (object.address != null) {
                    if (typeof object.address !== 'object') throw TypeError('.orders.Order.address: object expected')
                    message.address = $root.addresses.Address.fromObject(object.address)
                }
                switch (object.orderType) {
                    case 'GROCERY':
                    case 0:
                        message.orderType = 0
                        break
                    case 'FASHION':
                    case 1:
                        message.orderType = 1
                        break
                }
                return message
            }

            /**
             * Creates a plain object from an Order message. Also converts values to other types if specified.
             * @function toObject
             * @memberof orders.Order
             * @static
             * @param {orders.Order} message Order
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Order.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.defaults) {
                    object.id = ''
                    object.externalId = ''
                    object.contactId = ''
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.startBy =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.startBy = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.deliverByMin =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.deliverByMin = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.deliverByMax =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.deliverByMax = options.longs === String ? '0' : 0
                    object.note = ''
                    object.status = options.enums === String ? 'NEW' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.createdAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.createdAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.updatedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.updatedAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.deletedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.deletedAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.distance =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.distance = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.eta =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.eta = options.longs === String ? '0' : 0
                    object.seller = ''
                    object.address = null
                    object.orderType = options.enums === String ? 'GROCERY' : 0
                }
                if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
                if (message.externalId != null && message.hasOwnProperty('externalId'))
                    object.externalId = message.externalId
                if (message.contactId != null && message.hasOwnProperty('contactId'))
                    object.contactId = message.contactId
                if (message.startBy != null && message.hasOwnProperty('startBy'))
                    if (typeof message.startBy === 'number')
                        object.startBy = options.longs === String ? String(message.startBy) : message.startBy
                    else
                        object.startBy =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.startBy)
                                : options.longs === Number
                                ? new $util.LongBits(message.startBy.low >>> 0, message.startBy.high >>> 0).toNumber()
                                : message.startBy
                if (message.deliverByMin != null && message.hasOwnProperty('deliverByMin'))
                    if (typeof message.deliverByMin === 'number')
                        object.deliverByMin =
                            options.longs === String ? String(message.deliverByMin) : message.deliverByMin
                    else
                        object.deliverByMin =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.deliverByMin)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.deliverByMin.low >>> 0,
                                      message.deliverByMin.high >>> 0
                                  ).toNumber()
                                : message.deliverByMin
                if (message.deliverByMax != null && message.hasOwnProperty('deliverByMax'))
                    if (typeof message.deliverByMax === 'number')
                        object.deliverByMax =
                            options.longs === String ? String(message.deliverByMax) : message.deliverByMax
                    else
                        object.deliverByMax =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.deliverByMax)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.deliverByMax.low >>> 0,
                                      message.deliverByMax.high >>> 0
                                  ).toNumber()
                                : message.deliverByMax
                if (message.note != null && message.hasOwnProperty('note')) object.note = message.note
                if (message.status != null && message.hasOwnProperty('status'))
                    object.status = options.enums === String ? $root.orders.Status[message.status] : message.status
                if (message.createdAt != null && message.hasOwnProperty('createdAt'))
                    if (typeof message.createdAt === 'number')
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt
                    else
                        object.createdAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.createdAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.createdAt.low >>> 0,
                                      message.createdAt.high >>> 0
                                  ).toNumber()
                                : message.createdAt
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (typeof message.updatedAt === 'number')
                        object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt
                    else
                        object.updatedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.updatedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.updatedAt.low >>> 0,
                                      message.updatedAt.high >>> 0
                                  ).toNumber()
                                : message.updatedAt
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (typeof message.deletedAt === 'number')
                        object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt
                    else
                        object.deletedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.deletedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.deletedAt.low >>> 0,
                                      message.deletedAt.high >>> 0
                                  ).toNumber()
                                : message.deletedAt
                if (message.distance != null && message.hasOwnProperty('distance'))
                    if (typeof message.distance === 'number')
                        object.distance = options.longs === String ? String(message.distance) : message.distance
                    else
                        object.distance =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.distance)
                                : options.longs === Number
                                ? new $util.LongBits(message.distance.low >>> 0, message.distance.high >>> 0).toNumber()
                                : message.distance
                if (message.eta != null && message.hasOwnProperty('eta'))
                    if (typeof message.eta === 'number')
                        object.eta = options.longs === String ? String(message.eta) : message.eta
                    else
                        object.eta =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.eta)
                                : options.longs === Number
                                ? new $util.LongBits(message.eta.low >>> 0, message.eta.high >>> 0).toNumber()
                                : message.eta
                if (message.seller != null && message.hasOwnProperty('seller')) object.seller = message.seller
                if (message.address != null && message.hasOwnProperty('address'))
                    object.address = $root.addresses.Address.toObject(message.address, options)
                if (message.orderType != null && message.hasOwnProperty('orderType'))
                    object.orderType =
                        options.enums === String ? $root.orders.OrderType[message.orderType] : message.orderType
                return object
            }

            /**
             * Converts this Order to JSON.
             * @function toJSON
             * @memberof orders.Order
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Order.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for Order
             * @function getTypeUrl
             * @memberof orders.Order
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Order.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/orders.Order'
            }

            return Order
        })()

        return orders
    })()

    $root.addresses = (function () {
        /**
         * Namespace addresses.
         * @exports addresses
         * @namespace
         */
        var addresses = {}

        addresses.Address = (function () {
            /**
             * Properties of an Address.
             * @memberof addresses
             * @interface IAddress
             * @property {string} id Address id
             * @property {string} name Address name
             * @property {string|null} [externalId] Address externalId
             * @property {number} latitude Address latitude
             * @property {number} longitude Address longitude
             * @property {number} createdAt Address createdAt
             * @property {number|null} [updatedAt] Address updatedAt
             * @property {number|null} [deletedAt] Address deletedAt
             */

            /**
             * Constructs a new Address.
             * @memberof addresses
             * @classdesc Represents an Address.
             * @implements IAddress
             * @constructor
             * @param {addresses.IAddress=} [properties] Properties to set
             */
            function Address(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * Address id.
             * @member {string} id
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.id = ''

            /**
             * Address name.
             * @member {string} name
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.name = ''

            /**
             * Address externalId.
             * @member {string} externalId
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.externalId = ''

            /**
             * Address latitude.
             * @member {number} latitude
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.latitude = 0

            /**
             * Address longitude.
             * @member {number} longitude
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.longitude = 0

            /**
             * Address createdAt.
             * @member {number} createdAt
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.createdAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Address updatedAt.
             * @member {number} updatedAt
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Address deletedAt.
             * @member {number} deletedAt
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Creates a new Address instance using the specified properties.
             * @function create
             * @memberof addresses.Address
             * @static
             * @param {addresses.IAddress=} [properties] Properties to set
             * @returns {addresses.Address} Address instance
             */
            Address.create = function create(properties) {
                return new Address(properties)
            }

            /**
             * Encodes the specified Address message. Does not implicitly {@link addresses.Address.verify|verify} messages.
             * @function encode
             * @memberof addresses.Address
             * @static
             * @param {addresses.Address} message Address message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Address.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
                writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.name)
                if (message.externalId != null && Object.hasOwnProperty.call(message, 'externalId'))
                    writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.externalId)
                writer.uint32(/* id 4, wireType 1 =*/ 33).double(message.latitude)
                writer.uint32(/* id 5, wireType 1 =*/ 41).double(message.longitude)
                writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.createdAt)
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, 'updatedAt'))
                    writer.uint32(/* id 7, wireType 0 =*/ 56).int64(message.updatedAt)
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, 'deletedAt'))
                    writer.uint32(/* id 8, wireType 0 =*/ 64).int64(message.deletedAt)
                return writer
            }

            /**
             * Encodes the specified Address message, length delimited. Does not implicitly {@link addresses.Address.verify|verify} messages.
             * @function encodeDelimited
             * @memberof addresses.Address
             * @static
             * @param {addresses.Address} message Address message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Address.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes an Address message from the specified reader or buffer.
             * @function decode
             * @memberof addresses.Address
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {addresses.Address} Address
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Address.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.addresses.Address()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.id = reader.string()
                            break
                        }
                        case 2: {
                            message.name = reader.string()
                            break
                        }
                        case 3: {
                            message.externalId = reader.string()
                            break
                        }
                        case 4: {
                            message.latitude = reader.double()
                            break
                        }
                        case 5: {
                            message.longitude = reader.double()
                            break
                        }
                        case 6: {
                            message.createdAt = reader.int64()
                            break
                        }
                        case 7: {
                            message.updatedAt = reader.int64()
                            break
                        }
                        case 8: {
                            message.deletedAt = reader.int64()
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('id'))
                    throw $util.ProtocolError("missing required 'id'", { instance: message })
                if (!message.hasOwnProperty('name'))
                    throw $util.ProtocolError("missing required 'name'", { instance: message })
                if (!message.hasOwnProperty('latitude'))
                    throw $util.ProtocolError("missing required 'latitude'", { instance: message })
                if (!message.hasOwnProperty('longitude'))
                    throw $util.ProtocolError("missing required 'longitude'", { instance: message })
                if (!message.hasOwnProperty('createdAt'))
                    throw $util.ProtocolError("missing required 'createdAt'", { instance: message })
                return message
            }

            /**
             * Decodes an Address message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof addresses.Address
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {addresses.Address} Address
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Address.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies an Address message.
             * @function verify
             * @memberof addresses.Address
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Address.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (!$util.isString(message.id)) return 'id: string expected'
                if (!$util.isString(message.name)) return 'name: string expected'
                if (message.externalId != null && message.hasOwnProperty('externalId'))
                    if (!$util.isString(message.externalId)) return 'externalId: string expected'
                if (typeof message.latitude !== 'number') return 'latitude: number expected'
                if (typeof message.longitude !== 'number') return 'longitude: number expected'
                if (
                    !$util.isInteger(message.createdAt) &&
                    !(
                        message.createdAt &&
                        $util.isInteger(message.createdAt.low) &&
                        $util.isInteger(message.createdAt.high)
                    )
                )
                    return 'createdAt: integer|Long expected'
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (
                        !$util.isInteger(message.updatedAt) &&
                        !(
                            message.updatedAt &&
                            $util.isInteger(message.updatedAt.low) &&
                            $util.isInteger(message.updatedAt.high)
                        )
                    )
                        return 'updatedAt: integer|Long expected'
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (
                        !$util.isInteger(message.deletedAt) &&
                        !(
                            message.deletedAt &&
                            $util.isInteger(message.deletedAt.low) &&
                            $util.isInteger(message.deletedAt.high)
                        )
                    )
                        return 'deletedAt: integer|Long expected'
                return null
            }

            /**
             * Creates an Address message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof addresses.Address
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {addresses.Address} Address
             */
            Address.fromObject = function fromObject(object) {
                if (object instanceof $root.addresses.Address) return object
                var message = new $root.addresses.Address()
                if (object.id != null) message.id = String(object.id)
                if (object.name != null) message.name = String(object.name)
                if (object.externalId != null) message.externalId = String(object.externalId)
                if (object.latitude != null) message.latitude = Number(object.latitude)
                if (object.longitude != null) message.longitude = Number(object.longitude)
                if (object.createdAt != null)
                    if ($util.Long) (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false
                    else if (typeof object.createdAt === 'string') message.createdAt = parseInt(object.createdAt, 10)
                    else if (typeof object.createdAt === 'number') message.createdAt = object.createdAt
                    else if (typeof object.createdAt === 'object')
                        message.createdAt = new $util.LongBits(
                            object.createdAt.low >>> 0,
                            object.createdAt.high >>> 0
                        ).toNumber()
                if (object.updatedAt != null)
                    if ($util.Long) (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false
                    else if (typeof object.updatedAt === 'string') message.updatedAt = parseInt(object.updatedAt, 10)
                    else if (typeof object.updatedAt === 'number') message.updatedAt = object.updatedAt
                    else if (typeof object.updatedAt === 'object')
                        message.updatedAt = new $util.LongBits(
                            object.updatedAt.low >>> 0,
                            object.updatedAt.high >>> 0
                        ).toNumber()
                if (object.deletedAt != null)
                    if ($util.Long) (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false
                    else if (typeof object.deletedAt === 'string') message.deletedAt = parseInt(object.deletedAt, 10)
                    else if (typeof object.deletedAt === 'number') message.deletedAt = object.deletedAt
                    else if (typeof object.deletedAt === 'object')
                        message.deletedAt = new $util.LongBits(
                            object.deletedAt.low >>> 0,
                            object.deletedAt.high >>> 0
                        ).toNumber()
                return message
            }

            /**
             * Creates a plain object from an Address message. Also converts values to other types if specified.
             * @function toObject
             * @memberof addresses.Address
             * @static
             * @param {addresses.Address} message Address
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Address.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.defaults) {
                    object.id = ''
                    object.name = ''
                    object.externalId = ''
                    object.latitude = 0
                    object.longitude = 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.createdAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.createdAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.updatedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.updatedAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.deletedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.deletedAt = options.longs === String ? '0' : 0
                }
                if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
                if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
                if (message.externalId != null && message.hasOwnProperty('externalId'))
                    object.externalId = message.externalId
                if (message.latitude != null && message.hasOwnProperty('latitude'))
                    object.latitude =
                        options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude
                if (message.longitude != null && message.hasOwnProperty('longitude'))
                    object.longitude =
                        options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude
                if (message.createdAt != null && message.hasOwnProperty('createdAt'))
                    if (typeof message.createdAt === 'number')
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt
                    else
                        object.createdAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.createdAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.createdAt.low >>> 0,
                                      message.createdAt.high >>> 0
                                  ).toNumber()
                                : message.createdAt
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (typeof message.updatedAt === 'number')
                        object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt
                    else
                        object.updatedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.updatedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.updatedAt.low >>> 0,
                                      message.updatedAt.high >>> 0
                                  ).toNumber()
                                : message.updatedAt
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (typeof message.deletedAt === 'number')
                        object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt
                    else
                        object.deletedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.deletedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.deletedAt.low >>> 0,
                                      message.deletedAt.high >>> 0
                                  ).toNumber()
                                : message.deletedAt
                return object
            }

            /**
             * Converts this Address to JSON.
             * @function toJSON
             * @memberof addresses.Address
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Address.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for Address
             * @function getTypeUrl
             * @memberof addresses.Address
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Address.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/addresses.Address'
            }

            return Address
        })()

        return addresses
    })()

    return $root
})
