import { EditTeamDto } from './dto/EditTeamDto';
import {
  CourierAssignTeamResponse,
  CourierDetachTeamResponse,
} from './response/CourierAssignTeamResponse';
import { EditTeamResponse } from './response/EditTeamResponse';
import { DeleteTeamResponse } from './response/DeleteTeamResponse';
import { CreateTeamResponse } from './response/CreateTeamResponse';
import { CreateTeamDto } from './dto/CreateTeamDto';
import { RequestAPI, JiffyMessageType } from './Requests';
import { ADMIN_API_PREFIX, ADMIN_V1_API_PREFIX, API_URL_2 } from 'config';
import { JiffyMessage, JiffyMessageBody } from 'proto/build/js/message/message';

interface TeamRequestsInterface {
  createTeam(data: CreateTeamDto): Promise<CreateTeamResponse>;

  deleteTeam(id: string): Promise<DeleteTeamResponse>;

  editTeam(id: string, data: EditTeamDto): Promise<EditTeamResponse>;

  attachUsers(teamId: string, data: JiffyMessageType<JiffyMessageBody.Type.DISPATCHER__REQUEST__ADMIN__TEAM_ATTACH_USERS>): Promise<JiffyMessage>;

  detachUser(data: {teamId: string, userId: string}): Promise<CourierDetachTeamResponse>;

  assignUser(data: {teamId: string, userId: string}): Promise<CourierAssignTeamResponse>;
}

export const TeamRequests: TeamRequestsInterface = {
  createTeam: (data) => RequestAPI.post(API_URL_2 + ADMIN_V1_API_PREFIX + 'teams', data),

  deleteTeam: (id) => RequestAPI.delete(ADMIN_V1_API_PREFIX + `teams/${id}`),

  editTeam: (id, data) => RequestAPI.patch(ADMIN_V1_API_PREFIX + `teams/${id}`, data),

  attachUsers: (teamId, data) => RequestAPI.post(ADMIN_API_PREFIX + `v1/team/users/attach/to-team/${teamId}`, data),

  detachUser: (data) => RequestAPI.post(API_URL_2 + ADMIN_V1_API_PREFIX + `users/unassign-team`, data),

  assignUser: (data) =>
    RequestAPI.post(API_URL_2 + ADMIN_V1_API_PREFIX + 'users/assign-team', data),
};
