import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Switch from '../Switch';
import { MOBILE_WIDTH } from 'config';
import { mainStore } from 'stores/MainStore';
import Popup, { PopupMobileHeaderProps } from 'components/Popup';
import Warehouses from './Warehouses/index';
import { settingsStore } from './Warehouses/store';
import packageJSON from '../../../package.json';
import { useTranslation } from 'react-i18next';
import { MenuItem, useMenuItems } from './useMenuItems';
import { generateHtmlClasses } from 'utils/htmlClasses';

const getMobileHeaderProps = (): PopupMobileHeaderProps => {
  switch (settingsStore.activeFrame) {
    case 'team':
      return {
        title: settingsStore.selectedTeam?.team.name || 'Unknown team',
        onBackClick: () => settingsStore.setActiveFrame('home'),
      };
    case 'home':
    default:
      return { title: 'Settings', onBackClick: undefined };
  }
};

const DesktopSidebar = observer(() => {
  const { MENU_ITEMS } = useMenuItems();
  useEffect(() => {
    if (MENU_ITEMS.length > 0) {
      MENU_ITEMS[0].onClick();
    }
  }, [MENU_ITEMS]);
  return (
    <div className="popup-settings__sidebar sidebar">
      {MENU_ITEMS.map((item: MenuItem) => (
        <div
          key={item.name}
          className={generateHtmlClasses('sidebar__item item', {
            _active: mainStore.activeSettingsFrame === item.name,
          })}
          onClick={item.onClick}
        >
          <div className={`item__icon icon icon-${item.icon}`} />
          <div className="item__text">{item.text}</div>
        </div>
      ))}
    </div>
  );
});

const MobileSwitch = observer(() => {
  const { SWITCH_STATES, MENU_ITEMS } = useMenuItems();
  const [currentState, setCurrentState] = useState<string>(SWITCH_STATES[1]);

  const onSwitchItemChange = (state: string) => setCurrentState(state);

  useEffect(() => {
    const name = MENU_ITEMS.find((item) => item.text === currentState)?.name;
    if (name) mainStore.setActiveSettingsFrame(name);
  }, [currentState, MENU_ITEMS]);

  if (settingsStore.activeFrame !== 'home') return null;

  return (
    <div className="popup-settings__switch-wrapper">
      <Switch
        states={SWITCH_STATES}
        onChange={onSwitchItemChange}
        currentState={currentState}
        color={'green'}
      />
    </div>
  );
});

export default observer(() => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH}px)` });

  const handlePopupDismiss = () => {
    mainStore.closePopupSettings();
    mainStore.setIsShowPopupAddTeam(false);
    mainStore.setIsShowPopoverAddTeam(false);
    settingsStore.setActiveFrame('home');
  };

  const onAppVersionClick = async () => {
    await navigator.clipboard.writeText(packageJSON.version);
    mainStore.pushAlert('success', t('successPhrases:applicationVCopied'));
  };

  return (
    <Popup
      className="popup-settings"
      isShow={mainStore.isShowPopupSettings}
      onBackdropDismiss={handlePopupDismiss}
      noCloseIcon
      mobileFullScreen
      mobileHeaderProps={getMobileHeaderProps()}
    >
      {isMobile ? <MobileSwitch /> : <DesktopSidebar />}
      <div className="popup-settings__content content">
        <Warehouses />
      </div>
      <span onClick={onAppVersionClick} className="app-version">
        {t('common:version', { version: packageJSON.version })}
      </span>
    </Popup>
  );
});
