import { AdvancedSearchOrdersDto } from 'api/dto/AdvancedSearchOrdersDto';
import { ASOrder } from 'api/response/AdvancedSearchResponse';
import { SimpleSearchResponse } from 'api/response/SimpleSearchResponse';
import { SplitOrderResponse } from 'api/response/SplitOrderResponse';
import { SplitOrderDto } from './dto/SplitOrderDto';
import { RequestAPI, APIResponse } from './Requests';
import { ADMIN_V1_API_PREFIX, API_URL_2 } from 'config';
import { AxiosResponse } from 'axios';
import { PostReorderJobOrders } from './dto/ReorderJobsOrdersDto';

interface OrderRequestsInterface {
  forceComplete(data: { orderId: string }): Promise<APIResponse<Unnecessary>>;

  forceFail(data: { orderId: string }): Promise<APIResponse<Unnecessary>>;

  assignCourier(data: { jobId: string; courierId: string }): Promise<APIResponse<Unnecessary>>;

  unassignCourier(data: { jobId: string; courierId: string }): Promise<APIResponse<Unnecessary>>;

  split(data: SplitOrderDto): Promise<SplitOrderResponse>;

  search(externalId: string): Promise<APIResponse<SimpleSearchResponse>>;

  advancedSearch(data: AdvancedSearchOrdersDto): Promise<APIResponse<ASOrder[]>>;

  reorderJobOrders(jobId: PostReorderJobOrders): Promise<AxiosResponse<any>>;
}

export const OrderRequests: OrderRequestsInterface = {
  forceComplete: (data) => RequestAPI.post(API_URL_2 + ADMIN_V1_API_PREFIX + 'orders/force-complete', data),

  forceFail: (data) => RequestAPI.post(API_URL_2 + ADMIN_V1_API_PREFIX + 'orders/force-fail', data),

  assignCourier: (data) =>
    RequestAPI.post(API_URL_2 + ADMIN_V1_API_PREFIX + 'jobs/assign-courier', data),

  unassignCourier: (data) => RequestAPI.post(API_URL_2 + ADMIN_V1_API_PREFIX + 'jobs/unassign-courier', data),

  reorderJobOrders: (data) =>
  RequestAPI.post(API_URL_2 + ADMIN_V1_API_PREFIX + 'orders/display-order', data),

  split: (data) => RequestAPI.post(API_URL_2 + ADMIN_V1_API_PREFIX + 'orders/split', data),

  search: (externalId) => {
    const path = externalId ? `orders/search/?externalId=${externalId}` : 'orders/search';
    return RequestAPI.get(API_URL_2 + ADMIN_V1_API_PREFIX + path);
  },

  advancedSearch: (data) =>
    RequestAPI.post(API_URL_2 + ADMIN_V1_API_PREFIX + 'orders/advanced-search', data),
};
