import InputText from 'components/InputText';
import { settingsStore } from 'components/PopupSettings/Warehouses/store';
import Select, { SelectItem } from 'components/Select';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dataStore } from 'stores/DataStore';
import { mainStore } from 'stores/MainStore';
import { generateHtmlClasses } from 'utils/htmlClasses';

interface AddTeamFormProps {
  className?: string;
  onCancelClick: () => void;
}

const defaultWarehouseListValue =
  dataStore.warehouseList.length <= 1
    ? { value: '', text: '', rawText: '' }
    : {
        value: dataStore.warehouseList[0].value,
        text: dataStore.warehouseList[0].text,
        rawText: dataStore.warehouseList[0].text,
      };

export default observer(({ className, onCancelClick }: AddTeamFormProps) => {
  const { t } = useTranslation();
  const [nameVal, setNameVal] = useState<string>('');
  const [selectedWarehouse, setSelectedWarehouse] = useState<SelectItem>({
    value: '',
    text: '',
    rawText: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const isValid = (): boolean => !(!selectedWarehouse.value || !nameVal.trim());
  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => setNameVal(e.currentTarget.value);
  const handleChangeWarehouse = (val: SelectItem) => setSelectedWarehouse(val);
  const handleResetAndDismiss = () => {
    if (!settingsStore.selectedWarehouse) {
      resetForm();
    } else {
      setNameVal('');
    }
    onCancelClick();
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const warehouse =
      dataStore.warehouses &&
      dataStore.warehouses.find((warehouse) => warehouse.id === selectedWarehouse.value);

    if (!warehouse || !isValid()) return;

    setIsLoading(true);
    dataStore
      .createTeam({
        name: nameVal,
        externalId: warehouse.externalId.toLowerCase(),
        warehouseIds: [warehouse.id],
      })
      .then(() => {
        resetForm();
        if (settingsStore.selectedWarehouse) {
          settingsStore.fetchWarehouseWithTeams(warehouse.id);
          mainStore.setIsShowPopupSettings(true);
        }
      })
      .catch(() => void 0)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetForm = () => {
    setNameVal('');
    setSelectedWarehouse(defaultWarehouseListValue);
  };

  useEffect(() => {
    if (!mainStore.isShowPopoverAddTeam || settingsStore.selectedWarehouse) return;
    setSelectedWarehouse(defaultWarehouseListValue);
    //eslint-disable-next-line
  }, [mainStore.isShowPopoverAddTeam]);

  useEffect(() => {
    if (!mainStore.isShowPopupAddTeam || settingsStore.selectedWarehouse) return;
    setSelectedWarehouse(defaultWarehouseListValue);
    //eslint-disable-next-line
  }, [mainStore.isShowPopupAddTeam]);

  useEffect(() => {
    const warehouseItem = dataStore.warehouseList.find(
      (item) => item.value === settingsStore.selectedWarehouse?.warehouse.id,
    );
    if (warehouseItem) setSelectedWarehouse(warehouseItem);
    //eslint-disable-next-line
  }, [settingsStore.selectedWarehouse]);

  return (
    <form className={generateHtmlClasses('add-team-form', className)} onSubmit={handleSubmit}>
      <InputText
        className="add-team-form__input"
        placeholder={t('forms:addTeam:namePlaceholder')}
        value={nameVal}
        onChange={handleChangeName}
        autoFocus
      />
      <Select
        className="add-team-form__input"
        initialValue={t('forms:addTeam:addteamInitialValue')}
        value={selectedWarehouse}
        data={dataStore.warehouseList}
        onChange={handleChangeWarehouse}
      />
      <div className="add-team-form__buttons">
        <div className="button _secondary" onClick={handleResetAndDismiss}>
          {t('common:cancel')}
        </div>
        <button className="button _primary" disabled={!isValid() || isLoading}>
          {isLoading ? <span className="spinner" /> : t('forms:addTeam:apply')}
        </button>
      </div>
    </form>
  );
});
