import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { CourierLabel } from './CourierLabel';
import { searchStore } from '../Search/store';
import { mainStore } from '../../stores/MainStore';
import { CourierStatus, VehicleType } from '../../api/types/Courier';
import { generateHtmlClasses } from 'utils/htmlClasses';

type CourierPinProps = {
  lat: number;
  lng: number;
  id: string;
  status: CourierStatus;
  vehicleType: VehicleType;
  userName: string;
  teamId: string;
  isMobile?: boolean;
};

export const CourierPin = observer(
  ({ id, status, vehicleType, userName, isMobile }: CourierPinProps) => {
    const [statusName, setStatusName] = useState('');
    const [vehicle, setVehicle] = useState('');
    const [isActive, setIsActive] = useState(false);
    const handleCourierIconClick = () => {
      searchStore.resetSearch();
      mainStore.setSelectedCourier({ id, source: 'map' });
      if (!isMobile) {
        mainStore.setActiveDynamicFrame(null);
      } else {
        mainStore.setActiveMobileScreen('teams');
      }
    };

    useEffect(() => {
      setStatusName(CourierStatus[status]?.toLowerCase() || '');
    }, [status]);

    useEffect(() => {
      setVehicle(VehicleType[vehicleType]?.toLowerCase() || '');
    }, [vehicleType]);

    useEffect(() => {
      setIsActive(mainStore.selectedCourier.id === id);
      //eslint-disable-next-line
    }, [mainStore.selectedCourier.id]);

    return (
      <div className={generateHtmlClasses('map-pin _courier', { _active: isActive })}>
        <div
          className={`icon icon-${vehicle} courier-icon _${statusName}`}
          onClick={handleCourierIconClick}
        />
        <CourierLabel id={id} userName={userName} status={status} vehicleType={vehicleType} />
      </div>
    );
  },
);
