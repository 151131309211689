import { CompanyConfig, CompanyName } from './interface';

//FIXME: move to .env
export const defaultCompanyId = '1eb53a13-5f9e-4deb-92d7-090a4b53fd21';

export const COMPANY: Record<string, CompanyConfig> = {
  'bb111efb-a9d8-465d-b9ca-8d255f1d8e9d': {
    id: 'bb111efb-a9d8-465d-b9ca-8d255f1d8e9d',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.Jiffy,
    defaultPhoneCode: '+44',
    phonePlaceholder: '+44-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
  },
  '0e715914-1b33-4387-92db-fe3103f865bb': {
    id: '0e715914-1b33-4387-92db-fe3103f865bb',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.Baqal,

    defaultPhoneCode: '+44',
    phonePlaceholder: '+44-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
  },
  '49589d9f-753a-421f-bd36-cea6202c4ba7': {
    id: '49589d9f-753a-421f-bd36-cea6202c4ba7',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.Swifft,
    defaultPhoneCode: '+44',
    phonePlaceholder: '+44-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
  },
  'd05f30ba-ceb5-4633-a3b6-aa0f9463954f': {
    id: 'd05f30ba-ceb5-4633-a3b6-aa0f9463954f',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.Localee,
    defaultPhoneCode: '+1',
    phonePlaceholder: '+1-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
  },
  [defaultCompanyId]: {
    id: defaultCompanyId,
    lang: 'en',
    langs: ['en'],
    name: CompanyName.CityDrinks,
    defaultPhoneCode: '+971',
    phonePlaceholder: '+971-(__)-______',
    hostnames: ['*'],
    hostname: 'frontend-delivery.citydrinks.com',
  },
  '844a9e71-ed42-4ea9-a2c2-5a58b1db0a13': {
    id: '844a9e71-ed42-4ea9-a2c2-5a58b1db0a13',
    lang: 'ar',
    langs: ['en', 'ar'],
    name: CompanyName.CircleK,

    defaultPhoneCode: '+20',
    phonePlaceholder: '+20-(__)-______',

    hostnames: ['*'],
    hostname: 'frontend-delivery.jiffygrocery.co.uk',
  },
  'a1afcf22-db07-4c78-8e40-4ada483e1805': {
    id: 'a1afcf22-db07-4c78-8e40-4ada483e1805',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.Vilo,
    defaultPhoneCode: '+62',
    phonePlaceholder: '+62-(__)-______',

    hostnames: ['*'],
    hostname: 'hostname',
  },
  'fca60c82-0022-49e4-aee6-5ec196acd111': {
    id: 'fca60c82-0022-49e4-aee6-5ec196acd111',
    lang: 'ar',
    langs: ['en', 'ar'],
    name: CompanyName.OurKids,
    defaultPhoneCode: '+20',
    phonePlaceholder: '+20-(__)-______',

    hostnames: ['*'],
    hostname: 'hostname',
  },

  avantgardeFIXME: {
    id: 'avantgardeFIXME',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.AvantGarde,
    defaultPhoneCode: '+44',
    phonePlaceholder: '+44-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
  },
  rkbFIXME: {
    id: 'rkbFIXME',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.RKBFruit,
    defaultPhoneCode: '+44',
    phonePlaceholder: '+44-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
  },
  okieFIXME: {
    id: 'okieFIXME',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.OkieElectronics,
    defaultPhoneCode: '+44',
    phonePlaceholder: '+44-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
  },
  indimarketFIXME: {
    id: 'indimarketFIXME',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.IndiMarket,
    defaultPhoneCode: '+44',
    phonePlaceholder: '+44-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
  },
  oskuhusFIXME: {
    id: 'oskuhusFIXME',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.Oskuhus,
    defaultPhoneCode: '+44',
    phonePlaceholder: '+44-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
  },
  helenDoronFIXME: {
    id: 'helenDoronFIXME',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.HelenDoron,
    defaultPhoneCode: '+44',
    phonePlaceholder: '+44-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
  },
};
