import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { authStore } from 'stores/AuthStore';
import { mainStore } from 'stores/MainStore';
import LanguageSelector from './LanguageSelector/LanguageSelector';
import { generateHtmlClasses } from 'utils/htmlClasses';

type MobileSidebarProps = {
  sidebarRef: React.RefObject<HTMLDivElement>;
};

interface MobileSidebarItem {
  id: string;
  icon: string;
  text: string;
  onClick: () => void;
  separate?: boolean;
  bottom?: boolean;
}

export default observer(({ sidebarRef }: MobileSidebarProps) => {
  const { t } = useTranslation();
  const handleMobileSidebarClose = () => mainStore.setIsMobileSidebarOpened(false);

  const handleItemClick = (itemOnClickFn: () => void) => () => {
    handleMobileSidebarClose();
    itemOnClickFn();
  };

  const MOBILE_SIDEBAR_ITEMS: MobileSidebarItem[] = [
    {
      id: 'SETTINGS',
      icon: 'settings',
      text: t('sidemenu:settings'),
      onClick: () => mainStore.setIsShowPopupSettings(true),
    },
    {
      id: 'ADD-COURIER',
      icon: 'add',
      text: t('sidemenu:addNewCourier'),
      onClick: () => mainStore.setIsShowPopupAddCourier(true),
    },
    {
      id: 'FILTER',
      icon: 'filter',
      text: t('common:orderFilter'),
      onClick: () => mainStore.setIsShowPopupFilter(true),
    },
    {
      id: 'LOGOUT',
      icon: 'exit',
      text: t('common:logout'),
      onClick: () => authStore.logout(),
      separate: true,
      bottom: true,
    },
  ];

  return (
    <div
      className={generateHtmlClasses('mobile-sidebar', {
        _opened: mainStore.isMobileSidebarOpened,
      })}
      ref={sidebarRef}
    >
      <div className="mobile-sidebar__header">
        <div className="mobile-sidebar__title-container">
          <div className="mobile-sidebar__title">{t('common:menu')}</div>
          {authStore.showLangSelector && <LanguageSelector />}
        </div>
        <div
          className="icon icon-close mobile-sidebar__close-button"
          onClick={handleMobileSidebarClose}
        />
      </div>
      <div className="mobile-sidebar__menu-items">
        {MOBILE_SIDEBAR_ITEMS.map((item) => (
          <div
            key={item.id}
            className={generateHtmlClasses('mobile-sidebar__menu-item menu-item', {
              _separate: item.separate,
              _bottom: item.bottom,
            })}
            onClick={handleItemClick(item.onClick)}
          >
            <div className="menu-item__content">
              <div className={`menu-item__icon icon icon-${item.icon}`} />
              <div className="menu-item__text">{item.text}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});
