import { observer } from 'mobx-react-lite';
import { settingsStore } from './store';
import { useTranslation } from 'react-i18next';

type TeamCardProps = {
  teamId: string;
};

export default observer(({ teamId }: TeamCardProps) => {
  const { t } = useTranslation();
  const teamWithUsers = settingsStore.selectedWarehouseTeams.find(wTeam => wTeam.team.id === teamId);
  if (!teamWithUsers) return null;

  const offlineUsersLength = settingsStore.calculateOfflineCouriers(teamWithUsers.users);
  const allUsersLength = teamWithUsers.users.length;

  const handleTeamClick = async () => settingsStore.fetchTeamWithUsers(teamWithUsers.team);

  return (
    <div className='team-cards__team-card card' onClick={handleTeamClick}>
      <div className='team-card__name'>{teamWithUsers.team.name}</div>
      <div className='team-card__duty'>{allUsersLength - offlineUsersLength}/{allUsersLength} {t('phrases:onDuty')}</div>
      <div className='team-card__icon icon icon-chevron-up' />
    </div>
  );
});
