import Item from './Item';
import { searchStore } from 'components/Search/store';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export default observer(() => {
  const { t } = useTranslation();
  const orders = searchStore.advancedSearchResponse;
  const count = !orders?.length
    ? null
    : orders.length > 1
    ? t('phrases:ordersFound', { num: orders.length })
    : t('phrases:orderFound', { num: orders.length });

  return (
    <div className="result-list">
      <div className="result-list__title">
        <span className="_secondary">{count}</span>
      </div>
      {!!orders?.length && (
        <div className="result-list__list">
          {orders.map((order) => {
            return <Item key={order.id} order={order} />;
          })}
        </div>
      )}
    </div>
  );
});
