import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PopupAdvanced from './PopupAdvanced';
import { searchStore } from 'components/Search/store';
import Dropdown from '../../components/Search/Dropdown';
import { mainStore } from 'stores/MainStore';
import { SEARCH_URL_PARAM_NAME } from 'config';
import { dataStore } from 'stores/DataStore';
import InputText from '../../components/InputText';
import { useTranslation } from 'react-i18next';

export default observer(() => {
  const { t } = useTranslation();
  // for filtering only once by search param
  const [filteredOnStart, setFilteredOnStart] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.trim().toUpperCase();
    searchStore.setSearchValue(value);

    if (value.length) {
      setSearchParams({ [SEARCH_URL_PARAM_NAME]: value });
    } else {
      searchParams.delete(SEARCH_URL_PARAM_NAME);
      setSearchParams(searchParams);
    }

    if (value.length > 1) {
      searchStore.filterOrders();
      searchStore.searchOrders(searchStore.searchValue);
    } else {
      searchStore.setSearchResponse(null);
    }
  };

  useEffect(() => {
    const valueFromParams = searchParams.get(SEARCH_URL_PARAM_NAME);
    if (!valueFromParams) {
      searchStore.setSearchValue('');
      searchStore.setFilteredOrders([]);
      setFilteredOnStart(true);
    } else if (valueFromParams && dataStore.getJobListResponse && !filteredOnStart) {
      const value = valueFromParams.toUpperCase();
      searchStore.setSearchValue(value);
      setFilteredOnStart(true);
      if (value.length < 2) return;
      setTimeout(() => {
        searchStore.filterOrders();
        searchStore.searchOrders(searchStore.searchValue);
      }, 0);
    }
    //eslint-disable-next-line
  }, [searchParams, dataStore.getJobListResponse]);

  useEffect(() => {
    if (!searchStore.searchValue.length && filteredOnStart) {
      searchParams.delete(SEARCH_URL_PARAM_NAME);
      setSearchParams(searchParams);
    }
    //eslint-disable-next-line
  }, [mainStore.selectedOrder]);

  useEffect(() => {
    if (searchStore.searchValue.length < 2) {
      searchStore.setSearchResponse(null);
      searchStore.setFilteredOrders([]);
    }
    //eslint-disable-next-line
  }, [searchStore.searchValue]);

  return (
    <div className='header-search'>
      <div className='header-search__form form'>
        <InputText
          className='form__input'
          placeholder={t('inputs:search')}
          iconButtonName='magnifier'
          value={searchStore.searchValue}
          onChange={handleChangeSearch}
        />
      </div>
      <Dropdown />
      <PopupAdvanced/>
    </div>
  );
});
