import { makeAutoObservable } from 'mobx';
import { HOUR_IN_DAY, MIN_IN_HOUR, MONTH_NAMES, MS_IN_SEC, SEC_IN_MIN } from 'config';
import { dataStore } from './DataStore';

export type DayTime = { hours?: number, minutes?: number, seconds?: number };
export type FullTime = { days: number, hours: number, minutes: number };

class DateTimeStore {
  constructor() {
    makeAutoObservable(this);
  }

  // Getters
  public get today(): Date {
    return new Date(Date.now());
  }

  public dateWithTimezone(date: Date | number) {
    const timezone = dataStore.getCurrentTeamTimezone();

    return new Date(new Date(date).toLocaleString("en-US", {timeZone: timezone}));
  }

  // Example: 'Dec 22, 2021'
  public getFormattedFullDate(value: number): string | null {
    if (value === 0) return null;
    const date = this.dateWithTimezone(value);
    const month = MONTH_NAMES.SHORT[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  }

  // Example: 'Jan 2022'
  public getFormattedMonthYear(value: number): string | null {
    if (value === 0) return null;
    const date = this.dateWithTimezone(value);
    const month = MONTH_NAMES.SHORT[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${year}`;
  }

  // Example: 'Dec 22'
  public getFormattedShortDate(value: number): string | null {
    if (value === 0) return null;
    const date = this.dateWithTimezone(value);
    const month = MONTH_NAMES.SHORT[date.getMonth()];
    const day = date.getDate();
    return `${month} ${day}`;
  }

  // Example: '23:59:59'
  public getFormattedDayTime(value: number, includeSec?: boolean): string | null {
    if (value === 0) return null;
    const date = this.dateWithTimezone(value);
    const hours = `${date.getHours() < 10 ? '0' : ''}${date.getHours()}`;
    const minutes = `${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
    const seconds = `${date.getSeconds() < 10 ? '0' : ''}${date.getSeconds()}`;
    return `${hours}:${minutes}${includeSec ? `:${seconds}` : ''}`;
  }

  // Example: '23:59:59'
  public getFormattedDayTimeV2(value: DayTime, includeSec?: boolean): string {
    const hours = value.hours !== undefined ? `${value.hours < 10 ? '0' : ''}${value.hours}` : '__';
    const minutes = value.minutes !== undefined ? `${value.minutes < 10 ? '0' : ''}${value.minutes}` : '__';
    const seconds = value.seconds !== undefined ? `${value.seconds < 10 ? '0' : ''}${value.seconds}` : '__';
    return `${hours}:${minutes}${includeSec ? `:${seconds}` : ''}`;
  }

  public getDateDiffInMinutes(time1: number, time2: number): number {
    const diff = new Date(time1 * 1000).getTime() - new Date(time2 * 1000).getTime();
    const diffMin = diff / (MS_IN_SEC * SEC_IN_MIN);
    return Math.round(diffMin);
  }

  // Example: '3953 minutes' -> { days: 2, hours: 17, mins: 53 }
  public convertMinutesToFullTime(value: number): FullTime {
    let minutes = value;
    let hours = 0;
    let days = 0;

    if (minutes > MIN_IN_HOUR) {
      hours = Math.floor(minutes / MIN_IN_HOUR);
      minutes -= hours * MIN_IN_HOUR;
    }

    if (hours > HOUR_IN_DAY) {
      days = Math.floor(hours / HOUR_IN_DAY);
      hours -= days * HOUR_IN_DAY;
    }

    return { days, hours, minutes };
  }

  public fullTimeToString(fullTime: FullTime): string {
    const days = fullTime.days === 1 ? '1 day, ' :
                 fullTime.days !== 0 ? fullTime.days + ' days, ' : '';
    const hours = fullTime.hours === 1 ? '1 hour, ' :
                  fullTime.hours !== 0 ? fullTime.hours + ' hours, ' : '';
    let minutes = fullTime.minutes === 1 ? '1 min' : fullTime.minutes + ' min';
    if ((fullTime.days || fullTime.hours) && !fullTime.minutes) {
      minutes = '';
    }

    const result = (days  + hours + minutes).trim();
    if (result[result.length - 1] === ',') {
      return result.slice(0, -1);
    } else {
      return result;
    }
  }

  public getApproximateTime(min: number): string {
    const fullTime = this.convertMinutesToFullTime(min);

    if (!fullTime.days && !fullTime.hours) {
      return this.fullTimeToString(fullTime);
    }

    if (fullTime.minutes > MIN_IN_HOUR / 2) fullTime.hours = fullTime.hours + 1;
    fullTime.minutes = 0;

    if (fullTime.hours > HOUR_IN_DAY / 2) fullTime.days = fullTime.days + 1;
    fullTime.hours = 0;

    return this.fullTimeToString(fullTime);
  }

  public getTimeSeconds(date: Date) {
    return Math.floor(date.getTime() / 1000);
  }
}

export const dateTimeStore = new DateTimeStore();
