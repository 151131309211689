import { ASOrder } from 'api/response/AdvancedSearchResponse';
import { getOrderStatusNameByKey } from 'common/order-status';
import { searchStore } from 'components/Search/store';
import TextWithHighlight from 'components/TextWithHighlight';
import { MS_IN_SEC } from 'config';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { dateTimeStore } from 'stores/DateTimeStore';
import { generateHtmlClasses } from 'utils/htmlClasses';

interface ItemProps {
  order: ASOrder;
}

export default observer(({ order }: ItemProps) => {
  const { t } = useTranslation();
  const etaMin = dateTimeStore.getFormattedDayTime(order.deliverByMin * MS_IN_SEC) || '...';
  const etaMax = dateTimeStore.getFormattedDayTime(order.deliverByMax * MS_IN_SEC) || '...';

  const createdAt =
    order.createdAt > 0
      ? [
          dateTimeStore.getFormattedFullDate(order.createdAt * MS_IN_SEC),
          dateTimeStore.getFormattedDayTime(order.createdAt * MS_IN_SEC),
        ]
      : null;

  const dropOffTask = order.tasks?.find((task) => task.kind === 'DROP_OFF');
  const deliveredAt = dropOffTask?.completedAt
    ? [
        dateTimeStore.getFormattedFullDate(dropOffTask.completedAt * MS_IN_SEC),
        dateTimeStore.getFormattedDayTime(dropOffTask.completedAt * MS_IN_SEC),
      ]
    : null;

  const deliveryTimeMin =
    dropOffTask?.startedAt && dropOffTask?.completedAt
      ? dateTimeStore.getDateDiffInMinutes(dropOffTask.completedAt, dropOffTask.startedAt)
      : null;
  const deliveryTime =
    deliveryTimeMin !== null ? dateTimeStore.convertMinutesToFullTime(deliveryTimeMin) : null;

  const warehouse = order.warehouse.name;
  const barcode = order.warehouse.barcode;
  const courier = order.job.courier?.user?.name;

  return (
    <div className="result-list__item">
      <div className="result-list__rows">
        <div className="result-list__row">
          <div className="row__title">{t('order:creation')}</div>
          <div className={generateHtmlClasses('row__value _creation', { _empty: !createdAt })}>
            {createdAt && (
              <>
                {createdAt[0]} {createdAt[1]}
              </>
            )}
          </div>
        </div>
        <div className="result-list__row">
          <div className="row__title">{t('order:delivery')}</div>
          <div className={generateHtmlClasses('row__value _delivery', { _empty: !deliveredAt })}>
            {deliveredAt && (
              <>
                {deliveredAt[0]} {deliveredAt[1]}
              </>
            )}
          </div>
        </div>
        <div className="result-list__row">
          <div className="row__title">{t('order:deliveryTime')}</div>
          <div
            className={generateHtmlClasses('row__value _delivery-time', { _empty: !deliveryTime })}
          >
            {deliveryTime && dateTimeStore.fullTimeToString(deliveryTime)}
          </div>
        </div>
        <div className="result-list__row">
          <div className="row__title">{t('order:orderId')}</div>
          <div className="row__value _order-id">
            <TextWithHighlight
              text={order.externalId}
              highlightPart={searchStore.advancedSearchValue}
              takeOnlyFirst
              highlightColor={'green'}
            />
            <span className="_secondary">{t('order:0jobs')}</span>
          </div>
        </div>
        <div className="result-list__row">
          <div className="row__title">{t('order:warehouse')}</div>
          <div className={generateHtmlClasses('row__value _warehouse', { _empty: !warehouse })}>
            {warehouse || ''}
            <span className="_secondary">{barcode}</span>
          </div>
        </div>
        <div className="result-list__row">
          <div className="row__title">{t('common:courier')}</div>
          <div className={generateHtmlClasses('row__value _courier', { _empty: !courier })}>
            {courier || ''}
          </div>
        </div>
        <div className="result-list__row">
          <div className="row__title">{t('order:status')}</div>
          <div className="row__value _status">{getOrderStatusNameByKey(order.status as any)}</div>
        </div>
        <div className="result-list__row">
          <div className="row__title">{t('common:eta')}</div>
          <div className="row__value _eta">
            {t('common:eta')} {etaMin}/{etaMax}
          </div>
        </div>
        <div className="result-list__row">
          <div className="row__title">{t('order:sum')}</div>
          <div className="row__value _empty" />
        </div>
      </div>
    </div>
  );
});
