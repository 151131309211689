import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Scrollbars } from 'react-custom-scrollbars';
import { mainStore } from 'stores/MainStore';
import { Droppable } from '@hello-pangea/dnd';
import { dataStore } from 'stores/DataStore';
import Job from 'components/Job';
import { Slots } from './Slots';
import { ExpandableHeader } from 'components/ExpandableHeader/ExpandableHeader';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useTranslation } from 'react-i18next';
import { generateHtmlClasses } from 'utils/htmlClasses';

export default observer(() => {
  const { t } = useTranslation();
  const [isExpandedJobs, setIsExpandedJobs] = useState(true);
  const { height } = useWindowDimensions();

  const handeExpand = useCallback(() => {
    setIsExpandedJobs(!isExpandedJobs);
  }, [isExpandedJobs]);

  const handleHideFrame = () => {
    mainStore.setActiveDynamicFrame(null);
    mainStore.setSelectedOrder(null);
  };
  const isJobDropDisabled = (): boolean => {
    return mainStore.dragItem.group === 'jobUnassigned' && mainStore.dragItem.type === 'job';
  };

  const isOrderDropDisabled = (): boolean => {
    return mainStore.dragItem.group === 'orderUnassigned' && mainStore.dragItem.type === 'order';
  };

  return process.env.REACT_APP_SLOTS ? (
    <div
      className={generateHtmlClasses('frame-dynamic-unassigned', {
        _disabled: mainStore.activeDynamicFrame !== 'unassigned',
      })}
    >
      <div className="frame-dynamic-header">
        <div className="frame-dynamic-title">
          <div className="frame-dynamic-title__name">{t('courierState:unassigned')}</div>
          <div className="frame-dynamic-title__close icon icon-close" onClick={handleHideFrame} />
        </div>
        <div className="frame-dynamic-info">
          <div className="icon icon-hand-drag" />
          {t('phrases:dragTaskToCourier')}
        </div>
      </div>
      <div className="frame-dynamic-body">
        <Scrollbars>
          <ExpandableHeader
            containerClass="frame-dynamic-header"
            isExpanded={isExpandedJobs}
            title={t('phrases:currentOrders')}
            onClick={handeExpand}
          />
          {isExpandedJobs && (
            <>
              <Scrollbars
                className="frame-dynamic-scroll"
                autoHeight
                autoHeightMin={200}
                autoHeightMax={height * 0.7}
              >
                {dataStore.unassignedJobs && dataStore.unassignedJobs.length > 0 ? (
                  <Droppable
                    droppableId="jobUnassigned"
                    key="jobUnassigned1"
                    type="job"
                    isDropDisabled={isJobDropDisabled()}
                  >
                    {(provided) => (
                      <div className="courier__body" ref={provided.innerRef}>
                        {dataStore.unassignedJobs.map(({ job, orders }, index) => (
                          <Job
                            jobId={job.id}
                            orders={orders || []}
                            handleToggleExpanding={() => void 0}
                            isExpanded={true}
                            index={index}
                            key={job.id}
                            source={'unassigned'}
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ) : (
                  <Droppable
                    droppableId="jobUnassigned"
                    key="jobUnassigned2"
                    type="job"
                    isDropDisabled={isJobDropDisabled()}
                  >
                    {(provided, snapshot) => (
                      <div
                        className={generateHtmlClasses('drop-here', {
                          _hover: snapshot.isDraggingOver,
                        })}
                        data-text={t('phrases:dragToUnassign')}
                        ref={provided.innerRef}
                      >
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                )}
              </Scrollbars>
              <Droppable
                droppableId="orderUnassigned"
                type="order"
                isDropDisabled={isOrderDropDisabled()}
              >
                {(provided, snapshot) => (
                  <div
                    className={generateHtmlClasses('drop-here', {
                      _hover: snapshot.isDraggingOver,
                    })}
                    data-text={t('phrases:dropToSplit')}
                    ref={provided.innerRef}
                  >
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </>
          )}
          <Slots />
        </Scrollbars>
      </div>
    </div>
  ) : (
    <div
      className={generateHtmlClasses('frame-dynamic-unassigned', {
        _disabled: mainStore.activeDynamicFrame !== 'unassigned',
      })}
    >
      <div className="frame-dynamic-header">
        <div className="frame-dynamic-title">
          <div className="frame-dynamic-title__name">{t('courierState:unassigned')}</div>
          <div className="frame-dynamic-title__close icon icon-close" onClick={handleHideFrame} />
        </div>
        <div className="frame-dynamic-info">
          <div className="icon icon-hand-drag" />
          {t('phrases:dragTaskToCourier')}
        </div>
      </div>
      <div className="frame-dynamic-body">
        <Scrollbars>
          {dataStore.unassignedJobs && dataStore.unassignedJobs.length > 0 ? (
            <Droppable
              droppableId="jobUnassigned"
              type="job"
              isDropDisabled={isJobDropDisabled()}
              key="jobUnassigned3"
            >
              {(provided) => (
                <div className="courier__body" ref={provided.innerRef}>
                  {dataStore.unassignedJobs.map(({ job, orders }, index) => (
                    <Job
                      jobId={job.id}
                      orders={orders || []}
                      handleToggleExpanding={() => void 0}
                      isExpanded={true}
                      index={index}
                      key={job.id}
                      source={'unassigned'}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ) : (
            <Droppable
              droppableId="jobUnassigned"
              type="job"
              isDropDisabled={isJobDropDisabled()}
              key="jobUnassigned4"
            >
              {(provided, snapshot) => (
                <div
                  className={generateHtmlClasses('drop-here', {
                    _hover: snapshot.isDraggingOver,
                  })}
                  data-text={t('phrases:dragToUnassign')}
                  ref={provided.innerRef}
                >
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
          <Droppable
            droppableId="orderUnassigned"
            type="order"
            isDropDisabled={isOrderDropDisabled()}
          >
            {(provided, snapshot) => (
              <div
                className={generateHtmlClasses('drop-here', {
                  _hover: snapshot.isDraggingOver,
                })}
                data-text={t('phrases:dropToSplit')}
                ref={provided.innerRef}
              >
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Scrollbars>
      </div>
    </div>
  );
});
