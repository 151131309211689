import { ASOrder } from 'api/response/AdvancedSearchResponse';
import { getOrderStatusNameByKey } from 'common/order-status';
import { searchStore } from 'components/Search/store';
import { MS_IN_SEC } from 'config';
import { makeAutoObservable } from 'mobx';
import { dateTimeStore } from 'stores/DateTimeStore';

export interface RawResultTableRow {
  id: string;
  createdAt: number;
  deliveredAt?: number;
  deliveryTime: number | null;
  orderExternalId: string;
  posInJob: number;
  totalCountInJob: number;
  warehouseName: string;
  warehouseBarcode: string;
  courierName: string;
  status: number;
  etaMax: number;
  etaMin: number;
  sum: number;
}

export interface ResultTableRow {
  id: string;
  createdAt: (string | null)[] | null;
  deliveredAt: (string | null)[] | null;
  deliveryTime: string | null;
  order: {
    id: string;
    posInJob: number;
    totalCountInJob: number;
  };
  warehouse: {
    name: string;
    barcode: string;
  };
  courierName: string;
  status: string;
  eta: {
    max: string;
    min: string;
  };
  sum: number | null;
}

type SortingState = 'asc' | 'desc';

class AdvancedSearchResultStore {
  currentSortingField: keyof RawResultTableRow | null = null;
  currentSortingState: SortingState = 'asc';

  constructor() {
    makeAutoObservable(this);
  }

  toggleCurrentSortingState() {
    if (this.currentSortingState === 'asc') {
      this.setCurrentSortingState('desc');
    } else if (this.currentSortingState === 'desc') {
      this.setCurrentSortingField(null);
    } else {
      this.setCurrentSortingState('asc');
    }
  }

  setCurrentSortingState(val: SortingState) {
    this.currentSortingState = val;
  }

  setCurrentSortingField(val: keyof RawResultTableRow | null) {
    this.currentSortingField = val;
  }

  getRawResultTableRow(order: ASOrder): RawResultTableRow {
    const dropOffTask = order.tasks?.find(task => task.kind === 'DROP_OFF');
    const deliveryTimeMin = dropOffTask?.startedAt && dropOffTask?.completedAt
      ? dateTimeStore.getDateDiffInMinutes(dropOffTask.completedAt, dropOffTask.startedAt)
      : null;

    const warehouse = order.warehouse.name;
    const barcode = order.warehouse.barcode;
    const courier = order.job.courier?.user?.name;

    return {
      id: order.id,
      createdAt: order.createdAt,
      deliveredAt: dropOffTask?.completedAt,
      deliveryTime: deliveryTimeMin,
      orderExternalId: order.externalId,
      posInJob: 0,
      totalCountInJob: 0,
      warehouseName: warehouse || '',
      warehouseBarcode: barcode || '',
      courierName: courier || '',
      status: order.status as unknown as number,
      etaMax: order.deliverByMax,
      etaMin: order.deliverByMin,
      sum: 0,
    };
  }

  getResultTableByRaw(data: RawResultTableRow): ResultTableRow {
    const etaMin = dateTimeStore.getFormattedDayTime(data.etaMin * MS_IN_SEC) || '...';
    const etaMax = dateTimeStore.getFormattedDayTime(data.etaMax * MS_IN_SEC) || '...';

    const createdAt = data.createdAt > 0 ? [
      dateTimeStore.getFormattedFullDate(data.createdAt * MS_IN_SEC),
      dateTimeStore.getFormattedDayTime(data.createdAt * MS_IN_SEC),
    ] : null;

    const deliveredAt = data.deliveredAt ? [
      dateTimeStore.getFormattedFullDate(data.deliveredAt * MS_IN_SEC),
      dateTimeStore.getFormattedDayTime(data.deliveredAt * MS_IN_SEC),
    ] : null;

    const deliveryTime = data.deliveryTime !== null
      ? dateTimeStore.convertMinutesToFullTime(data.deliveryTime)
      : null;

    return {
      id: data.id,
      createdAt,
      deliveredAt,
      deliveryTime: deliveryTime ? dateTimeStore.fullTimeToString(deliveryTime) : null,
      order: {
        id: data.orderExternalId,
        posInJob: data.posInJob,
        totalCountInJob: data.totalCountInJob,
      },
      warehouse: {
        name: data.warehouseName || '',
        barcode: data.warehouseBarcode || '',
      },
      courierName: data.courierName || '',
      status: getOrderStatusNameByKey(data.status as unknown as string),
      eta: {
        max: etaMax,
        min: etaMin,
      },
      sum: null,
    };
  }

  get resultTable(): ResultTableRow[] {
    const orders = searchStore.advancedSearchResponse;
    if (!orders) return [];
    return this.getSortedResultTable(
      orders.map((order) => this.getRawResultTableRow(order)));
  }

  toggleSortingResultTable(field: keyof RawResultTableRow) {
    if (field === this.currentSortingField) {
      this.toggleCurrentSortingState();
    } else {
      this.setCurrentSortingState('asc');
      this.setCurrentSortingField(field);
    }
  }

  getSortedResultTable(rawResult: RawResultTableRow[]): ResultTableRow[] {
    if (this.currentSortingField !== null) {
      rawResult.sort((a, b) => {
        if (!this.currentSortingField) return 0;
        if (!a[this.currentSortingField] || !b[this.currentSortingField]) return 0;
        const isAsc = this.currentSortingState === 'asc' ? -1 : 1;
        return (a[this.currentSortingField] || 0) > (b[this.currentSortingField] || 0) ? -1 * isAsc : 1 * isAsc;
      });
    }
    return rawResult.map((raw) => this.getResultTableByRaw(raw));
  }
}

export const advancedSearchResultStore = new AdvancedSearchResultStore();
