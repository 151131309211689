import { JobState } from 'api/types/Job';
import { observer } from 'mobx-react-lite';
import FilterForm from 'components/Forms/FilterForm';
import Popover from './Popover';
import { mainStore } from 'stores/MainStore';
import { useState, useEffect } from 'react';
import { authStore } from 'stores/AuthStore';
import { useTranslation } from 'react-i18next';

export default observer(() => {
  const { t } = useTranslation();
  const [filterState, setFilterState] = useState<Record<JobState, boolean>>(
    authStore.jobStateFilter,
  );

  useEffect(() => {
    if (!mainStore.isShowPopoverFilter) return;
    setFilterState(authStore.jobStateFilter);
    //eslint-disable-next-line
  }, [mainStore.isShowPopoverFilter]);

  const handlePopoverDismiss = () => mainStore.setIsShowPopoverFilter(false);

  return (
    <Popover className="popover-filter _in-top" isShow={mainStore.isShowPopoverFilter}>
      <div className="popover-title">
        <div className="popover-title__name">{t('common:taskFilter')}</div>
        <div className="popover-title__icon icon icon-close" onClick={handlePopoverDismiss} />
      </div>
      <FilterForm
        filterState={filterState}
        setFilterState={setFilterState}
        onDismissClick={handlePopoverDismiss}
      />
    </Popover>
  );
});
