import React, { FC } from 'react';
import { generateHtmlClasses } from 'utils/htmlClasses';

type ExpandableHeaderProps = {
  containerClass?: string;
  titleClass?: string;
  isExpanded: boolean;
  title: string;
  onClick: () => void;
};

export const ExpandableHeader: FC<ExpandableHeaderProps> = ({
  containerClass,
  titleClass,
  isExpanded,
  title,
  onClick,
}) => {
  return (
    <div onClick={onClick} className={generateHtmlClasses('expandableHeader', containerClass)}>
      <span className={generateHtmlClasses('expandableHeader-title', titleClass)}>{title}</span>
      <div
        className={generateHtmlClasses('expandableHeader-title-icon icon icon-chevron-up', {
          __expanded: !isExpanded,
        })}
      />
    </div>
  );
};
