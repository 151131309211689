import { OrderWithRel } from 'api/response/GetJobListResponse';
import { TaskKind } from 'api/types/Task';
import MobileEmptyBlock from 'components/MobileEmptyBlock';
import { searchStore } from 'components/Search/store';
import { SearchIcon } from 'icons';
import { observer } from 'mobx-react-lite';
import { tasks } from 'proto/build/js/last-mile/dispatcher/public/admin/v2/jobs_get_list';
import React, { useCallback, useEffect, useState } from 'react';
import { authStore } from 'stores/AuthStore';
import { dataStore } from 'stores/DataStore';
import { mainStore } from 'stores/MainStore';
import Order from '../../../components/Search/Dropdown/Order';
import { useTranslation } from 'react-i18next';
import { generateHtmlClasses } from 'utils/htmlClasses';

export default observer(() => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState<boolean>();

  useEffect(() => {
    setIsExpanded(searchStore.searchValue.length > 0);
    //eslint-disable-next-line
  }, [searchStore.searchValue]);

  const handleLocalOrderClick = useCallback((orderId: string) => {
    if (!dataStore.getJobListResponse) return;
    const order = dataStore.getJobListResponse
      .flatMap((job) => job.orders)
      .find((order) => order.order.id === orderId);
    if (!order) return;

    const id = order.order.id;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { task } =
      order.tasks.find(
        ({ task }) => task.kind === TaskKind.DROP_OFF || task.kind === tasks.Kind.DROP_OFF,
      ) || {};
    if (!task) return;

    const taskToSet = order.tasks.find((item) => item.task.id === task.id);

    authStore.setActiveTeamId(task.teamId);
    setTimeout(() => {
      mainStore.setSelectedOrder({
        externalId: order.order.externalId,
        id,
        jobId: task.jobId,
        taskId: task.id,
        source: '',
      });
      mainStore.setActiveDynamicFrame('detail');
      if (taskToSet) {
        mainStore.setSelectedPointCoords({
          lat: taskToSet.address.latitude,
          lng: taskToSet.address.longitude,
        });
      }
      setIsExpanded(false);
      searchStore.setSearchValue('');
    }, 0);
  }, []);

  const handleServerOrderClick = useCallback((order: OrderWithRel, jobId: string) => {
    const { task } = order.tasks.find(({ task }) => task.kind === TaskKind.DROP_OFF) || {};
    if (!task) return;
    const taskToSet = order.tasks.find((item) => item.task.id === task.id);

    setTimeout(() => {
      mainStore.setSelectedOrder({
        externalId: order.order.externalId,
        id: order.order.id,
        jobId: jobId,
        taskId: task.id,
        source: '',
      });
      mainStore.setActiveDynamicFrame('detail');
      if (taskToSet) {
        mainStore.setSelectedPointCoords({
          lat: taskToSet.address.latitude,
          lng: taskToSet.address.longitude,
        });
      }
      if (
        dataStore.teamExistsLocally(task.teamId) &&
        dataStore.orderExistsLocally(order.order.id)
      ) {
        setIsExpanded(false);
        searchStore.setSearchValue('');
      }
    }, 0);
  }, []);

  const handleAdvancedSearchClick = useCallback(async () => {
    mainStore.setIsShowPopupAdvancedSearch(true);
    searchStore.setAdvancedSearchValue(searchStore.searchValue);
    await searchStore.advancedSearchOrders({ externalId: searchStore.searchValue });
  }, []);

  const atLeastOneServerOrderFound = !!searchStore.searchResponse?.jobs;

  return (
    <div
      className={generateHtmlClasses('header-search__dropdown dropdown', { _expanded: isExpanded })}
    >
      <div className="dropdown__groups groups">
        {searchStore.filteredOrders.length > 0 ? (
          <div className="groups__group group">
            <div className="group__title">{t('common:relevantOrders')}</div>
            <div className="group__list list">
              {searchStore.filteredOrders.map((order) => (
                <Order
                  key={order.order.id}
                  order={order.order}
                  tasks={order.tasks}
                  className="list__item"
                  onClick={() => handleLocalOrderClick(order.order.id)}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="groups__nothing-found nothing-found">
            <SearchIcon className="nothing-found__icon" />
            <div className="nothing-found__title">
              {t('phrases:noOrdersFound', { context: atLeastOneServerOrderFound ? 'recent' : '' })}
            </div>
            <div className="nothing-found__description">
              <span>{t('phrases:nothingFound1')}</span>
              <span>{t('phrases:nothingFound2')}</span>
            </div>
            <div
              className="nothing-found__button button _primary"
              onClick={handleAdvancedSearchClick}
            >
              {t('phrases:nothingFound3')}
            </div>
          </div>
        )}
        {searchStore.searchResponse && (
          <div className="groups__group group">
            <div className="group__title">{t('common:allOrders')}</div>
            <div className="group__list list">
              {searchStore.serverTeamsWithJobs.map((teamWithJob) => (
                <div className="list__item team" key={teamWithJob.team.id}>
                  <div className="team__title title">
                    <div className="title__name">{teamWithJob.team.name}</div>
                    {!dataStore.teamExistsLocally(teamWithJob.team.id) && (
                      <div className="title__label">{t('phrases:notInOurList')}</div>
                    )}
                  </div>
                  {teamWithJob.jobs.map((job) => (
                    <div className="team__job job" key={job.job.id}>
                      {job.orders.map((order) => (
                        <Order
                          key={order.order.id}
                          order={order.order}
                          tasks={order.tasks}
                          className="job__order"
                          onClick={() => handleServerOrderClick(order, job.job.id)}
                        />
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <MobileEmptyBlock />
    </div>
  );
});
