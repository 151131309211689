import { VehicleType } from 'api/types/Courier';
import { observer } from 'mobx-react-lite';
import EditCourierForm from 'components/Forms/EditCourierForm';
import { mainStore } from 'stores/MainStore';
import Popup from './Popup';
import { useTranslation } from 'react-i18next';

interface PopupEditCourierProps {
  courierName: string;
  courierId: string;
  vehicleType: VehicleType;
  capacity: number;
  agency: boolean;
  onDismissClick?: () => void;
}

export default observer(
  ({
    courierName,
    courierId,
    vehicleType,
    capacity,
    agency,
    onDismissClick,
  }: PopupEditCourierProps) => {
    const { t } = useTranslation();

    const handlePopupDismiss = () => {
      mainStore.setIsShowPopupEditCourier(false);
      onDismissClick?.call(null);
    };

    return (
      <Popup
        className="popup-edit-courier"
        isShow={mainStore.isShowPopupEditCourier}
        onBackdropDismiss={handlePopupDismiss}
        mobileCentered
      >
        <div className="popup-edit-courier__title title">{t('phrases:editCourier')}</div>
        <div className="popup-edit-courier__name name">
          <span className="name__label">{t('inputs:nameLabel')}:</span>
          <span className="name__value">{courierName}</span>
        </div>
        <EditCourierForm
          courierId={courierId}
          currentVehicleType={vehicleType}
          currentCapacity={capacity}
          currentAgency={agency}
        />
      </Popup>
    );
  },
);
