import { observer } from 'mobx-react-lite';
import { generateHtmlClasses } from 'utils/htmlClasses';

interface CheckboxProps {
  checked: boolean;
  onToggle?: () => void;
  className?: string;
}

export default observer(({ checked, onToggle, className }: CheckboxProps) => {
  const handleToggleValue = () => {
    onToggle?.call(null);
  };

  return (
    <div
      className={generateHtmlClasses('checkbox', className, { _active: checked })}
      onClick={handleToggleValue}
    >
      <div className="icon icon-check" />
    </div>
  );
});
