import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SettingsFrameName, mainStore } from "stores/MainStore";

export interface MenuItem {
  name: SettingsFrameName;
  text: string;
  icon: string;
  onClick: () => void;
}

export const useMenuItems = () => {
  const { t } = useTranslation();
  
  const MENU_ITEMS: MenuItem[] = useMemo(() => [
    // {
    //   name: 'users',
    //   text: 'Users',
    //   icon: 'team',
    //   onClick: () => mainStore.setActiveSettingsFrame('users'),
    // },
    {
      name: 'warehouses',
      text: t('common:warehouse'),
      icon: 'home',
      onClick: () => mainStore.setActiveSettingsFrame('warehouses'),
    },
    // {
    //   name: 'managers',
    //   text: 'Managers',
    //   icon: 'headphones',
    //   onClick: () => mainStore.setActiveSettingsFrame('managers'),
    // },
  ], [t]);
  
  const SWITCH_STATES = useMemo(() => MENU_ITEMS.map((item) => item.text), [MENU_ITEMS]);

  return {
    MENU_ITEMS,
    SWITCH_STATES
  };
};
