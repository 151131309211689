import { Parcel, ParcelStatus } from 'api/types/Parcel';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { groupBy } from 'common/group-by';
import ExpandableItem from '../ExpandableItem';
import { useTranslation } from 'react-i18next';

interface ProductsProps {
  parcels: Parcel[];
}

export default observer(({ parcels }: ProductsProps) => {
  const { t } = useTranslation();
  const titleRef = useRef<HTMLDivElement>(null);
  const categories = groupBy<Parcel, ParcelStatus>(parcels as Parcel[], 'status');
  const keys = Object.keys(categories) as ParcelStatus[];

  const getParcelStatus = (status: string) => {
    return status.replaceAll('_', ' ').toUpperCase();
  };

  return (
    <ExpandableItem className="_products" titleRef={titleRef}>
      <div className="item__title title" ref={titleRef}>
        <span className="title__sub">{t('common:totalItems')}</span>
        <span className="title__main">{parcels.length}</span>
        <div className="title__expand-icon icon icon-chevron-up" />
      </div>
      <div className="item__content content">
        <div className="product-categories">
          {keys.map((key: ParcelStatus) => (
            <div key={key} className="product-categories__category category">
              <div className="category__title title">
                <span className="title__name">{getParcelStatus(key)}</span>
                <span className="title__count">{categories[key].length}</span>
              </div>
              <div className="category__products products">
                {categories[key].map((product: Parcel) => (
                  <div key={product.id} className="products__product product">
                    <div className="product__image-wrapper">
                      <img src={product.image} alt="" />
                    </div>
                    <div className="product__details details">
                      <span className="details__name">{product.name}</span>
                      <span className="details__sku">{product.barcode}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </ExpandableItem>
  );
});
