import { observer } from 'mobx-react-lite';
import React from 'react';
import AddCourierForm from 'components/Forms/AddCourierForm';
import { mainStore } from 'stores/MainStore';
import Popup from './Popup';
import { useTranslation } from 'react-i18next';

export default observer(() => {
  const { t } = useTranslation();
  const handlePopupDismiss = () => mainStore.setIsShowPopupAddCourier(false);

  return (
    <Popup
      className="popup-add-courier"
      isShow={mainStore.isShowPopupAddCourier}
      onBackdropDismiss={handlePopupDismiss}
      mobileFullScreen
      mobileCentered
    >
      <div className="popup__title">{t('common:newCourier')}</div>
      <AddCourierForm className="popup-add-courier__form" onDismissClick={handlePopupDismiss} />
    </Popup>
  );
});
