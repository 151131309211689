import OrderInfoBar from 'components/Map/OrderInfoBar';
import { observer } from 'mobx-react-lite';
import type { TaskPoint } from 'stores/DataStore';
import { mainStore } from 'stores/MainStore';
import { generateHtmlClasses } from 'utils/htmlClasses';

type TaskClusterPinProps = {
  clusterId: number;
  orders: TaskPoint[];
};

export const TaskClusterPin = observer(({ clusterId, orders }: TaskClusterPinProps) => {
  const taskIds = orders.map((order) => order.properties.taskId);
  const isActive =
    mainStore.openedTaskCluster === clusterId || taskIds.includes(mainStore.selectedOrder.taskId);

  const handlePinClick = () => mainStore.setOpenedTaskCluster(clusterId);

  const handlePinMouseLeave = () => mainStore.setOpenedTaskCluster(null);

  return (
    <div
      className={generateHtmlClasses('map-pin _task-cluster', { _active: isActive })}
      onClick={handlePinClick}
      onMouseLeave={handlePinMouseLeave}
      onMouseEnter={handlePinClick}
    >
      <div className="count">{orders.length}</div>
      {orders && (
        <div className="orders">
          {orders.map((order) => {
            return (
              <OrderInfoBar
                externalId={order.properties.orderExternalId}
                key={order.properties.taskId}
                jobId={order.properties.jobId}
                orderId={order.properties.orderId}
                task={order.properties.task}
              />
            );
          })}
        </div>
      )}
    </div>
  );
});
