import { observer } from 'mobx-react-lite';
import AddCourierForm from 'components/Forms/AddCourierForm';
import { mainStore } from 'stores/MainStore';
import Popover from './Popover';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default observer(() => {
  const { t } = useTranslation();
  const handlePopoverDismiss = () => mainStore.setIsShowPopoverAddCourier(false);

  return (
    <Popover className="popover-add-courier _in-top" isShow={mainStore.isShowPopoverAddCourier}>
      <div className="popover-title">
        <div className="popover-title__name">{t('common:newCourier')}</div>
        <div className="popover-title__icon icon icon-close" onClick={handlePopoverDismiss} />
      </div>
      <AddCourierForm className="popover-add-courier-form" onDismissClick={handlePopoverDismiss} />
    </Popover>
  );
});
