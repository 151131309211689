import {
  advancedSearchResultStore,
  RawResultTableRow,
  ResultTableRow,
} from 'components/Search/PopupAdvanced/store';
import { observer } from 'mobx-react-lite';
import Row from './Row';
import { SortIcon } from 'icons';
import { useTranslation } from 'react-i18next';
import { generateHtmlClasses } from 'utils/htmlClasses';

interface TableHeadTemplateItem {
  title: string;
  field: keyof RawResultTableRow;
}

export default observer(() => {
  const { t } = useTranslation();
  const resultTable = advancedSearchResultStore.resultTable;
  const count = !resultTable?.length
    ? null
    : resultTable.length > 1
    ? `${resultTable.length} orders found`
    : `${resultTable.length} order found`;

  const handleHeadCellClick = (sortingField: keyof RawResultTableRow) => () => {
    advancedSearchResultStore.toggleSortingResultTable(sortingField);
  };

  const TABLE_HEAD_TEMPLATE: TableHeadTemplateItem[] = [
    {
      title: t('order:creation'),
      field: 'createdAt',
    },
    {
      title: t('order:creation'),
      field: 'deliveredAt',
    },
    {
      title: t('order:deliveryTime'),
      field: 'deliveryTime',
    },
    {
      title: t('order:orderId'),
      field: 'orderExternalId',
    },
    {
      title: t('order:warehouse'),
      field: 'warehouseName',
    },
    {
      title: t('common:courier'),
      field: 'courierName',
    },
    {
      title: t('order:status'),
      field: 'status',
    },
    {
      title: t('common:eta'),
      field: 'etaMax',
    },
    {
      title: t('order:sum'),
      field: 'sum',
    },
  ];

  return (
    <div className="result-table">
      <div className="result-table__title">
        <span className="_secondary">{count}</span>
      </div>
      {resultTable.length > 0 && (
        <div className="result-table__table">
          <div className="result-table__head head">
            <div className="result-table__row">
              {TABLE_HEAD_TEMPLATE.map((item: TableHeadTemplateItem) => (
                <div
                  className={generateHtmlClasses(`result-table__cell _${item.field}`, {
                    _active: item.field === advancedSearchResultStore.currentSortingField,
                    _asc:
                      advancedSearchResultStore.currentSortingState === 'asc' &&
                      item.field === advancedSearchResultStore.currentSortingField,
                  })}
                  key={item.field}
                  onClick={handleHeadCellClick(item.field)}
                >
                  <span className="cell__title">{item.title}</span>
                  <SortIcon className="cell__sort-icon" />
                </div>
              ))}
            </div>
          </div>
          <div className="result-table__body">
            {resultTable.map((row: ResultTableRow) => {
              return <Row key={row.id} row={row} />;
            })}
          </div>
        </div>
      )}
    </div>
  );
});
