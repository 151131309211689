import AddTeamForm from 'components/Forms/AddTeamForm';
import Popup from 'components/Popup';
import { settingsStore } from 'components/PopupSettings/Warehouses/store';
import { observer } from 'mobx-react-lite';
import { mainStore } from 'stores/MainStore';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default observer(() => {
  const { t } = useTranslation();

  const handleCancelClick = () => {
    if (settingsStore.selectedWarehouse) mainStore.setIsShowPopupSettings(true);
    mainStore.setIsShowPopoverAddTeam(false);
  };

  const handlePopupDismiss = () => mainStore.setIsShowPopupAddTeam(false);

  return (
    <Popup className='popup-add-team'
           isShow={mainStore.isShowPopupAddTeam}
           onBackdropDismiss={handlePopupDismiss}
           mobileCentered>
      <div className='popup__title'>{t('common:newTeam')}</div>
      <AddTeamForm className='popover-add-team-form' onCancelClick={handleCancelClick}/>
    </Popup>
  )
})
