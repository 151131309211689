import { User } from 'api/types/User';

export enum CourierStatus {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
  IDLE = 'IDLE',
  ASSIGNED_TO_JOB = 'ASSIGNED_TO_JOB',
  ACCEPTED_JOB = 'ACCEPTED_JOB',
  PICKING_UP = 'PICKING_UP',
  DELIVERING = 'DELIVERING',
  HEADING_TO_BASE = 'HEADING_TO_BASE',
  NOT_RESPONDING = 'NOT_RESPONDING'
}

export enum VehicleType {
  BICYCLE = 'BICYCLE',
  CAR = 'CAR',
  FOOTER = 'FOOTER',
  MOTORBIKE = 'MOTORBIKE'
}

export interface Courier {
  agency: boolean;
  capacityPackages: number | null;
  createdAt: number;
  currentWarehouseId: string | null;
  deletedAt: number;
  id: string;
  idleAt: number | null;
  latitude: number;
  longitude: number;
  status: CourierStatus;
  updatedAt: number;
  user: User | null;
  userId: string | null;
  vehicleType: VehicleType;
  name?: string;
}
