import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { DayTime } from 'stores/DateTimeStore';
import { TimeType } from './index';
import Cell from './Cell';

// Number of cells in one scroll view
const MAX_CELLS_IN_COLUMN = 8;

interface ColumnProps {
  array: number[];
  type: TimeType;
  selectedTime: DayTime | null;
  setSelectedTime: React.Dispatch<React.SetStateAction<DayTime | null>>;
}

export default observer(({ array, type, selectedTime, setSelectedTime }: ColumnProps) => {
  const columnRef = useRef<HTMLDivElement>(null);

  const handleTimeChange = (value: number, type: TimeType) => {
    switch (type) {
    case TimeType.HOUR:
      setSelectedTime((prev) => ({ ...prev, hours: value }));
      break;
    case TimeType.MINUTE:
      setSelectedTime((prev) => ({ ...prev, minutes: value }));
      break;
    case TimeType.SECOND:
      setSelectedTime((prev) => ({ ...prev, seconds: value }));
      break;
    }
  }

  return (
    <div className='react-timepicker__column' ref={columnRef}>
      {array.map(num => (
        <Cell key={num}
              value={num}
              type={type}
              selectedTime={selectedTime}
              onChange={handleTimeChange}
              columnRef={columnRef}/>
      ))}
      {Array.from(Array(MAX_CELLS_IN_COLUMN)).map((_, index) => (
        <Cell key={100 + index}
              type={type}
              selectedTime={selectedTime}
              columnRef={columnRef}/>
      ))}
    </div>
  );
});
