import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { IUserWithRoles } from '../api/dto/GetUserByTeamDto';
import { useTranslation } from 'react-i18next';
import { generateHtmlClasses } from 'utils/htmlClasses';

const LIST_ELEMENTS_LIMIT = 5;

interface SelectedMembersProps {
  members: IUserWithRoles[];
  onToggleClick: (member: IUserWithRoles) => void;
  onResetClick: () => void;
  type?: 'scroll' | 'wrap';
}

export default observer(
  ({ members, onToggleClick, onResetClick, type = 'scroll' }: SelectedMembersProps) => {
    const { t } = useTranslation();
    const [exceededLimit, setExceededLimit] = useState<number>(0);
    const [limitedItemsShown, setLimitedItemsShown] = useState<boolean>(false);

    useEffect(() => {
      setExceededLimit(members.length - LIST_ELEMENTS_LIMIT);
    }, [members]);

    const handleCloseClick = (member: IUserWithRoles) => () => onToggleClick(member);
    const handleLimitedItemsShownToggle = () => setLimitedItemsShown((prev) => !prev);

    const LimitedMembersList = () => (
      <React.Fragment>
        {members.map((member, index) => {
          if (index < LIST_ELEMENTS_LIMIT)
            return (
              <div key={member.id} className="list__item item">
                <span className="item__text">{member.name}</span>
                <span className="item__close icon icon-close" onClick={handleCloseClick(member)} />
              </div>
            );
          else return <React.Fragment key={member.id} />;
        })}
      </React.Fragment>
    );

    const AllMembersList = () => (
      <React.Fragment>
        {members.map((member) => (
          <div key={member.id} className="list__item item">
            <span className="item__text">{member.name}</span>
            <span className="item__close icon icon-close" onClick={handleCloseClick(member)} />
          </div>
        ))}
      </React.Fragment>
    );

    return (
      <div
        className={generateHtmlClasses('selected-members', {
          _scroll: type === 'scroll',
          _wrap: type === 'wrap',
        })}
      >
        <div className="selected-members__list list">
          {type === 'scroll' || limitedItemsShown ? <AllMembersList /> : <LimitedMembersList />}
          {type === 'wrap' && exceededLimit > 0 && (
            <button
              className={generateHtmlClasses('list__item item button _flat _view-all-btn', {
                '_limited-items-shown': limitedItemsShown,
              })}
              onClick={handleLimitedItemsShownToggle}
            >
              <span className="item__text">
                {limitedItemsShown ? 'Less' : `${exceededLimit} more`}
              </span>
              <span className="item__icon icon icon-chevron-up" />
            </button>
          )}
        </div>
        {type === 'scroll' && (
          <button className="selected-members__reset-button" onClick={onResetClick}>
            {t('common:resetAll')}
          </button>
        )}
      </div>
    );
  },
);
