import { observer } from 'mobx-react-lite';
import React from 'react';
import { mainStore } from 'stores/MainStore';
import FrameDetail from './FrameDetail/index';
import FrameTeamless from './FrameTeamless';
import FrameUnassigned from './FrameUnasigned/FrameUnassigned';
import FrameCourier from './FrameCourier';
import { generateHtmlClasses } from 'utils/htmlClasses';

export default observer(() => {
  const _hide =
    !mainStore.activeDynamicFrame ||
    (mainStore.activeDynamicFrame === 'detail' && !mainStore.selectedOrder.id) ||
    (mainStore.activeDynamicFrame === 'courier' && !mainStore.selectedCourier.id);
  return (
    <div
      className={generateHtmlClasses(
        'frame-dynamic _in-right',
        `_${mainStore.activeDynamicFrame}`,
        {
          _hide,
        },
      )}
    >
      <FrameUnassigned />
      <FrameTeamless />
      {mainStore.activeDynamicFrame === 'detail' && mainStore.selectedOrder.id && <FrameDetail />}
      {mainStore.activeDynamicFrame === 'courier' && mainStore.selectedCourier.id && (
        <FrameCourier />
      )}
    </div>
  );
});
