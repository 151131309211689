import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { MIN_IN_HOUR, SEC_IN_MIN } from 'config';
import InputPopup from './InputPopup';
import { dateTimeStore, DayTime } from 'stores/DateTimeStore';
import { useOutsideClicker } from 'hooks/useOutsideClicker';
import { ClockIcon, ResetIcon } from 'icons';
import { useTranslation } from 'react-i18next';
import { generateHtmlClasses } from 'utils/htmlClasses';

interface TimePickerProps {
  onStartTimeChange: (time: DayTime | null) => void;
  onEndTimeChange?: (time: DayTime | null) => void;
  label?: string;
  className?: string;
  placeholder?: string;
  range?: boolean;
}

export enum TimeType {
  HOUR,
  MINUTE,
  SECOND,
}

export default observer(
  ({
    onStartTimeChange,
    onEndTimeChange,
    label,
    className,
    placeholder,
    range,
  }: TimePickerProps) => {
    const { t } = useTranslation();
    const [isStartPopupOpen, setIsStartPopupOpen] = useState<boolean>(false);
    const [isEndPopupOpen, setIsEndPopupOpen] = useState<boolean>(false);

    const [selectedStartTime, setSelectedStartTime] = useState<DayTime | null>(null);
    const [selectedEndTime, setSelectedEndTime] = useState<DayTime | null>(null);

    const startPopupRef = useRef<HTMLDivElement>(null);
    const endPopupRef = useRef<HTMLDivElement>(null);

    const displayRef = useRef<HTMLDivElement>(null);

    const startTimeStr = selectedStartTime
      ? dateTimeStore.getFormattedDayTimeV2(selectedStartTime, true)
      : '...';
    const endTimeStr = selectedEndTime
      ? dateTimeStore.getFormattedDayTimeV2(selectedEndTime, true)
      : '...';

    const getDisplayValue = () => {
      if (range) {
        return selectedStartTime || selectedEndTime
          ? `${startTimeStr} – ${endTimeStr}`
          : placeholder || t('inputs:startEndTimePlaceholder');
      }

      return selectedStartTime
        ? dateTimeStore.getFormattedDayTimeV2(selectedStartTime, true)
        : placeholder || t('inputs:selectTimePlaceholder');
    };

    useOutsideClicker(() => {
      setIsStartPopupOpen(false);
    }, [startPopupRef, displayRef]);

    useOutsideClicker(() => {
      setIsEndPopupOpen(false);
    }, [endPopupRef, displayRef]);

    useEffect(() => {
      onStartTimeChange?.call(null, selectedStartTime);
      //eslint-disable-next-line
    }, [selectedStartTime]);

    useEffect(() => {
      onEndTimeChange?.call(this, selectedEndTime);
      //eslint-disable-next-line
    }, [selectedEndTime]);

    useEffect(() => {
      const [start, end] = [selectedStartTime, selectedEndTime];

      if (!start || !end) return;
      if (
        start?.seconds === undefined ||
        start?.minutes === undefined ||
        start?.hours === undefined
      )
        return;
      if (end?.seconds === undefined || end?.minutes === undefined || end?.hours === undefined)
        return;

      const startTotalSec =
        start.seconds + start.minutes * SEC_IN_MIN + start.hours * MIN_IN_HOUR * SEC_IN_MIN;
      const endTotalSec =
        end.seconds + end.minutes * SEC_IN_MIN + end.hours * MIN_IN_HOUR * SEC_IN_MIN;

      if (startTotalSec > endTotalSec) {
        handleResetValue();
        setIsStartPopupOpen(false);
        setIsEndPopupOpen(false);
      }
      //eslint-disable-next-line
    }, [selectedStartTime, selectedEndTime]);

    const handleDisplayInputClick = () => {
      setIsStartPopupOpen(true);
      setIsEndPopupOpen(true);
    };

    const handleDisplayIconClick = () => {
      setIsStartPopupOpen((prev) => !prev);
      setIsEndPopupOpen((prev) => !prev);
    };

    const handleResetValue = () => {
      setIsStartPopupOpen(false);
      setIsEndPopupOpen(false);
      setSelectedStartTime(null);
      setSelectedEndTime(null);
    };

    const isAtLeastOneTimeSelected = selectedStartTime || selectedEndTime;

    return (
      <div className="input-wrapper">
        {label && <div className="input-wrapper__label">{label}</div>}
        <div className={generateHtmlClasses('input-time', className, { _range: !!range })}>
          <div
            className={generateHtmlClasses('input-time__display display', { _start: range })}
            ref={displayRef}
          >
            <input
              readOnly
              className={generateHtmlClasses('display__input', { _active: !!selectedStartTime })}
              value={getDisplayValue()}
              onClick={handleDisplayInputClick}
            />
            <div
              className={generateHtmlClasses('display__icon _clock', {
                _active: !isAtLeastOneTimeSelected,
              })}
              onClick={handleDisplayIconClick}
            >
              <ClockIcon />
            </div>
            <div
              className={generateHtmlClasses('display__icon _reset', {
                _active: isAtLeastOneTimeSelected,
              })}
              onClick={handleResetValue}
            >
              <ResetIcon />
            </div>
          </div>
          <InputPopup
            className={range ? '_start' : ''}
            selectedTime={selectedStartTime}
            setSelectedTime={setSelectedStartTime}
            isOpen={isStartPopupOpen}
            setIsOpen={setIsStartPopupOpen}
            popupRef={startPopupRef}
            title={range ? t('inputs:start') : startTimeStr}
          />
          {range && (
            <InputPopup
              className="_end"
              selectedTime={selectedEndTime}
              setSelectedTime={setSelectedEndTime}
              isOpen={isEndPopupOpen}
              setIsOpen={setIsEndPopupOpen}
              popupRef={endPopupRef}
              title={t('inputs:end')}
            />
          )}
        </div>
      </div>
    );
  },
);
