import { JobState } from 'api/types/Job';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import Checkbox from 'components/Checkbox';
import PinAssigned from 'assets/img/pin_assigned.svg';
import PinCanceled from 'assets/img/pin_cancelled.svg';
import PinInProgress from 'assets/img/pin_in_progress.svg';
import PinCompleted from 'assets/img/pin_successful.svg';
import PinUnassigned from 'assets/img/pin_unassigned.svg';
import PinFailed from 'assets/img/pin_unsuccessful.svg';
import { authStore } from 'stores/AuthStore';
import { dataStore } from 'stores/DataStore';
import { useTranslation } from 'react-i18next';
import { generateHtmlClasses } from 'utils/htmlClasses';

interface FilterFormProps {
  filterState: Record<JobState, boolean>;
  setFilterState: React.Dispatch<React.SetStateAction<Record<JobState, boolean>>>;
  onDismissClick: () => void;
  className?: string;
}

export default observer(
  ({ filterState, setFilterState, onDismissClick, className }: FilterFormProps) => {
    const { t } = useTranslation();
    const [availableFilterState, setAvailableFilterState] = useState<JobState[]>([]);
    const isDifference = (): boolean => {
      return (
        JSON.stringify(Object.values(filterState)) !==
        JSON.stringify(Object.values(authStore.jobStateFilter))
      );
    };
    const isDefaultState = (): boolean => {
      return (
        JSON.stringify(Object.values(filterState)) ===
        JSON.stringify(Object.values(authStore.jobStateFilterDefault))
      );
    };
    const handleChangeFilter = (index: JobState) => {
      const newFilter = { ...filterState };
      newFilter[index] = !newFilter[index];
      setFilterState(newFilter);
    };
    const handleApply = () => {
      authStore.setJobStateFilter(filterState);
      dataStore
        .requestJobList()
        .catch((error) => error && console.error(error))
        .finally(onDismissClick);
    };
    const handleReset = () => {
      authStore.resetFilter();
      dataStore
        .requestJobList()
        .catch((error) => error && console.error(error))
        .finally(onDismissClick);
    };

    useEffect(() => {
      setAvailableFilterState(Object.keys(JobState) as JobState[]);
    }, []);

    const iconsList: Record<JobState, [string, string]> = {
      UNASSIGNED: [PinUnassigned, t('deliveryStatus:unassigned')],
      ASSIGNED: [PinAssigned, t('deliveryStatus:assigned')],
      IN_PROGRESS: [PinInProgress, t('deliveryStatus:inProgress')],
      COMPLETED: [PinCompleted, t('deliveryStatus:completed')],
      CANCELLED: [PinCanceled, t('deliveryStatus:canceled')],
      FAILED: [PinFailed, t('deliveryStatus:failed')],
    };

    return (
      <div className={generateHtmlClasses('filter-form', className)}>
        <div className="filter-form__list">
          {availableFilterState.map((state) => (
            <div className="filter-form__row" key={state}>
              <div className="filter-form__title">
                <img className="filter-form__icon" src={iconsList[state][0]} alt="" />
                <div className="filter-form__name">{iconsList[state][1]}</div>
              </div>
              <Checkbox checked={filterState[state]} onToggle={() => handleChangeFilter(state)} />
            </div>
          ))}
        </div>
        <div className="filter-form__buttons">
          <button className="button _primary" onClick={handleApply} disabled={!isDifference()}>
            {t('forms:filter:apply')}
          </button>
          <button className="button _secondary" onClick={handleReset} disabled={isDefaultState()}>
            {t('forms:filter:reset')}
          </button>
        </div>
      </div>
    );
  },
);
