/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
;(function (global, factory) {
    /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd) define(['protobufjs/minimal'], factory)
    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require('protobufjs/minimal'))
})(this, function ($protobuf) {
    'use strict'

    // Common aliases
    var $Reader = $protobuf.Reader,
        $Writer = $protobuf.Writer,
        $util = $protobuf.util

    // Exported root namespace
    var $root = $protobuf.roots['default'] || ($protobuf.roots['default'] = {})

    $root.JiffyMessage = (function () {
        /**
         * Properties of a JiffyMessage.
         * @exports IJiffyMessage
         * @interface IJiffyMessage
         * @property {string} id JiffyMessage id
         * @property {JiffyMessageBody} body JiffyMessage body
         */

        /**
         * Constructs a new JiffyMessage.
         * @exports JiffyMessage
         * @classdesc Represents a JiffyMessage.
         * @implements IJiffyMessage
         * @constructor
         * @param {IJiffyMessage=} [properties] Properties to set
         */
        function JiffyMessage(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * JiffyMessage id.
         * @member {string} id
         * @memberof JiffyMessage
         * @instance
         */
        JiffyMessage.prototype.id = ''

        /**
         * JiffyMessage body.
         * @member {JiffyMessageBody} body
         * @memberof JiffyMessage
         * @instance
         */
        JiffyMessage.prototype.body = null

        /**
         * Creates a new JiffyMessage instance using the specified properties.
         * @function create
         * @memberof JiffyMessage
         * @static
         * @param {IJiffyMessage=} [properties] Properties to set
         * @returns {JiffyMessage} JiffyMessage instance
         */
        JiffyMessage.create = function create(properties) {
            return new JiffyMessage(properties)
        }

        /**
         * Encodes the specified JiffyMessage message. Does not implicitly {@link JiffyMessage.verify|verify} messages.
         * @function encode
         * @memberof JiffyMessage
         * @static
         * @param {JiffyMessage} message JiffyMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JiffyMessage.encode = function encode(message, writer) {
            if (!writer) writer = $Writer.create()
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
            $root.JiffyMessageBody.encode(message.body, writer.uint32(/* id 2, wireType 2 =*/ 18).fork()).ldelim()
            return writer
        }

        /**
         * Encodes the specified JiffyMessage message, length delimited. Does not implicitly {@link JiffyMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof JiffyMessage
         * @static
         * @param {JiffyMessage} message JiffyMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JiffyMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a JiffyMessage message from the specified reader or buffer.
         * @function decode
         * @memberof JiffyMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {JiffyMessage} JiffyMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JiffyMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
            var end = length === undefined ? reader.len : reader.pos + length,
                message = new $root.JiffyMessage()
            while (reader.pos < end) {
                var tag = reader.uint32()
                switch (tag >>> 3) {
                    case 1: {
                        message.id = reader.string()
                        break
                    }
                    case 2: {
                        message.body = $root.JiffyMessageBody.decode(reader, reader.uint32())
                        break
                    }
                    default:
                        reader.skipType(tag & 7)
                        break
                }
            }
            if (!message.hasOwnProperty('id')) throw $util.ProtocolError("missing required 'id'", { instance: message })
            if (!message.hasOwnProperty('body'))
                throw $util.ProtocolError("missing required 'body'", { instance: message })
            return message
        }

        /**
         * Decodes a JiffyMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof JiffyMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {JiffyMessage} JiffyMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JiffyMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader)) reader = new $Reader(reader)
            return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a JiffyMessage message.
         * @function verify
         * @memberof JiffyMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        JiffyMessage.verify = function verify(message) {
            if (typeof message !== 'object' || message === null) return 'object expected'
            if (!$util.isString(message.id)) return 'id: string expected'
            {
                var error = $root.JiffyMessageBody.verify(message.body)
                if (error) return 'body.' + error
            }
            return null
        }

        /**
         * Creates a JiffyMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof JiffyMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {JiffyMessage} JiffyMessage
         */
        JiffyMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.JiffyMessage) return object
            var message = new $root.JiffyMessage()
            if (object.id != null) message.id = String(object.id)
            if (object.body != null) {
                if (typeof object.body !== 'object') throw TypeError('.JiffyMessage.body: object expected')
                message.body = $root.JiffyMessageBody.fromObject(object.body)
            }
            return message
        }

        /**
         * Creates a plain object from a JiffyMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof JiffyMessage
         * @static
         * @param {JiffyMessage} message JiffyMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        JiffyMessage.toObject = function toObject(message, options) {
            if (!options) options = {}
            var object = {}
            if (options.defaults) {
                object.id = ''
                object.body = null
            }
            if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
            if (message.body != null && message.hasOwnProperty('body'))
                object.body = $root.JiffyMessageBody.toObject(message.body, options)
            return object
        }

        /**
         * Converts this JiffyMessage to JSON.
         * @function toJSON
         * @memberof JiffyMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        JiffyMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        /**
         * Gets the default type url for JiffyMessage
         * @function getTypeUrl
         * @memberof JiffyMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        JiffyMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = 'type.googleapis.com'
            }
            return typeUrlPrefix + '/JiffyMessage'
        }

        return JiffyMessage
    })()

    $root.JiffyMessageBasic = (function () {
        /**
         * Properties of a JiffyMessageBasic.
         * @exports IJiffyMessageBasic
         * @interface IJiffyMessageBasic
         * @property {string} message JiffyMessageBasic message
         */

        /**
         * Constructs a new JiffyMessageBasic.
         * @exports JiffyMessageBasic
         * @classdesc Represents a JiffyMessageBasic.
         * @implements IJiffyMessageBasic
         * @constructor
         * @param {IJiffyMessageBasic=} [properties] Properties to set
         */
        function JiffyMessageBasic(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * JiffyMessageBasic message.
         * @member {string} message
         * @memberof JiffyMessageBasic
         * @instance
         */
        JiffyMessageBasic.prototype.message = ''

        /**
         * Creates a new JiffyMessageBasic instance using the specified properties.
         * @function create
         * @memberof JiffyMessageBasic
         * @static
         * @param {IJiffyMessageBasic=} [properties] Properties to set
         * @returns {JiffyMessageBasic} JiffyMessageBasic instance
         */
        JiffyMessageBasic.create = function create(properties) {
            return new JiffyMessageBasic(properties)
        }

        /**
         * Encodes the specified JiffyMessageBasic message. Does not implicitly {@link JiffyMessageBasic.verify|verify} messages.
         * @function encode
         * @memberof JiffyMessageBasic
         * @static
         * @param {JiffyMessageBasic} message JiffyMessageBasic message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JiffyMessageBasic.encode = function encode(message, writer) {
            if (!writer) writer = $Writer.create()
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.message)
            return writer
        }

        /**
         * Encodes the specified JiffyMessageBasic message, length delimited. Does not implicitly {@link JiffyMessageBasic.verify|verify} messages.
         * @function encodeDelimited
         * @memberof JiffyMessageBasic
         * @static
         * @param {JiffyMessageBasic} message JiffyMessageBasic message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JiffyMessageBasic.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a JiffyMessageBasic message from the specified reader or buffer.
         * @function decode
         * @memberof JiffyMessageBasic
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {JiffyMessageBasic} JiffyMessageBasic
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JiffyMessageBasic.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
            var end = length === undefined ? reader.len : reader.pos + length,
                message = new $root.JiffyMessageBasic()
            while (reader.pos < end) {
                var tag = reader.uint32()
                switch (tag >>> 3) {
                    case 1: {
                        message.message = reader.string()
                        break
                    }
                    default:
                        reader.skipType(tag & 7)
                        break
                }
            }
            if (!message.hasOwnProperty('message'))
                throw $util.ProtocolError("missing required 'message'", { instance: message })
            return message
        }

        /**
         * Decodes a JiffyMessageBasic message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof JiffyMessageBasic
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {JiffyMessageBasic} JiffyMessageBasic
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JiffyMessageBasic.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader)) reader = new $Reader(reader)
            return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a JiffyMessageBasic message.
         * @function verify
         * @memberof JiffyMessageBasic
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        JiffyMessageBasic.verify = function verify(message) {
            if (typeof message !== 'object' || message === null) return 'object expected'
            if (!$util.isString(message.message)) return 'message: string expected'
            return null
        }

        /**
         * Creates a JiffyMessageBasic message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof JiffyMessageBasic
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {JiffyMessageBasic} JiffyMessageBasic
         */
        JiffyMessageBasic.fromObject = function fromObject(object) {
            if (object instanceof $root.JiffyMessageBasic) return object
            var message = new $root.JiffyMessageBasic()
            if (object.message != null) message.message = String(object.message)
            return message
        }

        /**
         * Creates a plain object from a JiffyMessageBasic message. Also converts values to other types if specified.
         * @function toObject
         * @memberof JiffyMessageBasic
         * @static
         * @param {JiffyMessageBasic} message JiffyMessageBasic
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        JiffyMessageBasic.toObject = function toObject(message, options) {
            if (!options) options = {}
            var object = {}
            if (options.defaults) object.message = ''
            if (message.message != null && message.hasOwnProperty('message')) object.message = message.message
            return object
        }

        /**
         * Converts this JiffyMessageBasic to JSON.
         * @function toJSON
         * @memberof JiffyMessageBasic
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        JiffyMessageBasic.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        /**
         * Gets the default type url for JiffyMessageBasic
         * @function getTypeUrl
         * @memberof JiffyMessageBasic
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        JiffyMessageBasic.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = 'type.googleapis.com'
            }
            return typeUrlPrefix + '/JiffyMessageBasic'
        }

        return JiffyMessageBasic
    })()

    $root.JiffyMessageBody = (function () {
        /**
         * Properties of a JiffyMessageBody.
         * @exports IJiffyMessageBody
         * @interface IJiffyMessageBody
         * @property {JiffyMessageBody.Type} type JiffyMessageBody type
         * @property {Uint8Array} data JiffyMessageBody data
         */

        /**
         * Constructs a new JiffyMessageBody.
         * @exports JiffyMessageBody
         * @classdesc Represents a JiffyMessageBody.
         * @implements IJiffyMessageBody
         * @constructor
         * @param {IJiffyMessageBody=} [properties] Properties to set
         */
        function JiffyMessageBody(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * JiffyMessageBody type.
         * @member {JiffyMessageBody.Type} type
         * @memberof JiffyMessageBody
         * @instance
         */
        JiffyMessageBody.prototype.type = 0

        /**
         * JiffyMessageBody data.
         * @member {Uint8Array} data
         * @memberof JiffyMessageBody
         * @instance
         */
        JiffyMessageBody.prototype.data = $util.newBuffer([])

        /**
         * Creates a new JiffyMessageBody instance using the specified properties.
         * @function create
         * @memberof JiffyMessageBody
         * @static
         * @param {IJiffyMessageBody=} [properties] Properties to set
         * @returns {JiffyMessageBody} JiffyMessageBody instance
         */
        JiffyMessageBody.create = function create(properties) {
            return new JiffyMessageBody(properties)
        }

        /**
         * Encodes the specified JiffyMessageBody message. Does not implicitly {@link JiffyMessageBody.verify|verify} messages.
         * @function encode
         * @memberof JiffyMessageBody
         * @static
         * @param {JiffyMessageBody} message JiffyMessageBody message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JiffyMessageBody.encode = function encode(message, writer) {
            if (!writer) writer = $Writer.create()
            writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.type)
            writer.uint32(/* id 2, wireType 2 =*/ 18).bytes(message.data)
            return writer
        }

        /**
         * Encodes the specified JiffyMessageBody message, length delimited. Does not implicitly {@link JiffyMessageBody.verify|verify} messages.
         * @function encodeDelimited
         * @memberof JiffyMessageBody
         * @static
         * @param {JiffyMessageBody} message JiffyMessageBody message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JiffyMessageBody.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a JiffyMessageBody message from the specified reader or buffer.
         * @function decode
         * @memberof JiffyMessageBody
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {JiffyMessageBody} JiffyMessageBody
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JiffyMessageBody.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
            var end = length === undefined ? reader.len : reader.pos + length,
                message = new $root.JiffyMessageBody()
            while (reader.pos < end) {
                var tag = reader.uint32()
                switch (tag >>> 3) {
                    case 1: {
                        message.type = reader.int32()
                        break
                    }
                    case 2: {
                        message.data = reader.bytes()
                        break
                    }
                    default:
                        reader.skipType(tag & 7)
                        break
                }
            }
            if (!message.hasOwnProperty('type'))
                throw $util.ProtocolError("missing required 'type'", { instance: message })
            if (!message.hasOwnProperty('data'))
                throw $util.ProtocolError("missing required 'data'", { instance: message })
            return message
        }

        /**
         * Decodes a JiffyMessageBody message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof JiffyMessageBody
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {JiffyMessageBody} JiffyMessageBody
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JiffyMessageBody.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader)) reader = new $Reader(reader)
            return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a JiffyMessageBody message.
         * @function verify
         * @memberof JiffyMessageBody
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        JiffyMessageBody.verify = function verify(message) {
            if (typeof message !== 'object' || message === null) return 'object expected'
            switch (message.type) {
                default:
                    return 'type: enum value expected'
                case 0:
                case 1:
                case 21001:
                case 21002:
                case 21003:
                case 21004:
                case 21005:
                case 21006:
                case 21007:
                case 21008:
                case 21009:
                case 34001:
                case 37001:
                case 37002:
                case 37003:
                case 47001:
                case 47002:
                case 47003:
                case 47004:
                case 47005:
                case 47006:
                case 47007:
                case 47008:
                case 47009:
                case 47010:
                case 47011:
                case 47012:
                case 47013:
                case 47014:
                case 47015:
                case 47016:
                case 47017:
                case 47018:
                case 47019:
                case 47020:
                case 47021:
                case 47022:
                case 47023:
                case 47024:
                case 47025:
                case 47026:
                case 47027:
                case 47028:
                case 47029:
                case 47030:
                case 47031:
                case 47032:
                case 47033:
                case 47034:
                case 47035:
                case 47036:
                case 47037:
                case 47038:
                case 57001:
                case 57002:
                case 57003:
                case 57004:
                case 57005:
                case 57006:
                case 57007:
                case 57008:
                case 67002:
                case 67003:
                case 67004:
                case 67005:
                case 67006:
                case 67007:
                case 67008:
                case 71001:
                case 74001:
                case 74002:
                case 74003:
                case 74004:
                case 77001:
                case 77002:
                case 77003:
                case 77004:
                case 77005:
                case 81001:
                case 81002:
                case 81003:
                case 84001:
                case 84002:
                case 84003:
                case 87001:
                case 87002:
                case 87003:
                case 87004:
                case 97001:
                case 97002:
                case 97003:
                case 97004:
                case 97005:
                case 97006:
                case 97007:
                case 97008:
                case 97009:
                case 97010:
                case 97011:
                case 97012:
                case 97013:
                case 97014:
                case 97015:
                case 97016:
                case 97017:
                case 97018:
                case 101001:
                case 107001:
                case 107002:
                case 107003:
                case 107004:
                case 107005:
                case 107006:
                case 107007:
                case 114001:
                case 117001:
                case 121001:
                case 121002:
                case 121003:
                case 121004:
                case 121005:
                case 121006:
                case 121007:
                case 121008:
                case 121009:
                case 121010:
                case 121011:
                case 121012:
                case 121013:
                case 121014:
                case 121015:
                case 124001:
                case 124002:
                case 124003:
                case 124004:
                case 124005:
                case 124006:
                case 124007:
                case 124008:
                case 124009:
                case 124010:
                case 124011:
                case 124012:
                case 124013:
                case 127001:
                case 141001:
                case 141002:
                case 141003:
                case 141004:
                case 141031:
                case 141005:
                case 141006:
                case 141007:
                case 141008:
                case 141009:
                case 141010:
                case 141030:
                case 141011:
                case 141012:
                case 141013:
                case 141014:
                case 141015:
                case 141016:
                case 141017:
                case 141018:
                case 141019:
                case 141020:
                case 141021:
                case 141022:
                case 141023:
                case 141024:
                case 141025:
                case 141026:
                case 141027:
                case 141028:
                case 141033:
                case 141034:
                case 141035:
                case 141036:
                case 141037:
                case 141038:
                case 141039:
                case 141040:
                case 141041:
                case 141042:
                case 141043:
                case 141044:
                case 141045:
                case 141046:
                case 141047:
                case 141048:
                case 141029:
                case 144001:
                case 144002:
                case 144003:
                case 144004:
                case 144045:
                case 144005:
                case 144006:
                case 144007:
                case 144008:
                case 144009:
                case 144010:
                case 144011:
                case 144012:
                case 144013:
                case 144014:
                case 144015:
                case 144016:
                case 144017:
                case 144018:
                case 144019:
                case 144020:
                case 144021:
                case 144022:
                case 144023:
                case 144024:
                case 144025:
                case 144026:
                case 144027:
                case 144028:
                case 144029:
                case 144030:
                case 144033:
                case 144034:
                case 144035:
                case 144036:
                case 144037:
                case 144038:
                case 144040:
                case 144041:
                case 144042:
                case 144043:
                case 144044:
                case 144046:
                case 144047:
                case 144048:
                case 144049:
                case 144050:
                case 144031:
                case 144032:
                case 144051:
                case 144052:
                case 144053:
                case 144054:
                case 144055:
                case 144056:
                case 144061:
                case 144062:
                case 144063:
                case 144073:
                case 144074:
                case 144075:
                case 146000:
                    break
            }
            if (!((message.data && typeof message.data.length === 'number') || $util.isString(message.data)))
                return 'data: buffer expected'
            return null
        }

        /**
         * Creates a JiffyMessageBody message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof JiffyMessageBody
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {JiffyMessageBody} JiffyMessageBody
         */
        JiffyMessageBody.fromObject = function fromObject(object) {
            if (object instanceof $root.JiffyMessageBody) return object
            var message = new $root.JiffyMessageBody()
            switch (object.type) {
                case 'ERROR':
                case 0:
                    message.type = 0
                    break
                case 'SUCCESS':
                case 1:
                    message.type = 1
                    break
                case 'ONESIGNAL__REQUEST__NOTIFY__ORDER__PICKING':
                case 21001:
                    message.type = 21001
                    break
                case 'ONESIGNAL__REQUEST__NOTIFY__ORDER__PICKED_PARTIALLY':
                case 21002:
                    message.type = 21002
                    break
                case 'ONESIGNAL__REQUEST__NOTIFY__ORDER__IN_DELIVERY':
                case 21003:
                    message.type = 21003
                    break
                case 'ONESIGNAL__REQUEST__NOTIFY__ORDER__DELIVERED':
                case 21004:
                    message.type = 21004
                    break
                case 'ONESIGNAL__REQUEST__NOTIFY__ORDER__PAYMENT_CHARGED':
                case 21005:
                    message.type = 21005
                    break
                case 'ONESIGNAL__REQUEST__NOTIFY__SUPPORT__REPLY':
                case 21006:
                    message.type = 21006
                    break
                case 'ONESIGNAL__REQUEST__TAG__EDIT':
                case 21007:
                    message.type = 21007
                    break
                case 'ONESIGNAL__REQUEST__NOTIFY__SEARCH_AGAIN':
                case 21008:
                    message.type = 21008
                    break
                case 'ONESIGNAL__REQUEST__NOTIFY__ABANDONEW_VIEW':
                case 21009:
                    message.type = 21009
                    break
                case 'CHAT__RESPONSE__CONVERSATION':
                case 34001:
                    message.type = 34001
                    break
                case 'CHAT__ERROR__AUTH_INVALID':
                case 37001:
                    message.type = 37001
                    break
                case 'CHAT__ERROR__BODY_INVALID':
                case 37002:
                    message.type = 37002
                    break
                case 'CHAT__ERROR__VOXIMPLANT_UNAVAILABLE':
                case 37003:
                    message.type = 37003
                    break
                case 'OMS__ERROR__ORDER_CREATE__SLA_UNAVAILABLE':
                case 47001:
                    message.type = 47001
                    break
                case 'OMS__ERROR__ORDER_CREATE__CUSTOMER_SERVICE_UNAVAILABLE':
                case 47002:
                    message.type = 47002
                    break
                case 'OMS__ERROR__ORDER_CREATE__PAYMENT_SERVICE_UNAVAILABLE':
                case 47003:
                    message.type = 47003
                    break
                case 'OMS__ERROR__ORDER_CREATE__INVALID_WAREHOUSE_CODE':
                case 47004:
                    message.type = 47004
                    break
                case 'OMS__ERROR__ORDER_CREATE__INSUFFICIENT_ITEMS_IN_STOCK':
                case 47005:
                    message.type = 47005
                    break
                case 'OMS__ERROR__ORDER_CREATE__ITEM_OUT_OF_STOCK':
                case 47006:
                    message.type = 47006
                    break
                case 'OMS__ERROR__ORDER_CREATE__STOCK_RESERVATION_UNAVAILABLE':
                case 47007:
                    message.type = 47007
                    break
                case 'OMS__ERROR__ORDER_CREATE__INCORRECT_PRICE':
                case 47008:
                    message.type = 47008
                    break
                case 'OMS__ERROR__ORDER_CREATE__INCORRECT_DELIVERY_PRICE':
                case 47009:
                    message.type = 47009
                    break
                case 'OMS__ERROR__ORDER_CREATE__PROMOCODE__INVALID':
                case 47010:
                    message.type = 47010
                    break
                case 'OMS__ERROR__ORDER_CREATE__PROMOCODE__TOTAL_LESS_THAN_MIN_VALUE':
                case 47011:
                    message.type = 47011
                    break
                case 'OMS__ERROR__ORDER_CREATE__NO_ORDER_CREATED':
                case 47012:
                    message.type = 47012
                    break
                case 'OMS__ERROR__ORDER_UPDATE__ORDER_NOT_FOUND':
                case 47013:
                    message.type = 47013
                    break
                case 'OMS__ERROR__ORDER_UPDATE__ORDER_ITEM_NOT_FOUND':
                case 47014:
                    message.type = 47014
                    break
                case 'OMS__ERROR__ORDER_STATUS__NOT_FOUND':
                case 47015:
                    message.type = 47015
                    break
                case 'OMS__ERROR__ORDER_CANCEL__ORDER_NOT_FOUND':
                case 47016:
                    message.type = 47016
                    break
                case 'OMS__ERROR__ORDER_CANCEL__INVALID_STATE':
                case 47017:
                    message.type = 47017
                    break
                case 'OMS__ERROR__ORDER_CANCEL__DELIVERY_NOACK':
                case 47018:
                    message.type = 47018
                    break
                case 'OMS__ERROR__ORDER_CANCEL__PAYMENT_NOACK':
                case 47019:
                    message.type = 47019
                    break
                case 'OMS__ERROR__ORDER_CANCEL__FAILED':
                case 47020:
                    message.type = 47020
                    break
                case 'OMS__ERROR__ORDER_FEEDBACK__INVALID_RATING':
                case 47021:
                    message.type = 47021
                    break
                case 'OMS__ERROR__ORDER_FEEDBACK__ORDER_NOT_FOUND':
                case 47022:
                    message.type = 47022
                    break
                case 'OMS__ERROR__ORDER_FEEDBACK__ALREADY_EXISTS':
                case 47023:
                    message.type = 47023
                    break
                case 'OMS__ERROR__ORDER_FEEDBACK__FAILED_TO_CREATE':
                case 47024:
                    message.type = 47024
                    break
                case 'OMS__ERROR__ORDER_FEEDBACK__FAILED_TO_UPDATE':
                case 47025:
                    message.type = 47025
                    break
                case 'OMS__ERROR__UNRESERVE__FAILED':
                case 47026:
                    message.type = 47026
                    break
                case 'OMS__ERROR__PROMO__NOT_FOUND':
                case 47027:
                    message.type = 47027
                    break
                case 'OMS__ERROR__PROMO__INVALID':
                case 47028:
                    message.type = 47028
                    break
                case 'OMS__ERROR__FAVOURITES_GET__INVALID_WAREHOUSE_CODE':
                case 47029:
                    message.type = 47029
                    break
                case 'OMS__ERROR__FAVOURITES_GET__INVALID_PAGE_NUMBER':
                case 47030:
                    message.type = 47030
                    break
                case 'OMS__ERROR__FAVOURITES_GET__INVALID_LIMIT':
                case 47031:
                    message.type = 47031
                    break
                case 'OMS__ERROR__FAVOURITES_GET__CATALOGUE_SERVICE_UNAVAILABLE':
                case 47032:
                    message.type = 47032
                    break
                case 'OMS__ERROR__FAVOURITES_ADD__FAILED':
                case 47033:
                    message.type = 47033
                    break
                case 'OMS__ERROR__FAVOURITES_REMOVE__ITEM_NOT_FOUND':
                case 47034:
                    message.type = 47034
                    break
                case 'OMS__ERROR__BOUGHT_GET__NOT_FOUND':
                case 47035:
                    message.type = 47035
                    break
                case 'OMS__ERROR__BOUGHT_GET__CATALOGUE_SERVICE_UNAVAILABLE':
                case 47036:
                    message.type = 47036
                    break
                case 'OMS__ORDER_CREATED':
                case 47037:
                    message.type = 47037
                    break
                case 'OMS__ORDER_MODIFY':
                case 47038:
                    message.type = 47038
                    break
                case 'DISCOUNT__ERROR__COUPONS__USED':
                case 57001:
                    message.type = 57001
                    break
                case 'DISCOUNT__ERROR__COUPONS__ONLY_FIRST_ORDER_ALLOWED':
                case 57002:
                    message.type = 57002
                    break
                case 'DISCOUNT__ERROR__COUPONS__NOT_ACTIVE':
                case 57003:
                    message.type = 57003
                    break
                case 'DISCOUNT__ERROR__COUPONS__EXPIRED':
                case 57004:
                    message.type = 57004
                    break
                case 'DISCOUNT__ERROR__COUPONS__NOT_FOUND':
                case 57005:
                    message.type = 57005
                    break
                case 'DISCOUNT__ERROR__COUPONS__NOT_BEGIN':
                case 57006:
                    message.type = 57006
                    break
                case 'DISCOUNT__ERROR__COUPONS__NOT_FOUND_COUPON_GROUP':
                case 57007:
                    message.type = 57007
                    break
                case 'DISCOUNT__ERROR__COUPONS__NOT_FOUND_MECHANISM':
                case 57008:
                    message.type = 57008
                    break
                case 'STOCK__ERROR__RESERVE_NO_WAREHOUSE_CODE':
                case 67002:
                    message.type = 67002
                    break
                case 'STOCK__ERROR__RESERVE_ITEM_NOT_FOUND':
                case 67003:
                    message.type = 67003
                    break
                case 'STOCK__ERROR__RESERVE_NOT_ENOUGH_SELLABLE':
                case 67004:
                    message.type = 67004
                    break
                case 'STOCK__ERROR__RESERVE_QTY_LESS_ZERO':
                case 67005:
                    message.type = 67005
                    break
                case 'STOCK__ERROR__RESERVE_EMPTY_ARRAY':
                case 67006:
                    message.type = 67006
                    break
                case 'STOCK__ERROR__PUSH_EMPTY_ARRAY':
                case 67007:
                    message.type = 67007
                    break
                case 'STOCK__ERROR__GET_NO_SKU':
                case 67008:
                    message.type = 67008
                    break
                case 'ETA__REQUEST__ETA__CALCULATE':
                case 71001:
                    message.type = 71001
                    break
                case 'ETA__RESPONSE__ETA__CALCULATE':
                case 74001:
                    message.type = 74001
                    break
                case 'ETA__RESPONSE__WH__AVAILABILITY':
                case 74002:
                    message.type = 74002
                    break
                case 'ETA__RESPONSE__SHOW_ZONES':
                case 74003:
                    message.type = 74003
                    break
                case 'ETA__RESPONSE__V2_SHOW_ZONES':
                case 74004:
                    message.type = 74004
                    break
                case 'ETA__ERROR__ETA__INVALID_POSITION':
                case 77001:
                    message.type = 77001
                    break
                case 'ETA__ERROR__ETA__CANNOT_DELIVER_TO_ADDRESS':
                case 77002:
                    message.type = 77002
                    break
                case 'ETA__ERROR__ETA__ADDRESS_OUT_OF_RANGE':
                case 77003:
                    message.type = 77003
                    break
                case 'ETA__ERROR__ETA__UNAVAILABLE':
                case 77004:
                    message.type = 77004
                    break
                case 'ETA__ERROR__INVALID_WAREHOUSE_CODE':
                case 77005:
                    message.type = 77005
                    break
                case 'DELIVERY__REQUEST__DELIVERY__CREATE':
                case 81001:
                    message.type = 81001
                    break
                case 'DELIVERY__REQUEST__DELIVERY__CANCEL':
                case 81002:
                    message.type = 81002
                    break
                case 'DELIVERY__REQUEST__DELIVERY__DETAILS':
                case 81003:
                    message.type = 81003
                    break
                case 'DELIVERY__RESPONSE__DELIVERY__CREATE':
                case 84001:
                    message.type = 84001
                    break
                case 'DELIVERY__RESPONSE__DELIVERY__CANCEL':
                case 84002:
                    message.type = 84002
                    break
                case 'DELIVERY__RESPONSE__DELIVERY__DETAILS':
                case 84003:
                    message.type = 84003
                    break
                case 'DELIVERY__ERROR__UNKNOWN_ORDER':
                case 87001:
                    message.type = 87001
                    break
                case 'DELIVERY__ERROR__TASKS_FETCH_FAILED':
                case 87002:
                    message.type = 87002
                    break
                case 'DELIVERY__ERROR__TASKS_CANCEL_FAILED':
                case 87003:
                    message.type = 87003
                    break
                case 'DELIVERY_REQUEST_TIPS_RECEIVED_TIPS':
                case 87004:
                    message.type = 87004
                    break
                case 'CUSTOMER__ERROR__SMS__INVALID_PHONE_NUMBER':
                case 97001:
                    message.type = 97001
                    break
                case 'CUSTOMER__ERROR__SMS__NOT_SENT':
                case 97002:
                    message.type = 97002
                    break
                case 'CUSTOMER__ERROR__SMS__CONTACT__MISSING':
                case 97003:
                    message.type = 97003
                    break
                case 'CUSTOMER__ERROR__SMS__DEVICE_ID__MISSING':
                case 97004:
                    message.type = 97004
                    break
                case 'CUSTOMER__ERROR__SMS__DEVICE_ID__INVALID':
                case 97005:
                    message.type = 97005
                    break
                case 'CUSTOMER__ERROR__AUTH__CUSTOMER_ID__INVALID':
                case 97006:
                    message.type = 97006
                    break
                case 'CUSTOMER__ERROR__AUTH__CUSTOMER_ID__MISSING':
                case 97007:
                    message.type = 97007
                    break
                case 'CUSTOMER__ERROR__AUTH__TOKEN__MISSING':
                case 97008:
                    message.type = 97008
                    break
                case 'CUSTOMER__ERROR__AUTH__OTP__INVALID':
                case 97009:
                    message.type = 97009
                    break
                case 'CUSTOMER__ERROR__EMAIL__TAKEN':
                case 97010:
                    message.type = 97010
                    break
                case 'CUSTOMER__ERROR__LOGIN__INVALID':
                case 97011:
                    message.type = 97011
                    break
                case 'CUSTOMER__ERROR__COUNTRY__INVALID':
                case 97012:
                    message.type = 97012
                    break
                case 'CUSTOMER__CHANGE_WAREHOUSE':
                case 97013:
                    message.type = 97013
                    break
                case 'CUSTOMER__UPDATE_SUBSCRIPTIONS':
                case 97014:
                    message.type = 97014
                    break
                case 'CUSTOMER__REGISTER':
                case 97015:
                    message.type = 97015
                    break
                case 'CUSTOMER__UPDATE_PROFILE':
                case 97016:
                    message.type = 97016
                    break
                case 'CUSTOMER__REGISTER_PHONE':
                case 97017:
                    message.type = 97017
                    break
                case 'CUSTOMER__LOGIN':
                case 97018:
                    message.type = 97018
                    break
                case 'CATALOGUE__PRODUCT__UPSERT':
                case 101001:
                    message.type = 101001
                    break
                case 'CATALOGUE__ERROR__CATEGORY__INVALID_ID':
                case 107001:
                    message.type = 107001
                    break
                case 'CATALOGUE__ERROR__PRODUCT__INVALID_ID':
                case 107002:
                    message.type = 107002
                    break
                case 'CATALOGUE__ERROR__PRODUCT__INACTIVE':
                case 107003:
                    message.type = 107003
                    break
                case 'CATALOGUE__ERROR__PRODUCT__DELETED':
                case 107004:
                    message.type = 107004
                    break
                case 'CATALOGUE__ERROR__PRODUCT__UNSELLABLE':
                case 107005:
                    message.type = 107005
                    break
                case 'CATALOGUE__ERROR__PRODUCT__NOT_FOUND':
                case 107006:
                    message.type = 107006
                    break
                case 'CATALOGUE__ERROR__SEARCH__WH_CODE_MISSING':
                case 107007:
                    message.type = 107007
                    break
                case 'GEOCODER__RESPONSE__AUTOCOMPLETE':
                case 114001:
                    message.type = 114001
                    break
                case 'GEOCODER__ERROR__AUTOCOMPLETE__UNAVAILABLE':
                case 117001:
                    message.type = 117001
                    break
                case 'AUTH__REQUEST__PERMISSION__CREATE':
                case 121001:
                    message.type = 121001
                    break
                case 'AUTH__REQUEST__PERMISSION__PATCH':
                case 121002:
                    message.type = 121002
                    break
                case 'AUTH__REQUEST__PERMISSION__ASSIGN__TO__USER':
                case 121003:
                    message.type = 121003
                    break
                case 'AUTH__REQUEST__PERMISSION__UNLINK__FROM__USER':
                case 121004:
                    message.type = 121004
                    break
                case 'AUTH__REQUEST__PERMISSION__LINK__TO__ROLE':
                case 121005:
                    message.type = 121005
                    break
                case 'AUTH__REQUEST__PERMISSION__UNLINK__FROM__ROLE':
                case 121006:
                    message.type = 121006
                    break
                case 'AUTH__REQUEST__ROLE__CREATE':
                case 121007:
                    message.type = 121007
                    break
                case 'AUTH__REQUEST__ROLE__PATCH':
                case 121008:
                    message.type = 121008
                    break
                case 'AUTH__REQUEST__ROLE__ASSIGN__TO__USER':
                case 121009:
                    message.type = 121009
                    break
                case 'AUTH__REQUEST__ROLE__UNLINK__FROM__USER':
                case 121010:
                    message.type = 121010
                    break
                case 'AUTH__REQUEST__USER__CREATE':
                case 121011:
                    message.type = 121011
                    break
                case 'AUTH__REQUEST__AUTH__CONTACT':
                case 121012:
                    message.type = 121012
                    break
                case 'AUTH__REQUEST__AUTH__VERIFY':
                case 121013:
                    message.type = 121013
                    break
                case 'AUTH__REQUEST__AUTH__REFRESH':
                case 121014:
                    message.type = 121014
                    break
                case 'AUTH__REQUEST__AUTH__LOGOUT':
                case 121015:
                    message.type = 121015
                    break
                case 'AUTH__RESPONSE__PERMISSIONS__GET':
                case 124001:
                    message.type = 124001
                    break
                case 'AUTH__RESPONSE__PERMISSION__CREATE':
                case 124002:
                    message.type = 124002
                    break
                case 'AUTH__RESPONSE__PERMISSION__PATCH':
                case 124003:
                    message.type = 124003
                    break
                case 'AUTH__RESPONSE__ROLES__GET':
                case 124004:
                    message.type = 124004
                    break
                case 'AUTH__RESPONSE__ROLE__GET':
                case 124005:
                    message.type = 124005
                    break
                case 'AUTH__RESPONSE__ROLE__CREATE':
                case 124006:
                    message.type = 124006
                    break
                case 'AUTH__RESPONSE__ROLE__PATCH':
                case 124007:
                    message.type = 124007
                    break
                case 'AUTH__RESPONSE__USERS__GET':
                case 124008:
                    message.type = 124008
                    break
                case 'AUTH__RESPONSE__USER__GET':
                case 124009:
                    message.type = 124009
                    break
                case 'AUTH__RESPONSE__USER__CREATE':
                case 124010:
                    message.type = 124010
                    break
                case 'AUTH__RESPONSE__AUTH__CONTACT':
                case 124011:
                    message.type = 124011
                    break
                case 'AUTH__RESPONSE__AUTH__AUTHORIZE':
                case 124012:
                    message.type = 124012
                    break
                case 'AUTH__RESPONSE__AUTH__REFRESH':
                case 124013:
                    message.type = 124013
                    break
                case 'AUTH__ERROR__HANDLER':
                case 127001:
                    message.type = 127001
                    break
                case 'DISPATCHER__REQUEST__MOBILE__COURIER_MARK_ARRIVAL':
                case 141001:
                    message.type = 141001
                    break
                case 'DISPATCHER__REQUEST__MOBILE__COURIER_MARK_OFFLINE':
                case 141002:
                    message.type = 141002
                    break
                case 'DISPATCHER__REQUEST__MOBILE__COURIER_MARK_ONLINE':
                case 141003:
                    message.type = 141003
                    break
                case 'DISPATCHER__REQUEST__MOBILE__COURIER_MARK_RETURNING':
                case 141004:
                    message.type = 141004
                    break
                case 'DISPATCHER__REQUEST__MOBILE__COURIER_GET_INFO':
                case 141031:
                    message.type = 141031
                    break
                case 'DISPATCHER__REQUEST__MOBILE__JOB_ACCEPT':
                case 141005:
                    message.type = 141005
                    break
                case 'DISPATCHER__REQUEST__MOBILE__JOB_GET_ASSIGNED':
                case 141006:
                    message.type = 141006
                    break
                case 'DISPATCHER__REQUEST__MOBILE__JOB_OVERVIEW':
                case 141007:
                    message.type = 141007
                    break
                case 'DISPATCHER__REQUEST__MOBILE__TASK_COMPLETE':
                case 141008:
                    message.type = 141008
                    break
                case 'DISPATCHER__REQUEST__MOBILE__TASK_ON_POINT':
                case 141009:
                    message.type = 141009
                    break
                case 'DISPATCHER__REQUEST__MOBILE__TASK_START':
                case 141010:
                    message.type = 141010
                    break
                case 'DISPATCHER__REQUEST__MOBILE__TRIP_POINT':
                case 141030:
                    message.type = 141030
                    break
                case 'DISPATCHER__REQUEST__ADMIN__COURIER_ASSIGN_TEAM':
                case 141011:
                    message.type = 141011
                    break
                case 'DISPATCHER__REQUEST__ADMIN__GET_DASHBOARD_INFO':
                case 141012:
                    message.type = 141012
                    break
                case 'DISPATCHER__REQUEST__ADMIN__JOB_ASSIGN_COURIER':
                case 141013:
                    message.type = 141013
                    break
                case 'DISPATCHER__REQUEST__ADMIN__JOBS_GET_LIST':
                case 141014:
                    message.type = 141014
                    break
                case 'DISPATCHER__REQUEST__ADMIN__TASK_ASSIGN_JOB':
                case 141015:
                    message.type = 141015
                    break
                case 'DISPATCHER__REQUEST__ADMIN__TASK_GET_DETAILS':
                case 141016:
                    message.type = 141016
                    break
                case 'DISPATCHER__REQUEST__ADMIN__TEAM_CREATE':
                case 141017:
                    message.type = 141017
                    break
                case 'DISPATCHER__REQUEST__ADMIN__TEAM_UPDATE':
                case 141018:
                    message.type = 141018
                    break
                case 'DISPATCHER__REQUEST__ADMIN__TEAM_DELETE':
                case 141019:
                    message.type = 141019
                    break
                case 'DISPATCHER__REQUEST__ADMIN__TEAM_WITH_WAREHOUSES':
                case 141020:
                    message.type = 141020
                    break
                case 'DISPATCHER__REQUEST__ADMIN__TEAM_SET_WAREHOUSES':
                case 141021:
                    message.type = 141021
                    break
                case 'DISPATCHER__REQUEST__ADMIN__COURIER_CREATE':
                case 141022:
                    message.type = 141022
                    break
                case 'DISPATCHER__REQUEST__ADMIN__COURIER_UPDATE':
                case 141023:
                    message.type = 141023
                    break
                case 'DISPATCHER__REQUEST__ADMIN__COURIER_DEACTIVATE':
                case 141024:
                    message.type = 141024
                    break
                case 'DISPATCHER__REQUEST__ADMIN__COURIER_UNASSIGN_TEAM':
                case 141025:
                    message.type = 141025
                    break
                case 'DISPATCHER__REQUEST__ADMIN__JOB_UNASSIGN_COURIER':
                case 141026:
                    message.type = 141026
                    break
                case 'DISPATCHER__REQUEST__ADMIN__TASK_UNASSIGN_JOB':
                case 141027:
                    message.type = 141027
                    break
                case 'DISPATCHER__REQUEST__ADMIN__COURIER_FORCE_OFFLINE':
                case 141028:
                    message.type = 141028
                    break
                case 'DISPATCHER__REQUEST__ADMIN__ORDER_FORCE_COMPLETE':
                case 141033:
                    message.type = 141033
                    break
                case 'DISPATCHER__REQUEST__ADMIN__ORDER_FORCE_FAIL':
                case 141034:
                    message.type = 141034
                    break
                case 'DISPATCHER__REQUEST__ADMIN__GET_DELIVERY_ZONES':
                case 141035:
                    message.type = 141035
                    break
                case 'DISPATCHER__REQUEST__ADMIN__JOBS_GET_LIST_V2':
                case 141036:
                    message.type = 141036
                    break
                case 'DISPATCHER__REQUEST__ADMIN__JOB_UNASSIGN_ORDER_V2':
                case 141037:
                    message.type = 141037
                    break
                case 'DISPATCHER__REQUEST__ADMIN__ORDER_ASSIGN':
                case 141038:
                    message.type = 141038
                    break
                case 'DISPATCHER__REQUEST__ADMIN__COURIER_CREATE_V2':
                case 141039:
                    message.type = 141039
                    break
                case 'DISPATCHER__REQUEST__ADMIN__WAREHOUSE_WITH_TEAMS':
                case 141040:
                    message.type = 141040
                    break
                case 'DISPATCHER__REQUEST__ADMIN__TEAM_GET_USERS':
                case 141041:
                    message.type = 141041
                    break
                case 'DISPATCHER__REQUEST__ADMIN__SEARCH_USERS':
                case 141042:
                    message.type = 141042
                    break
                case 'DISPATCHER__REQUEST__ADMIN__TEAM_ATTACH_USERS':
                case 141043:
                    message.type = 141043
                    break
                case 'DISPATCHER__REQUEST__ADMIN__TEAM_DETACH_USER':
                case 141044:
                    message.type = 141044
                    break
                case 'DISPATCHER__REQUEST__ADMIN__FORCE_PICK':
                case 141045:
                    message.type = 141045
                    break
                case 'DISPATCHER__REQUEST__ADMIN__COURIER_CREATE_V3':
                case 141046:
                    message.type = 141046
                    break
                case 'DISPATCHER__REQUEST__ADMIN__GET_COURIER_BY_USER_EXTERNAL_ID':
                case 141047:
                    message.type = 141047
                    break
                case 'DISPATCHER__REQUEST__ADMIN__GET_ALL_ORDERS_WITH_FILTERS':
                case 141048:
                    message.type = 141048
                    break
                case 'DISPATCHER__REQUEST__ORDER_GET_LOCATION':
                case 141029:
                    message.type = 141029
                    break
                case 'DISPATCHER__RESPONSE__MOBILE__COURIER_MARK_ARRIVAL':
                case 144001:
                    message.type = 144001
                    break
                case 'DISPATCHER__RESPONSE__MOBILE__COURIER_MARK_OFFLINE':
                case 144002:
                    message.type = 144002
                    break
                case 'DISPATCHER__RESPONSE__MOBILE__COURIER_MARK_ONLINE':
                case 144003:
                    message.type = 144003
                    break
                case 'DISPATCHER__RESPONSE__MOBILE__COURIER_MARK_RETURNING':
                case 144004:
                    message.type = 144004
                    break
                case 'DISPATCHER__RESPONSE__MOBILE__COURIER_GET_INFO':
                case 144045:
                    message.type = 144045
                    break
                case 'DISPATCHER__RESPONSE__MOBILE__JOB_ACCEPT':
                case 144005:
                    message.type = 144005
                    break
                case 'DISPATCHER__RESPONSE__MOBILE__JOB_GET_ASSIGNED':
                case 144006:
                    message.type = 144006
                    break
                case 'DISPATCHER__RESPONSE__MOBILE__JOB_OVERVIEW':
                case 144007:
                    message.type = 144007
                    break
                case 'DISPATCHER__RESPONSE__MOBILE__TASK_COMPLETE':
                case 144008:
                    message.type = 144008
                    break
                case 'DISPATCHER__RESPONSE__MOBILE__TASK_ON_POINT':
                case 144009:
                    message.type = 144009
                    break
                case 'DISPATCHER__RESPONSE__MOBILE__TASK_START':
                case 144010:
                    message.type = 144010
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__COURIER_ASSIGN_TEAM':
                case 144011:
                    message.type = 144011
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__GET_DASHBOARD_INFO':
                case 144012:
                    message.type = 144012
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__JOB_ASSIGN_COURIER':
                case 144013:
                    message.type = 144013
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__JOBS_GET_LIST':
                case 144014:
                    message.type = 144014
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__TASK_ASSIGN_JOB':
                case 144015:
                    message.type = 144015
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__TASK_GET_DETAILS':
                case 144016:
                    message.type = 144016
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__TEAM_CREATE':
                case 144017:
                    message.type = 144017
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__TEAM_UPDATE':
                case 144018:
                    message.type = 144018
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__TEAM_DELETE':
                case 144019:
                    message.type = 144019
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__TEAM_GET_ALL':
                case 144020:
                    message.type = 144020
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__TEAM_WITH_WAREHOUSES':
                case 144021:
                    message.type = 144021
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__TEAM_SET_WAREHOUSES':
                case 144022:
                    message.type = 144022
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__COURIER_CREATE':
                case 144023:
                    message.type = 144023
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__COURIER_UPDATE':
                case 144024:
                    message.type = 144024
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__COURIER_DEACTIVATE':
                case 144025:
                    message.type = 144025
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__COURIER_UNASSIGN_TEAM':
                case 144026:
                    message.type = 144026
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__JOB_UNASSIGN_COURIER':
                case 144027:
                    message.type = 144027
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__TASK_UNASSIGN_JOB':
                case 144028:
                    message.type = 144028
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__COURIER_FORCE_OFFLINE':
                case 144029:
                    message.type = 144029
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__WAREHOUSES_GET_ALL':
                case 144030:
                    message.type = 144030
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__GET_DASHBOARD_INFO_V2':
                case 144033:
                    message.type = 144033
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__GET_DELIVERY_ZONES':
                case 144034:
                    message.type = 144034
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__JOBS_GET_LIST_V2':
                case 144035:
                    message.type = 144035
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__SEARCH_ORDERS':
                case 144036:
                    message.type = 144036
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__JOB_UNASSIGN_ORDER':
                case 144037:
                    message.type = 144037
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__ORDER_ASSIGN':
                case 144038:
                    message.type = 144038
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__WAREHOUSE_WITH_TEAMS':
                case 144040:
                    message.type = 144040
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__TEAM_GET_USERS':
                case 144041:
                    message.type = 144041
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__SEARCH_USERS':
                case 144042:
                    message.type = 144042
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__TEAM_ATTACH_USERS':
                case 144043:
                    message.type = 144043
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__TEAM_DETACH_USER':
                case 144044:
                    message.type = 144044
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__COURIER_CREATE_V3':
                case 144046:
                    message.type = 144046
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__GET_COURIER_BY_USER_EXTERNAL_ID':
                case 144047:
                    message.type = 144047
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__JOB_MAP_INFO':
                case 144048:
                    message.type = 144048
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__GET_ALL_COURIERS':
                case 144049:
                    message.type = 144049
                    break
                case 'DISPATCHER__RESPONSE__ADMIN__GET_ALL_ORDERS_WITH_FILTERS':
                case 144050:
                    message.type = 144050
                    break
                case 'DISPATCHER__RESPONSE__ORDER_GET_LOCATION':
                case 144031:
                    message.type = 144031
                    break
                case 'DISPATCHER__RESPONSE__COURIERS_GET_IDLE':
                case 144032:
                    message.type = 144032
                    break
                case 'DISPATCHER__RESPONSE__COURIER_LIST':
                case 144051:
                    message.type = 144051
                    break
                case 'DISPATCHER__RESPONSE__COURIER_BULK':
                case 144052:
                    message.type = 144052
                    break
                case 'DISPATCHER__RESPONSE__COURIER_GET':
                case 144053:
                    message.type = 144053
                    break
                case 'DISPATCHER__RESPONSE__TASK_LIST':
                case 144054:
                    message.type = 144054
                    break
                case 'DISPATCHER__RESPONSE__TASK_BULK':
                case 144055:
                    message.type = 144055
                    break
                case 'DISPATCHER__RESPONSE__TASK_GET':
                case 144056:
                    message.type = 144056
                    break
                case 'DISPATCHER__RESPONSE__CREATE_DELIVERY':
                case 144061:
                    message.type = 144061
                    break
                case 'DISPATCHER__RESPONSE__GET_PARCELS':
                case 144062:
                    message.type = 144062
                    break
                case 'DISPATCHER__RESPONSE__MARK_ORDER_PICKED':
                case 144063:
                    message.type = 144063
                    break
                case 'DISPATCHER__REQUEST__GET_PARCELS':
                case 144073:
                    message.type = 144073
                    break
                case 'DISPATCHER__REQUEST__CREATE_DELIVERY':
                case 144074:
                    message.type = 144074
                    break
                case 'DISPATCHER__REQUEST__MARK_ORDER_PICKED':
                case 144075:
                    message.type = 144075
                    break
                case 'DISPATCHER__ERROR_ORDERS_NOT_PICKED':
                case 146000:
                    message.type = 146000
                    break
            }
            if (object.data != null)
                if (typeof object.data === 'string')
                    $util.base64.decode(
                        object.data,
                        (message.data = $util.newBuffer($util.base64.length(object.data))),
                        0
                    )
                else if (object.data.length >= 0) message.data = object.data
            return message
        }

        /**
         * Creates a plain object from a JiffyMessageBody message. Also converts values to other types if specified.
         * @function toObject
         * @memberof JiffyMessageBody
         * @static
         * @param {JiffyMessageBody} message JiffyMessageBody
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        JiffyMessageBody.toObject = function toObject(message, options) {
            if (!options) options = {}
            var object = {}
            if (options.defaults) {
                object.type = options.enums === String ? 'ERROR' : 0
                if (options.bytes === String) object.data = ''
                else {
                    object.data = []
                    if (options.bytes !== Array) object.data = $util.newBuffer(object.data)
                }
            }
            if (message.type != null && message.hasOwnProperty('type'))
                object.type = options.enums === String ? $root.JiffyMessageBody.Type[message.type] : message.type
            if (message.data != null && message.hasOwnProperty('data'))
                object.data =
                    options.bytes === String
                        ? $util.base64.encode(message.data, 0, message.data.length)
                        : options.bytes === Array
                        ? Array.prototype.slice.call(message.data)
                        : message.data
            return object
        }

        /**
         * Converts this JiffyMessageBody to JSON.
         * @function toJSON
         * @memberof JiffyMessageBody
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        JiffyMessageBody.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        /**
         * Gets the default type url for JiffyMessageBody
         * @function getTypeUrl
         * @memberof JiffyMessageBody
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        JiffyMessageBody.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = 'type.googleapis.com'
            }
            return typeUrlPrefix + '/JiffyMessageBody'
        }

        /**
         * Type enum.
         * @name JiffyMessageBody.Type
         * @enum {number}
         * @property {number} ERROR=0 ERROR value
         * @property {number} SUCCESS=1 SUCCESS value
         * @property {number} ONESIGNAL__REQUEST__NOTIFY__ORDER__PICKING=21001 ONESIGNAL__REQUEST__NOTIFY__ORDER__PICKING value
         * @property {number} ONESIGNAL__REQUEST__NOTIFY__ORDER__PICKED_PARTIALLY=21002 ONESIGNAL__REQUEST__NOTIFY__ORDER__PICKED_PARTIALLY value
         * @property {number} ONESIGNAL__REQUEST__NOTIFY__ORDER__IN_DELIVERY=21003 ONESIGNAL__REQUEST__NOTIFY__ORDER__IN_DELIVERY value
         * @property {number} ONESIGNAL__REQUEST__NOTIFY__ORDER__DELIVERED=21004 ONESIGNAL__REQUEST__NOTIFY__ORDER__DELIVERED value
         * @property {number} ONESIGNAL__REQUEST__NOTIFY__ORDER__PAYMENT_CHARGED=21005 ONESIGNAL__REQUEST__NOTIFY__ORDER__PAYMENT_CHARGED value
         * @property {number} ONESIGNAL__REQUEST__NOTIFY__SUPPORT__REPLY=21006 ONESIGNAL__REQUEST__NOTIFY__SUPPORT__REPLY value
         * @property {number} ONESIGNAL__REQUEST__TAG__EDIT=21007 ONESIGNAL__REQUEST__TAG__EDIT value
         * @property {number} ONESIGNAL__REQUEST__NOTIFY__SEARCH_AGAIN=21008 ONESIGNAL__REQUEST__NOTIFY__SEARCH_AGAIN value
         * @property {number} ONESIGNAL__REQUEST__NOTIFY__ABANDONEW_VIEW=21009 ONESIGNAL__REQUEST__NOTIFY__ABANDONEW_VIEW value
         * @property {number} CHAT__RESPONSE__CONVERSATION=34001 CHAT__RESPONSE__CONVERSATION value
         * @property {number} CHAT__ERROR__AUTH_INVALID=37001 CHAT__ERROR__AUTH_INVALID value
         * @property {number} CHAT__ERROR__BODY_INVALID=37002 CHAT__ERROR__BODY_INVALID value
         * @property {number} CHAT__ERROR__VOXIMPLANT_UNAVAILABLE=37003 CHAT__ERROR__VOXIMPLANT_UNAVAILABLE value
         * @property {number} OMS__ERROR__ORDER_CREATE__SLA_UNAVAILABLE=47001 OMS__ERROR__ORDER_CREATE__SLA_UNAVAILABLE value
         * @property {number} OMS__ERROR__ORDER_CREATE__CUSTOMER_SERVICE_UNAVAILABLE=47002 OMS__ERROR__ORDER_CREATE__CUSTOMER_SERVICE_UNAVAILABLE value
         * @property {number} OMS__ERROR__ORDER_CREATE__PAYMENT_SERVICE_UNAVAILABLE=47003 OMS__ERROR__ORDER_CREATE__PAYMENT_SERVICE_UNAVAILABLE value
         * @property {number} OMS__ERROR__ORDER_CREATE__INVALID_WAREHOUSE_CODE=47004 OMS__ERROR__ORDER_CREATE__INVALID_WAREHOUSE_CODE value
         * @property {number} OMS__ERROR__ORDER_CREATE__INSUFFICIENT_ITEMS_IN_STOCK=47005 OMS__ERROR__ORDER_CREATE__INSUFFICIENT_ITEMS_IN_STOCK value
         * @property {number} OMS__ERROR__ORDER_CREATE__ITEM_OUT_OF_STOCK=47006 OMS__ERROR__ORDER_CREATE__ITEM_OUT_OF_STOCK value
         * @property {number} OMS__ERROR__ORDER_CREATE__STOCK_RESERVATION_UNAVAILABLE=47007 OMS__ERROR__ORDER_CREATE__STOCK_RESERVATION_UNAVAILABLE value
         * @property {number} OMS__ERROR__ORDER_CREATE__INCORRECT_PRICE=47008 OMS__ERROR__ORDER_CREATE__INCORRECT_PRICE value
         * @property {number} OMS__ERROR__ORDER_CREATE__INCORRECT_DELIVERY_PRICE=47009 OMS__ERROR__ORDER_CREATE__INCORRECT_DELIVERY_PRICE value
         * @property {number} OMS__ERROR__ORDER_CREATE__PROMOCODE__INVALID=47010 OMS__ERROR__ORDER_CREATE__PROMOCODE__INVALID value
         * @property {number} OMS__ERROR__ORDER_CREATE__PROMOCODE__TOTAL_LESS_THAN_MIN_VALUE=47011 OMS__ERROR__ORDER_CREATE__PROMOCODE__TOTAL_LESS_THAN_MIN_VALUE value
         * @property {number} OMS__ERROR__ORDER_CREATE__NO_ORDER_CREATED=47012 OMS__ERROR__ORDER_CREATE__NO_ORDER_CREATED value
         * @property {number} OMS__ERROR__ORDER_UPDATE__ORDER_NOT_FOUND=47013 OMS__ERROR__ORDER_UPDATE__ORDER_NOT_FOUND value
         * @property {number} OMS__ERROR__ORDER_UPDATE__ORDER_ITEM_NOT_FOUND=47014 OMS__ERROR__ORDER_UPDATE__ORDER_ITEM_NOT_FOUND value
         * @property {number} OMS__ERROR__ORDER_STATUS__NOT_FOUND=47015 OMS__ERROR__ORDER_STATUS__NOT_FOUND value
         * @property {number} OMS__ERROR__ORDER_CANCEL__ORDER_NOT_FOUND=47016 OMS__ERROR__ORDER_CANCEL__ORDER_NOT_FOUND value
         * @property {number} OMS__ERROR__ORDER_CANCEL__INVALID_STATE=47017 OMS__ERROR__ORDER_CANCEL__INVALID_STATE value
         * @property {number} OMS__ERROR__ORDER_CANCEL__DELIVERY_NOACK=47018 OMS__ERROR__ORDER_CANCEL__DELIVERY_NOACK value
         * @property {number} OMS__ERROR__ORDER_CANCEL__PAYMENT_NOACK=47019 OMS__ERROR__ORDER_CANCEL__PAYMENT_NOACK value
         * @property {number} OMS__ERROR__ORDER_CANCEL__FAILED=47020 OMS__ERROR__ORDER_CANCEL__FAILED value
         * @property {number} OMS__ERROR__ORDER_FEEDBACK__INVALID_RATING=47021 OMS__ERROR__ORDER_FEEDBACK__INVALID_RATING value
         * @property {number} OMS__ERROR__ORDER_FEEDBACK__ORDER_NOT_FOUND=47022 OMS__ERROR__ORDER_FEEDBACK__ORDER_NOT_FOUND value
         * @property {number} OMS__ERROR__ORDER_FEEDBACK__ALREADY_EXISTS=47023 OMS__ERROR__ORDER_FEEDBACK__ALREADY_EXISTS value
         * @property {number} OMS__ERROR__ORDER_FEEDBACK__FAILED_TO_CREATE=47024 OMS__ERROR__ORDER_FEEDBACK__FAILED_TO_CREATE value
         * @property {number} OMS__ERROR__ORDER_FEEDBACK__FAILED_TO_UPDATE=47025 OMS__ERROR__ORDER_FEEDBACK__FAILED_TO_UPDATE value
         * @property {number} OMS__ERROR__UNRESERVE__FAILED=47026 OMS__ERROR__UNRESERVE__FAILED value
         * @property {number} OMS__ERROR__PROMO__NOT_FOUND=47027 OMS__ERROR__PROMO__NOT_FOUND value
         * @property {number} OMS__ERROR__PROMO__INVALID=47028 OMS__ERROR__PROMO__INVALID value
         * @property {number} OMS__ERROR__FAVOURITES_GET__INVALID_WAREHOUSE_CODE=47029 OMS__ERROR__FAVOURITES_GET__INVALID_WAREHOUSE_CODE value
         * @property {number} OMS__ERROR__FAVOURITES_GET__INVALID_PAGE_NUMBER=47030 OMS__ERROR__FAVOURITES_GET__INVALID_PAGE_NUMBER value
         * @property {number} OMS__ERROR__FAVOURITES_GET__INVALID_LIMIT=47031 OMS__ERROR__FAVOURITES_GET__INVALID_LIMIT value
         * @property {number} OMS__ERROR__FAVOURITES_GET__CATALOGUE_SERVICE_UNAVAILABLE=47032 OMS__ERROR__FAVOURITES_GET__CATALOGUE_SERVICE_UNAVAILABLE value
         * @property {number} OMS__ERROR__FAVOURITES_ADD__FAILED=47033 OMS__ERROR__FAVOURITES_ADD__FAILED value
         * @property {number} OMS__ERROR__FAVOURITES_REMOVE__ITEM_NOT_FOUND=47034 OMS__ERROR__FAVOURITES_REMOVE__ITEM_NOT_FOUND value
         * @property {number} OMS__ERROR__BOUGHT_GET__NOT_FOUND=47035 OMS__ERROR__BOUGHT_GET__NOT_FOUND value
         * @property {number} OMS__ERROR__BOUGHT_GET__CATALOGUE_SERVICE_UNAVAILABLE=47036 OMS__ERROR__BOUGHT_GET__CATALOGUE_SERVICE_UNAVAILABLE value
         * @property {number} OMS__ORDER_CREATED=47037 OMS__ORDER_CREATED value
         * @property {number} OMS__ORDER_MODIFY=47038 OMS__ORDER_MODIFY value
         * @property {number} DISCOUNT__ERROR__COUPONS__USED=57001 DISCOUNT__ERROR__COUPONS__USED value
         * @property {number} DISCOUNT__ERROR__COUPONS__ONLY_FIRST_ORDER_ALLOWED=57002 DISCOUNT__ERROR__COUPONS__ONLY_FIRST_ORDER_ALLOWED value
         * @property {number} DISCOUNT__ERROR__COUPONS__NOT_ACTIVE=57003 DISCOUNT__ERROR__COUPONS__NOT_ACTIVE value
         * @property {number} DISCOUNT__ERROR__COUPONS__EXPIRED=57004 DISCOUNT__ERROR__COUPONS__EXPIRED value
         * @property {number} DISCOUNT__ERROR__COUPONS__NOT_FOUND=57005 DISCOUNT__ERROR__COUPONS__NOT_FOUND value
         * @property {number} DISCOUNT__ERROR__COUPONS__NOT_BEGIN=57006 DISCOUNT__ERROR__COUPONS__NOT_BEGIN value
         * @property {number} DISCOUNT__ERROR__COUPONS__NOT_FOUND_COUPON_GROUP=57007 DISCOUNT__ERROR__COUPONS__NOT_FOUND_COUPON_GROUP value
         * @property {number} DISCOUNT__ERROR__COUPONS__NOT_FOUND_MECHANISM=57008 DISCOUNT__ERROR__COUPONS__NOT_FOUND_MECHANISM value
         * @property {number} STOCK__ERROR__RESERVE_NO_WAREHOUSE_CODE=67002 STOCK__ERROR__RESERVE_NO_WAREHOUSE_CODE value
         * @property {number} STOCK__ERROR__RESERVE_ITEM_NOT_FOUND=67003 STOCK__ERROR__RESERVE_ITEM_NOT_FOUND value
         * @property {number} STOCK__ERROR__RESERVE_NOT_ENOUGH_SELLABLE=67004 STOCK__ERROR__RESERVE_NOT_ENOUGH_SELLABLE value
         * @property {number} STOCK__ERROR__RESERVE_QTY_LESS_ZERO=67005 STOCK__ERROR__RESERVE_QTY_LESS_ZERO value
         * @property {number} STOCK__ERROR__RESERVE_EMPTY_ARRAY=67006 STOCK__ERROR__RESERVE_EMPTY_ARRAY value
         * @property {number} STOCK__ERROR__PUSH_EMPTY_ARRAY=67007 STOCK__ERROR__PUSH_EMPTY_ARRAY value
         * @property {number} STOCK__ERROR__GET_NO_SKU=67008 STOCK__ERROR__GET_NO_SKU value
         * @property {number} ETA__REQUEST__ETA__CALCULATE=71001 ETA__REQUEST__ETA__CALCULATE value
         * @property {number} ETA__RESPONSE__ETA__CALCULATE=74001 ETA__RESPONSE__ETA__CALCULATE value
         * @property {number} ETA__RESPONSE__WH__AVAILABILITY=74002 ETA__RESPONSE__WH__AVAILABILITY value
         * @property {number} ETA__RESPONSE__SHOW_ZONES=74003 ETA__RESPONSE__SHOW_ZONES value
         * @property {number} ETA__RESPONSE__V2_SHOW_ZONES=74004 ETA__RESPONSE__V2_SHOW_ZONES value
         * @property {number} ETA__ERROR__ETA__INVALID_POSITION=77001 ETA__ERROR__ETA__INVALID_POSITION value
         * @property {number} ETA__ERROR__ETA__CANNOT_DELIVER_TO_ADDRESS=77002 ETA__ERROR__ETA__CANNOT_DELIVER_TO_ADDRESS value
         * @property {number} ETA__ERROR__ETA__ADDRESS_OUT_OF_RANGE=77003 ETA__ERROR__ETA__ADDRESS_OUT_OF_RANGE value
         * @property {number} ETA__ERROR__ETA__UNAVAILABLE=77004 ETA__ERROR__ETA__UNAVAILABLE value
         * @property {number} ETA__ERROR__INVALID_WAREHOUSE_CODE=77005 ETA__ERROR__INVALID_WAREHOUSE_CODE value
         * @property {number} DELIVERY__REQUEST__DELIVERY__CREATE=81001 DELIVERY__REQUEST__DELIVERY__CREATE value
         * @property {number} DELIVERY__REQUEST__DELIVERY__CANCEL=81002 DELIVERY__REQUEST__DELIVERY__CANCEL value
         * @property {number} DELIVERY__REQUEST__DELIVERY__DETAILS=81003 DELIVERY__REQUEST__DELIVERY__DETAILS value
         * @property {number} DELIVERY__RESPONSE__DELIVERY__CREATE=84001 DELIVERY__RESPONSE__DELIVERY__CREATE value
         * @property {number} DELIVERY__RESPONSE__DELIVERY__CANCEL=84002 DELIVERY__RESPONSE__DELIVERY__CANCEL value
         * @property {number} DELIVERY__RESPONSE__DELIVERY__DETAILS=84003 DELIVERY__RESPONSE__DELIVERY__DETAILS value
         * @property {number} DELIVERY__ERROR__UNKNOWN_ORDER=87001 DELIVERY__ERROR__UNKNOWN_ORDER value
         * @property {number} DELIVERY__ERROR__TASKS_FETCH_FAILED=87002 DELIVERY__ERROR__TASKS_FETCH_FAILED value
         * @property {number} DELIVERY__ERROR__TASKS_CANCEL_FAILED=87003 DELIVERY__ERROR__TASKS_CANCEL_FAILED value
         * @property {number} DELIVERY_REQUEST_TIPS_RECEIVED_TIPS=87004 DELIVERY_REQUEST_TIPS_RECEIVED_TIPS value
         * @property {number} CUSTOMER__ERROR__SMS__INVALID_PHONE_NUMBER=97001 CUSTOMER__ERROR__SMS__INVALID_PHONE_NUMBER value
         * @property {number} CUSTOMER__ERROR__SMS__NOT_SENT=97002 CUSTOMER__ERROR__SMS__NOT_SENT value
         * @property {number} CUSTOMER__ERROR__SMS__CONTACT__MISSING=97003 CUSTOMER__ERROR__SMS__CONTACT__MISSING value
         * @property {number} CUSTOMER__ERROR__SMS__DEVICE_ID__MISSING=97004 CUSTOMER__ERROR__SMS__DEVICE_ID__MISSING value
         * @property {number} CUSTOMER__ERROR__SMS__DEVICE_ID__INVALID=97005 CUSTOMER__ERROR__SMS__DEVICE_ID__INVALID value
         * @property {number} CUSTOMER__ERROR__AUTH__CUSTOMER_ID__INVALID=97006 CUSTOMER__ERROR__AUTH__CUSTOMER_ID__INVALID value
         * @property {number} CUSTOMER__ERROR__AUTH__CUSTOMER_ID__MISSING=97007 CUSTOMER__ERROR__AUTH__CUSTOMER_ID__MISSING value
         * @property {number} CUSTOMER__ERROR__AUTH__TOKEN__MISSING=97008 CUSTOMER__ERROR__AUTH__TOKEN__MISSING value
         * @property {number} CUSTOMER__ERROR__AUTH__OTP__INVALID=97009 CUSTOMER__ERROR__AUTH__OTP__INVALID value
         * @property {number} CUSTOMER__ERROR__EMAIL__TAKEN=97010 CUSTOMER__ERROR__EMAIL__TAKEN value
         * @property {number} CUSTOMER__ERROR__LOGIN__INVALID=97011 CUSTOMER__ERROR__LOGIN__INVALID value
         * @property {number} CUSTOMER__ERROR__COUNTRY__INVALID=97012 CUSTOMER__ERROR__COUNTRY__INVALID value
         * @property {number} CUSTOMER__CHANGE_WAREHOUSE=97013 CUSTOMER__CHANGE_WAREHOUSE value
         * @property {number} CUSTOMER__UPDATE_SUBSCRIPTIONS=97014 CUSTOMER__UPDATE_SUBSCRIPTIONS value
         * @property {number} CUSTOMER__REGISTER=97015 CUSTOMER__REGISTER value
         * @property {number} CUSTOMER__UPDATE_PROFILE=97016 CUSTOMER__UPDATE_PROFILE value
         * @property {number} CUSTOMER__REGISTER_PHONE=97017 CUSTOMER__REGISTER_PHONE value
         * @property {number} CUSTOMER__LOGIN=97018 CUSTOMER__LOGIN value
         * @property {number} CATALOGUE__PRODUCT__UPSERT=101001 CATALOGUE__PRODUCT__UPSERT value
         * @property {number} CATALOGUE__ERROR__CATEGORY__INVALID_ID=107001 CATALOGUE__ERROR__CATEGORY__INVALID_ID value
         * @property {number} CATALOGUE__ERROR__PRODUCT__INVALID_ID=107002 CATALOGUE__ERROR__PRODUCT__INVALID_ID value
         * @property {number} CATALOGUE__ERROR__PRODUCT__INACTIVE=107003 CATALOGUE__ERROR__PRODUCT__INACTIVE value
         * @property {number} CATALOGUE__ERROR__PRODUCT__DELETED=107004 CATALOGUE__ERROR__PRODUCT__DELETED value
         * @property {number} CATALOGUE__ERROR__PRODUCT__UNSELLABLE=107005 CATALOGUE__ERROR__PRODUCT__UNSELLABLE value
         * @property {number} CATALOGUE__ERROR__PRODUCT__NOT_FOUND=107006 CATALOGUE__ERROR__PRODUCT__NOT_FOUND value
         * @property {number} CATALOGUE__ERROR__SEARCH__WH_CODE_MISSING=107007 CATALOGUE__ERROR__SEARCH__WH_CODE_MISSING value
         * @property {number} GEOCODER__RESPONSE__AUTOCOMPLETE=114001 GEOCODER__RESPONSE__AUTOCOMPLETE value
         * @property {number} GEOCODER__ERROR__AUTOCOMPLETE__UNAVAILABLE=117001 GEOCODER__ERROR__AUTOCOMPLETE__UNAVAILABLE value
         * @property {number} AUTH__REQUEST__PERMISSION__CREATE=121001 AUTH__REQUEST__PERMISSION__CREATE value
         * @property {number} AUTH__REQUEST__PERMISSION__PATCH=121002 AUTH__REQUEST__PERMISSION__PATCH value
         * @property {number} AUTH__REQUEST__PERMISSION__ASSIGN__TO__USER=121003 AUTH__REQUEST__PERMISSION__ASSIGN__TO__USER value
         * @property {number} AUTH__REQUEST__PERMISSION__UNLINK__FROM__USER=121004 AUTH__REQUEST__PERMISSION__UNLINK__FROM__USER value
         * @property {number} AUTH__REQUEST__PERMISSION__LINK__TO__ROLE=121005 AUTH__REQUEST__PERMISSION__LINK__TO__ROLE value
         * @property {number} AUTH__REQUEST__PERMISSION__UNLINK__FROM__ROLE=121006 AUTH__REQUEST__PERMISSION__UNLINK__FROM__ROLE value
         * @property {number} AUTH__REQUEST__ROLE__CREATE=121007 AUTH__REQUEST__ROLE__CREATE value
         * @property {number} AUTH__REQUEST__ROLE__PATCH=121008 AUTH__REQUEST__ROLE__PATCH value
         * @property {number} AUTH__REQUEST__ROLE__ASSIGN__TO__USER=121009 AUTH__REQUEST__ROLE__ASSIGN__TO__USER value
         * @property {number} AUTH__REQUEST__ROLE__UNLINK__FROM__USER=121010 AUTH__REQUEST__ROLE__UNLINK__FROM__USER value
         * @property {number} AUTH__REQUEST__USER__CREATE=121011 AUTH__REQUEST__USER__CREATE value
         * @property {number} AUTH__REQUEST__AUTH__CONTACT=121012 AUTH__REQUEST__AUTH__CONTACT value
         * @property {number} AUTH__REQUEST__AUTH__VERIFY=121013 AUTH__REQUEST__AUTH__VERIFY value
         * @property {number} AUTH__REQUEST__AUTH__REFRESH=121014 AUTH__REQUEST__AUTH__REFRESH value
         * @property {number} AUTH__REQUEST__AUTH__LOGOUT=121015 AUTH__REQUEST__AUTH__LOGOUT value
         * @property {number} AUTH__RESPONSE__PERMISSIONS__GET=124001 AUTH__RESPONSE__PERMISSIONS__GET value
         * @property {number} AUTH__RESPONSE__PERMISSION__CREATE=124002 AUTH__RESPONSE__PERMISSION__CREATE value
         * @property {number} AUTH__RESPONSE__PERMISSION__PATCH=124003 AUTH__RESPONSE__PERMISSION__PATCH value
         * @property {number} AUTH__RESPONSE__ROLES__GET=124004 AUTH__RESPONSE__ROLES__GET value
         * @property {number} AUTH__RESPONSE__ROLE__GET=124005 AUTH__RESPONSE__ROLE__GET value
         * @property {number} AUTH__RESPONSE__ROLE__CREATE=124006 AUTH__RESPONSE__ROLE__CREATE value
         * @property {number} AUTH__RESPONSE__ROLE__PATCH=124007 AUTH__RESPONSE__ROLE__PATCH value
         * @property {number} AUTH__RESPONSE__USERS__GET=124008 AUTH__RESPONSE__USERS__GET value
         * @property {number} AUTH__RESPONSE__USER__GET=124009 AUTH__RESPONSE__USER__GET value
         * @property {number} AUTH__RESPONSE__USER__CREATE=124010 AUTH__RESPONSE__USER__CREATE value
         * @property {number} AUTH__RESPONSE__AUTH__CONTACT=124011 AUTH__RESPONSE__AUTH__CONTACT value
         * @property {number} AUTH__RESPONSE__AUTH__AUTHORIZE=124012 AUTH__RESPONSE__AUTH__AUTHORIZE value
         * @property {number} AUTH__RESPONSE__AUTH__REFRESH=124013 AUTH__RESPONSE__AUTH__REFRESH value
         * @property {number} AUTH__ERROR__HANDLER=127001 AUTH__ERROR__HANDLER value
         * @property {number} DISPATCHER__REQUEST__MOBILE__COURIER_MARK_ARRIVAL=141001 DISPATCHER__REQUEST__MOBILE__COURIER_MARK_ARRIVAL value
         * @property {number} DISPATCHER__REQUEST__MOBILE__COURIER_MARK_OFFLINE=141002 DISPATCHER__REQUEST__MOBILE__COURIER_MARK_OFFLINE value
         * @property {number} DISPATCHER__REQUEST__MOBILE__COURIER_MARK_ONLINE=141003 DISPATCHER__REQUEST__MOBILE__COURIER_MARK_ONLINE value
         * @property {number} DISPATCHER__REQUEST__MOBILE__COURIER_MARK_RETURNING=141004 DISPATCHER__REQUEST__MOBILE__COURIER_MARK_RETURNING value
         * @property {number} DISPATCHER__REQUEST__MOBILE__COURIER_GET_INFO=141031 DISPATCHER__REQUEST__MOBILE__COURIER_GET_INFO value
         * @property {number} DISPATCHER__REQUEST__MOBILE__JOB_ACCEPT=141005 DISPATCHER__REQUEST__MOBILE__JOB_ACCEPT value
         * @property {number} DISPATCHER__REQUEST__MOBILE__JOB_GET_ASSIGNED=141006 DISPATCHER__REQUEST__MOBILE__JOB_GET_ASSIGNED value
         * @property {number} DISPATCHER__REQUEST__MOBILE__JOB_OVERVIEW=141007 DISPATCHER__REQUEST__MOBILE__JOB_OVERVIEW value
         * @property {number} DISPATCHER__REQUEST__MOBILE__TASK_COMPLETE=141008 DISPATCHER__REQUEST__MOBILE__TASK_COMPLETE value
         * @property {number} DISPATCHER__REQUEST__MOBILE__TASK_ON_POINT=141009 DISPATCHER__REQUEST__MOBILE__TASK_ON_POINT value
         * @property {number} DISPATCHER__REQUEST__MOBILE__TASK_START=141010 DISPATCHER__REQUEST__MOBILE__TASK_START value
         * @property {number} DISPATCHER__REQUEST__MOBILE__TRIP_POINT=141030 DISPATCHER__REQUEST__MOBILE__TRIP_POINT value
         * @property {number} DISPATCHER__REQUEST__ADMIN__COURIER_ASSIGN_TEAM=141011 DISPATCHER__REQUEST__ADMIN__COURIER_ASSIGN_TEAM value
         * @property {number} DISPATCHER__REQUEST__ADMIN__GET_DASHBOARD_INFO=141012 DISPATCHER__REQUEST__ADMIN__GET_DASHBOARD_INFO value
         * @property {number} DISPATCHER__REQUEST__ADMIN__JOB_ASSIGN_COURIER=141013 DISPATCHER__REQUEST__ADMIN__JOB_ASSIGN_COURIER value
         * @property {number} DISPATCHER__REQUEST__ADMIN__JOBS_GET_LIST=141014 DISPATCHER__REQUEST__ADMIN__JOBS_GET_LIST value
         * @property {number} DISPATCHER__REQUEST__ADMIN__TASK_ASSIGN_JOB=141015 DISPATCHER__REQUEST__ADMIN__TASK_ASSIGN_JOB value
         * @property {number} DISPATCHER__REQUEST__ADMIN__TASK_GET_DETAILS=141016 DISPATCHER__REQUEST__ADMIN__TASK_GET_DETAILS value
         * @property {number} DISPATCHER__REQUEST__ADMIN__TEAM_CREATE=141017 DISPATCHER__REQUEST__ADMIN__TEAM_CREATE value
         * @property {number} DISPATCHER__REQUEST__ADMIN__TEAM_UPDATE=141018 DISPATCHER__REQUEST__ADMIN__TEAM_UPDATE value
         * @property {number} DISPATCHER__REQUEST__ADMIN__TEAM_DELETE=141019 DISPATCHER__REQUEST__ADMIN__TEAM_DELETE value
         * @property {number} DISPATCHER__REQUEST__ADMIN__TEAM_WITH_WAREHOUSES=141020 DISPATCHER__REQUEST__ADMIN__TEAM_WITH_WAREHOUSES value
         * @property {number} DISPATCHER__REQUEST__ADMIN__TEAM_SET_WAREHOUSES=141021 DISPATCHER__REQUEST__ADMIN__TEAM_SET_WAREHOUSES value
         * @property {number} DISPATCHER__REQUEST__ADMIN__COURIER_CREATE=141022 DISPATCHER__REQUEST__ADMIN__COURIER_CREATE value
         * @property {number} DISPATCHER__REQUEST__ADMIN__COURIER_UPDATE=141023 DISPATCHER__REQUEST__ADMIN__COURIER_UPDATE value
         * @property {number} DISPATCHER__REQUEST__ADMIN__COURIER_DEACTIVATE=141024 DISPATCHER__REQUEST__ADMIN__COURIER_DEACTIVATE value
         * @property {number} DISPATCHER__REQUEST__ADMIN__COURIER_UNASSIGN_TEAM=141025 DISPATCHER__REQUEST__ADMIN__COURIER_UNASSIGN_TEAM value
         * @property {number} DISPATCHER__REQUEST__ADMIN__JOB_UNASSIGN_COURIER=141026 DISPATCHER__REQUEST__ADMIN__JOB_UNASSIGN_COURIER value
         * @property {number} DISPATCHER__REQUEST__ADMIN__TASK_UNASSIGN_JOB=141027 DISPATCHER__REQUEST__ADMIN__TASK_UNASSIGN_JOB value
         * @property {number} DISPATCHER__REQUEST__ADMIN__COURIER_FORCE_OFFLINE=141028 DISPATCHER__REQUEST__ADMIN__COURIER_FORCE_OFFLINE value
         * @property {number} DISPATCHER__REQUEST__ADMIN__ORDER_FORCE_COMPLETE=141033 DISPATCHER__REQUEST__ADMIN__ORDER_FORCE_COMPLETE value
         * @property {number} DISPATCHER__REQUEST__ADMIN__ORDER_FORCE_FAIL=141034 DISPATCHER__REQUEST__ADMIN__ORDER_FORCE_FAIL value
         * @property {number} DISPATCHER__REQUEST__ADMIN__GET_DELIVERY_ZONES=141035 DISPATCHER__REQUEST__ADMIN__GET_DELIVERY_ZONES value
         * @property {number} DISPATCHER__REQUEST__ADMIN__JOBS_GET_LIST_V2=141036 DISPATCHER__REQUEST__ADMIN__JOBS_GET_LIST_V2 value
         * @property {number} DISPATCHER__REQUEST__ADMIN__JOB_UNASSIGN_ORDER_V2=141037 DISPATCHER__REQUEST__ADMIN__JOB_UNASSIGN_ORDER_V2 value
         * @property {number} DISPATCHER__REQUEST__ADMIN__ORDER_ASSIGN=141038 DISPATCHER__REQUEST__ADMIN__ORDER_ASSIGN value
         * @property {number} DISPATCHER__REQUEST__ADMIN__COURIER_CREATE_V2=141039 DISPATCHER__REQUEST__ADMIN__COURIER_CREATE_V2 value
         * @property {number} DISPATCHER__REQUEST__ADMIN__WAREHOUSE_WITH_TEAMS=141040 DISPATCHER__REQUEST__ADMIN__WAREHOUSE_WITH_TEAMS value
         * @property {number} DISPATCHER__REQUEST__ADMIN__TEAM_GET_USERS=141041 DISPATCHER__REQUEST__ADMIN__TEAM_GET_USERS value
         * @property {number} DISPATCHER__REQUEST__ADMIN__SEARCH_USERS=141042 DISPATCHER__REQUEST__ADMIN__SEARCH_USERS value
         * @property {number} DISPATCHER__REQUEST__ADMIN__TEAM_ATTACH_USERS=141043 DISPATCHER__REQUEST__ADMIN__TEAM_ATTACH_USERS value
         * @property {number} DISPATCHER__REQUEST__ADMIN__TEAM_DETACH_USER=141044 DISPATCHER__REQUEST__ADMIN__TEAM_DETACH_USER value
         * @property {number} DISPATCHER__REQUEST__ADMIN__FORCE_PICK=141045 DISPATCHER__REQUEST__ADMIN__FORCE_PICK value
         * @property {number} DISPATCHER__REQUEST__ADMIN__COURIER_CREATE_V3=141046 DISPATCHER__REQUEST__ADMIN__COURIER_CREATE_V3 value
         * @property {number} DISPATCHER__REQUEST__ADMIN__GET_COURIER_BY_USER_EXTERNAL_ID=141047 DISPATCHER__REQUEST__ADMIN__GET_COURIER_BY_USER_EXTERNAL_ID value
         * @property {number} DISPATCHER__REQUEST__ADMIN__GET_ALL_ORDERS_WITH_FILTERS=141048 DISPATCHER__REQUEST__ADMIN__GET_ALL_ORDERS_WITH_FILTERS value
         * @property {number} DISPATCHER__REQUEST__ORDER_GET_LOCATION=141029 DISPATCHER__REQUEST__ORDER_GET_LOCATION value
         * @property {number} DISPATCHER__RESPONSE__MOBILE__COURIER_MARK_ARRIVAL=144001 DISPATCHER__RESPONSE__MOBILE__COURIER_MARK_ARRIVAL value
         * @property {number} DISPATCHER__RESPONSE__MOBILE__COURIER_MARK_OFFLINE=144002 DISPATCHER__RESPONSE__MOBILE__COURIER_MARK_OFFLINE value
         * @property {number} DISPATCHER__RESPONSE__MOBILE__COURIER_MARK_ONLINE=144003 DISPATCHER__RESPONSE__MOBILE__COURIER_MARK_ONLINE value
         * @property {number} DISPATCHER__RESPONSE__MOBILE__COURIER_MARK_RETURNING=144004 DISPATCHER__RESPONSE__MOBILE__COURIER_MARK_RETURNING value
         * @property {number} DISPATCHER__RESPONSE__MOBILE__COURIER_GET_INFO=144045 DISPATCHER__RESPONSE__MOBILE__COURIER_GET_INFO value
         * @property {number} DISPATCHER__RESPONSE__MOBILE__JOB_ACCEPT=144005 DISPATCHER__RESPONSE__MOBILE__JOB_ACCEPT value
         * @property {number} DISPATCHER__RESPONSE__MOBILE__JOB_GET_ASSIGNED=144006 DISPATCHER__RESPONSE__MOBILE__JOB_GET_ASSIGNED value
         * @property {number} DISPATCHER__RESPONSE__MOBILE__JOB_OVERVIEW=144007 DISPATCHER__RESPONSE__MOBILE__JOB_OVERVIEW value
         * @property {number} DISPATCHER__RESPONSE__MOBILE__TASK_COMPLETE=144008 DISPATCHER__RESPONSE__MOBILE__TASK_COMPLETE value
         * @property {number} DISPATCHER__RESPONSE__MOBILE__TASK_ON_POINT=144009 DISPATCHER__RESPONSE__MOBILE__TASK_ON_POINT value
         * @property {number} DISPATCHER__RESPONSE__MOBILE__TASK_START=144010 DISPATCHER__RESPONSE__MOBILE__TASK_START value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__COURIER_ASSIGN_TEAM=144011 DISPATCHER__RESPONSE__ADMIN__COURIER_ASSIGN_TEAM value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__GET_DASHBOARD_INFO=144012 DISPATCHER__RESPONSE__ADMIN__GET_DASHBOARD_INFO value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__JOB_ASSIGN_COURIER=144013 DISPATCHER__RESPONSE__ADMIN__JOB_ASSIGN_COURIER value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__JOBS_GET_LIST=144014 DISPATCHER__RESPONSE__ADMIN__JOBS_GET_LIST value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__TASK_ASSIGN_JOB=144015 DISPATCHER__RESPONSE__ADMIN__TASK_ASSIGN_JOB value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__TASK_GET_DETAILS=144016 DISPATCHER__RESPONSE__ADMIN__TASK_GET_DETAILS value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__TEAM_CREATE=144017 DISPATCHER__RESPONSE__ADMIN__TEAM_CREATE value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__TEAM_UPDATE=144018 DISPATCHER__RESPONSE__ADMIN__TEAM_UPDATE value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__TEAM_DELETE=144019 DISPATCHER__RESPONSE__ADMIN__TEAM_DELETE value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__TEAM_GET_ALL=144020 DISPATCHER__RESPONSE__ADMIN__TEAM_GET_ALL value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__TEAM_WITH_WAREHOUSES=144021 DISPATCHER__RESPONSE__ADMIN__TEAM_WITH_WAREHOUSES value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__TEAM_SET_WAREHOUSES=144022 DISPATCHER__RESPONSE__ADMIN__TEAM_SET_WAREHOUSES value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__COURIER_CREATE=144023 DISPATCHER__RESPONSE__ADMIN__COURIER_CREATE value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__COURIER_UPDATE=144024 DISPATCHER__RESPONSE__ADMIN__COURIER_UPDATE value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__COURIER_DEACTIVATE=144025 DISPATCHER__RESPONSE__ADMIN__COURIER_DEACTIVATE value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__COURIER_UNASSIGN_TEAM=144026 DISPATCHER__RESPONSE__ADMIN__COURIER_UNASSIGN_TEAM value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__JOB_UNASSIGN_COURIER=144027 DISPATCHER__RESPONSE__ADMIN__JOB_UNASSIGN_COURIER value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__TASK_UNASSIGN_JOB=144028 DISPATCHER__RESPONSE__ADMIN__TASK_UNASSIGN_JOB value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__COURIER_FORCE_OFFLINE=144029 DISPATCHER__RESPONSE__ADMIN__COURIER_FORCE_OFFLINE value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__WAREHOUSES_GET_ALL=144030 DISPATCHER__RESPONSE__ADMIN__WAREHOUSES_GET_ALL value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__GET_DASHBOARD_INFO_V2=144033 DISPATCHER__RESPONSE__ADMIN__GET_DASHBOARD_INFO_V2 value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__GET_DELIVERY_ZONES=144034 DISPATCHER__RESPONSE__ADMIN__GET_DELIVERY_ZONES value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__JOBS_GET_LIST_V2=144035 DISPATCHER__RESPONSE__ADMIN__JOBS_GET_LIST_V2 value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__SEARCH_ORDERS=144036 DISPATCHER__RESPONSE__ADMIN__SEARCH_ORDERS value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__JOB_UNASSIGN_ORDER=144037 DISPATCHER__RESPONSE__ADMIN__JOB_UNASSIGN_ORDER value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__ORDER_ASSIGN=144038 DISPATCHER__RESPONSE__ADMIN__ORDER_ASSIGN value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__WAREHOUSE_WITH_TEAMS=144040 DISPATCHER__RESPONSE__ADMIN__WAREHOUSE_WITH_TEAMS value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__TEAM_GET_USERS=144041 DISPATCHER__RESPONSE__ADMIN__TEAM_GET_USERS value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__SEARCH_USERS=144042 DISPATCHER__RESPONSE__ADMIN__SEARCH_USERS value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__TEAM_ATTACH_USERS=144043 DISPATCHER__RESPONSE__ADMIN__TEAM_ATTACH_USERS value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__TEAM_DETACH_USER=144044 DISPATCHER__RESPONSE__ADMIN__TEAM_DETACH_USER value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__COURIER_CREATE_V3=144046 DISPATCHER__RESPONSE__ADMIN__COURIER_CREATE_V3 value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__GET_COURIER_BY_USER_EXTERNAL_ID=144047 DISPATCHER__RESPONSE__ADMIN__GET_COURIER_BY_USER_EXTERNAL_ID value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__JOB_MAP_INFO=144048 DISPATCHER__RESPONSE__ADMIN__JOB_MAP_INFO value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__GET_ALL_COURIERS=144049 DISPATCHER__RESPONSE__ADMIN__GET_ALL_COURIERS value
         * @property {number} DISPATCHER__RESPONSE__ADMIN__GET_ALL_ORDERS_WITH_FILTERS=144050 DISPATCHER__RESPONSE__ADMIN__GET_ALL_ORDERS_WITH_FILTERS value
         * @property {number} DISPATCHER__RESPONSE__ORDER_GET_LOCATION=144031 DISPATCHER__RESPONSE__ORDER_GET_LOCATION value
         * @property {number} DISPATCHER__RESPONSE__COURIERS_GET_IDLE=144032 DISPATCHER__RESPONSE__COURIERS_GET_IDLE value
         * @property {number} DISPATCHER__RESPONSE__COURIER_LIST=144051 DISPATCHER__RESPONSE__COURIER_LIST value
         * @property {number} DISPATCHER__RESPONSE__COURIER_BULK=144052 DISPATCHER__RESPONSE__COURIER_BULK value
         * @property {number} DISPATCHER__RESPONSE__COURIER_GET=144053 DISPATCHER__RESPONSE__COURIER_GET value
         * @property {number} DISPATCHER__RESPONSE__TASK_LIST=144054 DISPATCHER__RESPONSE__TASK_LIST value
         * @property {number} DISPATCHER__RESPONSE__TASK_BULK=144055 DISPATCHER__RESPONSE__TASK_BULK value
         * @property {number} DISPATCHER__RESPONSE__TASK_GET=144056 DISPATCHER__RESPONSE__TASK_GET value
         * @property {number} DISPATCHER__RESPONSE__CREATE_DELIVERY=144061 DISPATCHER__RESPONSE__CREATE_DELIVERY value
         * @property {number} DISPATCHER__RESPONSE__GET_PARCELS=144062 DISPATCHER__RESPONSE__GET_PARCELS value
         * @property {number} DISPATCHER__RESPONSE__MARK_ORDER_PICKED=144063 DISPATCHER__RESPONSE__MARK_ORDER_PICKED value
         * @property {number} DISPATCHER__REQUEST__GET_PARCELS=144073 DISPATCHER__REQUEST__GET_PARCELS value
         * @property {number} DISPATCHER__REQUEST__CREATE_DELIVERY=144074 DISPATCHER__REQUEST__CREATE_DELIVERY value
         * @property {number} DISPATCHER__REQUEST__MARK_ORDER_PICKED=144075 DISPATCHER__REQUEST__MARK_ORDER_PICKED value
         * @property {number} DISPATCHER__ERROR_ORDERS_NOT_PICKED=146000 DISPATCHER__ERROR_ORDERS_NOT_PICKED value
         */
        JiffyMessageBody.Type = (function () {
            var valuesById = {},
                values = Object.create(valuesById)
            values[(valuesById[0] = 'ERROR')] = 0
            values[(valuesById[1] = 'SUCCESS')] = 1
            values[(valuesById[21001] = 'ONESIGNAL__REQUEST__NOTIFY__ORDER__PICKING')] = 21001
            values[(valuesById[21002] = 'ONESIGNAL__REQUEST__NOTIFY__ORDER__PICKED_PARTIALLY')] = 21002
            values[(valuesById[21003] = 'ONESIGNAL__REQUEST__NOTIFY__ORDER__IN_DELIVERY')] = 21003
            values[(valuesById[21004] = 'ONESIGNAL__REQUEST__NOTIFY__ORDER__DELIVERED')] = 21004
            values[(valuesById[21005] = 'ONESIGNAL__REQUEST__NOTIFY__ORDER__PAYMENT_CHARGED')] = 21005
            values[(valuesById[21006] = 'ONESIGNAL__REQUEST__NOTIFY__SUPPORT__REPLY')] = 21006
            values[(valuesById[21007] = 'ONESIGNAL__REQUEST__TAG__EDIT')] = 21007
            values[(valuesById[21008] = 'ONESIGNAL__REQUEST__NOTIFY__SEARCH_AGAIN')] = 21008
            values[(valuesById[21009] = 'ONESIGNAL__REQUEST__NOTIFY__ABANDONEW_VIEW')] = 21009
            values[(valuesById[34001] = 'CHAT__RESPONSE__CONVERSATION')] = 34001
            values[(valuesById[37001] = 'CHAT__ERROR__AUTH_INVALID')] = 37001
            values[(valuesById[37002] = 'CHAT__ERROR__BODY_INVALID')] = 37002
            values[(valuesById[37003] = 'CHAT__ERROR__VOXIMPLANT_UNAVAILABLE')] = 37003
            values[(valuesById[47001] = 'OMS__ERROR__ORDER_CREATE__SLA_UNAVAILABLE')] = 47001
            values[(valuesById[47002] = 'OMS__ERROR__ORDER_CREATE__CUSTOMER_SERVICE_UNAVAILABLE')] = 47002
            values[(valuesById[47003] = 'OMS__ERROR__ORDER_CREATE__PAYMENT_SERVICE_UNAVAILABLE')] = 47003
            values[(valuesById[47004] = 'OMS__ERROR__ORDER_CREATE__INVALID_WAREHOUSE_CODE')] = 47004
            values[(valuesById[47005] = 'OMS__ERROR__ORDER_CREATE__INSUFFICIENT_ITEMS_IN_STOCK')] = 47005
            values[(valuesById[47006] = 'OMS__ERROR__ORDER_CREATE__ITEM_OUT_OF_STOCK')] = 47006
            values[(valuesById[47007] = 'OMS__ERROR__ORDER_CREATE__STOCK_RESERVATION_UNAVAILABLE')] = 47007
            values[(valuesById[47008] = 'OMS__ERROR__ORDER_CREATE__INCORRECT_PRICE')] = 47008
            values[(valuesById[47009] = 'OMS__ERROR__ORDER_CREATE__INCORRECT_DELIVERY_PRICE')] = 47009
            values[(valuesById[47010] = 'OMS__ERROR__ORDER_CREATE__PROMOCODE__INVALID')] = 47010
            values[(valuesById[47011] = 'OMS__ERROR__ORDER_CREATE__PROMOCODE__TOTAL_LESS_THAN_MIN_VALUE')] = 47011
            values[(valuesById[47012] = 'OMS__ERROR__ORDER_CREATE__NO_ORDER_CREATED')] = 47012
            values[(valuesById[47013] = 'OMS__ERROR__ORDER_UPDATE__ORDER_NOT_FOUND')] = 47013
            values[(valuesById[47014] = 'OMS__ERROR__ORDER_UPDATE__ORDER_ITEM_NOT_FOUND')] = 47014
            values[(valuesById[47015] = 'OMS__ERROR__ORDER_STATUS__NOT_FOUND')] = 47015
            values[(valuesById[47016] = 'OMS__ERROR__ORDER_CANCEL__ORDER_NOT_FOUND')] = 47016
            values[(valuesById[47017] = 'OMS__ERROR__ORDER_CANCEL__INVALID_STATE')] = 47017
            values[(valuesById[47018] = 'OMS__ERROR__ORDER_CANCEL__DELIVERY_NOACK')] = 47018
            values[(valuesById[47019] = 'OMS__ERROR__ORDER_CANCEL__PAYMENT_NOACK')] = 47019
            values[(valuesById[47020] = 'OMS__ERROR__ORDER_CANCEL__FAILED')] = 47020
            values[(valuesById[47021] = 'OMS__ERROR__ORDER_FEEDBACK__INVALID_RATING')] = 47021
            values[(valuesById[47022] = 'OMS__ERROR__ORDER_FEEDBACK__ORDER_NOT_FOUND')] = 47022
            values[(valuesById[47023] = 'OMS__ERROR__ORDER_FEEDBACK__ALREADY_EXISTS')] = 47023
            values[(valuesById[47024] = 'OMS__ERROR__ORDER_FEEDBACK__FAILED_TO_CREATE')] = 47024
            values[(valuesById[47025] = 'OMS__ERROR__ORDER_FEEDBACK__FAILED_TO_UPDATE')] = 47025
            values[(valuesById[47026] = 'OMS__ERROR__UNRESERVE__FAILED')] = 47026
            values[(valuesById[47027] = 'OMS__ERROR__PROMO__NOT_FOUND')] = 47027
            values[(valuesById[47028] = 'OMS__ERROR__PROMO__INVALID')] = 47028
            values[(valuesById[47029] = 'OMS__ERROR__FAVOURITES_GET__INVALID_WAREHOUSE_CODE')] = 47029
            values[(valuesById[47030] = 'OMS__ERROR__FAVOURITES_GET__INVALID_PAGE_NUMBER')] = 47030
            values[(valuesById[47031] = 'OMS__ERROR__FAVOURITES_GET__INVALID_LIMIT')] = 47031
            values[(valuesById[47032] = 'OMS__ERROR__FAVOURITES_GET__CATALOGUE_SERVICE_UNAVAILABLE')] = 47032
            values[(valuesById[47033] = 'OMS__ERROR__FAVOURITES_ADD__FAILED')] = 47033
            values[(valuesById[47034] = 'OMS__ERROR__FAVOURITES_REMOVE__ITEM_NOT_FOUND')] = 47034
            values[(valuesById[47035] = 'OMS__ERROR__BOUGHT_GET__NOT_FOUND')] = 47035
            values[(valuesById[47036] = 'OMS__ERROR__BOUGHT_GET__CATALOGUE_SERVICE_UNAVAILABLE')] = 47036
            values[(valuesById[47037] = 'OMS__ORDER_CREATED')] = 47037
            values[(valuesById[47038] = 'OMS__ORDER_MODIFY')] = 47038
            values[(valuesById[57001] = 'DISCOUNT__ERROR__COUPONS__USED')] = 57001
            values[(valuesById[57002] = 'DISCOUNT__ERROR__COUPONS__ONLY_FIRST_ORDER_ALLOWED')] = 57002
            values[(valuesById[57003] = 'DISCOUNT__ERROR__COUPONS__NOT_ACTIVE')] = 57003
            values[(valuesById[57004] = 'DISCOUNT__ERROR__COUPONS__EXPIRED')] = 57004
            values[(valuesById[57005] = 'DISCOUNT__ERROR__COUPONS__NOT_FOUND')] = 57005
            values[(valuesById[57006] = 'DISCOUNT__ERROR__COUPONS__NOT_BEGIN')] = 57006
            values[(valuesById[57007] = 'DISCOUNT__ERROR__COUPONS__NOT_FOUND_COUPON_GROUP')] = 57007
            values[(valuesById[57008] = 'DISCOUNT__ERROR__COUPONS__NOT_FOUND_MECHANISM')] = 57008
            values[(valuesById[67002] = 'STOCK__ERROR__RESERVE_NO_WAREHOUSE_CODE')] = 67002
            values[(valuesById[67003] = 'STOCK__ERROR__RESERVE_ITEM_NOT_FOUND')] = 67003
            values[(valuesById[67004] = 'STOCK__ERROR__RESERVE_NOT_ENOUGH_SELLABLE')] = 67004
            values[(valuesById[67005] = 'STOCK__ERROR__RESERVE_QTY_LESS_ZERO')] = 67005
            values[(valuesById[67006] = 'STOCK__ERROR__RESERVE_EMPTY_ARRAY')] = 67006
            values[(valuesById[67007] = 'STOCK__ERROR__PUSH_EMPTY_ARRAY')] = 67007
            values[(valuesById[67008] = 'STOCK__ERROR__GET_NO_SKU')] = 67008
            values[(valuesById[71001] = 'ETA__REQUEST__ETA__CALCULATE')] = 71001
            values[(valuesById[74001] = 'ETA__RESPONSE__ETA__CALCULATE')] = 74001
            values[(valuesById[74002] = 'ETA__RESPONSE__WH__AVAILABILITY')] = 74002
            values[(valuesById[74003] = 'ETA__RESPONSE__SHOW_ZONES')] = 74003
            values[(valuesById[74004] = 'ETA__RESPONSE__V2_SHOW_ZONES')] = 74004
            values[(valuesById[77001] = 'ETA__ERROR__ETA__INVALID_POSITION')] = 77001
            values[(valuesById[77002] = 'ETA__ERROR__ETA__CANNOT_DELIVER_TO_ADDRESS')] = 77002
            values[(valuesById[77003] = 'ETA__ERROR__ETA__ADDRESS_OUT_OF_RANGE')] = 77003
            values[(valuesById[77004] = 'ETA__ERROR__ETA__UNAVAILABLE')] = 77004
            values[(valuesById[77005] = 'ETA__ERROR__INVALID_WAREHOUSE_CODE')] = 77005
            values[(valuesById[81001] = 'DELIVERY__REQUEST__DELIVERY__CREATE')] = 81001
            values[(valuesById[81002] = 'DELIVERY__REQUEST__DELIVERY__CANCEL')] = 81002
            values[(valuesById[81003] = 'DELIVERY__REQUEST__DELIVERY__DETAILS')] = 81003
            values[(valuesById[84001] = 'DELIVERY__RESPONSE__DELIVERY__CREATE')] = 84001
            values[(valuesById[84002] = 'DELIVERY__RESPONSE__DELIVERY__CANCEL')] = 84002
            values[(valuesById[84003] = 'DELIVERY__RESPONSE__DELIVERY__DETAILS')] = 84003
            values[(valuesById[87001] = 'DELIVERY__ERROR__UNKNOWN_ORDER')] = 87001
            values[(valuesById[87002] = 'DELIVERY__ERROR__TASKS_FETCH_FAILED')] = 87002
            values[(valuesById[87003] = 'DELIVERY__ERROR__TASKS_CANCEL_FAILED')] = 87003
            values[(valuesById[87004] = 'DELIVERY_REQUEST_TIPS_RECEIVED_TIPS')] = 87004
            values[(valuesById[97001] = 'CUSTOMER__ERROR__SMS__INVALID_PHONE_NUMBER')] = 97001
            values[(valuesById[97002] = 'CUSTOMER__ERROR__SMS__NOT_SENT')] = 97002
            values[(valuesById[97003] = 'CUSTOMER__ERROR__SMS__CONTACT__MISSING')] = 97003
            values[(valuesById[97004] = 'CUSTOMER__ERROR__SMS__DEVICE_ID__MISSING')] = 97004
            values[(valuesById[97005] = 'CUSTOMER__ERROR__SMS__DEVICE_ID__INVALID')] = 97005
            values[(valuesById[97006] = 'CUSTOMER__ERROR__AUTH__CUSTOMER_ID__INVALID')] = 97006
            values[(valuesById[97007] = 'CUSTOMER__ERROR__AUTH__CUSTOMER_ID__MISSING')] = 97007
            values[(valuesById[97008] = 'CUSTOMER__ERROR__AUTH__TOKEN__MISSING')] = 97008
            values[(valuesById[97009] = 'CUSTOMER__ERROR__AUTH__OTP__INVALID')] = 97009
            values[(valuesById[97010] = 'CUSTOMER__ERROR__EMAIL__TAKEN')] = 97010
            values[(valuesById[97011] = 'CUSTOMER__ERROR__LOGIN__INVALID')] = 97011
            values[(valuesById[97012] = 'CUSTOMER__ERROR__COUNTRY__INVALID')] = 97012
            values[(valuesById[97013] = 'CUSTOMER__CHANGE_WAREHOUSE')] = 97013
            values[(valuesById[97014] = 'CUSTOMER__UPDATE_SUBSCRIPTIONS')] = 97014
            values[(valuesById[97015] = 'CUSTOMER__REGISTER')] = 97015
            values[(valuesById[97016] = 'CUSTOMER__UPDATE_PROFILE')] = 97016
            values[(valuesById[97017] = 'CUSTOMER__REGISTER_PHONE')] = 97017
            values[(valuesById[97018] = 'CUSTOMER__LOGIN')] = 97018
            values[(valuesById[101001] = 'CATALOGUE__PRODUCT__UPSERT')] = 101001
            values[(valuesById[107001] = 'CATALOGUE__ERROR__CATEGORY__INVALID_ID')] = 107001
            values[(valuesById[107002] = 'CATALOGUE__ERROR__PRODUCT__INVALID_ID')] = 107002
            values[(valuesById[107003] = 'CATALOGUE__ERROR__PRODUCT__INACTIVE')] = 107003
            values[(valuesById[107004] = 'CATALOGUE__ERROR__PRODUCT__DELETED')] = 107004
            values[(valuesById[107005] = 'CATALOGUE__ERROR__PRODUCT__UNSELLABLE')] = 107005
            values[(valuesById[107006] = 'CATALOGUE__ERROR__PRODUCT__NOT_FOUND')] = 107006
            values[(valuesById[107007] = 'CATALOGUE__ERROR__SEARCH__WH_CODE_MISSING')] = 107007
            values[(valuesById[114001] = 'GEOCODER__RESPONSE__AUTOCOMPLETE')] = 114001
            values[(valuesById[117001] = 'GEOCODER__ERROR__AUTOCOMPLETE__UNAVAILABLE')] = 117001
            values[(valuesById[121001] = 'AUTH__REQUEST__PERMISSION__CREATE')] = 121001
            values[(valuesById[121002] = 'AUTH__REQUEST__PERMISSION__PATCH')] = 121002
            values[(valuesById[121003] = 'AUTH__REQUEST__PERMISSION__ASSIGN__TO__USER')] = 121003
            values[(valuesById[121004] = 'AUTH__REQUEST__PERMISSION__UNLINK__FROM__USER')] = 121004
            values[(valuesById[121005] = 'AUTH__REQUEST__PERMISSION__LINK__TO__ROLE')] = 121005
            values[(valuesById[121006] = 'AUTH__REQUEST__PERMISSION__UNLINK__FROM__ROLE')] = 121006
            values[(valuesById[121007] = 'AUTH__REQUEST__ROLE__CREATE')] = 121007
            values[(valuesById[121008] = 'AUTH__REQUEST__ROLE__PATCH')] = 121008
            values[(valuesById[121009] = 'AUTH__REQUEST__ROLE__ASSIGN__TO__USER')] = 121009
            values[(valuesById[121010] = 'AUTH__REQUEST__ROLE__UNLINK__FROM__USER')] = 121010
            values[(valuesById[121011] = 'AUTH__REQUEST__USER__CREATE')] = 121011
            values[(valuesById[121012] = 'AUTH__REQUEST__AUTH__CONTACT')] = 121012
            values[(valuesById[121013] = 'AUTH__REQUEST__AUTH__VERIFY')] = 121013
            values[(valuesById[121014] = 'AUTH__REQUEST__AUTH__REFRESH')] = 121014
            values[(valuesById[121015] = 'AUTH__REQUEST__AUTH__LOGOUT')] = 121015
            values[(valuesById[124001] = 'AUTH__RESPONSE__PERMISSIONS__GET')] = 124001
            values[(valuesById[124002] = 'AUTH__RESPONSE__PERMISSION__CREATE')] = 124002
            values[(valuesById[124003] = 'AUTH__RESPONSE__PERMISSION__PATCH')] = 124003
            values[(valuesById[124004] = 'AUTH__RESPONSE__ROLES__GET')] = 124004
            values[(valuesById[124005] = 'AUTH__RESPONSE__ROLE__GET')] = 124005
            values[(valuesById[124006] = 'AUTH__RESPONSE__ROLE__CREATE')] = 124006
            values[(valuesById[124007] = 'AUTH__RESPONSE__ROLE__PATCH')] = 124007
            values[(valuesById[124008] = 'AUTH__RESPONSE__USERS__GET')] = 124008
            values[(valuesById[124009] = 'AUTH__RESPONSE__USER__GET')] = 124009
            values[(valuesById[124010] = 'AUTH__RESPONSE__USER__CREATE')] = 124010
            values[(valuesById[124011] = 'AUTH__RESPONSE__AUTH__CONTACT')] = 124011
            values[(valuesById[124012] = 'AUTH__RESPONSE__AUTH__AUTHORIZE')] = 124012
            values[(valuesById[124013] = 'AUTH__RESPONSE__AUTH__REFRESH')] = 124013
            values[(valuesById[127001] = 'AUTH__ERROR__HANDLER')] = 127001
            values[(valuesById[141001] = 'DISPATCHER__REQUEST__MOBILE__COURIER_MARK_ARRIVAL')] = 141001
            values[(valuesById[141002] = 'DISPATCHER__REQUEST__MOBILE__COURIER_MARK_OFFLINE')] = 141002
            values[(valuesById[141003] = 'DISPATCHER__REQUEST__MOBILE__COURIER_MARK_ONLINE')] = 141003
            values[(valuesById[141004] = 'DISPATCHER__REQUEST__MOBILE__COURIER_MARK_RETURNING')] = 141004
            values[(valuesById[141031] = 'DISPATCHER__REQUEST__MOBILE__COURIER_GET_INFO')] = 141031
            values[(valuesById[141005] = 'DISPATCHER__REQUEST__MOBILE__JOB_ACCEPT')] = 141005
            values[(valuesById[141006] = 'DISPATCHER__REQUEST__MOBILE__JOB_GET_ASSIGNED')] = 141006
            values[(valuesById[141007] = 'DISPATCHER__REQUEST__MOBILE__JOB_OVERVIEW')] = 141007
            values[(valuesById[141008] = 'DISPATCHER__REQUEST__MOBILE__TASK_COMPLETE')] = 141008
            values[(valuesById[141009] = 'DISPATCHER__REQUEST__MOBILE__TASK_ON_POINT')] = 141009
            values[(valuesById[141010] = 'DISPATCHER__REQUEST__MOBILE__TASK_START')] = 141010
            values[(valuesById[141030] = 'DISPATCHER__REQUEST__MOBILE__TRIP_POINT')] = 141030
            values[(valuesById[141011] = 'DISPATCHER__REQUEST__ADMIN__COURIER_ASSIGN_TEAM')] = 141011
            values[(valuesById[141012] = 'DISPATCHER__REQUEST__ADMIN__GET_DASHBOARD_INFO')] = 141012
            values[(valuesById[141013] = 'DISPATCHER__REQUEST__ADMIN__JOB_ASSIGN_COURIER')] = 141013
            values[(valuesById[141014] = 'DISPATCHER__REQUEST__ADMIN__JOBS_GET_LIST')] = 141014
            values[(valuesById[141015] = 'DISPATCHER__REQUEST__ADMIN__TASK_ASSIGN_JOB')] = 141015
            values[(valuesById[141016] = 'DISPATCHER__REQUEST__ADMIN__TASK_GET_DETAILS')] = 141016
            values[(valuesById[141017] = 'DISPATCHER__REQUEST__ADMIN__TEAM_CREATE')] = 141017
            values[(valuesById[141018] = 'DISPATCHER__REQUEST__ADMIN__TEAM_UPDATE')] = 141018
            values[(valuesById[141019] = 'DISPATCHER__REQUEST__ADMIN__TEAM_DELETE')] = 141019
            values[(valuesById[141020] = 'DISPATCHER__REQUEST__ADMIN__TEAM_WITH_WAREHOUSES')] = 141020
            values[(valuesById[141021] = 'DISPATCHER__REQUEST__ADMIN__TEAM_SET_WAREHOUSES')] = 141021
            values[(valuesById[141022] = 'DISPATCHER__REQUEST__ADMIN__COURIER_CREATE')] = 141022
            values[(valuesById[141023] = 'DISPATCHER__REQUEST__ADMIN__COURIER_UPDATE')] = 141023
            values[(valuesById[141024] = 'DISPATCHER__REQUEST__ADMIN__COURIER_DEACTIVATE')] = 141024
            values[(valuesById[141025] = 'DISPATCHER__REQUEST__ADMIN__COURIER_UNASSIGN_TEAM')] = 141025
            values[(valuesById[141026] = 'DISPATCHER__REQUEST__ADMIN__JOB_UNASSIGN_COURIER')] = 141026
            values[(valuesById[141027] = 'DISPATCHER__REQUEST__ADMIN__TASK_UNASSIGN_JOB')] = 141027
            values[(valuesById[141028] = 'DISPATCHER__REQUEST__ADMIN__COURIER_FORCE_OFFLINE')] = 141028
            values[(valuesById[141033] = 'DISPATCHER__REQUEST__ADMIN__ORDER_FORCE_COMPLETE')] = 141033
            values[(valuesById[141034] = 'DISPATCHER__REQUEST__ADMIN__ORDER_FORCE_FAIL')] = 141034
            values[(valuesById[141035] = 'DISPATCHER__REQUEST__ADMIN__GET_DELIVERY_ZONES')] = 141035
            values[(valuesById[141036] = 'DISPATCHER__REQUEST__ADMIN__JOBS_GET_LIST_V2')] = 141036
            values[(valuesById[141037] = 'DISPATCHER__REQUEST__ADMIN__JOB_UNASSIGN_ORDER_V2')] = 141037
            values[(valuesById[141038] = 'DISPATCHER__REQUEST__ADMIN__ORDER_ASSIGN')] = 141038
            values[(valuesById[141039] = 'DISPATCHER__REQUEST__ADMIN__COURIER_CREATE_V2')] = 141039
            values[(valuesById[141040] = 'DISPATCHER__REQUEST__ADMIN__WAREHOUSE_WITH_TEAMS')] = 141040
            values[(valuesById[141041] = 'DISPATCHER__REQUEST__ADMIN__TEAM_GET_USERS')] = 141041
            values[(valuesById[141042] = 'DISPATCHER__REQUEST__ADMIN__SEARCH_USERS')] = 141042
            values[(valuesById[141043] = 'DISPATCHER__REQUEST__ADMIN__TEAM_ATTACH_USERS')] = 141043
            values[(valuesById[141044] = 'DISPATCHER__REQUEST__ADMIN__TEAM_DETACH_USER')] = 141044
            values[(valuesById[141045] = 'DISPATCHER__REQUEST__ADMIN__FORCE_PICK')] = 141045
            values[(valuesById[141046] = 'DISPATCHER__REQUEST__ADMIN__COURIER_CREATE_V3')] = 141046
            values[(valuesById[141047] = 'DISPATCHER__REQUEST__ADMIN__GET_COURIER_BY_USER_EXTERNAL_ID')] = 141047
            values[(valuesById[141048] = 'DISPATCHER__REQUEST__ADMIN__GET_ALL_ORDERS_WITH_FILTERS')] = 141048
            values[(valuesById[141029] = 'DISPATCHER__REQUEST__ORDER_GET_LOCATION')] = 141029
            values[(valuesById[144001] = 'DISPATCHER__RESPONSE__MOBILE__COURIER_MARK_ARRIVAL')] = 144001
            values[(valuesById[144002] = 'DISPATCHER__RESPONSE__MOBILE__COURIER_MARK_OFFLINE')] = 144002
            values[(valuesById[144003] = 'DISPATCHER__RESPONSE__MOBILE__COURIER_MARK_ONLINE')] = 144003
            values[(valuesById[144004] = 'DISPATCHER__RESPONSE__MOBILE__COURIER_MARK_RETURNING')] = 144004
            values[(valuesById[144045] = 'DISPATCHER__RESPONSE__MOBILE__COURIER_GET_INFO')] = 144045
            values[(valuesById[144005] = 'DISPATCHER__RESPONSE__MOBILE__JOB_ACCEPT')] = 144005
            values[(valuesById[144006] = 'DISPATCHER__RESPONSE__MOBILE__JOB_GET_ASSIGNED')] = 144006
            values[(valuesById[144007] = 'DISPATCHER__RESPONSE__MOBILE__JOB_OVERVIEW')] = 144007
            values[(valuesById[144008] = 'DISPATCHER__RESPONSE__MOBILE__TASK_COMPLETE')] = 144008
            values[(valuesById[144009] = 'DISPATCHER__RESPONSE__MOBILE__TASK_ON_POINT')] = 144009
            values[(valuesById[144010] = 'DISPATCHER__RESPONSE__MOBILE__TASK_START')] = 144010
            values[(valuesById[144011] = 'DISPATCHER__RESPONSE__ADMIN__COURIER_ASSIGN_TEAM')] = 144011
            values[(valuesById[144012] = 'DISPATCHER__RESPONSE__ADMIN__GET_DASHBOARD_INFO')] = 144012
            values[(valuesById[144013] = 'DISPATCHER__RESPONSE__ADMIN__JOB_ASSIGN_COURIER')] = 144013
            values[(valuesById[144014] = 'DISPATCHER__RESPONSE__ADMIN__JOBS_GET_LIST')] = 144014
            values[(valuesById[144015] = 'DISPATCHER__RESPONSE__ADMIN__TASK_ASSIGN_JOB')] = 144015
            values[(valuesById[144016] = 'DISPATCHER__RESPONSE__ADMIN__TASK_GET_DETAILS')] = 144016
            values[(valuesById[144017] = 'DISPATCHER__RESPONSE__ADMIN__TEAM_CREATE')] = 144017
            values[(valuesById[144018] = 'DISPATCHER__RESPONSE__ADMIN__TEAM_UPDATE')] = 144018
            values[(valuesById[144019] = 'DISPATCHER__RESPONSE__ADMIN__TEAM_DELETE')] = 144019
            values[(valuesById[144020] = 'DISPATCHER__RESPONSE__ADMIN__TEAM_GET_ALL')] = 144020
            values[(valuesById[144021] = 'DISPATCHER__RESPONSE__ADMIN__TEAM_WITH_WAREHOUSES')] = 144021
            values[(valuesById[144022] = 'DISPATCHER__RESPONSE__ADMIN__TEAM_SET_WAREHOUSES')] = 144022
            values[(valuesById[144023] = 'DISPATCHER__RESPONSE__ADMIN__COURIER_CREATE')] = 144023
            values[(valuesById[144024] = 'DISPATCHER__RESPONSE__ADMIN__COURIER_UPDATE')] = 144024
            values[(valuesById[144025] = 'DISPATCHER__RESPONSE__ADMIN__COURIER_DEACTIVATE')] = 144025
            values[(valuesById[144026] = 'DISPATCHER__RESPONSE__ADMIN__COURIER_UNASSIGN_TEAM')] = 144026
            values[(valuesById[144027] = 'DISPATCHER__RESPONSE__ADMIN__JOB_UNASSIGN_COURIER')] = 144027
            values[(valuesById[144028] = 'DISPATCHER__RESPONSE__ADMIN__TASK_UNASSIGN_JOB')] = 144028
            values[(valuesById[144029] = 'DISPATCHER__RESPONSE__ADMIN__COURIER_FORCE_OFFLINE')] = 144029
            values[(valuesById[144030] = 'DISPATCHER__RESPONSE__ADMIN__WAREHOUSES_GET_ALL')] = 144030
            values[(valuesById[144033] = 'DISPATCHER__RESPONSE__ADMIN__GET_DASHBOARD_INFO_V2')] = 144033
            values[(valuesById[144034] = 'DISPATCHER__RESPONSE__ADMIN__GET_DELIVERY_ZONES')] = 144034
            values[(valuesById[144035] = 'DISPATCHER__RESPONSE__ADMIN__JOBS_GET_LIST_V2')] = 144035
            values[(valuesById[144036] = 'DISPATCHER__RESPONSE__ADMIN__SEARCH_ORDERS')] = 144036
            values[(valuesById[144037] = 'DISPATCHER__RESPONSE__ADMIN__JOB_UNASSIGN_ORDER')] = 144037
            values[(valuesById[144038] = 'DISPATCHER__RESPONSE__ADMIN__ORDER_ASSIGN')] = 144038
            values[(valuesById[144040] = 'DISPATCHER__RESPONSE__ADMIN__WAREHOUSE_WITH_TEAMS')] = 144040
            values[(valuesById[144041] = 'DISPATCHER__RESPONSE__ADMIN__TEAM_GET_USERS')] = 144041
            values[(valuesById[144042] = 'DISPATCHER__RESPONSE__ADMIN__SEARCH_USERS')] = 144042
            values[(valuesById[144043] = 'DISPATCHER__RESPONSE__ADMIN__TEAM_ATTACH_USERS')] = 144043
            values[(valuesById[144044] = 'DISPATCHER__RESPONSE__ADMIN__TEAM_DETACH_USER')] = 144044
            values[(valuesById[144046] = 'DISPATCHER__RESPONSE__ADMIN__COURIER_CREATE_V3')] = 144046
            values[(valuesById[144047] = 'DISPATCHER__RESPONSE__ADMIN__GET_COURIER_BY_USER_EXTERNAL_ID')] = 144047
            values[(valuesById[144048] = 'DISPATCHER__RESPONSE__ADMIN__JOB_MAP_INFO')] = 144048
            values[(valuesById[144049] = 'DISPATCHER__RESPONSE__ADMIN__GET_ALL_COURIERS')] = 144049
            values[(valuesById[144050] = 'DISPATCHER__RESPONSE__ADMIN__GET_ALL_ORDERS_WITH_FILTERS')] = 144050
            values[(valuesById[144031] = 'DISPATCHER__RESPONSE__ORDER_GET_LOCATION')] = 144031
            values[(valuesById[144032] = 'DISPATCHER__RESPONSE__COURIERS_GET_IDLE')] = 144032
            values[(valuesById[144051] = 'DISPATCHER__RESPONSE__COURIER_LIST')] = 144051
            values[(valuesById[144052] = 'DISPATCHER__RESPONSE__COURIER_BULK')] = 144052
            values[(valuesById[144053] = 'DISPATCHER__RESPONSE__COURIER_GET')] = 144053
            values[(valuesById[144054] = 'DISPATCHER__RESPONSE__TASK_LIST')] = 144054
            values[(valuesById[144055] = 'DISPATCHER__RESPONSE__TASK_BULK')] = 144055
            values[(valuesById[144056] = 'DISPATCHER__RESPONSE__TASK_GET')] = 144056
            values[(valuesById[144061] = 'DISPATCHER__RESPONSE__CREATE_DELIVERY')] = 144061
            values[(valuesById[144062] = 'DISPATCHER__RESPONSE__GET_PARCELS')] = 144062
            values[(valuesById[144063] = 'DISPATCHER__RESPONSE__MARK_ORDER_PICKED')] = 144063
            values[(valuesById[144073] = 'DISPATCHER__REQUEST__GET_PARCELS')] = 144073
            values[(valuesById[144074] = 'DISPATCHER__REQUEST__CREATE_DELIVERY')] = 144074
            values[(valuesById[144075] = 'DISPATCHER__REQUEST__MARK_ORDER_PICKED')] = 144075
            values[(valuesById[146000] = 'DISPATCHER__ERROR_ORDERS_NOT_PICKED')] = 146000
            return values
        })()

        return JiffyMessageBody
    })()

    return $root
})
