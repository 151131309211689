export const capitalizeFirstLetter = (text: string, forceLowerCase?: boolean) => {
  if (!text) return '';

  const textClean = text.replaceAll('_', ' ');

  const firstChar = textClean[0].toUpperCase();
  const remainChars = textClean.slice(1, textClean.length);

  if (forceLowerCase) return firstChar + remainChars.toLowerCase()
  else return firstChar + remainChars;
}
