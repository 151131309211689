import { SelectItem } from 'components/Select';
import { orders } from 'proto/build/js/last-mile/dispatcher/orders';

interface OrderStatus {
  raw: string;
  display: string;
}

// Returns name of all Order statuses
export const deliveryStatus = (): OrderStatus[] => {
  return Object.keys(orders.Status).map(status => {
    const display = status.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    return { raw: status, display };
  });
}

// Returns items for Select input of Order statuses
export const getOrderStatusesSelectItems = (): SelectItem[] => {
  const statuses = deliveryStatus();
  return statuses.map((status: OrderStatus) => ({
    value: status.raw,
    text: status.display,
    rawText: status.display
  }));
}

export const getOrderStatusNameByValue = (value: number) => {
  return orders.Status[value].split('_').map((word) => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
};

export const getOrderStatusNameByKey = (value: string) => {
  const status = Object.keys(orders.Status).find(status => status === value);
  if (!status) return value;
  return status?.split('_').map((word) => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
};
