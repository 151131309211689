import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useLang } from '../../hooks/useLang';

import { LOCALE } from './constants';
import styles from './LanguageSelector.module.scss';
import { mainStore } from 'stores/MainStore';
import { authStore } from 'stores/AuthStore';

const LanguageSelector = () => {
  const { setLanguage } = useLang();
  const [curLangNum, setCurLangNum] = useState(0);

  useEffect(() => {
    const idx = authStore.companyConfig?.langs.indexOf(
      mainStore.lang || authStore.companyConfig?.lang,
    );
    setCurLangNum(idx);
  }, []);

  if (!authStore.companyConfig?.langs || authStore.companyConfig?.langs.length < 2) {
    return null;
  }

  const nextLangNum =
    curLangNum + 1 < authStore.companyConfig?.langs.length ? curLangNum + 1 : 0;

  const handleChangeLang = (nextNum: number) => {
    setCurLangNum(nextNum);
    const nextLang = authStore.companyConfig?.langs[nextNum];
    if (nextLang) {
      setLanguage(nextLang);
    }
  };

  return (
    <button
      className={styles.root}
      onClick={() => handleChangeLang(nextLangNum)}
    >
      <img
        src={
          LOCALE[authStore.companyConfig.langs[nextLangNum] as keyof typeof LOCALE].img ?? ''
        }
        alt={LOCALE[authStore.companyConfig.langs[nextLangNum] as keyof typeof LOCALE].title}
      />
      <span>
        {LOCALE[authStore.companyConfig?.langs[nextLangNum] as keyof typeof LOCALE].title}
      </span>
    </button>
  );
};

export default observer(LanguageSelector);
