import { ExpandableHeader } from 'components/ExpandableHeader/ExpandableHeader';
import { FC, useCallback, useMemo, useState } from 'react';
import { Interval } from './Interval';
import format from 'date-fns/format';
import { SlotJob } from 'api/types/Job';
import { generateHtmlClasses } from 'utils/htmlClasses';

type SlotProps = {
  job: SlotJob;
  date: string;
  index: number;
};

export const Slot: FC<SlotProps> = ({ job, date, index: slotIndex }) => {
  const title = useMemo(() => format(new Date(+date), 'dd MMMM yy'), [date]);
  const [isExpanded, setIsExpanded] = useState(slotIndex === 0);
  const handleExpand = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  return (
    <div className="slot">
      <ExpandableHeader
        titleClass="slot-headerTitle"
        isExpanded={isExpanded}
        onClick={handleExpand}
        title={title}
      />
      <div
        className={generateHtmlClasses('slot-body', {
          __expanded: isExpanded,
        })}
      >
        {Object.keys(job).map((item, index) => {
          const interval = item.split('-');
          return (
            <Interval
              index={index}
              isExpandedByDefault={slotIndex === 0 && index === 0}
              orders={job[item]}
              key={index}
              title={`${interval[0]} - ${interval[1]}`}
            />
          );
        })}
      </div>
    </div>
  );
};
