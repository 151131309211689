import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { authStore } from 'stores/AuthStore';
import { mainStore } from 'stores/MainStore';
import { getCookieValue, setCookie } from 'utils/cookies';

const rtlLangs = ['ar', 'he', 'fa', 'ur', 'sd', 'hw', 'ps', 'ug'];

export const useLang = () => {
  const { i18n } = useTranslation();

  const changeLang = useCallback((lang: string) => {
    mainStore.setLang(lang);
    i18n.changeLanguage(lang);
    mainStore.setDir(rtlLangs.includes(lang) ? 'rtl' : 'ltr');
  }, [i18n]);

  const setNavigatorLang = useCallback(() => {
    const browserLang = navigator.language;
    const langGroup = browserLang.split('-')[0] ?? authStore.companyConfig.lang;
    const checkedLang = authStore.companyConfig.langs.includes(langGroup) ? langGroup : authStore.companyConfig.lang;
    changeLang(checkedLang);
    setCookie('lang', checkedLang, { 'max-age': 2678400 });
  }, [changeLang]);

  useEffect(() => {
    const cookieLang = getCookieValue('lang');

    if (cookieLang && authStore.companyConfig?.langs?.includes(cookieLang)) {
      if (authStore.companyConfig.langs.includes(cookieLang)) {
        changeLang(cookieLang);
      } else {
        setNavigatorLang();
      }
    } else {
      setNavigatorLang();
    }
  }, [setNavigatorLang, changeLang]);

  const setLanguage = (lang: string) => {
    // Temporary fix. While waiting for SSR release
    setCookie('lang', lang, { 'max-age': 2678400 });
    window.location.reload();
  };

  return { setLanguage };
};
