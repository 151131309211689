/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
;(function (global, factory) {
    /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd) define(['protobufjs/minimal'], factory)
    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require('protobufjs/minimal'))
})(this, function ($protobuf) {
    'use strict'

    // Common aliases
    var $Reader = $protobuf.Reader,
        $Writer = $protobuf.Writer,
        $util = $protobuf.util

    // Exported root namespace
    var $root = $protobuf.roots['default'] || ($protobuf.roots['default'] = {})

    $root.JobUnassignCourierReq = (function () {
        /**
         * Properties of a JobUnassignCourierReq.
         * @exports IJobUnassignCourierReq
         * @interface IJobUnassignCourierReq
         * @property {string} courierID JobUnassignCourierReq courierID
         * @property {string} jobID JobUnassignCourierReq jobID
         */

        /**
         * Constructs a new JobUnassignCourierReq.
         * @exports JobUnassignCourierReq
         * @classdesc Represents a JobUnassignCourierReq.
         * @implements IJobUnassignCourierReq
         * @constructor
         * @param {IJobUnassignCourierReq=} [properties] Properties to set
         */
        function JobUnassignCourierReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * JobUnassignCourierReq courierID.
         * @member {string} courierID
         * @memberof JobUnassignCourierReq
         * @instance
         */
        JobUnassignCourierReq.prototype.courierID = ''

        /**
         * JobUnassignCourierReq jobID.
         * @member {string} jobID
         * @memberof JobUnassignCourierReq
         * @instance
         */
        JobUnassignCourierReq.prototype.jobID = ''

        /**
         * Creates a new JobUnassignCourierReq instance using the specified properties.
         * @function create
         * @memberof JobUnassignCourierReq
         * @static
         * @param {IJobUnassignCourierReq=} [properties] Properties to set
         * @returns {JobUnassignCourierReq} JobUnassignCourierReq instance
         */
        JobUnassignCourierReq.create = function create(properties) {
            return new JobUnassignCourierReq(properties)
        }

        /**
         * Encodes the specified JobUnassignCourierReq message. Does not implicitly {@link JobUnassignCourierReq.verify|verify} messages.
         * @function encode
         * @memberof JobUnassignCourierReq
         * @static
         * @param {JobUnassignCourierReq} message JobUnassignCourierReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JobUnassignCourierReq.encode = function encode(message, writer) {
            if (!writer) writer = $Writer.create()
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.courierID)
            writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.jobID)
            return writer
        }

        /**
         * Encodes the specified JobUnassignCourierReq message, length delimited. Does not implicitly {@link JobUnassignCourierReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof JobUnassignCourierReq
         * @static
         * @param {JobUnassignCourierReq} message JobUnassignCourierReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JobUnassignCourierReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a JobUnassignCourierReq message from the specified reader or buffer.
         * @function decode
         * @memberof JobUnassignCourierReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {JobUnassignCourierReq} JobUnassignCourierReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JobUnassignCourierReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
            var end = length === undefined ? reader.len : reader.pos + length,
                message = new $root.JobUnassignCourierReq()
            while (reader.pos < end) {
                var tag = reader.uint32()
                switch (tag >>> 3) {
                    case 1: {
                        message.courierID = reader.string()
                        break
                    }
                    case 2: {
                        message.jobID = reader.string()
                        break
                    }
                    default:
                        reader.skipType(tag & 7)
                        break
                }
            }
            if (!message.hasOwnProperty('courierID'))
                throw $util.ProtocolError("missing required 'courierID'", { instance: message })
            if (!message.hasOwnProperty('jobID'))
                throw $util.ProtocolError("missing required 'jobID'", { instance: message })
            return message
        }

        /**
         * Decodes a JobUnassignCourierReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof JobUnassignCourierReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {JobUnassignCourierReq} JobUnassignCourierReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JobUnassignCourierReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader)) reader = new $Reader(reader)
            return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a JobUnassignCourierReq message.
         * @function verify
         * @memberof JobUnassignCourierReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        JobUnassignCourierReq.verify = function verify(message) {
            if (typeof message !== 'object' || message === null) return 'object expected'
            if (!$util.isString(message.courierID)) return 'courierID: string expected'
            if (!$util.isString(message.jobID)) return 'jobID: string expected'
            return null
        }

        /**
         * Creates a JobUnassignCourierReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof JobUnassignCourierReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {JobUnassignCourierReq} JobUnassignCourierReq
         */
        JobUnassignCourierReq.fromObject = function fromObject(object) {
            if (object instanceof $root.JobUnassignCourierReq) return object
            var message = new $root.JobUnassignCourierReq()
            if (object.courierID != null) message.courierID = String(object.courierID)
            if (object.jobID != null) message.jobID = String(object.jobID)
            return message
        }

        /**
         * Creates a plain object from a JobUnassignCourierReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof JobUnassignCourierReq
         * @static
         * @param {JobUnassignCourierReq} message JobUnassignCourierReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        JobUnassignCourierReq.toObject = function toObject(message, options) {
            if (!options) options = {}
            var object = {}
            if (options.defaults) {
                object.courierID = ''
                object.jobID = ''
            }
            if (message.courierID != null && message.hasOwnProperty('courierID')) object.courierID = message.courierID
            if (message.jobID != null && message.hasOwnProperty('jobID')) object.jobID = message.jobID
            return object
        }

        /**
         * Converts this JobUnassignCourierReq to JSON.
         * @function toJSON
         * @memberof JobUnassignCourierReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        JobUnassignCourierReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        /**
         * Gets the default type url for JobUnassignCourierReq
         * @function getTypeUrl
         * @memberof JobUnassignCourierReq
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        JobUnassignCourierReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = 'type.googleapis.com'
            }
            return typeUrlPrefix + '/JobUnassignCourierReq'
        }

        return JobUnassignCourierReq
    })()

    $root.JobUnassignCourierResp = (function () {
        /**
         * Properties of a JobUnassignCourierResp.
         * @exports IJobUnassignCourierResp
         * @interface IJobUnassignCourierResp
         * @property {jobs.JobWithRel} job JobUnassignCourierResp job
         */

        /**
         * Constructs a new JobUnassignCourierResp.
         * @exports JobUnassignCourierResp
         * @classdesc Represents a JobUnassignCourierResp.
         * @implements IJobUnassignCourierResp
         * @constructor
         * @param {IJobUnassignCourierResp=} [properties] Properties to set
         */
        function JobUnassignCourierResp(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * JobUnassignCourierResp job.
         * @member {jobs.JobWithRel} job
         * @memberof JobUnassignCourierResp
         * @instance
         */
        JobUnassignCourierResp.prototype.job = null

        /**
         * Creates a new JobUnassignCourierResp instance using the specified properties.
         * @function create
         * @memberof JobUnassignCourierResp
         * @static
         * @param {IJobUnassignCourierResp=} [properties] Properties to set
         * @returns {JobUnassignCourierResp} JobUnassignCourierResp instance
         */
        JobUnassignCourierResp.create = function create(properties) {
            return new JobUnassignCourierResp(properties)
        }

        /**
         * Encodes the specified JobUnassignCourierResp message. Does not implicitly {@link JobUnassignCourierResp.verify|verify} messages.
         * @function encode
         * @memberof JobUnassignCourierResp
         * @static
         * @param {JobUnassignCourierResp} message JobUnassignCourierResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JobUnassignCourierResp.encode = function encode(message, writer) {
            if (!writer) writer = $Writer.create()
            $root.jobs.JobWithRel.encode(message.job, writer.uint32(/* id 1, wireType 2 =*/ 10).fork()).ldelim()
            return writer
        }

        /**
         * Encodes the specified JobUnassignCourierResp message, length delimited. Does not implicitly {@link JobUnassignCourierResp.verify|verify} messages.
         * @function encodeDelimited
         * @memberof JobUnassignCourierResp
         * @static
         * @param {JobUnassignCourierResp} message JobUnassignCourierResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JobUnassignCourierResp.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a JobUnassignCourierResp message from the specified reader or buffer.
         * @function decode
         * @memberof JobUnassignCourierResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {JobUnassignCourierResp} JobUnassignCourierResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JobUnassignCourierResp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
            var end = length === undefined ? reader.len : reader.pos + length,
                message = new $root.JobUnassignCourierResp()
            while (reader.pos < end) {
                var tag = reader.uint32()
                switch (tag >>> 3) {
                    case 1: {
                        message.job = $root.jobs.JobWithRel.decode(reader, reader.uint32())
                        break
                    }
                    default:
                        reader.skipType(tag & 7)
                        break
                }
            }
            if (!message.hasOwnProperty('job'))
                throw $util.ProtocolError("missing required 'job'", { instance: message })
            return message
        }

        /**
         * Decodes a JobUnassignCourierResp message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof JobUnassignCourierResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {JobUnassignCourierResp} JobUnassignCourierResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JobUnassignCourierResp.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader)) reader = new $Reader(reader)
            return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a JobUnassignCourierResp message.
         * @function verify
         * @memberof JobUnassignCourierResp
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        JobUnassignCourierResp.verify = function verify(message) {
            if (typeof message !== 'object' || message === null) return 'object expected'
            {
                var error = $root.jobs.JobWithRel.verify(message.job)
                if (error) return 'job.' + error
            }
            return null
        }

        /**
         * Creates a JobUnassignCourierResp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof JobUnassignCourierResp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {JobUnassignCourierResp} JobUnassignCourierResp
         */
        JobUnassignCourierResp.fromObject = function fromObject(object) {
            if (object instanceof $root.JobUnassignCourierResp) return object
            var message = new $root.JobUnassignCourierResp()
            if (object.job != null) {
                if (typeof object.job !== 'object') throw TypeError('.JobUnassignCourierResp.job: object expected')
                message.job = $root.jobs.JobWithRel.fromObject(object.job)
            }
            return message
        }

        /**
         * Creates a plain object from a JobUnassignCourierResp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof JobUnassignCourierResp
         * @static
         * @param {JobUnassignCourierResp} message JobUnassignCourierResp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        JobUnassignCourierResp.toObject = function toObject(message, options) {
            if (!options) options = {}
            var object = {}
            if (options.defaults) object.job = null
            if (message.job != null && message.hasOwnProperty('job'))
                object.job = $root.jobs.JobWithRel.toObject(message.job, options)
            return object
        }

        /**
         * Converts this JobUnassignCourierResp to JSON.
         * @function toJSON
         * @memberof JobUnassignCourierResp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        JobUnassignCourierResp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        /**
         * Gets the default type url for JobUnassignCourierResp
         * @function getTypeUrl
         * @memberof JobUnassignCourierResp
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        JobUnassignCourierResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = 'type.googleapis.com'
            }
            return typeUrlPrefix + '/JobUnassignCourierResp'
        }

        return JobUnassignCourierResp
    })()

    $root.jobs = (function () {
        /**
         * Namespace jobs.
         * @exports jobs
         * @namespace
         */
        var jobs = {}

        /**
         * State enum.
         * @name jobs.State
         * @enum {number}
         * @property {number} UNASSIGNED=0 UNASSIGNED value
         * @property {number} ASSIGNED=1 ASSIGNED value
         * @property {number} IN_PROGRESS=2 IN_PROGRESS value
         * @property {number} COMPLETED=3 COMPLETED value
         * @property {number} CANCELLED=4 CANCELLED value
         * @property {number} FAILED=5 FAILED value
         */
        jobs.State = (function () {
            var valuesById = {},
                values = Object.create(valuesById)
            values[(valuesById[0] = 'UNASSIGNED')] = 0
            values[(valuesById[1] = 'ASSIGNED')] = 1
            values[(valuesById[2] = 'IN_PROGRESS')] = 2
            values[(valuesById[3] = 'COMPLETED')] = 3
            values[(valuesById[4] = 'CANCELLED')] = 4
            values[(valuesById[5] = 'FAILED')] = 5
            return values
        })()

        jobs.Job = (function () {
            /**
             * Properties of a Job.
             * @memberof jobs
             * @interface IJob
             * @property {string} id Job id
             * @property {jobs.State} state Job state
             * @property {string|null} [courierId] Job courierId
             * @property {number} createdAt Job createdAt
             * @property {number|null} [updatedAt] Job updatedAt
             * @property {number|null} [deletedAt] Job deletedAt
             * @property {string|null} [teamID] Job teamID
             */

            /**
             * Constructs a new Job.
             * @memberof jobs
             * @classdesc Represents a Job.
             * @implements IJob
             * @constructor
             * @param {jobs.IJob=} [properties] Properties to set
             */
            function Job(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * Job id.
             * @member {string} id
             * @memberof jobs.Job
             * @instance
             */
            Job.prototype.id = ''

            /**
             * Job state.
             * @member {jobs.State} state
             * @memberof jobs.Job
             * @instance
             */
            Job.prototype.state = 0

            /**
             * Job courierId.
             * @member {string} courierId
             * @memberof jobs.Job
             * @instance
             */
            Job.prototype.courierId = ''

            /**
             * Job createdAt.
             * @member {number} createdAt
             * @memberof jobs.Job
             * @instance
             */
            Job.prototype.createdAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Job updatedAt.
             * @member {number} updatedAt
             * @memberof jobs.Job
             * @instance
             */
            Job.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Job deletedAt.
             * @member {number} deletedAt
             * @memberof jobs.Job
             * @instance
             */
            Job.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Job teamID.
             * @member {string} teamID
             * @memberof jobs.Job
             * @instance
             */
            Job.prototype.teamID = ''

            /**
             * Creates a new Job instance using the specified properties.
             * @function create
             * @memberof jobs.Job
             * @static
             * @param {jobs.IJob=} [properties] Properties to set
             * @returns {jobs.Job} Job instance
             */
            Job.create = function create(properties) {
                return new Job(properties)
            }

            /**
             * Encodes the specified Job message. Does not implicitly {@link jobs.Job.verify|verify} messages.
             * @function encode
             * @memberof jobs.Job
             * @static
             * @param {jobs.Job} message Job message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Job.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
                writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.state)
                if (message.courierId != null && Object.hasOwnProperty.call(message, 'courierId'))
                    writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.courierId)
                writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.createdAt)
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, 'updatedAt'))
                    writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.updatedAt)
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, 'deletedAt'))
                    writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.deletedAt)
                if (message.teamID != null && Object.hasOwnProperty.call(message, 'teamID'))
                    writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.teamID)
                return writer
            }

            /**
             * Encodes the specified Job message, length delimited. Does not implicitly {@link jobs.Job.verify|verify} messages.
             * @function encodeDelimited
             * @memberof jobs.Job
             * @static
             * @param {jobs.Job} message Job message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Job.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a Job message from the specified reader or buffer.
             * @function decode
             * @memberof jobs.Job
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {jobs.Job} Job
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Job.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.jobs.Job()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.id = reader.string()
                            break
                        }
                        case 2: {
                            message.state = reader.int32()
                            break
                        }
                        case 3: {
                            message.courierId = reader.string()
                            break
                        }
                        case 4: {
                            message.createdAt = reader.int64()
                            break
                        }
                        case 5: {
                            message.updatedAt = reader.int64()
                            break
                        }
                        case 6: {
                            message.deletedAt = reader.int64()
                            break
                        }
                        case 7: {
                            message.teamID = reader.string()
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('id'))
                    throw $util.ProtocolError("missing required 'id'", { instance: message })
                if (!message.hasOwnProperty('state'))
                    throw $util.ProtocolError("missing required 'state'", { instance: message })
                if (!message.hasOwnProperty('createdAt'))
                    throw $util.ProtocolError("missing required 'createdAt'", { instance: message })
                return message
            }

            /**
             * Decodes a Job message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof jobs.Job
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {jobs.Job} Job
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Job.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a Job message.
             * @function verify
             * @memberof jobs.Job
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Job.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (!$util.isString(message.id)) return 'id: string expected'
                switch (message.state) {
                    default:
                        return 'state: enum value expected'
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break
                }
                if (message.courierId != null && message.hasOwnProperty('courierId'))
                    if (!$util.isString(message.courierId)) return 'courierId: string expected'
                if (
                    !$util.isInteger(message.createdAt) &&
                    !(
                        message.createdAt &&
                        $util.isInteger(message.createdAt.low) &&
                        $util.isInteger(message.createdAt.high)
                    )
                )
                    return 'createdAt: integer|Long expected'
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (
                        !$util.isInteger(message.updatedAt) &&
                        !(
                            message.updatedAt &&
                            $util.isInteger(message.updatedAt.low) &&
                            $util.isInteger(message.updatedAt.high)
                        )
                    )
                        return 'updatedAt: integer|Long expected'
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (
                        !$util.isInteger(message.deletedAt) &&
                        !(
                            message.deletedAt &&
                            $util.isInteger(message.deletedAt.low) &&
                            $util.isInteger(message.deletedAt.high)
                        )
                    )
                        return 'deletedAt: integer|Long expected'
                if (message.teamID != null && message.hasOwnProperty('teamID'))
                    if (!$util.isString(message.teamID)) return 'teamID: string expected'
                return null
            }

            /**
             * Creates a Job message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof jobs.Job
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {jobs.Job} Job
             */
            Job.fromObject = function fromObject(object) {
                if (object instanceof $root.jobs.Job) return object
                var message = new $root.jobs.Job()
                if (object.id != null) message.id = String(object.id)
                switch (object.state) {
                    case 'UNASSIGNED':
                    case 0:
                        message.state = 0
                        break
                    case 'ASSIGNED':
                    case 1:
                        message.state = 1
                        break
                    case 'IN_PROGRESS':
                    case 2:
                        message.state = 2
                        break
                    case 'COMPLETED':
                    case 3:
                        message.state = 3
                        break
                    case 'CANCELLED':
                    case 4:
                        message.state = 4
                        break
                    case 'FAILED':
                    case 5:
                        message.state = 5
                        break
                }
                if (object.courierId != null) message.courierId = String(object.courierId)
                if (object.createdAt != null)
                    if ($util.Long) (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false
                    else if (typeof object.createdAt === 'string') message.createdAt = parseInt(object.createdAt, 10)
                    else if (typeof object.createdAt === 'number') message.createdAt = object.createdAt
                    else if (typeof object.createdAt === 'object')
                        message.createdAt = new $util.LongBits(
                            object.createdAt.low >>> 0,
                            object.createdAt.high >>> 0
                        ).toNumber()
                if (object.updatedAt != null)
                    if ($util.Long) (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false
                    else if (typeof object.updatedAt === 'string') message.updatedAt = parseInt(object.updatedAt, 10)
                    else if (typeof object.updatedAt === 'number') message.updatedAt = object.updatedAt
                    else if (typeof object.updatedAt === 'object')
                        message.updatedAt = new $util.LongBits(
                            object.updatedAt.low >>> 0,
                            object.updatedAt.high >>> 0
                        ).toNumber()
                if (object.deletedAt != null)
                    if ($util.Long) (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false
                    else if (typeof object.deletedAt === 'string') message.deletedAt = parseInt(object.deletedAt, 10)
                    else if (typeof object.deletedAt === 'number') message.deletedAt = object.deletedAt
                    else if (typeof object.deletedAt === 'object')
                        message.deletedAt = new $util.LongBits(
                            object.deletedAt.low >>> 0,
                            object.deletedAt.high >>> 0
                        ).toNumber()
                if (object.teamID != null) message.teamID = String(object.teamID)
                return message
            }

            /**
             * Creates a plain object from a Job message. Also converts values to other types if specified.
             * @function toObject
             * @memberof jobs.Job
             * @static
             * @param {jobs.Job} message Job
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Job.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.defaults) {
                    object.id = ''
                    object.state = options.enums === String ? 'UNASSIGNED' : 0
                    object.courierId = ''
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.createdAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.createdAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.updatedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.updatedAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.deletedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.deletedAt = options.longs === String ? '0' : 0
                    object.teamID = ''
                }
                if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
                if (message.state != null && message.hasOwnProperty('state'))
                    object.state = options.enums === String ? $root.jobs.State[message.state] : message.state
                if (message.courierId != null && message.hasOwnProperty('courierId'))
                    object.courierId = message.courierId
                if (message.createdAt != null && message.hasOwnProperty('createdAt'))
                    if (typeof message.createdAt === 'number')
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt
                    else
                        object.createdAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.createdAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.createdAt.low >>> 0,
                                      message.createdAt.high >>> 0
                                  ).toNumber()
                                : message.createdAt
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (typeof message.updatedAt === 'number')
                        object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt
                    else
                        object.updatedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.updatedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.updatedAt.low >>> 0,
                                      message.updatedAt.high >>> 0
                                  ).toNumber()
                                : message.updatedAt
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (typeof message.deletedAt === 'number')
                        object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt
                    else
                        object.deletedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.deletedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.deletedAt.low >>> 0,
                                      message.deletedAt.high >>> 0
                                  ).toNumber()
                                : message.deletedAt
                if (message.teamID != null && message.hasOwnProperty('teamID')) object.teamID = message.teamID
                return object
            }

            /**
             * Converts this Job to JSON.
             * @function toJSON
             * @memberof jobs.Job
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Job.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for Job
             * @function getTypeUrl
             * @memberof jobs.Job
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Job.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/jobs.Job'
            }

            return Job
        })()

        jobs.JobWithRel = (function () {
            /**
             * Properties of a JobWithRel.
             * @memberof jobs
             * @interface IJobWithRel
             * @property {jobs.Job} job JobWithRel job
             * @property {Array.<tasks.TaskWithRel>|null} [pickupTasks] JobWithRel pickupTasks
             * @property {Array.<tasks.TaskWithRel>|null} [dropOffTasks] JobWithRel dropOffTasks
             * @property {Array.<tasks.TaskWithRel>|null} [returnTasks] JobWithRel returnTasks
             */

            /**
             * Constructs a new JobWithRel.
             * @memberof jobs
             * @classdesc Represents a JobWithRel.
             * @implements IJobWithRel
             * @constructor
             * @param {jobs.IJobWithRel=} [properties] Properties to set
             */
            function JobWithRel(properties) {
                this.pickupTasks = []
                this.dropOffTasks = []
                this.returnTasks = []
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * JobWithRel job.
             * @member {jobs.Job} job
             * @memberof jobs.JobWithRel
             * @instance
             */
            JobWithRel.prototype.job = null

            /**
             * JobWithRel pickupTasks.
             * @member {Array.<tasks.TaskWithRel>} pickupTasks
             * @memberof jobs.JobWithRel
             * @instance
             */
            JobWithRel.prototype.pickupTasks = $util.emptyArray

            /**
             * JobWithRel dropOffTasks.
             * @member {Array.<tasks.TaskWithRel>} dropOffTasks
             * @memberof jobs.JobWithRel
             * @instance
             */
            JobWithRel.prototype.dropOffTasks = $util.emptyArray

            /**
             * JobWithRel returnTasks.
             * @member {Array.<tasks.TaskWithRel>} returnTasks
             * @memberof jobs.JobWithRel
             * @instance
             */
            JobWithRel.prototype.returnTasks = $util.emptyArray

            /**
             * Creates a new JobWithRel instance using the specified properties.
             * @function create
             * @memberof jobs.JobWithRel
             * @static
             * @param {jobs.IJobWithRel=} [properties] Properties to set
             * @returns {jobs.JobWithRel} JobWithRel instance
             */
            JobWithRel.create = function create(properties) {
                return new JobWithRel(properties)
            }

            /**
             * Encodes the specified JobWithRel message. Does not implicitly {@link jobs.JobWithRel.verify|verify} messages.
             * @function encode
             * @memberof jobs.JobWithRel
             * @static
             * @param {jobs.JobWithRel} message JobWithRel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            JobWithRel.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                $root.jobs.Job.encode(message.job, writer.uint32(/* id 1, wireType 2 =*/ 10).fork()).ldelim()
                if (message.pickupTasks != null && message.pickupTasks.length)
                    for (var i = 0; i < message.pickupTasks.length; ++i)
                        $root.tasks.TaskWithRel.encode(
                            message.pickupTasks[i],
                            writer.uint32(/* id 2, wireType 2 =*/ 18).fork()
                        ).ldelim()
                if (message.dropOffTasks != null && message.dropOffTasks.length)
                    for (var i = 0; i < message.dropOffTasks.length; ++i)
                        $root.tasks.TaskWithRel.encode(
                            message.dropOffTasks[i],
                            writer.uint32(/* id 3, wireType 2 =*/ 26).fork()
                        ).ldelim()
                if (message.returnTasks != null && message.returnTasks.length)
                    for (var i = 0; i < message.returnTasks.length; ++i)
                        $root.tasks.TaskWithRel.encode(
                            message.returnTasks[i],
                            writer.uint32(/* id 4, wireType 2 =*/ 34).fork()
                        ).ldelim()
                return writer
            }

            /**
             * Encodes the specified JobWithRel message, length delimited. Does not implicitly {@link jobs.JobWithRel.verify|verify} messages.
             * @function encodeDelimited
             * @memberof jobs.JobWithRel
             * @static
             * @param {jobs.JobWithRel} message JobWithRel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            JobWithRel.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a JobWithRel message from the specified reader or buffer.
             * @function decode
             * @memberof jobs.JobWithRel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {jobs.JobWithRel} JobWithRel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            JobWithRel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.jobs.JobWithRel()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.job = $root.jobs.Job.decode(reader, reader.uint32())
                            break
                        }
                        case 2: {
                            if (!(message.pickupTasks && message.pickupTasks.length)) message.pickupTasks = []
                            message.pickupTasks.push($root.tasks.TaskWithRel.decode(reader, reader.uint32()))
                            break
                        }
                        case 3: {
                            if (!(message.dropOffTasks && message.dropOffTasks.length)) message.dropOffTasks = []
                            message.dropOffTasks.push($root.tasks.TaskWithRel.decode(reader, reader.uint32()))
                            break
                        }
                        case 4: {
                            if (!(message.returnTasks && message.returnTasks.length)) message.returnTasks = []
                            message.returnTasks.push($root.tasks.TaskWithRel.decode(reader, reader.uint32()))
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('job'))
                    throw $util.ProtocolError("missing required 'job'", { instance: message })
                return message
            }

            /**
             * Decodes a JobWithRel message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof jobs.JobWithRel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {jobs.JobWithRel} JobWithRel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            JobWithRel.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a JobWithRel message.
             * @function verify
             * @memberof jobs.JobWithRel
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            JobWithRel.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                {
                    var error = $root.jobs.Job.verify(message.job)
                    if (error) return 'job.' + error
                }
                if (message.pickupTasks != null && message.hasOwnProperty('pickupTasks')) {
                    if (!Array.isArray(message.pickupTasks)) return 'pickupTasks: array expected'
                    for (var i = 0; i < message.pickupTasks.length; ++i) {
                        var error = $root.tasks.TaskWithRel.verify(message.pickupTasks[i])
                        if (error) return 'pickupTasks.' + error
                    }
                }
                if (message.dropOffTasks != null && message.hasOwnProperty('dropOffTasks')) {
                    if (!Array.isArray(message.dropOffTasks)) return 'dropOffTasks: array expected'
                    for (var i = 0; i < message.dropOffTasks.length; ++i) {
                        var error = $root.tasks.TaskWithRel.verify(message.dropOffTasks[i])
                        if (error) return 'dropOffTasks.' + error
                    }
                }
                if (message.returnTasks != null && message.hasOwnProperty('returnTasks')) {
                    if (!Array.isArray(message.returnTasks)) return 'returnTasks: array expected'
                    for (var i = 0; i < message.returnTasks.length; ++i) {
                        var error = $root.tasks.TaskWithRel.verify(message.returnTasks[i])
                        if (error) return 'returnTasks.' + error
                    }
                }
                return null
            }

            /**
             * Creates a JobWithRel message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof jobs.JobWithRel
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {jobs.JobWithRel} JobWithRel
             */
            JobWithRel.fromObject = function fromObject(object) {
                if (object instanceof $root.jobs.JobWithRel) return object
                var message = new $root.jobs.JobWithRel()
                if (object.job != null) {
                    if (typeof object.job !== 'object') throw TypeError('.jobs.JobWithRel.job: object expected')
                    message.job = $root.jobs.Job.fromObject(object.job)
                }
                if (object.pickupTasks) {
                    if (!Array.isArray(object.pickupTasks))
                        throw TypeError('.jobs.JobWithRel.pickupTasks: array expected')
                    message.pickupTasks = []
                    for (var i = 0; i < object.pickupTasks.length; ++i) {
                        if (typeof object.pickupTasks[i] !== 'object')
                            throw TypeError('.jobs.JobWithRel.pickupTasks: object expected')
                        message.pickupTasks[i] = $root.tasks.TaskWithRel.fromObject(object.pickupTasks[i])
                    }
                }
                if (object.dropOffTasks) {
                    if (!Array.isArray(object.dropOffTasks))
                        throw TypeError('.jobs.JobWithRel.dropOffTasks: array expected')
                    message.dropOffTasks = []
                    for (var i = 0; i < object.dropOffTasks.length; ++i) {
                        if (typeof object.dropOffTasks[i] !== 'object')
                            throw TypeError('.jobs.JobWithRel.dropOffTasks: object expected')
                        message.dropOffTasks[i] = $root.tasks.TaskWithRel.fromObject(object.dropOffTasks[i])
                    }
                }
                if (object.returnTasks) {
                    if (!Array.isArray(object.returnTasks))
                        throw TypeError('.jobs.JobWithRel.returnTasks: array expected')
                    message.returnTasks = []
                    for (var i = 0; i < object.returnTasks.length; ++i) {
                        if (typeof object.returnTasks[i] !== 'object')
                            throw TypeError('.jobs.JobWithRel.returnTasks: object expected')
                        message.returnTasks[i] = $root.tasks.TaskWithRel.fromObject(object.returnTasks[i])
                    }
                }
                return message
            }

            /**
             * Creates a plain object from a JobWithRel message. Also converts values to other types if specified.
             * @function toObject
             * @memberof jobs.JobWithRel
             * @static
             * @param {jobs.JobWithRel} message JobWithRel
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            JobWithRel.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.arrays || options.defaults) {
                    object.pickupTasks = []
                    object.dropOffTasks = []
                    object.returnTasks = []
                }
                if (options.defaults) object.job = null
                if (message.job != null && message.hasOwnProperty('job'))
                    object.job = $root.jobs.Job.toObject(message.job, options)
                if (message.pickupTasks && message.pickupTasks.length) {
                    object.pickupTasks = []
                    for (var j = 0; j < message.pickupTasks.length; ++j)
                        object.pickupTasks[j] = $root.tasks.TaskWithRel.toObject(message.pickupTasks[j], options)
                }
                if (message.dropOffTasks && message.dropOffTasks.length) {
                    object.dropOffTasks = []
                    for (var j = 0; j < message.dropOffTasks.length; ++j)
                        object.dropOffTasks[j] = $root.tasks.TaskWithRel.toObject(message.dropOffTasks[j], options)
                }
                if (message.returnTasks && message.returnTasks.length) {
                    object.returnTasks = []
                    for (var j = 0; j < message.returnTasks.length; ++j)
                        object.returnTasks[j] = $root.tasks.TaskWithRel.toObject(message.returnTasks[j], options)
                }
                return object
            }

            /**
             * Converts this JobWithRel to JSON.
             * @function toJSON
             * @memberof jobs.JobWithRel
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            JobWithRel.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for JobWithRel
             * @function getTypeUrl
             * @memberof jobs.JobWithRel
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            JobWithRel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/jobs.JobWithRel'
            }

            return JobWithRel
        })()

        return jobs
    })()

    $root.tasks = (function () {
        /**
         * Namespace tasks.
         * @exports tasks
         * @namespace
         */
        var tasks = {}

        tasks.TaskWithParcels = (function () {
            /**
             * Properties of a TaskWithParcels.
             * @memberof tasks
             * @interface ITaskWithParcels
             * @property {tasks.Task} task TaskWithParcels task
             * @property {Array.<parcels.Parcel>|null} [parcels] TaskWithParcels parcels
             */

            /**
             * Constructs a new TaskWithParcels.
             * @memberof tasks
             * @classdesc Represents a TaskWithParcels.
             * @implements ITaskWithParcels
             * @constructor
             * @param {tasks.ITaskWithParcels=} [properties] Properties to set
             */
            function TaskWithParcels(properties) {
                this.parcels = []
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * TaskWithParcels task.
             * @member {tasks.Task} task
             * @memberof tasks.TaskWithParcels
             * @instance
             */
            TaskWithParcels.prototype.task = null

            /**
             * TaskWithParcels parcels.
             * @member {Array.<parcels.Parcel>} parcels
             * @memberof tasks.TaskWithParcels
             * @instance
             */
            TaskWithParcels.prototype.parcels = $util.emptyArray

            /**
             * Creates a new TaskWithParcels instance using the specified properties.
             * @function create
             * @memberof tasks.TaskWithParcels
             * @static
             * @param {tasks.ITaskWithParcels=} [properties] Properties to set
             * @returns {tasks.TaskWithParcels} TaskWithParcels instance
             */
            TaskWithParcels.create = function create(properties) {
                return new TaskWithParcels(properties)
            }

            /**
             * Encodes the specified TaskWithParcels message. Does not implicitly {@link tasks.TaskWithParcels.verify|verify} messages.
             * @function encode
             * @memberof tasks.TaskWithParcels
             * @static
             * @param {tasks.TaskWithParcels} message TaskWithParcels message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TaskWithParcels.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                $root.tasks.Task.encode(message.task, writer.uint32(/* id 1, wireType 2 =*/ 10).fork()).ldelim()
                if (message.parcels != null && message.parcels.length)
                    for (var i = 0; i < message.parcels.length; ++i)
                        $root.parcels.Parcel.encode(
                            message.parcels[i],
                            writer.uint32(/* id 2, wireType 2 =*/ 18).fork()
                        ).ldelim()
                return writer
            }

            /**
             * Encodes the specified TaskWithParcels message, length delimited. Does not implicitly {@link tasks.TaskWithParcels.verify|verify} messages.
             * @function encodeDelimited
             * @memberof tasks.TaskWithParcels
             * @static
             * @param {tasks.TaskWithParcels} message TaskWithParcels message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TaskWithParcels.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a TaskWithParcels message from the specified reader or buffer.
             * @function decode
             * @memberof tasks.TaskWithParcels
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {tasks.TaskWithParcels} TaskWithParcels
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TaskWithParcels.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.tasks.TaskWithParcels()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.task = $root.tasks.Task.decode(reader, reader.uint32())
                            break
                        }
                        case 2: {
                            if (!(message.parcels && message.parcels.length)) message.parcels = []
                            message.parcels.push($root.parcels.Parcel.decode(reader, reader.uint32()))
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('task'))
                    throw $util.ProtocolError("missing required 'task'", { instance: message })
                return message
            }

            /**
             * Decodes a TaskWithParcels message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof tasks.TaskWithParcels
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {tasks.TaskWithParcels} TaskWithParcels
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TaskWithParcels.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a TaskWithParcels message.
             * @function verify
             * @memberof tasks.TaskWithParcels
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TaskWithParcels.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                {
                    var error = $root.tasks.Task.verify(message.task)
                    if (error) return 'task.' + error
                }
                if (message.parcels != null && message.hasOwnProperty('parcels')) {
                    if (!Array.isArray(message.parcels)) return 'parcels: array expected'
                    for (var i = 0; i < message.parcels.length; ++i) {
                        var error = $root.parcels.Parcel.verify(message.parcels[i])
                        if (error) return 'parcels.' + error
                    }
                }
                return null
            }

            /**
             * Creates a TaskWithParcels message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof tasks.TaskWithParcels
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {tasks.TaskWithParcels} TaskWithParcels
             */
            TaskWithParcels.fromObject = function fromObject(object) {
                if (object instanceof $root.tasks.TaskWithParcels) return object
                var message = new $root.tasks.TaskWithParcels()
                if (object.task != null) {
                    if (typeof object.task !== 'object') throw TypeError('.tasks.TaskWithParcels.task: object expected')
                    message.task = $root.tasks.Task.fromObject(object.task)
                }
                if (object.parcels) {
                    if (!Array.isArray(object.parcels))
                        throw TypeError('.tasks.TaskWithParcels.parcels: array expected')
                    message.parcels = []
                    for (var i = 0; i < object.parcels.length; ++i) {
                        if (typeof object.parcels[i] !== 'object')
                            throw TypeError('.tasks.TaskWithParcels.parcels: object expected')
                        message.parcels[i] = $root.parcels.Parcel.fromObject(object.parcels[i])
                    }
                }
                return message
            }

            /**
             * Creates a plain object from a TaskWithParcels message. Also converts values to other types if specified.
             * @function toObject
             * @memberof tasks.TaskWithParcels
             * @static
             * @param {tasks.TaskWithParcels} message TaskWithParcels
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TaskWithParcels.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.arrays || options.defaults) object.parcels = []
                if (options.defaults) object.task = null
                if (message.task != null && message.hasOwnProperty('task'))
                    object.task = $root.tasks.Task.toObject(message.task, options)
                if (message.parcels && message.parcels.length) {
                    object.parcels = []
                    for (var j = 0; j < message.parcels.length; ++j)
                        object.parcels[j] = $root.parcels.Parcel.toObject(message.parcels[j], options)
                }
                return object
            }

            /**
             * Converts this TaskWithParcels to JSON.
             * @function toJSON
             * @memberof tasks.TaskWithParcels
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TaskWithParcels.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for TaskWithParcels
             * @function getTypeUrl
             * @memberof tasks.TaskWithParcels
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TaskWithParcels.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/tasks.TaskWithParcels'
            }

            return TaskWithParcels
        })()

        tasks.TaskWithRel = (function () {
            /**
             * Properties of a TaskWithRel.
             * @memberof tasks
             * @interface ITaskWithRel
             * @property {tasks.Task} task TaskWithRel task
             * @property {Array.<parcels.Parcel>|null} [parcels] TaskWithRel parcels
             * @property {orders.Order} order TaskWithRel order
             * @property {addresses.Address} address TaskWithRel address
             * @property {contacts.Contact} contact TaskWithRel contact
             * @property {Array.<photos.Photo>|null} [photos] TaskWithRel photos
             */

            /**
             * Constructs a new TaskWithRel.
             * @memberof tasks
             * @classdesc Represents a TaskWithRel.
             * @implements ITaskWithRel
             * @constructor
             * @param {tasks.ITaskWithRel=} [properties] Properties to set
             */
            function TaskWithRel(properties) {
                this.parcels = []
                this.photos = []
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * TaskWithRel task.
             * @member {tasks.Task} task
             * @memberof tasks.TaskWithRel
             * @instance
             */
            TaskWithRel.prototype.task = null

            /**
             * TaskWithRel parcels.
             * @member {Array.<parcels.Parcel>} parcels
             * @memberof tasks.TaskWithRel
             * @instance
             */
            TaskWithRel.prototype.parcels = $util.emptyArray

            /**
             * TaskWithRel order.
             * @member {orders.Order} order
             * @memberof tasks.TaskWithRel
             * @instance
             */
            TaskWithRel.prototype.order = null

            /**
             * TaskWithRel address.
             * @member {addresses.Address} address
             * @memberof tasks.TaskWithRel
             * @instance
             */
            TaskWithRel.prototype.address = null

            /**
             * TaskWithRel contact.
             * @member {contacts.Contact} contact
             * @memberof tasks.TaskWithRel
             * @instance
             */
            TaskWithRel.prototype.contact = null

            /**
             * TaskWithRel photos.
             * @member {Array.<photos.Photo>} photos
             * @memberof tasks.TaskWithRel
             * @instance
             */
            TaskWithRel.prototype.photos = $util.emptyArray

            /**
             * Creates a new TaskWithRel instance using the specified properties.
             * @function create
             * @memberof tasks.TaskWithRel
             * @static
             * @param {tasks.ITaskWithRel=} [properties] Properties to set
             * @returns {tasks.TaskWithRel} TaskWithRel instance
             */
            TaskWithRel.create = function create(properties) {
                return new TaskWithRel(properties)
            }

            /**
             * Encodes the specified TaskWithRel message. Does not implicitly {@link tasks.TaskWithRel.verify|verify} messages.
             * @function encode
             * @memberof tasks.TaskWithRel
             * @static
             * @param {tasks.TaskWithRel} message TaskWithRel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TaskWithRel.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                $root.tasks.Task.encode(message.task, writer.uint32(/* id 1, wireType 2 =*/ 10).fork()).ldelim()
                if (message.parcels != null && message.parcels.length)
                    for (var i = 0; i < message.parcels.length; ++i)
                        $root.parcels.Parcel.encode(
                            message.parcels[i],
                            writer.uint32(/* id 2, wireType 2 =*/ 18).fork()
                        ).ldelim()
                $root.orders.Order.encode(message.order, writer.uint32(/* id 3, wireType 2 =*/ 26).fork()).ldelim()
                $root.addresses.Address.encode(
                    message.address,
                    writer.uint32(/* id 4, wireType 2 =*/ 34).fork()
                ).ldelim()
                $root.contacts.Contact.encode(
                    message.contact,
                    writer.uint32(/* id 5, wireType 2 =*/ 42).fork()
                ).ldelim()
                if (message.photos != null && message.photos.length)
                    for (var i = 0; i < message.photos.length; ++i)
                        $root.photos.Photo.encode(
                            message.photos[i],
                            writer.uint32(/* id 6, wireType 2 =*/ 50).fork()
                        ).ldelim()
                return writer
            }

            /**
             * Encodes the specified TaskWithRel message, length delimited. Does not implicitly {@link tasks.TaskWithRel.verify|verify} messages.
             * @function encodeDelimited
             * @memberof tasks.TaskWithRel
             * @static
             * @param {tasks.TaskWithRel} message TaskWithRel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TaskWithRel.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a TaskWithRel message from the specified reader or buffer.
             * @function decode
             * @memberof tasks.TaskWithRel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {tasks.TaskWithRel} TaskWithRel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TaskWithRel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.tasks.TaskWithRel()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.task = $root.tasks.Task.decode(reader, reader.uint32())
                            break
                        }
                        case 2: {
                            if (!(message.parcels && message.parcels.length)) message.parcels = []
                            message.parcels.push($root.parcels.Parcel.decode(reader, reader.uint32()))
                            break
                        }
                        case 3: {
                            message.order = $root.orders.Order.decode(reader, reader.uint32())
                            break
                        }
                        case 4: {
                            message.address = $root.addresses.Address.decode(reader, reader.uint32())
                            break
                        }
                        case 5: {
                            message.contact = $root.contacts.Contact.decode(reader, reader.uint32())
                            break
                        }
                        case 6: {
                            if (!(message.photos && message.photos.length)) message.photos = []
                            message.photos.push($root.photos.Photo.decode(reader, reader.uint32()))
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('task'))
                    throw $util.ProtocolError("missing required 'task'", { instance: message })
                if (!message.hasOwnProperty('order'))
                    throw $util.ProtocolError("missing required 'order'", { instance: message })
                if (!message.hasOwnProperty('address'))
                    throw $util.ProtocolError("missing required 'address'", { instance: message })
                if (!message.hasOwnProperty('contact'))
                    throw $util.ProtocolError("missing required 'contact'", { instance: message })
                return message
            }

            /**
             * Decodes a TaskWithRel message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof tasks.TaskWithRel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {tasks.TaskWithRel} TaskWithRel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TaskWithRel.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a TaskWithRel message.
             * @function verify
             * @memberof tasks.TaskWithRel
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TaskWithRel.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                {
                    var error = $root.tasks.Task.verify(message.task)
                    if (error) return 'task.' + error
                }
                if (message.parcels != null && message.hasOwnProperty('parcels')) {
                    if (!Array.isArray(message.parcels)) return 'parcels: array expected'
                    for (var i = 0; i < message.parcels.length; ++i) {
                        var error = $root.parcels.Parcel.verify(message.parcels[i])
                        if (error) return 'parcels.' + error
                    }
                }
                {
                    var error = $root.orders.Order.verify(message.order)
                    if (error) return 'order.' + error
                }
                {
                    var error = $root.addresses.Address.verify(message.address)
                    if (error) return 'address.' + error
                }
                {
                    var error = $root.contacts.Contact.verify(message.contact)
                    if (error) return 'contact.' + error
                }
                if (message.photos != null && message.hasOwnProperty('photos')) {
                    if (!Array.isArray(message.photos)) return 'photos: array expected'
                    for (var i = 0; i < message.photos.length; ++i) {
                        var error = $root.photos.Photo.verify(message.photos[i])
                        if (error) return 'photos.' + error
                    }
                }
                return null
            }

            /**
             * Creates a TaskWithRel message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof tasks.TaskWithRel
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {tasks.TaskWithRel} TaskWithRel
             */
            TaskWithRel.fromObject = function fromObject(object) {
                if (object instanceof $root.tasks.TaskWithRel) return object
                var message = new $root.tasks.TaskWithRel()
                if (object.task != null) {
                    if (typeof object.task !== 'object') throw TypeError('.tasks.TaskWithRel.task: object expected')
                    message.task = $root.tasks.Task.fromObject(object.task)
                }
                if (object.parcels) {
                    if (!Array.isArray(object.parcels)) throw TypeError('.tasks.TaskWithRel.parcels: array expected')
                    message.parcels = []
                    for (var i = 0; i < object.parcels.length; ++i) {
                        if (typeof object.parcels[i] !== 'object')
                            throw TypeError('.tasks.TaskWithRel.parcels: object expected')
                        message.parcels[i] = $root.parcels.Parcel.fromObject(object.parcels[i])
                    }
                }
                if (object.order != null) {
                    if (typeof object.order !== 'object') throw TypeError('.tasks.TaskWithRel.order: object expected')
                    message.order = $root.orders.Order.fromObject(object.order)
                }
                if (object.address != null) {
                    if (typeof object.address !== 'object')
                        throw TypeError('.tasks.TaskWithRel.address: object expected')
                    message.address = $root.addresses.Address.fromObject(object.address)
                }
                if (object.contact != null) {
                    if (typeof object.contact !== 'object')
                        throw TypeError('.tasks.TaskWithRel.contact: object expected')
                    message.contact = $root.contacts.Contact.fromObject(object.contact)
                }
                if (object.photos) {
                    if (!Array.isArray(object.photos)) throw TypeError('.tasks.TaskWithRel.photos: array expected')
                    message.photos = []
                    for (var i = 0; i < object.photos.length; ++i) {
                        if (typeof object.photos[i] !== 'object')
                            throw TypeError('.tasks.TaskWithRel.photos: object expected')
                        message.photos[i] = $root.photos.Photo.fromObject(object.photos[i])
                    }
                }
                return message
            }

            /**
             * Creates a plain object from a TaskWithRel message. Also converts values to other types if specified.
             * @function toObject
             * @memberof tasks.TaskWithRel
             * @static
             * @param {tasks.TaskWithRel} message TaskWithRel
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TaskWithRel.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.arrays || options.defaults) {
                    object.parcels = []
                    object.photos = []
                }
                if (options.defaults) {
                    object.task = null
                    object.order = null
                    object.address = null
                    object.contact = null
                }
                if (message.task != null && message.hasOwnProperty('task'))
                    object.task = $root.tasks.Task.toObject(message.task, options)
                if (message.parcels && message.parcels.length) {
                    object.parcels = []
                    for (var j = 0; j < message.parcels.length; ++j)
                        object.parcels[j] = $root.parcels.Parcel.toObject(message.parcels[j], options)
                }
                if (message.order != null && message.hasOwnProperty('order'))
                    object.order = $root.orders.Order.toObject(message.order, options)
                if (message.address != null && message.hasOwnProperty('address'))
                    object.address = $root.addresses.Address.toObject(message.address, options)
                if (message.contact != null && message.hasOwnProperty('contact'))
                    object.contact = $root.contacts.Contact.toObject(message.contact, options)
                if (message.photos && message.photos.length) {
                    object.photos = []
                    for (var j = 0; j < message.photos.length; ++j)
                        object.photos[j] = $root.photos.Photo.toObject(message.photos[j], options)
                }
                return object
            }

            /**
             * Converts this TaskWithRel to JSON.
             * @function toJSON
             * @memberof tasks.TaskWithRel
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TaskWithRel.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for TaskWithRel
             * @function getTypeUrl
             * @memberof tasks.TaskWithRel
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TaskWithRel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/tasks.TaskWithRel'
            }

            return TaskWithRel
        })()

        tasks.GetTaskListResponse = (function () {
            /**
             * Properties of a GetTaskListResponse.
             * @memberof tasks
             * @interface IGetTaskListResponse
             * @property {Array.<tasks.Task>|null} [tasks] GetTaskListResponse tasks
             */

            /**
             * Constructs a new GetTaskListResponse.
             * @memberof tasks
             * @classdesc Represents a GetTaskListResponse.
             * @implements IGetTaskListResponse
             * @constructor
             * @param {tasks.IGetTaskListResponse=} [properties] Properties to set
             */
            function GetTaskListResponse(properties) {
                this.tasks = []
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * GetTaskListResponse tasks.
             * @member {Array.<tasks.Task>} tasks
             * @memberof tasks.GetTaskListResponse
             * @instance
             */
            GetTaskListResponse.prototype.tasks = $util.emptyArray

            /**
             * Creates a new GetTaskListResponse instance using the specified properties.
             * @function create
             * @memberof tasks.GetTaskListResponse
             * @static
             * @param {tasks.IGetTaskListResponse=} [properties] Properties to set
             * @returns {tasks.GetTaskListResponse} GetTaskListResponse instance
             */
            GetTaskListResponse.create = function create(properties) {
                return new GetTaskListResponse(properties)
            }

            /**
             * Encodes the specified GetTaskListResponse message. Does not implicitly {@link tasks.GetTaskListResponse.verify|verify} messages.
             * @function encode
             * @memberof tasks.GetTaskListResponse
             * @static
             * @param {tasks.GetTaskListResponse} message GetTaskListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTaskListResponse.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                if (message.tasks != null && message.tasks.length)
                    for (var i = 0; i < message.tasks.length; ++i)
                        $root.tasks.Task.encode(
                            message.tasks[i],
                            writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
                        ).ldelim()
                return writer
            }

            /**
             * Encodes the specified GetTaskListResponse message, length delimited. Does not implicitly {@link tasks.GetTaskListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof tasks.GetTaskListResponse
             * @static
             * @param {tasks.GetTaskListResponse} message GetTaskListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTaskListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a GetTaskListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof tasks.GetTaskListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {tasks.GetTaskListResponse} GetTaskListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTaskListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.tasks.GetTaskListResponse()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            if (!(message.tasks && message.tasks.length)) message.tasks = []
                            message.tasks.push($root.tasks.Task.decode(reader, reader.uint32()))
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                return message
            }

            /**
             * Decodes a GetTaskListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof tasks.GetTaskListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {tasks.GetTaskListResponse} GetTaskListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTaskListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a GetTaskListResponse message.
             * @function verify
             * @memberof tasks.GetTaskListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetTaskListResponse.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (message.tasks != null && message.hasOwnProperty('tasks')) {
                    if (!Array.isArray(message.tasks)) return 'tasks: array expected'
                    for (var i = 0; i < message.tasks.length; ++i) {
                        var error = $root.tasks.Task.verify(message.tasks[i])
                        if (error) return 'tasks.' + error
                    }
                }
                return null
            }

            /**
             * Creates a GetTaskListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof tasks.GetTaskListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {tasks.GetTaskListResponse} GetTaskListResponse
             */
            GetTaskListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.tasks.GetTaskListResponse) return object
                var message = new $root.tasks.GetTaskListResponse()
                if (object.tasks) {
                    if (!Array.isArray(object.tasks))
                        throw TypeError('.tasks.GetTaskListResponse.tasks: array expected')
                    message.tasks = []
                    for (var i = 0; i < object.tasks.length; ++i) {
                        if (typeof object.tasks[i] !== 'object')
                            throw TypeError('.tasks.GetTaskListResponse.tasks: object expected')
                        message.tasks[i] = $root.tasks.Task.fromObject(object.tasks[i])
                    }
                }
                return message
            }

            /**
             * Creates a plain object from a GetTaskListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof tasks.GetTaskListResponse
             * @static
             * @param {tasks.GetTaskListResponse} message GetTaskListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetTaskListResponse.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.arrays || options.defaults) object.tasks = []
                if (message.tasks && message.tasks.length) {
                    object.tasks = []
                    for (var j = 0; j < message.tasks.length; ++j)
                        object.tasks[j] = $root.tasks.Task.toObject(message.tasks[j], options)
                }
                return object
            }

            /**
             * Converts this GetTaskListResponse to JSON.
             * @function toJSON
             * @memberof tasks.GetTaskListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetTaskListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for GetTaskListResponse
             * @function getTypeUrl
             * @memberof tasks.GetTaskListResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetTaskListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/tasks.GetTaskListResponse'
            }

            return GetTaskListResponse
        })()

        tasks.BulkTasksResponse = (function () {
            /**
             * Properties of a BulkTasksResponse.
             * @memberof tasks
             * @interface IBulkTasksResponse
             * @property {Array.<tasks.Task>|null} [tasks] BulkTasksResponse tasks
             */

            /**
             * Constructs a new BulkTasksResponse.
             * @memberof tasks
             * @classdesc Represents a BulkTasksResponse.
             * @implements IBulkTasksResponse
             * @constructor
             * @param {tasks.IBulkTasksResponse=} [properties] Properties to set
             */
            function BulkTasksResponse(properties) {
                this.tasks = []
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * BulkTasksResponse tasks.
             * @member {Array.<tasks.Task>} tasks
             * @memberof tasks.BulkTasksResponse
             * @instance
             */
            BulkTasksResponse.prototype.tasks = $util.emptyArray

            /**
             * Creates a new BulkTasksResponse instance using the specified properties.
             * @function create
             * @memberof tasks.BulkTasksResponse
             * @static
             * @param {tasks.IBulkTasksResponse=} [properties] Properties to set
             * @returns {tasks.BulkTasksResponse} BulkTasksResponse instance
             */
            BulkTasksResponse.create = function create(properties) {
                return new BulkTasksResponse(properties)
            }

            /**
             * Encodes the specified BulkTasksResponse message. Does not implicitly {@link tasks.BulkTasksResponse.verify|verify} messages.
             * @function encode
             * @memberof tasks.BulkTasksResponse
             * @static
             * @param {tasks.BulkTasksResponse} message BulkTasksResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BulkTasksResponse.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                if (message.tasks != null && message.tasks.length)
                    for (var i = 0; i < message.tasks.length; ++i)
                        $root.tasks.Task.encode(
                            message.tasks[i],
                            writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
                        ).ldelim()
                return writer
            }

            /**
             * Encodes the specified BulkTasksResponse message, length delimited. Does not implicitly {@link tasks.BulkTasksResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof tasks.BulkTasksResponse
             * @static
             * @param {tasks.BulkTasksResponse} message BulkTasksResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BulkTasksResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a BulkTasksResponse message from the specified reader or buffer.
             * @function decode
             * @memberof tasks.BulkTasksResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {tasks.BulkTasksResponse} BulkTasksResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BulkTasksResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.tasks.BulkTasksResponse()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            if (!(message.tasks && message.tasks.length)) message.tasks = []
                            message.tasks.push($root.tasks.Task.decode(reader, reader.uint32()))
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                return message
            }

            /**
             * Decodes a BulkTasksResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof tasks.BulkTasksResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {tasks.BulkTasksResponse} BulkTasksResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BulkTasksResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a BulkTasksResponse message.
             * @function verify
             * @memberof tasks.BulkTasksResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BulkTasksResponse.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (message.tasks != null && message.hasOwnProperty('tasks')) {
                    if (!Array.isArray(message.tasks)) return 'tasks: array expected'
                    for (var i = 0; i < message.tasks.length; ++i) {
                        var error = $root.tasks.Task.verify(message.tasks[i])
                        if (error) return 'tasks.' + error
                    }
                }
                return null
            }

            /**
             * Creates a BulkTasksResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof tasks.BulkTasksResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {tasks.BulkTasksResponse} BulkTasksResponse
             */
            BulkTasksResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.tasks.BulkTasksResponse) return object
                var message = new $root.tasks.BulkTasksResponse()
                if (object.tasks) {
                    if (!Array.isArray(object.tasks)) throw TypeError('.tasks.BulkTasksResponse.tasks: array expected')
                    message.tasks = []
                    for (var i = 0; i < object.tasks.length; ++i) {
                        if (typeof object.tasks[i] !== 'object')
                            throw TypeError('.tasks.BulkTasksResponse.tasks: object expected')
                        message.tasks[i] = $root.tasks.Task.fromObject(object.tasks[i])
                    }
                }
                return message
            }

            /**
             * Creates a plain object from a BulkTasksResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof tasks.BulkTasksResponse
             * @static
             * @param {tasks.BulkTasksResponse} message BulkTasksResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BulkTasksResponse.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.arrays || options.defaults) object.tasks = []
                if (message.tasks && message.tasks.length) {
                    object.tasks = []
                    for (var j = 0; j < message.tasks.length; ++j)
                        object.tasks[j] = $root.tasks.Task.toObject(message.tasks[j], options)
                }
                return object
            }

            /**
             * Converts this BulkTasksResponse to JSON.
             * @function toJSON
             * @memberof tasks.BulkTasksResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BulkTasksResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for BulkTasksResponse
             * @function getTypeUrl
             * @memberof tasks.BulkTasksResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BulkTasksResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/tasks.BulkTasksResponse'
            }

            return BulkTasksResponse
        })()

        /**
         * State enum.
         * @name tasks.State
         * @enum {number}
         * @property {number} UNASSIGNED=0 UNASSIGNED value
         * @property {number} ASSIGNED_TO_JOB=1 ASSIGNED_TO_JOB value
         * @property {number} ON_THE_WAY=2 ON_THE_WAY value
         * @property {number} ON_POINT=3 ON_POINT value
         * @property {number} COMPLETED=4 COMPLETED value
         * @property {number} FAILED=5 FAILED value
         * @property {number} CANCELED=6 CANCELED value
         */
        tasks.State = (function () {
            var valuesById = {},
                values = Object.create(valuesById)
            values[(valuesById[0] = 'UNASSIGNED')] = 0
            values[(valuesById[1] = 'ASSIGNED_TO_JOB')] = 1
            values[(valuesById[2] = 'ON_THE_WAY')] = 2
            values[(valuesById[3] = 'ON_POINT')] = 3
            values[(valuesById[4] = 'COMPLETED')] = 4
            values[(valuesById[5] = 'FAILED')] = 5
            values[(valuesById[6] = 'CANCELED')] = 6
            return values
        })()

        /**
         * Kind enum.
         * @name tasks.Kind
         * @enum {number}
         * @property {number} PICK_UP=0 PICK_UP value
         * @property {number} DROP_OFF=1 DROP_OFF value
         * @property {number} RETURN=2 RETURN value
         */
        tasks.Kind = (function () {
            var valuesById = {},
                values = Object.create(valuesById)
            values[(valuesById[0] = 'PICK_UP')] = 0
            values[(valuesById[1] = 'DROP_OFF')] = 1
            values[(valuesById[2] = 'RETURN')] = 2
            return values
        })()

        tasks.Task = (function () {
            /**
             * Properties of a Task.
             * @memberof tasks
             * @interface ITask
             * @property {string} id Task id
             * @property {string|null} [jobId] Task jobId
             * @property {string|null} [warehouseId] Task warehouseId
             * @property {string|null} [teamId] Task teamId
             * @property {string} orderId Task orderId
             * @property {string} addressId Task addressId
             * @property {string} contactId Task contactId
             * @property {tasks.State} state Task state
             * @property {tasks.Kind} kind Task kind
             * @property {number} startBy Task startBy
             * @property {number} completeByMin Task completeByMin
             * @property {number} completeByMax Task completeByMax
             * @property {number|null} [startedAt] Task startedAt
             * @property {number|null} [completedAt] Task completedAt
             * @property {number} createdAt Task createdAt
             * @property {number|null} [updatedAt] Task updatedAt
             * @property {number|null} [deletedAt] Task deletedAt
             * @property {string|null} [commentary] Task commentary
             */

            /**
             * Constructs a new Task.
             * @memberof tasks
             * @classdesc Represents a Task.
             * @implements ITask
             * @constructor
             * @param {tasks.ITask=} [properties] Properties to set
             */
            function Task(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * Task id.
             * @member {string} id
             * @memberof tasks.Task
             * @instance
             */
            Task.prototype.id = ''

            /**
             * Task jobId.
             * @member {string} jobId
             * @memberof tasks.Task
             * @instance
             */
            Task.prototype.jobId = ''

            /**
             * Task warehouseId.
             * @member {string} warehouseId
             * @memberof tasks.Task
             * @instance
             */
            Task.prototype.warehouseId = ''

            /**
             * Task teamId.
             * @member {string} teamId
             * @memberof tasks.Task
             * @instance
             */
            Task.prototype.teamId = ''

            /**
             * Task orderId.
             * @member {string} orderId
             * @memberof tasks.Task
             * @instance
             */
            Task.prototype.orderId = ''

            /**
             * Task addressId.
             * @member {string} addressId
             * @memberof tasks.Task
             * @instance
             */
            Task.prototype.addressId = ''

            /**
             * Task contactId.
             * @member {string} contactId
             * @memberof tasks.Task
             * @instance
             */
            Task.prototype.contactId = ''

            /**
             * Task state.
             * @member {tasks.State} state
             * @memberof tasks.Task
             * @instance
             */
            Task.prototype.state = 0

            /**
             * Task kind.
             * @member {tasks.Kind} kind
             * @memberof tasks.Task
             * @instance
             */
            Task.prototype.kind = 0

            /**
             * Task startBy.
             * @member {number} startBy
             * @memberof tasks.Task
             * @instance
             */
            Task.prototype.startBy = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Task completeByMin.
             * @member {number} completeByMin
             * @memberof tasks.Task
             * @instance
             */
            Task.prototype.completeByMin = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Task completeByMax.
             * @member {number} completeByMax
             * @memberof tasks.Task
             * @instance
             */
            Task.prototype.completeByMax = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Task startedAt.
             * @member {number} startedAt
             * @memberof tasks.Task
             * @instance
             */
            Task.prototype.startedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Task completedAt.
             * @member {number} completedAt
             * @memberof tasks.Task
             * @instance
             */
            Task.prototype.completedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Task createdAt.
             * @member {number} createdAt
             * @memberof tasks.Task
             * @instance
             */
            Task.prototype.createdAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Task updatedAt.
             * @member {number} updatedAt
             * @memberof tasks.Task
             * @instance
             */
            Task.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Task deletedAt.
             * @member {number} deletedAt
             * @memberof tasks.Task
             * @instance
             */
            Task.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Task commentary.
             * @member {string} commentary
             * @memberof tasks.Task
             * @instance
             */
            Task.prototype.commentary = ''

            /**
             * Creates a new Task instance using the specified properties.
             * @function create
             * @memberof tasks.Task
             * @static
             * @param {tasks.ITask=} [properties] Properties to set
             * @returns {tasks.Task} Task instance
             */
            Task.create = function create(properties) {
                return new Task(properties)
            }

            /**
             * Encodes the specified Task message. Does not implicitly {@link tasks.Task.verify|verify} messages.
             * @function encode
             * @memberof tasks.Task
             * @static
             * @param {tasks.Task} message Task message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Task.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
                if (message.jobId != null && Object.hasOwnProperty.call(message, 'jobId'))
                    writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.jobId)
                if (message.warehouseId != null && Object.hasOwnProperty.call(message, 'warehouseId'))
                    writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.warehouseId)
                if (message.teamId != null && Object.hasOwnProperty.call(message, 'teamId'))
                    writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.teamId)
                writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.orderId)
                writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.addressId)
                writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.contactId)
                writer.uint32(/* id 8, wireType 0 =*/ 64).int32(message.state)
                writer.uint32(/* id 9, wireType 0 =*/ 72).int32(message.kind)
                writer.uint32(/* id 10, wireType 0 =*/ 80).int64(message.startBy)
                writer.uint32(/* id 11, wireType 0 =*/ 88).int64(message.completeByMin)
                writer.uint32(/* id 12, wireType 0 =*/ 96).int64(message.completeByMax)
                if (message.startedAt != null && Object.hasOwnProperty.call(message, 'startedAt'))
                    writer.uint32(/* id 13, wireType 0 =*/ 104).int64(message.startedAt)
                if (message.completedAt != null && Object.hasOwnProperty.call(message, 'completedAt'))
                    writer.uint32(/* id 14, wireType 0 =*/ 112).int64(message.completedAt)
                writer.uint32(/* id 15, wireType 0 =*/ 120).int64(message.createdAt)
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, 'updatedAt'))
                    writer.uint32(/* id 16, wireType 0 =*/ 128).int64(message.updatedAt)
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, 'deletedAt'))
                    writer.uint32(/* id 17, wireType 0 =*/ 136).int64(message.deletedAt)
                if (message.commentary != null && Object.hasOwnProperty.call(message, 'commentary'))
                    writer.uint32(/* id 18, wireType 2 =*/ 146).string(message.commentary)
                return writer
            }

            /**
             * Encodes the specified Task message, length delimited. Does not implicitly {@link tasks.Task.verify|verify} messages.
             * @function encodeDelimited
             * @memberof tasks.Task
             * @static
             * @param {tasks.Task} message Task message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Task.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a Task message from the specified reader or buffer.
             * @function decode
             * @memberof tasks.Task
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {tasks.Task} Task
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Task.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.tasks.Task()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.id = reader.string()
                            break
                        }
                        case 2: {
                            message.jobId = reader.string()
                            break
                        }
                        case 3: {
                            message.warehouseId = reader.string()
                            break
                        }
                        case 4: {
                            message.teamId = reader.string()
                            break
                        }
                        case 5: {
                            message.orderId = reader.string()
                            break
                        }
                        case 6: {
                            message.addressId = reader.string()
                            break
                        }
                        case 7: {
                            message.contactId = reader.string()
                            break
                        }
                        case 8: {
                            message.state = reader.int32()
                            break
                        }
                        case 9: {
                            message.kind = reader.int32()
                            break
                        }
                        case 10: {
                            message.startBy = reader.int64()
                            break
                        }
                        case 11: {
                            message.completeByMin = reader.int64()
                            break
                        }
                        case 12: {
                            message.completeByMax = reader.int64()
                            break
                        }
                        case 13: {
                            message.startedAt = reader.int64()
                            break
                        }
                        case 14: {
                            message.completedAt = reader.int64()
                            break
                        }
                        case 15: {
                            message.createdAt = reader.int64()
                            break
                        }
                        case 16: {
                            message.updatedAt = reader.int64()
                            break
                        }
                        case 17: {
                            message.deletedAt = reader.int64()
                            break
                        }
                        case 18: {
                            message.commentary = reader.string()
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('id'))
                    throw $util.ProtocolError("missing required 'id'", { instance: message })
                if (!message.hasOwnProperty('orderId'))
                    throw $util.ProtocolError("missing required 'orderId'", { instance: message })
                if (!message.hasOwnProperty('addressId'))
                    throw $util.ProtocolError("missing required 'addressId'", { instance: message })
                if (!message.hasOwnProperty('contactId'))
                    throw $util.ProtocolError("missing required 'contactId'", { instance: message })
                if (!message.hasOwnProperty('state'))
                    throw $util.ProtocolError("missing required 'state'", { instance: message })
                if (!message.hasOwnProperty('kind'))
                    throw $util.ProtocolError("missing required 'kind'", { instance: message })
                if (!message.hasOwnProperty('startBy'))
                    throw $util.ProtocolError("missing required 'startBy'", { instance: message })
                if (!message.hasOwnProperty('completeByMin'))
                    throw $util.ProtocolError("missing required 'completeByMin'", { instance: message })
                if (!message.hasOwnProperty('completeByMax'))
                    throw $util.ProtocolError("missing required 'completeByMax'", { instance: message })
                if (!message.hasOwnProperty('createdAt'))
                    throw $util.ProtocolError("missing required 'createdAt'", { instance: message })
                return message
            }

            /**
             * Decodes a Task message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof tasks.Task
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {tasks.Task} Task
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Task.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a Task message.
             * @function verify
             * @memberof tasks.Task
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Task.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (!$util.isString(message.id)) return 'id: string expected'
                if (message.jobId != null && message.hasOwnProperty('jobId'))
                    if (!$util.isString(message.jobId)) return 'jobId: string expected'
                if (message.warehouseId != null && message.hasOwnProperty('warehouseId'))
                    if (!$util.isString(message.warehouseId)) return 'warehouseId: string expected'
                if (message.teamId != null && message.hasOwnProperty('teamId'))
                    if (!$util.isString(message.teamId)) return 'teamId: string expected'
                if (!$util.isString(message.orderId)) return 'orderId: string expected'
                if (!$util.isString(message.addressId)) return 'addressId: string expected'
                if (!$util.isString(message.contactId)) return 'contactId: string expected'
                switch (message.state) {
                    default:
                        return 'state: enum value expected'
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break
                }
                switch (message.kind) {
                    default:
                        return 'kind: enum value expected'
                    case 0:
                    case 1:
                    case 2:
                        break
                }
                if (
                    !$util.isInteger(message.startBy) &&
                    !(message.startBy && $util.isInteger(message.startBy.low) && $util.isInteger(message.startBy.high))
                )
                    return 'startBy: integer|Long expected'
                if (
                    !$util.isInteger(message.completeByMin) &&
                    !(
                        message.completeByMin &&
                        $util.isInteger(message.completeByMin.low) &&
                        $util.isInteger(message.completeByMin.high)
                    )
                )
                    return 'completeByMin: integer|Long expected'
                if (
                    !$util.isInteger(message.completeByMax) &&
                    !(
                        message.completeByMax &&
                        $util.isInteger(message.completeByMax.low) &&
                        $util.isInteger(message.completeByMax.high)
                    )
                )
                    return 'completeByMax: integer|Long expected'
                if (message.startedAt != null && message.hasOwnProperty('startedAt'))
                    if (
                        !$util.isInteger(message.startedAt) &&
                        !(
                            message.startedAt &&
                            $util.isInteger(message.startedAt.low) &&
                            $util.isInteger(message.startedAt.high)
                        )
                    )
                        return 'startedAt: integer|Long expected'
                if (message.completedAt != null && message.hasOwnProperty('completedAt'))
                    if (
                        !$util.isInteger(message.completedAt) &&
                        !(
                            message.completedAt &&
                            $util.isInteger(message.completedAt.low) &&
                            $util.isInteger(message.completedAt.high)
                        )
                    )
                        return 'completedAt: integer|Long expected'
                if (
                    !$util.isInteger(message.createdAt) &&
                    !(
                        message.createdAt &&
                        $util.isInteger(message.createdAt.low) &&
                        $util.isInteger(message.createdAt.high)
                    )
                )
                    return 'createdAt: integer|Long expected'
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (
                        !$util.isInteger(message.updatedAt) &&
                        !(
                            message.updatedAt &&
                            $util.isInteger(message.updatedAt.low) &&
                            $util.isInteger(message.updatedAt.high)
                        )
                    )
                        return 'updatedAt: integer|Long expected'
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (
                        !$util.isInteger(message.deletedAt) &&
                        !(
                            message.deletedAt &&
                            $util.isInteger(message.deletedAt.low) &&
                            $util.isInteger(message.deletedAt.high)
                        )
                    )
                        return 'deletedAt: integer|Long expected'
                if (message.commentary != null && message.hasOwnProperty('commentary'))
                    if (!$util.isString(message.commentary)) return 'commentary: string expected'
                return null
            }

            /**
             * Creates a Task message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof tasks.Task
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {tasks.Task} Task
             */
            Task.fromObject = function fromObject(object) {
                if (object instanceof $root.tasks.Task) return object
                var message = new $root.tasks.Task()
                if (object.id != null) message.id = String(object.id)
                if (object.jobId != null) message.jobId = String(object.jobId)
                if (object.warehouseId != null) message.warehouseId = String(object.warehouseId)
                if (object.teamId != null) message.teamId = String(object.teamId)
                if (object.orderId != null) message.orderId = String(object.orderId)
                if (object.addressId != null) message.addressId = String(object.addressId)
                if (object.contactId != null) message.contactId = String(object.contactId)
                switch (object.state) {
                    case 'UNASSIGNED':
                    case 0:
                        message.state = 0
                        break
                    case 'ASSIGNED_TO_JOB':
                    case 1:
                        message.state = 1
                        break
                    case 'ON_THE_WAY':
                    case 2:
                        message.state = 2
                        break
                    case 'ON_POINT':
                    case 3:
                        message.state = 3
                        break
                    case 'COMPLETED':
                    case 4:
                        message.state = 4
                        break
                    case 'FAILED':
                    case 5:
                        message.state = 5
                        break
                    case 'CANCELED':
                    case 6:
                        message.state = 6
                        break
                }
                switch (object.kind) {
                    case 'PICK_UP':
                    case 0:
                        message.kind = 0
                        break
                    case 'DROP_OFF':
                    case 1:
                        message.kind = 1
                        break
                    case 'RETURN':
                    case 2:
                        message.kind = 2
                        break
                }
                if (object.startBy != null)
                    if ($util.Long) (message.startBy = $util.Long.fromValue(object.startBy)).unsigned = false
                    else if (typeof object.startBy === 'string') message.startBy = parseInt(object.startBy, 10)
                    else if (typeof object.startBy === 'number') message.startBy = object.startBy
                    else if (typeof object.startBy === 'object')
                        message.startBy = new $util.LongBits(
                            object.startBy.low >>> 0,
                            object.startBy.high >>> 0
                        ).toNumber()
                if (object.completeByMin != null)
                    if ($util.Long)
                        (message.completeByMin = $util.Long.fromValue(object.completeByMin)).unsigned = false
                    else if (typeof object.completeByMin === 'string')
                        message.completeByMin = parseInt(object.completeByMin, 10)
                    else if (typeof object.completeByMin === 'number') message.completeByMin = object.completeByMin
                    else if (typeof object.completeByMin === 'object')
                        message.completeByMin = new $util.LongBits(
                            object.completeByMin.low >>> 0,
                            object.completeByMin.high >>> 0
                        ).toNumber()
                if (object.completeByMax != null)
                    if ($util.Long)
                        (message.completeByMax = $util.Long.fromValue(object.completeByMax)).unsigned = false
                    else if (typeof object.completeByMax === 'string')
                        message.completeByMax = parseInt(object.completeByMax, 10)
                    else if (typeof object.completeByMax === 'number') message.completeByMax = object.completeByMax
                    else if (typeof object.completeByMax === 'object')
                        message.completeByMax = new $util.LongBits(
                            object.completeByMax.low >>> 0,
                            object.completeByMax.high >>> 0
                        ).toNumber()
                if (object.startedAt != null)
                    if ($util.Long) (message.startedAt = $util.Long.fromValue(object.startedAt)).unsigned = false
                    else if (typeof object.startedAt === 'string') message.startedAt = parseInt(object.startedAt, 10)
                    else if (typeof object.startedAt === 'number') message.startedAt = object.startedAt
                    else if (typeof object.startedAt === 'object')
                        message.startedAt = new $util.LongBits(
                            object.startedAt.low >>> 0,
                            object.startedAt.high >>> 0
                        ).toNumber()
                if (object.completedAt != null)
                    if ($util.Long) (message.completedAt = $util.Long.fromValue(object.completedAt)).unsigned = false
                    else if (typeof object.completedAt === 'string')
                        message.completedAt = parseInt(object.completedAt, 10)
                    else if (typeof object.completedAt === 'number') message.completedAt = object.completedAt
                    else if (typeof object.completedAt === 'object')
                        message.completedAt = new $util.LongBits(
                            object.completedAt.low >>> 0,
                            object.completedAt.high >>> 0
                        ).toNumber()
                if (object.createdAt != null)
                    if ($util.Long) (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false
                    else if (typeof object.createdAt === 'string') message.createdAt = parseInt(object.createdAt, 10)
                    else if (typeof object.createdAt === 'number') message.createdAt = object.createdAt
                    else if (typeof object.createdAt === 'object')
                        message.createdAt = new $util.LongBits(
                            object.createdAt.low >>> 0,
                            object.createdAt.high >>> 0
                        ).toNumber()
                if (object.updatedAt != null)
                    if ($util.Long) (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false
                    else if (typeof object.updatedAt === 'string') message.updatedAt = parseInt(object.updatedAt, 10)
                    else if (typeof object.updatedAt === 'number') message.updatedAt = object.updatedAt
                    else if (typeof object.updatedAt === 'object')
                        message.updatedAt = new $util.LongBits(
                            object.updatedAt.low >>> 0,
                            object.updatedAt.high >>> 0
                        ).toNumber()
                if (object.deletedAt != null)
                    if ($util.Long) (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false
                    else if (typeof object.deletedAt === 'string') message.deletedAt = parseInt(object.deletedAt, 10)
                    else if (typeof object.deletedAt === 'number') message.deletedAt = object.deletedAt
                    else if (typeof object.deletedAt === 'object')
                        message.deletedAt = new $util.LongBits(
                            object.deletedAt.low >>> 0,
                            object.deletedAt.high >>> 0
                        ).toNumber()
                if (object.commentary != null) message.commentary = String(object.commentary)
                return message
            }

            /**
             * Creates a plain object from a Task message. Also converts values to other types if specified.
             * @function toObject
             * @memberof tasks.Task
             * @static
             * @param {tasks.Task} message Task
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Task.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.defaults) {
                    object.id = ''
                    object.jobId = ''
                    object.warehouseId = ''
                    object.teamId = ''
                    object.orderId = ''
                    object.addressId = ''
                    object.contactId = ''
                    object.state = options.enums === String ? 'UNASSIGNED' : 0
                    object.kind = options.enums === String ? 'PICK_UP' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.startBy =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.startBy = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.completeByMin =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.completeByMin = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.completeByMax =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.completeByMax = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.startedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.startedAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.completedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.completedAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.createdAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.createdAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.updatedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.updatedAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.deletedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.deletedAt = options.longs === String ? '0' : 0
                    object.commentary = ''
                }
                if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
                if (message.jobId != null && message.hasOwnProperty('jobId')) object.jobId = message.jobId
                if (message.warehouseId != null && message.hasOwnProperty('warehouseId'))
                    object.warehouseId = message.warehouseId
                if (message.teamId != null && message.hasOwnProperty('teamId')) object.teamId = message.teamId
                if (message.orderId != null && message.hasOwnProperty('orderId')) object.orderId = message.orderId
                if (message.addressId != null && message.hasOwnProperty('addressId'))
                    object.addressId = message.addressId
                if (message.contactId != null && message.hasOwnProperty('contactId'))
                    object.contactId = message.contactId
                if (message.state != null && message.hasOwnProperty('state'))
                    object.state = options.enums === String ? $root.tasks.State[message.state] : message.state
                if (message.kind != null && message.hasOwnProperty('kind'))
                    object.kind = options.enums === String ? $root.tasks.Kind[message.kind] : message.kind
                if (message.startBy != null && message.hasOwnProperty('startBy'))
                    if (typeof message.startBy === 'number')
                        object.startBy = options.longs === String ? String(message.startBy) : message.startBy
                    else
                        object.startBy =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.startBy)
                                : options.longs === Number
                                ? new $util.LongBits(message.startBy.low >>> 0, message.startBy.high >>> 0).toNumber()
                                : message.startBy
                if (message.completeByMin != null && message.hasOwnProperty('completeByMin'))
                    if (typeof message.completeByMin === 'number')
                        object.completeByMin =
                            options.longs === String ? String(message.completeByMin) : message.completeByMin
                    else
                        object.completeByMin =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.completeByMin)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.completeByMin.low >>> 0,
                                      message.completeByMin.high >>> 0
                                  ).toNumber()
                                : message.completeByMin
                if (message.completeByMax != null && message.hasOwnProperty('completeByMax'))
                    if (typeof message.completeByMax === 'number')
                        object.completeByMax =
                            options.longs === String ? String(message.completeByMax) : message.completeByMax
                    else
                        object.completeByMax =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.completeByMax)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.completeByMax.low >>> 0,
                                      message.completeByMax.high >>> 0
                                  ).toNumber()
                                : message.completeByMax
                if (message.startedAt != null && message.hasOwnProperty('startedAt'))
                    if (typeof message.startedAt === 'number')
                        object.startedAt = options.longs === String ? String(message.startedAt) : message.startedAt
                    else
                        object.startedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.startedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.startedAt.low >>> 0,
                                      message.startedAt.high >>> 0
                                  ).toNumber()
                                : message.startedAt
                if (message.completedAt != null && message.hasOwnProperty('completedAt'))
                    if (typeof message.completedAt === 'number')
                        object.completedAt =
                            options.longs === String ? String(message.completedAt) : message.completedAt
                    else
                        object.completedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.completedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.completedAt.low >>> 0,
                                      message.completedAt.high >>> 0
                                  ).toNumber()
                                : message.completedAt
                if (message.createdAt != null && message.hasOwnProperty('createdAt'))
                    if (typeof message.createdAt === 'number')
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt
                    else
                        object.createdAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.createdAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.createdAt.low >>> 0,
                                      message.createdAt.high >>> 0
                                  ).toNumber()
                                : message.createdAt
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (typeof message.updatedAt === 'number')
                        object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt
                    else
                        object.updatedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.updatedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.updatedAt.low >>> 0,
                                      message.updatedAt.high >>> 0
                                  ).toNumber()
                                : message.updatedAt
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (typeof message.deletedAt === 'number')
                        object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt
                    else
                        object.deletedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.deletedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.deletedAt.low >>> 0,
                                      message.deletedAt.high >>> 0
                                  ).toNumber()
                                : message.deletedAt
                if (message.commentary != null && message.hasOwnProperty('commentary'))
                    object.commentary = message.commentary
                return object
            }

            /**
             * Converts this Task to JSON.
             * @function toJSON
             * @memberof tasks.Task
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Task.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for Task
             * @function getTypeUrl
             * @memberof tasks.Task
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Task.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/tasks.Task'
            }

            return Task
        })()

        return tasks
    })()

    $root.parcels = (function () {
        /**
         * Namespace parcels.
         * @exports parcels
         * @namespace
         */
        var parcels = {}

        /**
         * Status enum.
         * @name parcels.Status
         * @enum {number}
         * @property {number} ABSTRACT=0 ABSTRACT value
         * @property {number} WAITING_FOR_PICKUP=1 WAITING_FOR_PICKUP value
         * @property {number} PICKED=2 PICKED value
         * @property {number} DELIVERED=3 DELIVERED value
         * @property {number} REJECTED=4 REJECTED value
         * @property {number} RETURNED=5 RETURNED value
         * @property {number} MISSING=6 MISSING value
         * @property {number} NOT_RETURNED=7 NOT_RETURNED value
         */
        parcels.Status = (function () {
            var valuesById = {},
                values = Object.create(valuesById)
            values[(valuesById[0] = 'ABSTRACT')] = 0
            values[(valuesById[1] = 'WAITING_FOR_PICKUP')] = 1
            values[(valuesById[2] = 'PICKED')] = 2
            values[(valuesById[3] = 'DELIVERED')] = 3
            values[(valuesById[4] = 'REJECTED')] = 4
            values[(valuesById[5] = 'RETURNED')] = 5
            values[(valuesById[6] = 'MISSING')] = 6
            values[(valuesById[7] = 'NOT_RETURNED')] = 7
            return values
        })()

        /**
         * ContentKind enum.
         * @name parcels.ContentKind
         * @enum {number}
         * @property {number} GROCERY=0 GROCERY value
         * @property {number} ALCOHOL=1 ALCOHOL value
         * @property {number} TOBACCO=2 TOBACCO value
         * @property {number} PHARMA=3 PHARMA value
         * @property {number} CHEMICAL=4 CHEMICAL value
         * @property {number} FROZEN=5 FROZEN value
         * @property {number} OWN_KITCHEN=6 OWN_KITCHEN value
         */
        parcels.ContentKind = (function () {
            var valuesById = {},
                values = Object.create(valuesById)
            values[(valuesById[0] = 'GROCERY')] = 0
            values[(valuesById[1] = 'ALCOHOL')] = 1
            values[(valuesById[2] = 'TOBACCO')] = 2
            values[(valuesById[3] = 'PHARMA')] = 3
            values[(valuesById[4] = 'CHEMICAL')] = 4
            values[(valuesById[5] = 'FROZEN')] = 5
            values[(valuesById[6] = 'OWN_KITCHEN')] = 6
            return values
        })()

        parcels.Parcel = (function () {
            /**
             * Properties of a Parcel.
             * @memberof parcels
             * @interface IParcel
             * @property {string} id Parcel id
             * @property {string} orderId Parcel orderId
             * @property {parcels.Status} status Parcel status
             * @property {parcels.ContentKind} contentKind Parcel contentKind
             * @property {string} barcode Parcel barcode
             * @property {string|null} [externalId] Parcel externalId
             * @property {number} ageRestriction Parcel ageRestriction
             * @property {boolean} ageConfirmed Parcel ageConfirmed
             * @property {number} weight Parcel weight
             * @property {number|null} [height] Parcel height
             * @property {number|null} [width] Parcel width
             * @property {number|null} [length] Parcel length
             * @property {string|null} [type] Parcel type
             * @property {number} createdAt Parcel createdAt
             * @property {number|null} [updatedAt] Parcel updatedAt
             * @property {number|null} [deletedAt] Parcel deletedAt
             * @property {string|null} [image] Parcel image
             * @property {string|null} [name] Parcel name
             * @property {string|null} [itemId] Parcel itemId
             */

            /**
             * Constructs a new Parcel.
             * @memberof parcels
             * @classdesc Represents a Parcel.
             * @implements IParcel
             * @constructor
             * @param {parcels.IParcel=} [properties] Properties to set
             */
            function Parcel(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * Parcel id.
             * @member {string} id
             * @memberof parcels.Parcel
             * @instance
             */
            Parcel.prototype.id = ''

            /**
             * Parcel orderId.
             * @member {string} orderId
             * @memberof parcels.Parcel
             * @instance
             */
            Parcel.prototype.orderId = ''

            /**
             * Parcel status.
             * @member {parcels.Status} status
             * @memberof parcels.Parcel
             * @instance
             */
            Parcel.prototype.status = 0

            /**
             * Parcel contentKind.
             * @member {parcels.ContentKind} contentKind
             * @memberof parcels.Parcel
             * @instance
             */
            Parcel.prototype.contentKind = 0

            /**
             * Parcel barcode.
             * @member {string} barcode
             * @memberof parcels.Parcel
             * @instance
             */
            Parcel.prototype.barcode = ''

            /**
             * Parcel externalId.
             * @member {string} externalId
             * @memberof parcels.Parcel
             * @instance
             */
            Parcel.prototype.externalId = ''

            /**
             * Parcel ageRestriction.
             * @member {number} ageRestriction
             * @memberof parcels.Parcel
             * @instance
             */
            Parcel.prototype.ageRestriction = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Parcel ageConfirmed.
             * @member {boolean} ageConfirmed
             * @memberof parcels.Parcel
             * @instance
             */
            Parcel.prototype.ageConfirmed = false

            /**
             * Parcel weight.
             * @member {number} weight
             * @memberof parcels.Parcel
             * @instance
             */
            Parcel.prototype.weight = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Parcel height.
             * @member {number} height
             * @memberof parcels.Parcel
             * @instance
             */
            Parcel.prototype.height = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Parcel width.
             * @member {number} width
             * @memberof parcels.Parcel
             * @instance
             */
            Parcel.prototype.width = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Parcel length.
             * @member {number} length
             * @memberof parcels.Parcel
             * @instance
             */
            Parcel.prototype.length = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Parcel type.
             * @member {string} type
             * @memberof parcels.Parcel
             * @instance
             */
            Parcel.prototype.type = ''

            /**
             * Parcel createdAt.
             * @member {number} createdAt
             * @memberof parcels.Parcel
             * @instance
             */
            Parcel.prototype.createdAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Parcel updatedAt.
             * @member {number} updatedAt
             * @memberof parcels.Parcel
             * @instance
             */
            Parcel.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Parcel deletedAt.
             * @member {number} deletedAt
             * @memberof parcels.Parcel
             * @instance
             */
            Parcel.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Parcel image.
             * @member {string} image
             * @memberof parcels.Parcel
             * @instance
             */
            Parcel.prototype.image = ''

            /**
             * Parcel name.
             * @member {string} name
             * @memberof parcels.Parcel
             * @instance
             */
            Parcel.prototype.name = ''

            /**
             * Parcel itemId.
             * @member {string} itemId
             * @memberof parcels.Parcel
             * @instance
             */
            Parcel.prototype.itemId = ''

            /**
             * Creates a new Parcel instance using the specified properties.
             * @function create
             * @memberof parcels.Parcel
             * @static
             * @param {parcels.IParcel=} [properties] Properties to set
             * @returns {parcels.Parcel} Parcel instance
             */
            Parcel.create = function create(properties) {
                return new Parcel(properties)
            }

            /**
             * Encodes the specified Parcel message. Does not implicitly {@link parcels.Parcel.verify|verify} messages.
             * @function encode
             * @memberof parcels.Parcel
             * @static
             * @param {parcels.Parcel} message Parcel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Parcel.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
                writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.orderId)
                writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.status)
                writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.contentKind)
                writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.barcode)
                if (message.externalId != null && Object.hasOwnProperty.call(message, 'externalId'))
                    writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.externalId)
                writer.uint32(/* id 7, wireType 0 =*/ 56).int64(message.ageRestriction)
                writer.uint32(/* id 8, wireType 0 =*/ 64).bool(message.ageConfirmed)
                writer.uint32(/* id 9, wireType 0 =*/ 72).int64(message.weight)
                if (message.height != null && Object.hasOwnProperty.call(message, 'height'))
                    writer.uint32(/* id 10, wireType 0 =*/ 80).int64(message.height)
                if (message.width != null && Object.hasOwnProperty.call(message, 'width'))
                    writer.uint32(/* id 11, wireType 0 =*/ 88).int64(message.width)
                if (message.length != null && Object.hasOwnProperty.call(message, 'length'))
                    writer.uint32(/* id 12, wireType 0 =*/ 96).int64(message.length)
                if (message.type != null && Object.hasOwnProperty.call(message, 'type'))
                    writer.uint32(/* id 13, wireType 2 =*/ 106).string(message.type)
                writer.uint32(/* id 14, wireType 0 =*/ 112).int64(message.createdAt)
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, 'updatedAt'))
                    writer.uint32(/* id 15, wireType 0 =*/ 120).int64(message.updatedAt)
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, 'deletedAt'))
                    writer.uint32(/* id 16, wireType 0 =*/ 128).int64(message.deletedAt)
                if (message.image != null && Object.hasOwnProperty.call(message, 'image'))
                    writer.uint32(/* id 17, wireType 2 =*/ 138).string(message.image)
                if (message.name != null && Object.hasOwnProperty.call(message, 'name'))
                    writer.uint32(/* id 18, wireType 2 =*/ 146).string(message.name)
                if (message.itemId != null && Object.hasOwnProperty.call(message, 'itemId'))
                    writer.uint32(/* id 19, wireType 2 =*/ 154).string(message.itemId)
                return writer
            }

            /**
             * Encodes the specified Parcel message, length delimited. Does not implicitly {@link parcels.Parcel.verify|verify} messages.
             * @function encodeDelimited
             * @memberof parcels.Parcel
             * @static
             * @param {parcels.Parcel} message Parcel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Parcel.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a Parcel message from the specified reader or buffer.
             * @function decode
             * @memberof parcels.Parcel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {parcels.Parcel} Parcel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Parcel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.parcels.Parcel()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.id = reader.string()
                            break
                        }
                        case 2: {
                            message.orderId = reader.string()
                            break
                        }
                        case 3: {
                            message.status = reader.int32()
                            break
                        }
                        case 4: {
                            message.contentKind = reader.int32()
                            break
                        }
                        case 5: {
                            message.barcode = reader.string()
                            break
                        }
                        case 6: {
                            message.externalId = reader.string()
                            break
                        }
                        case 7: {
                            message.ageRestriction = reader.int64()
                            break
                        }
                        case 8: {
                            message.ageConfirmed = reader.bool()
                            break
                        }
                        case 9: {
                            message.weight = reader.int64()
                            break
                        }
                        case 10: {
                            message.height = reader.int64()
                            break
                        }
                        case 11: {
                            message.width = reader.int64()
                            break
                        }
                        case 12: {
                            message.length = reader.int64()
                            break
                        }
                        case 13: {
                            message.type = reader.string()
                            break
                        }
                        case 14: {
                            message.createdAt = reader.int64()
                            break
                        }
                        case 15: {
                            message.updatedAt = reader.int64()
                            break
                        }
                        case 16: {
                            message.deletedAt = reader.int64()
                            break
                        }
                        case 17: {
                            message.image = reader.string()
                            break
                        }
                        case 18: {
                            message.name = reader.string()
                            break
                        }
                        case 19: {
                            message.itemId = reader.string()
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('id'))
                    throw $util.ProtocolError("missing required 'id'", { instance: message })
                if (!message.hasOwnProperty('orderId'))
                    throw $util.ProtocolError("missing required 'orderId'", { instance: message })
                if (!message.hasOwnProperty('status'))
                    throw $util.ProtocolError("missing required 'status'", { instance: message })
                if (!message.hasOwnProperty('contentKind'))
                    throw $util.ProtocolError("missing required 'contentKind'", { instance: message })
                if (!message.hasOwnProperty('barcode'))
                    throw $util.ProtocolError("missing required 'barcode'", { instance: message })
                if (!message.hasOwnProperty('ageRestriction'))
                    throw $util.ProtocolError("missing required 'ageRestriction'", { instance: message })
                if (!message.hasOwnProperty('ageConfirmed'))
                    throw $util.ProtocolError("missing required 'ageConfirmed'", { instance: message })
                if (!message.hasOwnProperty('weight'))
                    throw $util.ProtocolError("missing required 'weight'", { instance: message })
                if (!message.hasOwnProperty('createdAt'))
                    throw $util.ProtocolError("missing required 'createdAt'", { instance: message })
                return message
            }

            /**
             * Decodes a Parcel message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof parcels.Parcel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {parcels.Parcel} Parcel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Parcel.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a Parcel message.
             * @function verify
             * @memberof parcels.Parcel
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Parcel.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (!$util.isString(message.id)) return 'id: string expected'
                if (!$util.isString(message.orderId)) return 'orderId: string expected'
                switch (message.status) {
                    default:
                        return 'status: enum value expected'
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        break
                }
                switch (message.contentKind) {
                    default:
                        return 'contentKind: enum value expected'
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break
                }
                if (!$util.isString(message.barcode)) return 'barcode: string expected'
                if (message.externalId != null && message.hasOwnProperty('externalId'))
                    if (!$util.isString(message.externalId)) return 'externalId: string expected'
                if (
                    !$util.isInteger(message.ageRestriction) &&
                    !(
                        message.ageRestriction &&
                        $util.isInteger(message.ageRestriction.low) &&
                        $util.isInteger(message.ageRestriction.high)
                    )
                )
                    return 'ageRestriction: integer|Long expected'
                if (typeof message.ageConfirmed !== 'boolean') return 'ageConfirmed: boolean expected'
                if (
                    !$util.isInteger(message.weight) &&
                    !(message.weight && $util.isInteger(message.weight.low) && $util.isInteger(message.weight.high))
                )
                    return 'weight: integer|Long expected'
                if (message.height != null && message.hasOwnProperty('height'))
                    if (
                        !$util.isInteger(message.height) &&
                        !(message.height && $util.isInteger(message.height.low) && $util.isInteger(message.height.high))
                    )
                        return 'height: integer|Long expected'
                if (message.width != null && message.hasOwnProperty('width'))
                    if (
                        !$util.isInteger(message.width) &&
                        !(message.width && $util.isInteger(message.width.low) && $util.isInteger(message.width.high))
                    )
                        return 'width: integer|Long expected'
                if (message.length != null && message.hasOwnProperty('length'))
                    if (
                        !$util.isInteger(message.length) &&
                        !(message.length && $util.isInteger(message.length.low) && $util.isInteger(message.length.high))
                    )
                        return 'length: integer|Long expected'
                if (message.type != null && message.hasOwnProperty('type'))
                    if (!$util.isString(message.type)) return 'type: string expected'
                if (
                    !$util.isInteger(message.createdAt) &&
                    !(
                        message.createdAt &&
                        $util.isInteger(message.createdAt.low) &&
                        $util.isInteger(message.createdAt.high)
                    )
                )
                    return 'createdAt: integer|Long expected'
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (
                        !$util.isInteger(message.updatedAt) &&
                        !(
                            message.updatedAt &&
                            $util.isInteger(message.updatedAt.low) &&
                            $util.isInteger(message.updatedAt.high)
                        )
                    )
                        return 'updatedAt: integer|Long expected'
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (
                        !$util.isInteger(message.deletedAt) &&
                        !(
                            message.deletedAt &&
                            $util.isInteger(message.deletedAt.low) &&
                            $util.isInteger(message.deletedAt.high)
                        )
                    )
                        return 'deletedAt: integer|Long expected'
                if (message.image != null && message.hasOwnProperty('image'))
                    if (!$util.isString(message.image)) return 'image: string expected'
                if (message.name != null && message.hasOwnProperty('name'))
                    if (!$util.isString(message.name)) return 'name: string expected'
                if (message.itemId != null && message.hasOwnProperty('itemId'))
                    if (!$util.isString(message.itemId)) return 'itemId: string expected'
                return null
            }

            /**
             * Creates a Parcel message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof parcels.Parcel
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {parcels.Parcel} Parcel
             */
            Parcel.fromObject = function fromObject(object) {
                if (object instanceof $root.parcels.Parcel) return object
                var message = new $root.parcels.Parcel()
                if (object.id != null) message.id = String(object.id)
                if (object.orderId != null) message.orderId = String(object.orderId)
                switch (object.status) {
                    case 'ABSTRACT':
                    case 0:
                        message.status = 0
                        break
                    case 'WAITING_FOR_PICKUP':
                    case 1:
                        message.status = 1
                        break
                    case 'PICKED':
                    case 2:
                        message.status = 2
                        break
                    case 'DELIVERED':
                    case 3:
                        message.status = 3
                        break
                    case 'REJECTED':
                    case 4:
                        message.status = 4
                        break
                    case 'RETURNED':
                    case 5:
                        message.status = 5
                        break
                    case 'MISSING':
                    case 6:
                        message.status = 6
                        break
                    case 'NOT_RETURNED':
                    case 7:
                        message.status = 7
                        break
                }
                switch (object.contentKind) {
                    case 'GROCERY':
                    case 0:
                        message.contentKind = 0
                        break
                    case 'ALCOHOL':
                    case 1:
                        message.contentKind = 1
                        break
                    case 'TOBACCO':
                    case 2:
                        message.contentKind = 2
                        break
                    case 'PHARMA':
                    case 3:
                        message.contentKind = 3
                        break
                    case 'CHEMICAL':
                    case 4:
                        message.contentKind = 4
                        break
                    case 'FROZEN':
                    case 5:
                        message.contentKind = 5
                        break
                    case 'OWN_KITCHEN':
                    case 6:
                        message.contentKind = 6
                        break
                }
                if (object.barcode != null) message.barcode = String(object.barcode)
                if (object.externalId != null) message.externalId = String(object.externalId)
                if (object.ageRestriction != null)
                    if ($util.Long)
                        (message.ageRestriction = $util.Long.fromValue(object.ageRestriction)).unsigned = false
                    else if (typeof object.ageRestriction === 'string')
                        message.ageRestriction = parseInt(object.ageRestriction, 10)
                    else if (typeof object.ageRestriction === 'number') message.ageRestriction = object.ageRestriction
                    else if (typeof object.ageRestriction === 'object')
                        message.ageRestriction = new $util.LongBits(
                            object.ageRestriction.low >>> 0,
                            object.ageRestriction.high >>> 0
                        ).toNumber()
                if (object.ageConfirmed != null) message.ageConfirmed = Boolean(object.ageConfirmed)
                if (object.weight != null)
                    if ($util.Long) (message.weight = $util.Long.fromValue(object.weight)).unsigned = false
                    else if (typeof object.weight === 'string') message.weight = parseInt(object.weight, 10)
                    else if (typeof object.weight === 'number') message.weight = object.weight
                    else if (typeof object.weight === 'object')
                        message.weight = new $util.LongBits(
                            object.weight.low >>> 0,
                            object.weight.high >>> 0
                        ).toNumber()
                if (object.height != null)
                    if ($util.Long) (message.height = $util.Long.fromValue(object.height)).unsigned = false
                    else if (typeof object.height === 'string') message.height = parseInt(object.height, 10)
                    else if (typeof object.height === 'number') message.height = object.height
                    else if (typeof object.height === 'object')
                        message.height = new $util.LongBits(
                            object.height.low >>> 0,
                            object.height.high >>> 0
                        ).toNumber()
                if (object.width != null)
                    if ($util.Long) (message.width = $util.Long.fromValue(object.width)).unsigned = false
                    else if (typeof object.width === 'string') message.width = parseInt(object.width, 10)
                    else if (typeof object.width === 'number') message.width = object.width
                    else if (typeof object.width === 'object')
                        message.width = new $util.LongBits(object.width.low >>> 0, object.width.high >>> 0).toNumber()
                if (object.length != null)
                    if ($util.Long) (message.length = $util.Long.fromValue(object.length)).unsigned = false
                    else if (typeof object.length === 'string') message.length = parseInt(object.length, 10)
                    else if (typeof object.length === 'number') message.length = object.length
                    else if (typeof object.length === 'object')
                        message.length = new $util.LongBits(
                            object.length.low >>> 0,
                            object.length.high >>> 0
                        ).toNumber()
                if (object.type != null) message.type = String(object.type)
                if (object.createdAt != null)
                    if ($util.Long) (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false
                    else if (typeof object.createdAt === 'string') message.createdAt = parseInt(object.createdAt, 10)
                    else if (typeof object.createdAt === 'number') message.createdAt = object.createdAt
                    else if (typeof object.createdAt === 'object')
                        message.createdAt = new $util.LongBits(
                            object.createdAt.low >>> 0,
                            object.createdAt.high >>> 0
                        ).toNumber()
                if (object.updatedAt != null)
                    if ($util.Long) (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false
                    else if (typeof object.updatedAt === 'string') message.updatedAt = parseInt(object.updatedAt, 10)
                    else if (typeof object.updatedAt === 'number') message.updatedAt = object.updatedAt
                    else if (typeof object.updatedAt === 'object')
                        message.updatedAt = new $util.LongBits(
                            object.updatedAt.low >>> 0,
                            object.updatedAt.high >>> 0
                        ).toNumber()
                if (object.deletedAt != null)
                    if ($util.Long) (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false
                    else if (typeof object.deletedAt === 'string') message.deletedAt = parseInt(object.deletedAt, 10)
                    else if (typeof object.deletedAt === 'number') message.deletedAt = object.deletedAt
                    else if (typeof object.deletedAt === 'object')
                        message.deletedAt = new $util.LongBits(
                            object.deletedAt.low >>> 0,
                            object.deletedAt.high >>> 0
                        ).toNumber()
                if (object.image != null) message.image = String(object.image)
                if (object.name != null) message.name = String(object.name)
                if (object.itemId != null) message.itemId = String(object.itemId)
                return message
            }

            /**
             * Creates a plain object from a Parcel message. Also converts values to other types if specified.
             * @function toObject
             * @memberof parcels.Parcel
             * @static
             * @param {parcels.Parcel} message Parcel
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Parcel.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.defaults) {
                    object.id = ''
                    object.orderId = ''
                    object.status = options.enums === String ? 'ABSTRACT' : 0
                    object.contentKind = options.enums === String ? 'GROCERY' : 0
                    object.barcode = ''
                    object.externalId = ''
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.ageRestriction =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.ageRestriction = options.longs === String ? '0' : 0
                    object.ageConfirmed = false
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.weight =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.weight = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.height =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.height = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.width =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.width = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.length =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.length = options.longs === String ? '0' : 0
                    object.type = ''
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.createdAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.createdAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.updatedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.updatedAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.deletedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.deletedAt = options.longs === String ? '0' : 0
                    object.image = ''
                    object.name = ''
                    object.itemId = ''
                }
                if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
                if (message.orderId != null && message.hasOwnProperty('orderId')) object.orderId = message.orderId
                if (message.status != null && message.hasOwnProperty('status'))
                    object.status = options.enums === String ? $root.parcels.Status[message.status] : message.status
                if (message.contentKind != null && message.hasOwnProperty('contentKind'))
                    object.contentKind =
                        options.enums === String ? $root.parcels.ContentKind[message.contentKind] : message.contentKind
                if (message.barcode != null && message.hasOwnProperty('barcode')) object.barcode = message.barcode
                if (message.externalId != null && message.hasOwnProperty('externalId'))
                    object.externalId = message.externalId
                if (message.ageRestriction != null && message.hasOwnProperty('ageRestriction'))
                    if (typeof message.ageRestriction === 'number')
                        object.ageRestriction =
                            options.longs === String ? String(message.ageRestriction) : message.ageRestriction
                    else
                        object.ageRestriction =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.ageRestriction)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.ageRestriction.low >>> 0,
                                      message.ageRestriction.high >>> 0
                                  ).toNumber()
                                : message.ageRestriction
                if (message.ageConfirmed != null && message.hasOwnProperty('ageConfirmed'))
                    object.ageConfirmed = message.ageConfirmed
                if (message.weight != null && message.hasOwnProperty('weight'))
                    if (typeof message.weight === 'number')
                        object.weight = options.longs === String ? String(message.weight) : message.weight
                    else
                        object.weight =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.weight)
                                : options.longs === Number
                                ? new $util.LongBits(message.weight.low >>> 0, message.weight.high >>> 0).toNumber()
                                : message.weight
                if (message.height != null && message.hasOwnProperty('height'))
                    if (typeof message.height === 'number')
                        object.height = options.longs === String ? String(message.height) : message.height
                    else
                        object.height =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.height)
                                : options.longs === Number
                                ? new $util.LongBits(message.height.low >>> 0, message.height.high >>> 0).toNumber()
                                : message.height
                if (message.width != null && message.hasOwnProperty('width'))
                    if (typeof message.width === 'number')
                        object.width = options.longs === String ? String(message.width) : message.width
                    else
                        object.width =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.width)
                                : options.longs === Number
                                ? new $util.LongBits(message.width.low >>> 0, message.width.high >>> 0).toNumber()
                                : message.width
                if (message.length != null && message.hasOwnProperty('length'))
                    if (typeof message.length === 'number')
                        object.length = options.longs === String ? String(message.length) : message.length
                    else
                        object.length =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.length)
                                : options.longs === Number
                                ? new $util.LongBits(message.length.low >>> 0, message.length.high >>> 0).toNumber()
                                : message.length
                if (message.type != null && message.hasOwnProperty('type')) object.type = message.type
                if (message.createdAt != null && message.hasOwnProperty('createdAt'))
                    if (typeof message.createdAt === 'number')
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt
                    else
                        object.createdAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.createdAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.createdAt.low >>> 0,
                                      message.createdAt.high >>> 0
                                  ).toNumber()
                                : message.createdAt
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (typeof message.updatedAt === 'number')
                        object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt
                    else
                        object.updatedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.updatedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.updatedAt.low >>> 0,
                                      message.updatedAt.high >>> 0
                                  ).toNumber()
                                : message.updatedAt
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (typeof message.deletedAt === 'number')
                        object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt
                    else
                        object.deletedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.deletedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.deletedAt.low >>> 0,
                                      message.deletedAt.high >>> 0
                                  ).toNumber()
                                : message.deletedAt
                if (message.image != null && message.hasOwnProperty('image')) object.image = message.image
                if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
                if (message.itemId != null && message.hasOwnProperty('itemId')) object.itemId = message.itemId
                return object
            }

            /**
             * Converts this Parcel to JSON.
             * @function toJSON
             * @memberof parcels.Parcel
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Parcel.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for Parcel
             * @function getTypeUrl
             * @memberof parcels.Parcel
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Parcel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/parcels.Parcel'
            }

            return Parcel
        })()

        return parcels
    })()

    $root.orders = (function () {
        /**
         * Namespace orders.
         * @exports orders
         * @namespace
         */
        var orders = {}

        orders.GetOrderListResponse = (function () {
            /**
             * Properties of a GetOrderListResponse.
             * @memberof orders
             * @interface IGetOrderListResponse
             * @property {Array.<orders.Order>|null} [orders] GetOrderListResponse orders
             */

            /**
             * Constructs a new GetOrderListResponse.
             * @memberof orders
             * @classdesc Represents a GetOrderListResponse.
             * @implements IGetOrderListResponse
             * @constructor
             * @param {orders.IGetOrderListResponse=} [properties] Properties to set
             */
            function GetOrderListResponse(properties) {
                this.orders = []
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * GetOrderListResponse orders.
             * @member {Array.<orders.Order>} orders
             * @memberof orders.GetOrderListResponse
             * @instance
             */
            GetOrderListResponse.prototype.orders = $util.emptyArray

            /**
             * Creates a new GetOrderListResponse instance using the specified properties.
             * @function create
             * @memberof orders.GetOrderListResponse
             * @static
             * @param {orders.IGetOrderListResponse=} [properties] Properties to set
             * @returns {orders.GetOrderListResponse} GetOrderListResponse instance
             */
            GetOrderListResponse.create = function create(properties) {
                return new GetOrderListResponse(properties)
            }

            /**
             * Encodes the specified GetOrderListResponse message. Does not implicitly {@link orders.GetOrderListResponse.verify|verify} messages.
             * @function encode
             * @memberof orders.GetOrderListResponse
             * @static
             * @param {orders.GetOrderListResponse} message GetOrderListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOrderListResponse.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                if (message.orders != null && message.orders.length)
                    for (var i = 0; i < message.orders.length; ++i)
                        $root.orders.Order.encode(
                            message.orders[i],
                            writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
                        ).ldelim()
                return writer
            }

            /**
             * Encodes the specified GetOrderListResponse message, length delimited. Does not implicitly {@link orders.GetOrderListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof orders.GetOrderListResponse
             * @static
             * @param {orders.GetOrderListResponse} message GetOrderListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOrderListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a GetOrderListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof orders.GetOrderListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {orders.GetOrderListResponse} GetOrderListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOrderListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.orders.GetOrderListResponse()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            if (!(message.orders && message.orders.length)) message.orders = []
                            message.orders.push($root.orders.Order.decode(reader, reader.uint32()))
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                return message
            }

            /**
             * Decodes a GetOrderListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof orders.GetOrderListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {orders.GetOrderListResponse} GetOrderListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOrderListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a GetOrderListResponse message.
             * @function verify
             * @memberof orders.GetOrderListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetOrderListResponse.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (message.orders != null && message.hasOwnProperty('orders')) {
                    if (!Array.isArray(message.orders)) return 'orders: array expected'
                    for (var i = 0; i < message.orders.length; ++i) {
                        var error = $root.orders.Order.verify(message.orders[i])
                        if (error) return 'orders.' + error
                    }
                }
                return null
            }

            /**
             * Creates a GetOrderListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof orders.GetOrderListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {orders.GetOrderListResponse} GetOrderListResponse
             */
            GetOrderListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.orders.GetOrderListResponse) return object
                var message = new $root.orders.GetOrderListResponse()
                if (object.orders) {
                    if (!Array.isArray(object.orders))
                        throw TypeError('.orders.GetOrderListResponse.orders: array expected')
                    message.orders = []
                    for (var i = 0; i < object.orders.length; ++i) {
                        if (typeof object.orders[i] !== 'object')
                            throw TypeError('.orders.GetOrderListResponse.orders: object expected')
                        message.orders[i] = $root.orders.Order.fromObject(object.orders[i])
                    }
                }
                return message
            }

            /**
             * Creates a plain object from a GetOrderListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof orders.GetOrderListResponse
             * @static
             * @param {orders.GetOrderListResponse} message GetOrderListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetOrderListResponse.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.arrays || options.defaults) object.orders = []
                if (message.orders && message.orders.length) {
                    object.orders = []
                    for (var j = 0; j < message.orders.length; ++j)
                        object.orders[j] = $root.orders.Order.toObject(message.orders[j], options)
                }
                return object
            }

            /**
             * Converts this GetOrderListResponse to JSON.
             * @function toJSON
             * @memberof orders.GetOrderListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetOrderListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for GetOrderListResponse
             * @function getTypeUrl
             * @memberof orders.GetOrderListResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetOrderListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/orders.GetOrderListResponse'
            }

            return GetOrderListResponse
        })()

        /**
         * Status enum.
         * @name orders.Status
         * @enum {number}
         * @property {number} NEW=0 NEW value
         * @property {number} BATCHED=1 BATCHED value
         * @property {number} IN_PROGRESS=2 IN_PROGRESS value
         * @property {number} COMPLETED=3 COMPLETED value
         * @property {number} CANCELED=4 CANCELED value
         * @property {number} FAILED=5 FAILED value
         */
        orders.Status = (function () {
            var valuesById = {},
                values = Object.create(valuesById)
            values[(valuesById[0] = 'NEW')] = 0
            values[(valuesById[1] = 'BATCHED')] = 1
            values[(valuesById[2] = 'IN_PROGRESS')] = 2
            values[(valuesById[3] = 'COMPLETED')] = 3
            values[(valuesById[4] = 'CANCELED')] = 4
            values[(valuesById[5] = 'FAILED')] = 5
            return values
        })()

        /**
         * OrderType enum.
         * @name orders.OrderType
         * @enum {number}
         * @property {number} GROCERY=0 GROCERY value
         * @property {number} FASHION=1 FASHION value
         */
        orders.OrderType = (function () {
            var valuesById = {},
                values = Object.create(valuesById)
            values[(valuesById[0] = 'GROCERY')] = 0
            values[(valuesById[1] = 'FASHION')] = 1
            return values
        })()

        orders.Order = (function () {
            /**
             * Properties of an Order.
             * @memberof orders
             * @interface IOrder
             * @property {string} id Order id
             * @property {string} externalId Order externalId
             * @property {string} contactId Order contactId
             * @property {number} startBy Order startBy
             * @property {number} deliverByMin Order deliverByMin
             * @property {number} deliverByMax Order deliverByMax
             * @property {string|null} [note] Order note
             * @property {orders.Status} status Order status
             * @property {number} createdAt Order createdAt
             * @property {number|null} [updatedAt] Order updatedAt
             * @property {number|null} [deletedAt] Order deletedAt
             * @property {number|null} [distance] Order distance
             * @property {number|null} [eta] Order eta
             * @property {string|null} [seller] Order seller
             * @property {addresses.Address|null} [address] Order address
             * @property {orders.OrderType} orderType Order orderType
             */

            /**
             * Constructs a new Order.
             * @memberof orders
             * @classdesc Represents an Order.
             * @implements IOrder
             * @constructor
             * @param {orders.IOrder=} [properties] Properties to set
             */
            function Order(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * Order id.
             * @member {string} id
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.id = ''

            /**
             * Order externalId.
             * @member {string} externalId
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.externalId = ''

            /**
             * Order contactId.
             * @member {string} contactId
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.contactId = ''

            /**
             * Order startBy.
             * @member {number} startBy
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.startBy = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Order deliverByMin.
             * @member {number} deliverByMin
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.deliverByMin = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Order deliverByMax.
             * @member {number} deliverByMax
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.deliverByMax = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Order note.
             * @member {string} note
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.note = ''

            /**
             * Order status.
             * @member {orders.Status} status
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.status = 0

            /**
             * Order createdAt.
             * @member {number} createdAt
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.createdAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Order updatedAt.
             * @member {number} updatedAt
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Order deletedAt.
             * @member {number} deletedAt
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Order distance.
             * @member {number} distance
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.distance = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Order eta.
             * @member {number} eta
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.eta = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Order seller.
             * @member {string} seller
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.seller = ''

            /**
             * Order address.
             * @member {addresses.Address|null|undefined} address
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.address = null

            /**
             * Order orderType.
             * @member {orders.OrderType} orderType
             * @memberof orders.Order
             * @instance
             */
            Order.prototype.orderType = 0

            /**
             * Creates a new Order instance using the specified properties.
             * @function create
             * @memberof orders.Order
             * @static
             * @param {orders.IOrder=} [properties] Properties to set
             * @returns {orders.Order} Order instance
             */
            Order.create = function create(properties) {
                return new Order(properties)
            }

            /**
             * Encodes the specified Order message. Does not implicitly {@link orders.Order.verify|verify} messages.
             * @function encode
             * @memberof orders.Order
             * @static
             * @param {orders.Order} message Order message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Order.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
                writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.externalId)
                writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.contactId)
                writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.startBy)
                writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.deliverByMin)
                writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.deliverByMax)
                if (message.note != null && Object.hasOwnProperty.call(message, 'note'))
                    writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.note)
                writer.uint32(/* id 8, wireType 0 =*/ 64).int32(message.status)
                writer.uint32(/* id 9, wireType 0 =*/ 72).int64(message.createdAt)
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, 'updatedAt'))
                    writer.uint32(/* id 10, wireType 0 =*/ 80).int64(message.updatedAt)
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, 'deletedAt'))
                    writer.uint32(/* id 11, wireType 0 =*/ 88).int64(message.deletedAt)
                if (message.distance != null && Object.hasOwnProperty.call(message, 'distance'))
                    writer.uint32(/* id 12, wireType 0 =*/ 96).int64(message.distance)
                if (message.eta != null && Object.hasOwnProperty.call(message, 'eta'))
                    writer.uint32(/* id 13, wireType 0 =*/ 104).int64(message.eta)
                if (message.seller != null && Object.hasOwnProperty.call(message, 'seller'))
                    writer.uint32(/* id 14, wireType 2 =*/ 114).string(message.seller)
                if (message.address != null && Object.hasOwnProperty.call(message, 'address'))
                    $root.addresses.Address.encode(
                        message.address,
                        writer.uint32(/* id 15, wireType 2 =*/ 122).fork()
                    ).ldelim()
                writer.uint32(/* id 16, wireType 0 =*/ 128).int32(message.orderType)
                return writer
            }

            /**
             * Encodes the specified Order message, length delimited. Does not implicitly {@link orders.Order.verify|verify} messages.
             * @function encodeDelimited
             * @memberof orders.Order
             * @static
             * @param {orders.Order} message Order message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Order.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes an Order message from the specified reader or buffer.
             * @function decode
             * @memberof orders.Order
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {orders.Order} Order
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Order.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.orders.Order()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.id = reader.string()
                            break
                        }
                        case 2: {
                            message.externalId = reader.string()
                            break
                        }
                        case 3: {
                            message.contactId = reader.string()
                            break
                        }
                        case 4: {
                            message.startBy = reader.int64()
                            break
                        }
                        case 5: {
                            message.deliverByMin = reader.int64()
                            break
                        }
                        case 6: {
                            message.deliverByMax = reader.int64()
                            break
                        }
                        case 7: {
                            message.note = reader.string()
                            break
                        }
                        case 8: {
                            message.status = reader.int32()
                            break
                        }
                        case 9: {
                            message.createdAt = reader.int64()
                            break
                        }
                        case 10: {
                            message.updatedAt = reader.int64()
                            break
                        }
                        case 11: {
                            message.deletedAt = reader.int64()
                            break
                        }
                        case 12: {
                            message.distance = reader.int64()
                            break
                        }
                        case 13: {
                            message.eta = reader.int64()
                            break
                        }
                        case 14: {
                            message.seller = reader.string()
                            break
                        }
                        case 15: {
                            message.address = $root.addresses.Address.decode(reader, reader.uint32())
                            break
                        }
                        case 16: {
                            message.orderType = reader.int32()
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('id'))
                    throw $util.ProtocolError("missing required 'id'", { instance: message })
                if (!message.hasOwnProperty('externalId'))
                    throw $util.ProtocolError("missing required 'externalId'", { instance: message })
                if (!message.hasOwnProperty('contactId'))
                    throw $util.ProtocolError("missing required 'contactId'", { instance: message })
                if (!message.hasOwnProperty('startBy'))
                    throw $util.ProtocolError("missing required 'startBy'", { instance: message })
                if (!message.hasOwnProperty('deliverByMin'))
                    throw $util.ProtocolError("missing required 'deliverByMin'", { instance: message })
                if (!message.hasOwnProperty('deliverByMax'))
                    throw $util.ProtocolError("missing required 'deliverByMax'", { instance: message })
                if (!message.hasOwnProperty('status'))
                    throw $util.ProtocolError("missing required 'status'", { instance: message })
                if (!message.hasOwnProperty('createdAt'))
                    throw $util.ProtocolError("missing required 'createdAt'", { instance: message })
                if (!message.hasOwnProperty('orderType'))
                    throw $util.ProtocolError("missing required 'orderType'", { instance: message })
                return message
            }

            /**
             * Decodes an Order message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof orders.Order
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {orders.Order} Order
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Order.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies an Order message.
             * @function verify
             * @memberof orders.Order
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Order.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (!$util.isString(message.id)) return 'id: string expected'
                if (!$util.isString(message.externalId)) return 'externalId: string expected'
                if (!$util.isString(message.contactId)) return 'contactId: string expected'
                if (
                    !$util.isInteger(message.startBy) &&
                    !(message.startBy && $util.isInteger(message.startBy.low) && $util.isInteger(message.startBy.high))
                )
                    return 'startBy: integer|Long expected'
                if (
                    !$util.isInteger(message.deliverByMin) &&
                    !(
                        message.deliverByMin &&
                        $util.isInteger(message.deliverByMin.low) &&
                        $util.isInteger(message.deliverByMin.high)
                    )
                )
                    return 'deliverByMin: integer|Long expected'
                if (
                    !$util.isInteger(message.deliverByMax) &&
                    !(
                        message.deliverByMax &&
                        $util.isInteger(message.deliverByMax.low) &&
                        $util.isInteger(message.deliverByMax.high)
                    )
                )
                    return 'deliverByMax: integer|Long expected'
                if (message.note != null && message.hasOwnProperty('note'))
                    if (!$util.isString(message.note)) return 'note: string expected'
                switch (message.status) {
                    default:
                        return 'status: enum value expected'
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break
                }
                if (
                    !$util.isInteger(message.createdAt) &&
                    !(
                        message.createdAt &&
                        $util.isInteger(message.createdAt.low) &&
                        $util.isInteger(message.createdAt.high)
                    )
                )
                    return 'createdAt: integer|Long expected'
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (
                        !$util.isInteger(message.updatedAt) &&
                        !(
                            message.updatedAt &&
                            $util.isInteger(message.updatedAt.low) &&
                            $util.isInteger(message.updatedAt.high)
                        )
                    )
                        return 'updatedAt: integer|Long expected'
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (
                        !$util.isInteger(message.deletedAt) &&
                        !(
                            message.deletedAt &&
                            $util.isInteger(message.deletedAt.low) &&
                            $util.isInteger(message.deletedAt.high)
                        )
                    )
                        return 'deletedAt: integer|Long expected'
                if (message.distance != null && message.hasOwnProperty('distance'))
                    if (
                        !$util.isInteger(message.distance) &&
                        !(
                            message.distance &&
                            $util.isInteger(message.distance.low) &&
                            $util.isInteger(message.distance.high)
                        )
                    )
                        return 'distance: integer|Long expected'
                if (message.eta != null && message.hasOwnProperty('eta'))
                    if (
                        !$util.isInteger(message.eta) &&
                        !(message.eta && $util.isInteger(message.eta.low) && $util.isInteger(message.eta.high))
                    )
                        return 'eta: integer|Long expected'
                if (message.seller != null && message.hasOwnProperty('seller'))
                    if (!$util.isString(message.seller)) return 'seller: string expected'
                if (message.address != null && message.hasOwnProperty('address')) {
                    var error = $root.addresses.Address.verify(message.address)
                    if (error) return 'address.' + error
                }
                switch (message.orderType) {
                    default:
                        return 'orderType: enum value expected'
                    case 0:
                    case 1:
                        break
                }
                return null
            }

            /**
             * Creates an Order message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof orders.Order
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {orders.Order} Order
             */
            Order.fromObject = function fromObject(object) {
                if (object instanceof $root.orders.Order) return object
                var message = new $root.orders.Order()
                if (object.id != null) message.id = String(object.id)
                if (object.externalId != null) message.externalId = String(object.externalId)
                if (object.contactId != null) message.contactId = String(object.contactId)
                if (object.startBy != null)
                    if ($util.Long) (message.startBy = $util.Long.fromValue(object.startBy)).unsigned = false
                    else if (typeof object.startBy === 'string') message.startBy = parseInt(object.startBy, 10)
                    else if (typeof object.startBy === 'number') message.startBy = object.startBy
                    else if (typeof object.startBy === 'object')
                        message.startBy = new $util.LongBits(
                            object.startBy.low >>> 0,
                            object.startBy.high >>> 0
                        ).toNumber()
                if (object.deliverByMin != null)
                    if ($util.Long) (message.deliverByMin = $util.Long.fromValue(object.deliverByMin)).unsigned = false
                    else if (typeof object.deliverByMin === 'string')
                        message.deliverByMin = parseInt(object.deliverByMin, 10)
                    else if (typeof object.deliverByMin === 'number') message.deliverByMin = object.deliverByMin
                    else if (typeof object.deliverByMin === 'object')
                        message.deliverByMin = new $util.LongBits(
                            object.deliverByMin.low >>> 0,
                            object.deliverByMin.high >>> 0
                        ).toNumber()
                if (object.deliverByMax != null)
                    if ($util.Long) (message.deliverByMax = $util.Long.fromValue(object.deliverByMax)).unsigned = false
                    else if (typeof object.deliverByMax === 'string')
                        message.deliverByMax = parseInt(object.deliverByMax, 10)
                    else if (typeof object.deliverByMax === 'number') message.deliverByMax = object.deliverByMax
                    else if (typeof object.deliverByMax === 'object')
                        message.deliverByMax = new $util.LongBits(
                            object.deliverByMax.low >>> 0,
                            object.deliverByMax.high >>> 0
                        ).toNumber()
                if (object.note != null) message.note = String(object.note)
                switch (object.status) {
                    case 'NEW':
                    case 0:
                        message.status = 0
                        break
                    case 'BATCHED':
                    case 1:
                        message.status = 1
                        break
                    case 'IN_PROGRESS':
                    case 2:
                        message.status = 2
                        break
                    case 'COMPLETED':
                    case 3:
                        message.status = 3
                        break
                    case 'CANCELED':
                    case 4:
                        message.status = 4
                        break
                    case 'FAILED':
                    case 5:
                        message.status = 5
                        break
                }
                if (object.createdAt != null)
                    if ($util.Long) (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false
                    else if (typeof object.createdAt === 'string') message.createdAt = parseInt(object.createdAt, 10)
                    else if (typeof object.createdAt === 'number') message.createdAt = object.createdAt
                    else if (typeof object.createdAt === 'object')
                        message.createdAt = new $util.LongBits(
                            object.createdAt.low >>> 0,
                            object.createdAt.high >>> 0
                        ).toNumber()
                if (object.updatedAt != null)
                    if ($util.Long) (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false
                    else if (typeof object.updatedAt === 'string') message.updatedAt = parseInt(object.updatedAt, 10)
                    else if (typeof object.updatedAt === 'number') message.updatedAt = object.updatedAt
                    else if (typeof object.updatedAt === 'object')
                        message.updatedAt = new $util.LongBits(
                            object.updatedAt.low >>> 0,
                            object.updatedAt.high >>> 0
                        ).toNumber()
                if (object.deletedAt != null)
                    if ($util.Long) (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false
                    else if (typeof object.deletedAt === 'string') message.deletedAt = parseInt(object.deletedAt, 10)
                    else if (typeof object.deletedAt === 'number') message.deletedAt = object.deletedAt
                    else if (typeof object.deletedAt === 'object')
                        message.deletedAt = new $util.LongBits(
                            object.deletedAt.low >>> 0,
                            object.deletedAt.high >>> 0
                        ).toNumber()
                if (object.distance != null)
                    if ($util.Long) (message.distance = $util.Long.fromValue(object.distance)).unsigned = false
                    else if (typeof object.distance === 'string') message.distance = parseInt(object.distance, 10)
                    else if (typeof object.distance === 'number') message.distance = object.distance
                    else if (typeof object.distance === 'object')
                        message.distance = new $util.LongBits(
                            object.distance.low >>> 0,
                            object.distance.high >>> 0
                        ).toNumber()
                if (object.eta != null)
                    if ($util.Long) (message.eta = $util.Long.fromValue(object.eta)).unsigned = false
                    else if (typeof object.eta === 'string') message.eta = parseInt(object.eta, 10)
                    else if (typeof object.eta === 'number') message.eta = object.eta
                    else if (typeof object.eta === 'object')
                        message.eta = new $util.LongBits(object.eta.low >>> 0, object.eta.high >>> 0).toNumber()
                if (object.seller != null) message.seller = String(object.seller)
                if (object.address != null) {
                    if (typeof object.address !== 'object') throw TypeError('.orders.Order.address: object expected')
                    message.address = $root.addresses.Address.fromObject(object.address)
                }
                switch (object.orderType) {
                    case 'GROCERY':
                    case 0:
                        message.orderType = 0
                        break
                    case 'FASHION':
                    case 1:
                        message.orderType = 1
                        break
                }
                return message
            }

            /**
             * Creates a plain object from an Order message. Also converts values to other types if specified.
             * @function toObject
             * @memberof orders.Order
             * @static
             * @param {orders.Order} message Order
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Order.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.defaults) {
                    object.id = ''
                    object.externalId = ''
                    object.contactId = ''
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.startBy =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.startBy = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.deliverByMin =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.deliverByMin = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.deliverByMax =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.deliverByMax = options.longs === String ? '0' : 0
                    object.note = ''
                    object.status = options.enums === String ? 'NEW' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.createdAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.createdAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.updatedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.updatedAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.deletedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.deletedAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.distance =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.distance = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.eta =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.eta = options.longs === String ? '0' : 0
                    object.seller = ''
                    object.address = null
                    object.orderType = options.enums === String ? 'GROCERY' : 0
                }
                if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
                if (message.externalId != null && message.hasOwnProperty('externalId'))
                    object.externalId = message.externalId
                if (message.contactId != null && message.hasOwnProperty('contactId'))
                    object.contactId = message.contactId
                if (message.startBy != null && message.hasOwnProperty('startBy'))
                    if (typeof message.startBy === 'number')
                        object.startBy = options.longs === String ? String(message.startBy) : message.startBy
                    else
                        object.startBy =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.startBy)
                                : options.longs === Number
                                ? new $util.LongBits(message.startBy.low >>> 0, message.startBy.high >>> 0).toNumber()
                                : message.startBy
                if (message.deliverByMin != null && message.hasOwnProperty('deliverByMin'))
                    if (typeof message.deliverByMin === 'number')
                        object.deliverByMin =
                            options.longs === String ? String(message.deliverByMin) : message.deliverByMin
                    else
                        object.deliverByMin =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.deliverByMin)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.deliverByMin.low >>> 0,
                                      message.deliverByMin.high >>> 0
                                  ).toNumber()
                                : message.deliverByMin
                if (message.deliverByMax != null && message.hasOwnProperty('deliverByMax'))
                    if (typeof message.deliverByMax === 'number')
                        object.deliverByMax =
                            options.longs === String ? String(message.deliverByMax) : message.deliverByMax
                    else
                        object.deliverByMax =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.deliverByMax)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.deliverByMax.low >>> 0,
                                      message.deliverByMax.high >>> 0
                                  ).toNumber()
                                : message.deliverByMax
                if (message.note != null && message.hasOwnProperty('note')) object.note = message.note
                if (message.status != null && message.hasOwnProperty('status'))
                    object.status = options.enums === String ? $root.orders.Status[message.status] : message.status
                if (message.createdAt != null && message.hasOwnProperty('createdAt'))
                    if (typeof message.createdAt === 'number')
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt
                    else
                        object.createdAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.createdAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.createdAt.low >>> 0,
                                      message.createdAt.high >>> 0
                                  ).toNumber()
                                : message.createdAt
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (typeof message.updatedAt === 'number')
                        object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt
                    else
                        object.updatedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.updatedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.updatedAt.low >>> 0,
                                      message.updatedAt.high >>> 0
                                  ).toNumber()
                                : message.updatedAt
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (typeof message.deletedAt === 'number')
                        object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt
                    else
                        object.deletedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.deletedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.deletedAt.low >>> 0,
                                      message.deletedAt.high >>> 0
                                  ).toNumber()
                                : message.deletedAt
                if (message.distance != null && message.hasOwnProperty('distance'))
                    if (typeof message.distance === 'number')
                        object.distance = options.longs === String ? String(message.distance) : message.distance
                    else
                        object.distance =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.distance)
                                : options.longs === Number
                                ? new $util.LongBits(message.distance.low >>> 0, message.distance.high >>> 0).toNumber()
                                : message.distance
                if (message.eta != null && message.hasOwnProperty('eta'))
                    if (typeof message.eta === 'number')
                        object.eta = options.longs === String ? String(message.eta) : message.eta
                    else
                        object.eta =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.eta)
                                : options.longs === Number
                                ? new $util.LongBits(message.eta.low >>> 0, message.eta.high >>> 0).toNumber()
                                : message.eta
                if (message.seller != null && message.hasOwnProperty('seller')) object.seller = message.seller
                if (message.address != null && message.hasOwnProperty('address'))
                    object.address = $root.addresses.Address.toObject(message.address, options)
                if (message.orderType != null && message.hasOwnProperty('orderType'))
                    object.orderType =
                        options.enums === String ? $root.orders.OrderType[message.orderType] : message.orderType
                return object
            }

            /**
             * Converts this Order to JSON.
             * @function toJSON
             * @memberof orders.Order
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Order.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for Order
             * @function getTypeUrl
             * @memberof orders.Order
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Order.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/orders.Order'
            }

            return Order
        })()

        return orders
    })()

    $root.addresses = (function () {
        /**
         * Namespace addresses.
         * @exports addresses
         * @namespace
         */
        var addresses = {}

        addresses.Address = (function () {
            /**
             * Properties of an Address.
             * @memberof addresses
             * @interface IAddress
             * @property {string} id Address id
             * @property {string} name Address name
             * @property {string|null} [externalId] Address externalId
             * @property {number} latitude Address latitude
             * @property {number} longitude Address longitude
             * @property {number} createdAt Address createdAt
             * @property {number|null} [updatedAt] Address updatedAt
             * @property {number|null} [deletedAt] Address deletedAt
             */

            /**
             * Constructs a new Address.
             * @memberof addresses
             * @classdesc Represents an Address.
             * @implements IAddress
             * @constructor
             * @param {addresses.IAddress=} [properties] Properties to set
             */
            function Address(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * Address id.
             * @member {string} id
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.id = ''

            /**
             * Address name.
             * @member {string} name
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.name = ''

            /**
             * Address externalId.
             * @member {string} externalId
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.externalId = ''

            /**
             * Address latitude.
             * @member {number} latitude
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.latitude = 0

            /**
             * Address longitude.
             * @member {number} longitude
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.longitude = 0

            /**
             * Address createdAt.
             * @member {number} createdAt
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.createdAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Address updatedAt.
             * @member {number} updatedAt
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Address deletedAt.
             * @member {number} deletedAt
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Creates a new Address instance using the specified properties.
             * @function create
             * @memberof addresses.Address
             * @static
             * @param {addresses.IAddress=} [properties] Properties to set
             * @returns {addresses.Address} Address instance
             */
            Address.create = function create(properties) {
                return new Address(properties)
            }

            /**
             * Encodes the specified Address message. Does not implicitly {@link addresses.Address.verify|verify} messages.
             * @function encode
             * @memberof addresses.Address
             * @static
             * @param {addresses.Address} message Address message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Address.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
                writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.name)
                if (message.externalId != null && Object.hasOwnProperty.call(message, 'externalId'))
                    writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.externalId)
                writer.uint32(/* id 4, wireType 1 =*/ 33).double(message.latitude)
                writer.uint32(/* id 5, wireType 1 =*/ 41).double(message.longitude)
                writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.createdAt)
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, 'updatedAt'))
                    writer.uint32(/* id 7, wireType 0 =*/ 56).int64(message.updatedAt)
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, 'deletedAt'))
                    writer.uint32(/* id 8, wireType 0 =*/ 64).int64(message.deletedAt)
                return writer
            }

            /**
             * Encodes the specified Address message, length delimited. Does not implicitly {@link addresses.Address.verify|verify} messages.
             * @function encodeDelimited
             * @memberof addresses.Address
             * @static
             * @param {addresses.Address} message Address message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Address.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes an Address message from the specified reader or buffer.
             * @function decode
             * @memberof addresses.Address
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {addresses.Address} Address
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Address.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.addresses.Address()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.id = reader.string()
                            break
                        }
                        case 2: {
                            message.name = reader.string()
                            break
                        }
                        case 3: {
                            message.externalId = reader.string()
                            break
                        }
                        case 4: {
                            message.latitude = reader.double()
                            break
                        }
                        case 5: {
                            message.longitude = reader.double()
                            break
                        }
                        case 6: {
                            message.createdAt = reader.int64()
                            break
                        }
                        case 7: {
                            message.updatedAt = reader.int64()
                            break
                        }
                        case 8: {
                            message.deletedAt = reader.int64()
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('id'))
                    throw $util.ProtocolError("missing required 'id'", { instance: message })
                if (!message.hasOwnProperty('name'))
                    throw $util.ProtocolError("missing required 'name'", { instance: message })
                if (!message.hasOwnProperty('latitude'))
                    throw $util.ProtocolError("missing required 'latitude'", { instance: message })
                if (!message.hasOwnProperty('longitude'))
                    throw $util.ProtocolError("missing required 'longitude'", { instance: message })
                if (!message.hasOwnProperty('createdAt'))
                    throw $util.ProtocolError("missing required 'createdAt'", { instance: message })
                return message
            }

            /**
             * Decodes an Address message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof addresses.Address
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {addresses.Address} Address
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Address.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies an Address message.
             * @function verify
             * @memberof addresses.Address
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Address.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (!$util.isString(message.id)) return 'id: string expected'
                if (!$util.isString(message.name)) return 'name: string expected'
                if (message.externalId != null && message.hasOwnProperty('externalId'))
                    if (!$util.isString(message.externalId)) return 'externalId: string expected'
                if (typeof message.latitude !== 'number') return 'latitude: number expected'
                if (typeof message.longitude !== 'number') return 'longitude: number expected'
                if (
                    !$util.isInteger(message.createdAt) &&
                    !(
                        message.createdAt &&
                        $util.isInteger(message.createdAt.low) &&
                        $util.isInteger(message.createdAt.high)
                    )
                )
                    return 'createdAt: integer|Long expected'
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (
                        !$util.isInteger(message.updatedAt) &&
                        !(
                            message.updatedAt &&
                            $util.isInteger(message.updatedAt.low) &&
                            $util.isInteger(message.updatedAt.high)
                        )
                    )
                        return 'updatedAt: integer|Long expected'
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (
                        !$util.isInteger(message.deletedAt) &&
                        !(
                            message.deletedAt &&
                            $util.isInteger(message.deletedAt.low) &&
                            $util.isInteger(message.deletedAt.high)
                        )
                    )
                        return 'deletedAt: integer|Long expected'
                return null
            }

            /**
             * Creates an Address message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof addresses.Address
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {addresses.Address} Address
             */
            Address.fromObject = function fromObject(object) {
                if (object instanceof $root.addresses.Address) return object
                var message = new $root.addresses.Address()
                if (object.id != null) message.id = String(object.id)
                if (object.name != null) message.name = String(object.name)
                if (object.externalId != null) message.externalId = String(object.externalId)
                if (object.latitude != null) message.latitude = Number(object.latitude)
                if (object.longitude != null) message.longitude = Number(object.longitude)
                if (object.createdAt != null)
                    if ($util.Long) (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false
                    else if (typeof object.createdAt === 'string') message.createdAt = parseInt(object.createdAt, 10)
                    else if (typeof object.createdAt === 'number') message.createdAt = object.createdAt
                    else if (typeof object.createdAt === 'object')
                        message.createdAt = new $util.LongBits(
                            object.createdAt.low >>> 0,
                            object.createdAt.high >>> 0
                        ).toNumber()
                if (object.updatedAt != null)
                    if ($util.Long) (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false
                    else if (typeof object.updatedAt === 'string') message.updatedAt = parseInt(object.updatedAt, 10)
                    else if (typeof object.updatedAt === 'number') message.updatedAt = object.updatedAt
                    else if (typeof object.updatedAt === 'object')
                        message.updatedAt = new $util.LongBits(
                            object.updatedAt.low >>> 0,
                            object.updatedAt.high >>> 0
                        ).toNumber()
                if (object.deletedAt != null)
                    if ($util.Long) (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false
                    else if (typeof object.deletedAt === 'string') message.deletedAt = parseInt(object.deletedAt, 10)
                    else if (typeof object.deletedAt === 'number') message.deletedAt = object.deletedAt
                    else if (typeof object.deletedAt === 'object')
                        message.deletedAt = new $util.LongBits(
                            object.deletedAt.low >>> 0,
                            object.deletedAt.high >>> 0
                        ).toNumber()
                return message
            }

            /**
             * Creates a plain object from an Address message. Also converts values to other types if specified.
             * @function toObject
             * @memberof addresses.Address
             * @static
             * @param {addresses.Address} message Address
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Address.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.defaults) {
                    object.id = ''
                    object.name = ''
                    object.externalId = ''
                    object.latitude = 0
                    object.longitude = 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.createdAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.createdAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.updatedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.updatedAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.deletedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.deletedAt = options.longs === String ? '0' : 0
                }
                if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
                if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
                if (message.externalId != null && message.hasOwnProperty('externalId'))
                    object.externalId = message.externalId
                if (message.latitude != null && message.hasOwnProperty('latitude'))
                    object.latitude =
                        options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude
                if (message.longitude != null && message.hasOwnProperty('longitude'))
                    object.longitude =
                        options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude
                if (message.createdAt != null && message.hasOwnProperty('createdAt'))
                    if (typeof message.createdAt === 'number')
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt
                    else
                        object.createdAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.createdAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.createdAt.low >>> 0,
                                      message.createdAt.high >>> 0
                                  ).toNumber()
                                : message.createdAt
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (typeof message.updatedAt === 'number')
                        object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt
                    else
                        object.updatedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.updatedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.updatedAt.low >>> 0,
                                      message.updatedAt.high >>> 0
                                  ).toNumber()
                                : message.updatedAt
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (typeof message.deletedAt === 'number')
                        object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt
                    else
                        object.deletedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.deletedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.deletedAt.low >>> 0,
                                      message.deletedAt.high >>> 0
                                  ).toNumber()
                                : message.deletedAt
                return object
            }

            /**
             * Converts this Address to JSON.
             * @function toJSON
             * @memberof addresses.Address
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Address.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for Address
             * @function getTypeUrl
             * @memberof addresses.Address
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Address.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/addresses.Address'
            }

            return Address
        })()

        return addresses
    })()

    $root.contacts = (function () {
        /**
         * Namespace contacts.
         * @exports contacts
         * @namespace
         */
        var contacts = {}

        contacts.Contact = (function () {
            /**
             * Properties of a Contact.
             * @memberof contacts
             * @interface IContact
             * @property {string} id Contact id
             * @property {string} name Contact name
             * @property {string} phone Contact phone
             * @property {string|null} [externalId] Contact externalId
             * @property {number} createdAt Contact createdAt
             * @property {number|null} [updatedAt] Contact updatedAt
             * @property {number|null} [deletedAt] Contact deletedAt
             */

            /**
             * Constructs a new Contact.
             * @memberof contacts
             * @classdesc Represents a Contact.
             * @implements IContact
             * @constructor
             * @param {contacts.IContact=} [properties] Properties to set
             */
            function Contact(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * Contact id.
             * @member {string} id
             * @memberof contacts.Contact
             * @instance
             */
            Contact.prototype.id = ''

            /**
             * Contact name.
             * @member {string} name
             * @memberof contacts.Contact
             * @instance
             */
            Contact.prototype.name = ''

            /**
             * Contact phone.
             * @member {string} phone
             * @memberof contacts.Contact
             * @instance
             */
            Contact.prototype.phone = ''

            /**
             * Contact externalId.
             * @member {string} externalId
             * @memberof contacts.Contact
             * @instance
             */
            Contact.prototype.externalId = ''

            /**
             * Contact createdAt.
             * @member {number} createdAt
             * @memberof contacts.Contact
             * @instance
             */
            Contact.prototype.createdAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Contact updatedAt.
             * @member {number} updatedAt
             * @memberof contacts.Contact
             * @instance
             */
            Contact.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Contact deletedAt.
             * @member {number} deletedAt
             * @memberof contacts.Contact
             * @instance
             */
            Contact.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Creates a new Contact instance using the specified properties.
             * @function create
             * @memberof contacts.Contact
             * @static
             * @param {contacts.IContact=} [properties] Properties to set
             * @returns {contacts.Contact} Contact instance
             */
            Contact.create = function create(properties) {
                return new Contact(properties)
            }

            /**
             * Encodes the specified Contact message. Does not implicitly {@link contacts.Contact.verify|verify} messages.
             * @function encode
             * @memberof contacts.Contact
             * @static
             * @param {contacts.Contact} message Contact message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Contact.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
                writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.name)
                writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.phone)
                if (message.externalId != null && Object.hasOwnProperty.call(message, 'externalId'))
                    writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.externalId)
                writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.createdAt)
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, 'updatedAt'))
                    writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.updatedAt)
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, 'deletedAt'))
                    writer.uint32(/* id 7, wireType 0 =*/ 56).int64(message.deletedAt)
                return writer
            }

            /**
             * Encodes the specified Contact message, length delimited. Does not implicitly {@link contacts.Contact.verify|verify} messages.
             * @function encodeDelimited
             * @memberof contacts.Contact
             * @static
             * @param {contacts.Contact} message Contact message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Contact.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a Contact message from the specified reader or buffer.
             * @function decode
             * @memberof contacts.Contact
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {contacts.Contact} Contact
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Contact.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.contacts.Contact()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.id = reader.string()
                            break
                        }
                        case 2: {
                            message.name = reader.string()
                            break
                        }
                        case 3: {
                            message.phone = reader.string()
                            break
                        }
                        case 4: {
                            message.externalId = reader.string()
                            break
                        }
                        case 5: {
                            message.createdAt = reader.int64()
                            break
                        }
                        case 6: {
                            message.updatedAt = reader.int64()
                            break
                        }
                        case 7: {
                            message.deletedAt = reader.int64()
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('id'))
                    throw $util.ProtocolError("missing required 'id'", { instance: message })
                if (!message.hasOwnProperty('name'))
                    throw $util.ProtocolError("missing required 'name'", { instance: message })
                if (!message.hasOwnProperty('phone'))
                    throw $util.ProtocolError("missing required 'phone'", { instance: message })
                if (!message.hasOwnProperty('createdAt'))
                    throw $util.ProtocolError("missing required 'createdAt'", { instance: message })
                return message
            }

            /**
             * Decodes a Contact message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof contacts.Contact
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {contacts.Contact} Contact
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Contact.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a Contact message.
             * @function verify
             * @memberof contacts.Contact
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Contact.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (!$util.isString(message.id)) return 'id: string expected'
                if (!$util.isString(message.name)) return 'name: string expected'
                if (!$util.isString(message.phone)) return 'phone: string expected'
                if (message.externalId != null && message.hasOwnProperty('externalId'))
                    if (!$util.isString(message.externalId)) return 'externalId: string expected'
                if (
                    !$util.isInteger(message.createdAt) &&
                    !(
                        message.createdAt &&
                        $util.isInteger(message.createdAt.low) &&
                        $util.isInteger(message.createdAt.high)
                    )
                )
                    return 'createdAt: integer|Long expected'
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (
                        !$util.isInteger(message.updatedAt) &&
                        !(
                            message.updatedAt &&
                            $util.isInteger(message.updatedAt.low) &&
                            $util.isInteger(message.updatedAt.high)
                        )
                    )
                        return 'updatedAt: integer|Long expected'
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (
                        !$util.isInteger(message.deletedAt) &&
                        !(
                            message.deletedAt &&
                            $util.isInteger(message.deletedAt.low) &&
                            $util.isInteger(message.deletedAt.high)
                        )
                    )
                        return 'deletedAt: integer|Long expected'
                return null
            }

            /**
             * Creates a Contact message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof contacts.Contact
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {contacts.Contact} Contact
             */
            Contact.fromObject = function fromObject(object) {
                if (object instanceof $root.contacts.Contact) return object
                var message = new $root.contacts.Contact()
                if (object.id != null) message.id = String(object.id)
                if (object.name != null) message.name = String(object.name)
                if (object.phone != null) message.phone = String(object.phone)
                if (object.externalId != null) message.externalId = String(object.externalId)
                if (object.createdAt != null)
                    if ($util.Long) (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false
                    else if (typeof object.createdAt === 'string') message.createdAt = parseInt(object.createdAt, 10)
                    else if (typeof object.createdAt === 'number') message.createdAt = object.createdAt
                    else if (typeof object.createdAt === 'object')
                        message.createdAt = new $util.LongBits(
                            object.createdAt.low >>> 0,
                            object.createdAt.high >>> 0
                        ).toNumber()
                if (object.updatedAt != null)
                    if ($util.Long) (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false
                    else if (typeof object.updatedAt === 'string') message.updatedAt = parseInt(object.updatedAt, 10)
                    else if (typeof object.updatedAt === 'number') message.updatedAt = object.updatedAt
                    else if (typeof object.updatedAt === 'object')
                        message.updatedAt = new $util.LongBits(
                            object.updatedAt.low >>> 0,
                            object.updatedAt.high >>> 0
                        ).toNumber()
                if (object.deletedAt != null)
                    if ($util.Long) (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false
                    else if (typeof object.deletedAt === 'string') message.deletedAt = parseInt(object.deletedAt, 10)
                    else if (typeof object.deletedAt === 'number') message.deletedAt = object.deletedAt
                    else if (typeof object.deletedAt === 'object')
                        message.deletedAt = new $util.LongBits(
                            object.deletedAt.low >>> 0,
                            object.deletedAt.high >>> 0
                        ).toNumber()
                return message
            }

            /**
             * Creates a plain object from a Contact message. Also converts values to other types if specified.
             * @function toObject
             * @memberof contacts.Contact
             * @static
             * @param {contacts.Contact} message Contact
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Contact.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.defaults) {
                    object.id = ''
                    object.name = ''
                    object.phone = ''
                    object.externalId = ''
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.createdAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.createdAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.updatedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.updatedAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.deletedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.deletedAt = options.longs === String ? '0' : 0
                }
                if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
                if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
                if (message.phone != null && message.hasOwnProperty('phone')) object.phone = message.phone
                if (message.externalId != null && message.hasOwnProperty('externalId'))
                    object.externalId = message.externalId
                if (message.createdAt != null && message.hasOwnProperty('createdAt'))
                    if (typeof message.createdAt === 'number')
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt
                    else
                        object.createdAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.createdAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.createdAt.low >>> 0,
                                      message.createdAt.high >>> 0
                                  ).toNumber()
                                : message.createdAt
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (typeof message.updatedAt === 'number')
                        object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt
                    else
                        object.updatedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.updatedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.updatedAt.low >>> 0,
                                      message.updatedAt.high >>> 0
                                  ).toNumber()
                                : message.updatedAt
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (typeof message.deletedAt === 'number')
                        object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt
                    else
                        object.deletedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.deletedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.deletedAt.low >>> 0,
                                      message.deletedAt.high >>> 0
                                  ).toNumber()
                                : message.deletedAt
                return object
            }

            /**
             * Converts this Contact to JSON.
             * @function toJSON
             * @memberof contacts.Contact
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Contact.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for Contact
             * @function getTypeUrl
             * @memberof contacts.Contact
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Contact.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/contacts.Contact'
            }

            return Contact
        })()

        contacts.UpsertContact = (function () {
            /**
             * Properties of an UpsertContact.
             * @memberof contacts
             * @interface IUpsertContact
             * @property {string} name UpsertContact name
             * @property {string} phone UpsertContact phone
             * @property {string} externalId UpsertContact externalId
             */

            /**
             * Constructs a new UpsertContact.
             * @memberof contacts
             * @classdesc Represents an UpsertContact.
             * @implements IUpsertContact
             * @constructor
             * @param {contacts.IUpsertContact=} [properties] Properties to set
             */
            function UpsertContact(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * UpsertContact name.
             * @member {string} name
             * @memberof contacts.UpsertContact
             * @instance
             */
            UpsertContact.prototype.name = ''

            /**
             * UpsertContact phone.
             * @member {string} phone
             * @memberof contacts.UpsertContact
             * @instance
             */
            UpsertContact.prototype.phone = ''

            /**
             * UpsertContact externalId.
             * @member {string} externalId
             * @memberof contacts.UpsertContact
             * @instance
             */
            UpsertContact.prototype.externalId = ''

            /**
             * Creates a new UpsertContact instance using the specified properties.
             * @function create
             * @memberof contacts.UpsertContact
             * @static
             * @param {contacts.IUpsertContact=} [properties] Properties to set
             * @returns {contacts.UpsertContact} UpsertContact instance
             */
            UpsertContact.create = function create(properties) {
                return new UpsertContact(properties)
            }

            /**
             * Encodes the specified UpsertContact message. Does not implicitly {@link contacts.UpsertContact.verify|verify} messages.
             * @function encode
             * @memberof contacts.UpsertContact
             * @static
             * @param {contacts.UpsertContact} message UpsertContact message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpsertContact.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.name)
                writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.phone)
                writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.externalId)
                return writer
            }

            /**
             * Encodes the specified UpsertContact message, length delimited. Does not implicitly {@link contacts.UpsertContact.verify|verify} messages.
             * @function encodeDelimited
             * @memberof contacts.UpsertContact
             * @static
             * @param {contacts.UpsertContact} message UpsertContact message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpsertContact.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes an UpsertContact message from the specified reader or buffer.
             * @function decode
             * @memberof contacts.UpsertContact
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {contacts.UpsertContact} UpsertContact
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpsertContact.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.contacts.UpsertContact()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.name = reader.string()
                            break
                        }
                        case 2: {
                            message.phone = reader.string()
                            break
                        }
                        case 3: {
                            message.externalId = reader.string()
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('name'))
                    throw $util.ProtocolError("missing required 'name'", { instance: message })
                if (!message.hasOwnProperty('phone'))
                    throw $util.ProtocolError("missing required 'phone'", { instance: message })
                if (!message.hasOwnProperty('externalId'))
                    throw $util.ProtocolError("missing required 'externalId'", { instance: message })
                return message
            }

            /**
             * Decodes an UpsertContact message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof contacts.UpsertContact
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {contacts.UpsertContact} UpsertContact
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpsertContact.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies an UpsertContact message.
             * @function verify
             * @memberof contacts.UpsertContact
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpsertContact.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (!$util.isString(message.name)) return 'name: string expected'
                if (!$util.isString(message.phone)) return 'phone: string expected'
                if (!$util.isString(message.externalId)) return 'externalId: string expected'
                return null
            }

            /**
             * Creates an UpsertContact message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof contacts.UpsertContact
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {contacts.UpsertContact} UpsertContact
             */
            UpsertContact.fromObject = function fromObject(object) {
                if (object instanceof $root.contacts.UpsertContact) return object
                var message = new $root.contacts.UpsertContact()
                if (object.name != null) message.name = String(object.name)
                if (object.phone != null) message.phone = String(object.phone)
                if (object.externalId != null) message.externalId = String(object.externalId)
                return message
            }

            /**
             * Creates a plain object from an UpsertContact message. Also converts values to other types if specified.
             * @function toObject
             * @memberof contacts.UpsertContact
             * @static
             * @param {contacts.UpsertContact} message UpsertContact
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpsertContact.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.defaults) {
                    object.name = ''
                    object.phone = ''
                    object.externalId = ''
                }
                if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
                if (message.phone != null && message.hasOwnProperty('phone')) object.phone = message.phone
                if (message.externalId != null && message.hasOwnProperty('externalId'))
                    object.externalId = message.externalId
                return object
            }

            /**
             * Converts this UpsertContact to JSON.
             * @function toJSON
             * @memberof contacts.UpsertContact
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpsertContact.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for UpsertContact
             * @function getTypeUrl
             * @memberof contacts.UpsertContact
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpsertContact.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/contacts.UpsertContact'
            }

            return UpsertContact
        })()

        return contacts
    })()

    $root.photos = (function () {
        /**
         * Namespace photos.
         * @exports photos
         * @namespace
         */
        var photos = {}

        photos.Photo = (function () {
            /**
             * Properties of a Photo.
             * @memberof photos
             * @interface IPhoto
             * @property {string} id Photo id
             * @property {string} taskId Photo taskId
             * @property {string} userId Photo userId
             * @property {string} url Photo url
             * @property {number} createdAt Photo createdAt
             * @property {number|null} [updatedAt] Photo updatedAt
             * @property {number|null} [deletedAt] Photo deletedAt
             */

            /**
             * Constructs a new Photo.
             * @memberof photos
             * @classdesc Represents a Photo.
             * @implements IPhoto
             * @constructor
             * @param {photos.IPhoto=} [properties] Properties to set
             */
            function Photo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * Photo id.
             * @member {string} id
             * @memberof photos.Photo
             * @instance
             */
            Photo.prototype.id = ''

            /**
             * Photo taskId.
             * @member {string} taskId
             * @memberof photos.Photo
             * @instance
             */
            Photo.prototype.taskId = ''

            /**
             * Photo userId.
             * @member {string} userId
             * @memberof photos.Photo
             * @instance
             */
            Photo.prototype.userId = ''

            /**
             * Photo url.
             * @member {string} url
             * @memberof photos.Photo
             * @instance
             */
            Photo.prototype.url = ''

            /**
             * Photo createdAt.
             * @member {number} createdAt
             * @memberof photos.Photo
             * @instance
             */
            Photo.prototype.createdAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Photo updatedAt.
             * @member {number} updatedAt
             * @memberof photos.Photo
             * @instance
             */
            Photo.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Photo deletedAt.
             * @member {number} deletedAt
             * @memberof photos.Photo
             * @instance
             */
            Photo.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Creates a new Photo instance using the specified properties.
             * @function create
             * @memberof photos.Photo
             * @static
             * @param {photos.IPhoto=} [properties] Properties to set
             * @returns {photos.Photo} Photo instance
             */
            Photo.create = function create(properties) {
                return new Photo(properties)
            }

            /**
             * Encodes the specified Photo message. Does not implicitly {@link photos.Photo.verify|verify} messages.
             * @function encode
             * @memberof photos.Photo
             * @static
             * @param {photos.Photo} message Photo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Photo.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
                writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.taskId)
                writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.userId)
                writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.url)
                writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.createdAt)
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, 'updatedAt'))
                    writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.updatedAt)
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, 'deletedAt'))
                    writer.uint32(/* id 7, wireType 0 =*/ 56).int64(message.deletedAt)
                return writer
            }

            /**
             * Encodes the specified Photo message, length delimited. Does not implicitly {@link photos.Photo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof photos.Photo
             * @static
             * @param {photos.Photo} message Photo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Photo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a Photo message from the specified reader or buffer.
             * @function decode
             * @memberof photos.Photo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {photos.Photo} Photo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Photo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.photos.Photo()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.id = reader.string()
                            break
                        }
                        case 2: {
                            message.taskId = reader.string()
                            break
                        }
                        case 3: {
                            message.userId = reader.string()
                            break
                        }
                        case 4: {
                            message.url = reader.string()
                            break
                        }
                        case 5: {
                            message.createdAt = reader.int64()
                            break
                        }
                        case 6: {
                            message.updatedAt = reader.int64()
                            break
                        }
                        case 7: {
                            message.deletedAt = reader.int64()
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('id'))
                    throw $util.ProtocolError("missing required 'id'", { instance: message })
                if (!message.hasOwnProperty('taskId'))
                    throw $util.ProtocolError("missing required 'taskId'", { instance: message })
                if (!message.hasOwnProperty('userId'))
                    throw $util.ProtocolError("missing required 'userId'", { instance: message })
                if (!message.hasOwnProperty('url'))
                    throw $util.ProtocolError("missing required 'url'", { instance: message })
                if (!message.hasOwnProperty('createdAt'))
                    throw $util.ProtocolError("missing required 'createdAt'", { instance: message })
                return message
            }

            /**
             * Decodes a Photo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof photos.Photo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {photos.Photo} Photo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Photo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a Photo message.
             * @function verify
             * @memberof photos.Photo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Photo.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (!$util.isString(message.id)) return 'id: string expected'
                if (!$util.isString(message.taskId)) return 'taskId: string expected'
                if (!$util.isString(message.userId)) return 'userId: string expected'
                if (!$util.isString(message.url)) return 'url: string expected'
                if (
                    !$util.isInteger(message.createdAt) &&
                    !(
                        message.createdAt &&
                        $util.isInteger(message.createdAt.low) &&
                        $util.isInteger(message.createdAt.high)
                    )
                )
                    return 'createdAt: integer|Long expected'
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (
                        !$util.isInteger(message.updatedAt) &&
                        !(
                            message.updatedAt &&
                            $util.isInteger(message.updatedAt.low) &&
                            $util.isInteger(message.updatedAt.high)
                        )
                    )
                        return 'updatedAt: integer|Long expected'
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (
                        !$util.isInteger(message.deletedAt) &&
                        !(
                            message.deletedAt &&
                            $util.isInteger(message.deletedAt.low) &&
                            $util.isInteger(message.deletedAt.high)
                        )
                    )
                        return 'deletedAt: integer|Long expected'
                return null
            }

            /**
             * Creates a Photo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof photos.Photo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {photos.Photo} Photo
             */
            Photo.fromObject = function fromObject(object) {
                if (object instanceof $root.photos.Photo) return object
                var message = new $root.photos.Photo()
                if (object.id != null) message.id = String(object.id)
                if (object.taskId != null) message.taskId = String(object.taskId)
                if (object.userId != null) message.userId = String(object.userId)
                if (object.url != null) message.url = String(object.url)
                if (object.createdAt != null)
                    if ($util.Long) (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false
                    else if (typeof object.createdAt === 'string') message.createdAt = parseInt(object.createdAt, 10)
                    else if (typeof object.createdAt === 'number') message.createdAt = object.createdAt
                    else if (typeof object.createdAt === 'object')
                        message.createdAt = new $util.LongBits(
                            object.createdAt.low >>> 0,
                            object.createdAt.high >>> 0
                        ).toNumber()
                if (object.updatedAt != null)
                    if ($util.Long) (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false
                    else if (typeof object.updatedAt === 'string') message.updatedAt = parseInt(object.updatedAt, 10)
                    else if (typeof object.updatedAt === 'number') message.updatedAt = object.updatedAt
                    else if (typeof object.updatedAt === 'object')
                        message.updatedAt = new $util.LongBits(
                            object.updatedAt.low >>> 0,
                            object.updatedAt.high >>> 0
                        ).toNumber()
                if (object.deletedAt != null)
                    if ($util.Long) (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false
                    else if (typeof object.deletedAt === 'string') message.deletedAt = parseInt(object.deletedAt, 10)
                    else if (typeof object.deletedAt === 'number') message.deletedAt = object.deletedAt
                    else if (typeof object.deletedAt === 'object')
                        message.deletedAt = new $util.LongBits(
                            object.deletedAt.low >>> 0,
                            object.deletedAt.high >>> 0
                        ).toNumber()
                return message
            }

            /**
             * Creates a plain object from a Photo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof photos.Photo
             * @static
             * @param {photos.Photo} message Photo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Photo.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.defaults) {
                    object.id = ''
                    object.taskId = ''
                    object.userId = ''
                    object.url = ''
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.createdAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.createdAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.updatedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.updatedAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.deletedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.deletedAt = options.longs === String ? '0' : 0
                }
                if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
                if (message.taskId != null && message.hasOwnProperty('taskId')) object.taskId = message.taskId
                if (message.userId != null && message.hasOwnProperty('userId')) object.userId = message.userId
                if (message.url != null && message.hasOwnProperty('url')) object.url = message.url
                if (message.createdAt != null && message.hasOwnProperty('createdAt'))
                    if (typeof message.createdAt === 'number')
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt
                    else
                        object.createdAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.createdAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.createdAt.low >>> 0,
                                      message.createdAt.high >>> 0
                                  ).toNumber()
                                : message.createdAt
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (typeof message.updatedAt === 'number')
                        object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt
                    else
                        object.updatedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.updatedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.updatedAt.low >>> 0,
                                      message.updatedAt.high >>> 0
                                  ).toNumber()
                                : message.updatedAt
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (typeof message.deletedAt === 'number')
                        object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt
                    else
                        object.deletedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.deletedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.deletedAt.low >>> 0,
                                      message.deletedAt.high >>> 0
                                  ).toNumber()
                                : message.deletedAt
                return object
            }

            /**
             * Converts this Photo to JSON.
             * @function toJSON
             * @memberof photos.Photo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Photo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for Photo
             * @function getTypeUrl
             * @memberof photos.Photo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Photo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/photos.Photo'
            }

            return Photo
        })()

        return photos
    })()

    return $root
})
