import { AdvancedSearchOrdersDto } from 'api/dto/AdvancedSearchOrdersDto';
import { ASOrder } from 'api/response/AdvancedSearchResponse';
import { OrderWithRel } from 'api/response/GetJobListResponse';
import { JobWithRel, SimpleSearchResponse } from 'api/response/SimpleSearchResponse';
import { Team } from 'api/types/Team';
import { frameDetailStore } from 'components/FrameDetail/store';
import { makeAutoObservable } from 'mobx';
import { isHydrated, makePersistable } from 'mobx-persist-store';
import { OrderRequests } from 'api/Order';
import { mainStore } from 'stores/MainStore';
import { dataStore } from 'stores/DataStore';
import i18n from 'i18next';

type TeamWithJobs = {
  team: Team;
  jobs: JobWithRel[];
};

class SearchStore {
  searchValue = '';
  advancedSearchValue = '';
  filteredOrders: OrderWithRel[] = [];
  searchResponse: SimpleSearchResponse | null = null;
  advancedSearchResponse: ASOrder[] | null = null;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'SearchStore',
      properties: ['filteredOrders'],
      storage: window.localStorage,
    }).catch((error) => error && console.error(error));
  }

  // Getters
  get isSynchronized(): boolean {
    return isHydrated(this);
  }

  get filteredOrderIds(): string[] {
    return this.filteredOrders.map((order) => order.order.id);
  }

  get serverTeamsWithJobs(): TeamWithJobs[] {
    if (!this.searchResponse?.teams || !this.searchResponse.jobs) return [];
    const result: any[] = [];

    this.searchResponse.teams.forEach((team) => {
      this.searchResponse?.jobs.forEach((job) => {
        if (team.id === job.job.teamId) {
          const existing = result.find((item) => item.team.id === team.id);
          if (existing) {
            existing.jobs.push(job);
          } else {
            result.push({ team, jobs: [job] });
          }
        }
      });
    });

    result.sort((a, b) => {
      return dataStore.teamExistsLocally(a.team.id) ? -1 : 1;
    });

    return result;
  }

  // Setters
  setFilteredOrders(val: OrderWithRel[]) {
    this.filteredOrders = val;
  }

  setSearchValue(val: string) {
    this.searchValue = val;
    if (!val) this.setFilteredOrders([]);
  }

  setAdvancedSearchValue(val: string) {
    this.advancedSearchValue = val;
  }

  setSearchResponse(val: SimpleSearchResponse | null) {
    this.searchResponse = val;
  }

  setAdvancedSearchResponse(val: ASOrder[] | null) {
    this.advancedSearchResponse = val;
  }

  // Actions
  filterOrders() {
    if (!this.searchValue || !dataStore.getJobListResponse) {
      this.setFilteredOrders([]);
      return;
    }

    const filteredOrders = dataStore.getJobListResponse.flatMap((job) => {
      return job.orders.filter((order) =>
        order.order.externalId.toLowerCase().includes(this.searchValue.toLowerCase()),
      );
    });
    this.setFilteredOrders(filteredOrders);
    mainStore.clearAlerts();
  }

  resetSearch() {
    this.setSearchValue('');
    this.setFilteredOrders([]);
  }

  // Requests
  async searchOrders(externalId: string): Promise<void> {
    if (!externalId.length) return;
    try {
      const searchEncoded = encodeURIComponent(externalId.toUpperCase());
      const resp = await OrderRequests.search(searchEncoded);

      if (resp.data && resp.data.teams.length > 0 && resp.data.jobs.length > 0) {
        this.setSearchResponse(resp.data);
        frameDetailStore.updateOrderInfo();
      } else {
        mainStore.pushAlert('error', i18n.t('errorPhrases:notFound'));
        this.setSearchResponse(null);
      }
    } catch (error) {
      mainStore.pushAlert('error', i18n.t('errorPhrases:notFound'));
      this.setSearchResponse(null);
      error && console.error(error);
      return Promise.reject();
    }
  }

  async advancedSearchOrders(data: AdvancedSearchOrdersDto): Promise<void> {
    try {
      const resp = await OrderRequests.advancedSearch(data);
      const orders = resp.data;
      if (orders.length > 0) {
        mainStore.clearAlerts();
        this.setAdvancedSearchResponse(orders);
      } else {
        mainStore.pushAlert('error', i18n.t('phrases:noorderFound'));
        this.setAdvancedSearchResponse([]);
      }
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'advancedSearchOrders');
      return Promise.reject();
    }
  }
}

export const searchStore = new SearchStore();
