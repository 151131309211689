import { GetJobListResponse } from 'api/response/GetJobListResponse';
import { ExpandableHeader } from 'components/ExpandableHeader/ExpandableHeader';
import Job from 'components/Job';
import { useState, useCallback, FC } from 'react';
import { Droppable } from '@hello-pangea/dnd';
import { generateHtmlClasses } from 'utils/htmlClasses';

type IntervalProps = {
  orders: GetJobListResponse;
  title: string;
  index: number;
  isExpandedByDefault: boolean;
};

export const Interval: FC<IntervalProps> = ({ orders, title, index, isExpandedByDefault }) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedByDefault);
  const handleExpand = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  return (
    <div className="interval">
      <ExpandableHeader
        titleClass="interval-headerTitle"
        isExpanded={isExpanded}
        onClick={handleExpand}
        title={title}
      />
      <Droppable droppableId="jobSlotsUnassigned" type="job" isDropDisabled={true}>
        {(provided) => (
          <div
            className={generateHtmlClasses('interval-body', {
              __expanded: isExpanded,
            })}
            ref={provided.innerRef}
          >
            <Job
              avoidBatching
              isSlot
              jobId={orders.job.id}
              orders={orders.orders || []}
              handleToggleExpanding={() => 0}
              isExpanded={true}
              index={index}
              source={'unassigned'}
            />
          </div>
        )}
      </Droppable>
    </div>
  );
};
