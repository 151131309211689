import IMask from 'imask';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { AnyMasked, IMaskInput } from 'react-imask';
import InputText from './InputText';
import { authStore } from 'stores/AuthStore';

type InputPhoneProps = {
  value: string;
  onValidate: (flag: boolean) => void;
  onChange?: (val: string) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
};

const maskData: (IMask.AnyMaskedOptions & { startsWith: string })[] = [
  {
    mask: '+00 (0000) 000-000',
    startsWith: '44',
    lazy: false,
  },
  {
    mask: '+0 (000) 000-00-00',
    startsWith: '7',
    lazy: false,
  },
  {
    mask: '+000-(00)-0000000',
    startsWith: '971',
    lazy: false,
  },
  {
    mask: '+0000000000[00000]',
    startsWith: '',
  },
];

const maskDispatch = (appended: string, dynamicMasked: AnyMasked): AnyMasked => {
  const number = (dynamicMasked.value + appended).replace(/\D/g, '');
  return dynamicMasked.compiledMasks.find((m: AnyMasked) => number.indexOf(m.startsWith) === 0);
};

export default observer(
  ({ value, onValidate, onChange, className, disabled = false }: InputPhoneProps) => {
    const placeholder = authStore.companyConfig?.phonePlaceholder;
    const [isValid, setIsValid] = useState(false);
    const handleChange = (val: string, mask: AnyMasked) => {
      if (onChange) onChange(val);
      onValidate(mask.masked.isComplete);
      setIsValid(mask.masked.isComplete);
    };

    useEffect(() => {
      if (!value) return;
      const masked = IMask.createMask({
        mask: maskData,
        dispatch: maskDispatch,
      });
      masked.resolve(value);
      onValidate(masked.isComplete);
      setIsValid(masked.isComplete);
      //eslint-disable-next-line
    }, []);

    return (
      <InputText className={className} value={value} placeholder={placeholder}>
        {isValid && <div className="icon icon-check input-text__check" />}
        <IMaskInput
          className="input-text"
          placeholder={placeholder}
          mask={maskData}
          value={value}
          unmask={true}
          type="tel"
          onAccept={handleChange}
          dispatch={maskDispatch}
          disabled={disabled}
          autoComplete="new-password"
          id="new-password"
          name="noSearchField"
        />
      </InputText>
    );
  },
);
