import { MOBILE_EMPTY_BLOCK_HEIGHT, MOBILE_WIDTH } from 'config';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

export default observer(() => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH}px)` });

  if (isMobile) {
    return <div style={{ height: MOBILE_EMPTY_BLOCK_HEIGHT }}/>;
  }
  else {
    return null;
  }
});
