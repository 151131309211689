import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateHtmlClasses } from 'utils/htmlClasses';

interface MultilineEllipsisProps {
  text: string;
  seeAllButton?: boolean;
}

export default observer(({ text, seeAllButton }: MultilineEllipsisProps) => {
  const { t } = useTranslation();
  const [isButtonShown, setIsButtonShown] = useState<boolean>(true);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const textHeight = textRef.current?.clientHeight || 0;
    setIsButtonShown(textHeight > 20);
  }, []);

  const handleSeeAllClick = () => setIsButtonShown(false);

  return (
    <div className={generateHtmlClasses('multiline-ellipsis', { _full: !isButtonShown })}>
      <div className="multiline-ellipsis__text" ref={textRef}>
        {text}
        {seeAllButton && isButtonShown && (
          <div className="multiline-ellipsis__button" onClick={handleSeeAllClick}>
            {t('common:seeAll')}
          </div>
        )}
      </div>
    </div>
  );
});
