import { observer } from 'mobx-react-lite';
import React, { useCallback, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useOutsideClicker } from 'hooks/useOutsideClicker';
import { dateTimeStore } from 'stores/DateTimeStore';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
  ResetIcon,
} from 'icons';
import { generateHtmlClasses } from 'utils/htmlClasses';

export const DEFAULT_DATEPICKER_VALUE = { start: null, end: null };

export type DateRange = {
  start: Date | null;
  end: Date | null;
};

interface DatePickerProps {
  onChange: (range: DateRange) => void;
  label?: string;
  className?: string;
  placeholder?: string;
}

export default observer(({ label, className, onChange, placeholder }: DatePickerProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const popupRef = useRef<HTMLDivElement>(null);
  const displayRef = useRef<HTMLDivElement>(null);

  useOutsideClicker(() => {
    setIsOpen(false);
  }, [popupRef, displayRef]);

  const handleRangeChange = useCallback(
    (dates: [Date | null, Date | null]) => {
      const [start, end] = dates;
      start?.setHours(0, 0, 0);
      end?.setHours(0, 0, 0);
      // avoid bug with react-date-picker
      if (!startDate) start?.setDate(start?.getDate() + 1);
      setStartDate(start);
      setEndDate(end);
      onChange({ start, end });
    },
    [onChange, startDate],
  );

  const startDateStr = startDate ? dateTimeStore.getFormattedFullDate(startDate.getTime()) : '__';
  const endDateStr = endDate ? dateTimeStore.getFormattedFullDate(endDate.getTime()) : '__';

  const displayValue =
    !startDate && !endDate
      ? placeholder || 'Start date – End date'
      : `${startDateStr} – ${endDateStr}`;

  const handlePopupToggle = () => setIsOpen((prev) => !prev);
  const handlePopupOpen = () => setIsOpen(true);

  const handleResetValue = () => {
    setIsOpen(false);
    setStartDate(null);
    setEndDate(null);
    handleRangeChange([null, null]);
  };

  const isAtLeastOneTimeSelected = startDate || endDate;

  return (
    <div className="input-wrapper">
      {label && <div className="input-wrapper__label">{label}</div>}
      <div className={generateHtmlClasses('input-date', className)}>
        <div className="input-date__display display" ref={displayRef}>
          <input
            readOnly
            className={generateHtmlClasses('display__input', { _active: startDate || endDate })}
            value={displayValue}
            onClick={handlePopupOpen}
          />
          <div
            className={generateHtmlClasses('display__icon _calendar', {
              _active: !isAtLeastOneTimeSelected,
            })}
            onClick={handlePopupToggle}
          >
            <CalendarIcon />
          </div>
          <div
            className={generateHtmlClasses('display__icon _reset', {
              _active: isAtLeastOneTimeSelected,
            })}
            onClick={handleResetValue}
          >
            <ResetIcon />
          </div>
        </div>
        <div
          className={generateHtmlClasses('input-date__input-popup input-popup', {
            _opened: isOpen,
          })}
          ref={popupRef}
        >
          <ReactDatePicker
            selected={startDate}
            onChange={handleRangeChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            todayButton
            inline
            maxDate={dateTimeStore.today}
            renderCustomHeader={({
              date,
              decreaseMonth,
              decreaseYear,
              increaseMonth,
              increaseYear,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
              prevYearButtonDisabled,
              nextYearButtonDisabled,
            }) => (
              <div className="react-datepicker__navigation navigation">
                <div className="navigation__buttons buttons _prev">
                  <button
                    className="buttons__button _year"
                    onClick={decreaseYear}
                    disabled={prevYearButtonDisabled}
                  >
                    <DoubleArrowLeftIcon />
                  </button>
                  <button
                    className="buttons__button _month"
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    <ArrowLeftIcon />
                  </button>
                </div>
                <div className="navigation__date">
                  {dateTimeStore.getFormattedMonthYear(date.getTime())}
                </div>
                <div className="navigation__buttons buttons _next">
                  <button
                    className="buttons__button _month"
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    <ArrowRightIcon />
                  </button>
                  <button
                    className="buttons__button _year"
                    onClick={increaseYear}
                    disabled={nextYearButtonDisabled}
                  >
                    <DoubleArrowRightIcon />
                  </button>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
});
