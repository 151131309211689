export enum ParcelStatus {
  ABSTRACT = 'ABSTRACT',
  WAITING_FOR_PICKUP = 'WAITING_FOR_PICKUP',
  PICKED = 'PICKED',
  DELIVERED = 'DELIVERED',
  REJECTED = 'REJECTED',
  RETURNED = 'RETURNED',
  MISSING = 'MISSING',
  NOT_RETURNED = 'NOT_RETURNED'
}

export enum ParcelContentKind {
  GROCERY = 'GROCERY',
  ALCOHOL = 'ALCOHOL',
  TOBACCO = 'TOBACCO',
  PHARMA = 'PHARMA',
  CHEMICAL = 'CHEMICAL',
  FROZEN = 'FROZEN',
  OWN_KITCHEN = 'OWN_KITCHEN'
}

export interface Parcel {
  id: string;
  orderId: string;
  status: ParcelStatus;
  contentKind: ParcelContentKind;
  barcode: string;
  externalId: string;
  ageRestriction: number;
  ageConfirmed: boolean;
  weight: number;
  height: number;
  width: number;
  length: number;
  type: string;
  createdAt: number;
  updatedAt: number;
  deletedAt: number;
  image: string;
  name: string;
  itemId: string;
}
