import AddTeamForm from 'components/Forms/AddTeamForm';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { settingsStore } from './PopupSettings/Warehouses/store';
import { mainStore } from 'stores/MainStore';
import Popover from './Popover';
import { useTranslation } from 'react-i18next';

export default observer(() => {
  const { t } = useTranslation();

  const handlePopoverDismiss = () => {
    mainStore.setIsShowPopoverAddTeam(false);
    if (settingsStore.selectedWarehouse) mainStore.setIsShowPopupSettings(true);
  };

  return (
    <Popover className='popover-add-team _in-top' isShow={mainStore.isShowPopoverAddTeam}>
      <div className='popover-title'>
        <div className='popover-title__name'>{t('common:newTeam')}</div>
        <div className='popover-title__icon icon icon-close' onClick={handlePopoverDismiss} />
      </div>
      <AddTeamForm className='popover-add-team-form' onCancelClick={handlePopoverDismiss}/>
    </Popover>
  );
});
