import { TaskPriority } from 'api/types/Task';

export const sortNumbers = (a: number, b: number, sorting: 'asc' | 'desc' = 'asc'): number => {
  const result = a < b ? -1 : a > b ? 1 : 0;
  if (!result || sorting === 'asc') return result;
  return result * -1;
};

export const sortAlphabet = (a: string, b: string): number => {
  if (!a || !b) return 0;
  return a.localeCompare(b);
};

export const sortKindPriority = (a: string, b: string): number => {
  if (TaskPriority[a] === TaskPriority[b]) return 0;
  return TaskPriority[a] < TaskPriority[b] ? -1 : 1;
};
