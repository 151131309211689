import type { TaskWithRel } from 'api/response/GetJobListResponse';
import OrderInfoBar from 'components/Map/OrderInfoBar';
import { searchStore } from 'components/Search/store';
import { observer } from 'mobx-react-lite';
import { authStore } from 'stores/AuthStore';
import { dataStore } from 'stores/DataStore';
import { mainStore } from 'stores/MainStore';
import { generateHtmlClasses } from 'utils/htmlClasses';

export type TaskPinProps = {
  lat: number;
  lng: number;
  jobId: string;
  orderId: string;
  orderExternalId: string;
  task: TaskWithRel;
  showInfoBar?: boolean;
  isMobile?: boolean;
};

export const TaskPin = observer(
  ({
    orderExternalId,
    jobId,
    orderId,
    task,
    isMobile = false,
    showInfoBar = true,
  }: TaskPinProps) => {
    const isActive = mainStore.selectedOrder.taskId === task?.task?.id;
    const handleTaskIconClick = () => {
      if (dataStore.orderExistsLocally(orderId)) {
        searchStore.resetSearch();
        mainStore.setSelectedOrder({
          externalId: orderExternalId,
          id: orderId,
          jobId,
          taskId: task.task.id,
          source: 'map',
        });
      }
      mainStore.setSelectedPointCoords({
        lat: task?.address?.latitude,
        lng: task?.address?.longitude,
      });
      if (!isMobile) {
        authStore.setIsFrameTeamsHidden(false);
      }
      mainStore.setActiveDynamicFrame('detail');
      mainStore.setIsMapDragged(false);
    };

    return (
      <div className={generateHtmlClasses('map-pin _task', { _active: isActive })}>
        <img
          src={dataStore.getTaskStatusIconV2(task?.task?.state)}
          onClick={handleTaskIconClick}
          alt=""
        />
        {showInfoBar && task ? (
          <OrderInfoBar externalId={orderExternalId} orderId={orderId} jobId={jobId} task={task} />
        ) : null}
      </div>
    );
  },
);
