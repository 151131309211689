interface Params {
  path?: string;
  expires?: Date | number | string;
  'max-age'?: number;
}

export const setCookie = (name: string, value: string | number | boolean, params: Params = {}) => {
  params = { path: '/', ...params };

  if (params.expires instanceof Date) {
    params.expires = params.expires.toUTCString();
  }

  const init = encodeURIComponent(name) + '=' + encodeURIComponent(value);

  document.cookie = Object.entries(params).reduce((acc, [key, value]) => {
    return `${acc}; ${key}=${value}`;
  }, init);
};

export const getCookie = (name: string) => {
  const result =
    document.cookie
      .split(';')
      .map((item) => item.trim().split('='))
      .find(([key]) => key === name) || [];

  return result ? decodeURIComponent(result[1] ?? '') : undefined;
};

export const deleteCookie = (name: string) => {
  setCookie(name, '', { 'max-age': -1 });
};

export const getCookieValue = (name: string) => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = document.cookie.match(regex);
  if (match) {
    return match[2];
  }
};
