import { JobState } from 'api/types/Job';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import FilterForm from 'components/Forms/FilterForm';
import Popup from './Popup';
import { mainStore } from 'stores/MainStore';
import { authStore } from 'stores/AuthStore';
import { useTranslation } from 'react-i18next';

export default observer(() => {
  const { t } = useTranslation();
  const [filterState, setFilterState] = useState<Record<JobState, boolean>>(
    authStore.jobStateFilter,
  );

  useEffect(() => {
    if (!mainStore.isShowPopupFilter) return;
    setFilterState(authStore.jobStateFilter);
    //eslint-disable-next-line
  }, [mainStore.isShowPopupFilter]);

  const handlePopupDismiss = () => mainStore.setIsShowPopupFilter(false);

  return (
    <Popup
      className="popup-filter"
      isShow={mainStore.isShowPopupFilter}
      onBackdropDismiss={handlePopupDismiss}
      mobileCentered
    >
      <div className="popup__title">{t('common:orderFilter')}</div>
      <FilterForm
        onDismissClick={handlePopupDismiss}
        filterState={filterState}
        setFilterState={setFilterState}
      />
    </Popup>
  );
});
