import type { TaskWithRel } from 'api/response/GetJobListResponse';
import type { Order } from 'api/types/Order';
import { TaskKind, TaskState } from 'api/types/Task';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import type { DraggableProvided } from '@hello-pangea/dnd';
import { dataStore } from 'stores/DataStore';
import lockIcon from 'assets/img/lock.svg';
import { formatDistanceToNowStrict } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { generateHtmlClasses } from 'utils/htmlClasses';
import { sortNumbers } from 'utils/sorting';

interface OrderBase {
  order: Order;
  tasks: TaskWithRel[];
  className?: string;
  onClick?: () => void;
  provided?: DraggableProvided;
  isAssigned?: boolean;
  progress: number;
  isSlot?: boolean;
}

export default observer(
  ({ order, tasks, className, onClick, provided, progress, isSlot, isAssigned }: OrderBase) => {
    const { t } = useTranslation();
    const [orderTask, setOrderTask] = useState<TaskWithRel | null>(null);
    const [orderCustomerTask, setOrderCustomerTask] = useState<TaskWithRel | null>(null);
    const [isOrderCompleted, setIsOrderCompleted] = useState<boolean>(false);
    const [isVisibleTooltip, setIsVisibleTooltip] = useState<boolean>(false);
    const isShownSlotTitle = !!order.slotDeliveryId && !isSlot;
    const isStarted = order.startBy * 1000 < new Date().getTime();
    const canHandleTooltip = !isStarted && isShownSlotTitle && !isAssigned;

    const progressColor = useMemo<string>(() => {
      if (progress >= 50) return '_green';
      if (progress >= 30) return '_yellow';
      return '_red';
    }, [progress]);

    useEffect(() => {
      const finishedTaskStates = [TaskState.COMPLETED, TaskState.CANCELED, TaskState.FAILED];
      const result = tasks.slice().sort(({ task: a }, { task: b }) => {
        const keys = Object.keys(TaskKind);
        return sortNumbers(keys.indexOf(a.kind), keys.indexOf(b.kind));
      });
      const task = result[1] || result[0];

      setOrderTask(task);
      // Order customer task contains contact and address info of original customer (we find it by comparing original contact id of a customer)
      setOrderCustomerTask(result.find((task) => task.contact.id === order.contactId) || task);
      setIsOrderCompleted(task?.task ? finishedTaskStates.includes(task.task.state) : false);
    }, [order, tasks]);

    const taskIcon = dataStore.getTaskStatusIconV2(orderTask?.task.state as TaskState);
    const etaMin = dataStore.getEta(order.deliverByMin);
    const etaMax = dataStore.getEta(order.deliverByMax);

    const handleMouseOn = (isOver: boolean) => () => {
      if (!isStarted && isShownSlotTitle) {
        setIsVisibleTooltip(isOver);
      }
    };

    return (
      <div
        className={generateHtmlClasses('order', className)}
        onClick={onClick}
        ref={provided?.innerRef}
        {...provided?.dragHandleProps}
        {...provided?.draggableProps}
      >
        {!isOrderCompleted && (
          <div
            className={generateHtmlClasses('order__bg', progressColor)}
            style={{ width: `${progress}%` }}
          />
        )}
        <div className="order__top">
          <div className="order__icon">{taskIcon !== null && <img src={taskIcon} alt="" />}</div>
          <div
            onMouseOver={canHandleTooltip ? handleMouseOn(true) : undefined}
            onMouseLeave={canHandleTooltip ? handleMouseOn(false) : undefined}
            className={generateHtmlClasses(`order__number`, {
              isSlot: isShownSlotTitle && !isStarted,
            })}
          >
            {order.externalId}
          </div>
          <div className="order__address">{orderCustomerTask?.address.name || ''}</div>
          <img src={lockIcon} alt="" className="order__lock_icon" />
        </div>
        <div className="order__bottom">
          <div className="order__count">{t('bags', { num: orderTask?.parcels.length || 0 })}</div>
          <div className="order__arrow" />
          <div className="order__name">{orderCustomerTask?.contact.name}</div>
          <div className="order__eta">
            ETA {etaMin}/{etaMax}
          </div>
        </div>
        {isShownSlotTitle && isVisibleTooltip && (
          <div className="order__tooltip">
            <span>
              {t('phrases:orderWillBeAssignIn')}{' '}
              {formatDistanceToNowStrict(order.startBy * 1000, {
                addSuffix: false,
              })}
            </span>
          </div>
        )}
      </div>
    );
  },
);
