import { RequestAPI } from 'api/Requests';
import { AxiosResponse } from 'axios';
import { ADMIN_V1_API_PREFIX, API_URL_2 } from 'config';
import { GetTripDto } from './dto/GetTripDto';

interface JobRequestsInterface {
  getTrip(jobId: string): Promise<AxiosResponse<GetTripDto>>;
}

export const JobRequests: JobRequestsInterface = {
  getTrip: (jobId) => RequestAPI.post(API_URL_2 + ADMIN_V1_API_PREFIX + `jobs/trip/${jobId}`),
};
