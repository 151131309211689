import { TaskWithRel } from 'api/response/GetJobListResponse';
import { TaskKind } from 'api/types/Task';
import { makeAutoObservable } from 'mobx';
import { dataStore, OrderInfo } from 'stores/DataStore';
import { mainStore } from 'stores/MainStore';
import { sortAlphabet } from 'utils/sorting';

export type OrderTasks = Record<TaskKind, TaskWithRel | null>;

class FrameDetailStore {
  orderInfo: OrderInfo | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  // Getters
  public get tasks(): OrderTasks | null {
    if (!this.orderInfo?.tasks || !this.orderInfo.tasks.length) {
      return null;
    }

    const orderTasks: OrderTasks = {
      PICK_UP: null,
      DROP_OFF: null,
      RETURN: null,
    };
    this.orderInfo.tasks
      .slice()
      .sort(({ task: a }, { task: b }) => sortAlphabet(a.kind.toString(), b.kind.toString()))
      .forEach((task) => {
        orderTasks[TaskKind[task.task.kind]] = task;
      });

    return orderTasks;
  }

  // Setters
  setOrderInfo(val: OrderInfo | null) {
    this.orderInfo = val;
  }

  // Functions
  updateOrderInfo() {
    const orderInfo =
      dataStore.getServerOrderById(mainStore.selectedOrder.id, mainStore.selectedOrder.jobId) ||
      dataStore.getLocalOrderById(mainStore.selectedOrder.id, mainStore.selectedOrder.jobId);

    if (orderInfo?.order.id !== mainStore.selectedOrder.id) {
      return;
    }

    this.setOrderInfo(orderInfo);
  }
}

export const frameDetailStore = new FrameDetailStore();
