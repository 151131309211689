import { CourierWithRel, TaskWithRel } from 'api/response/GetJobListResponse';
import { Photo } from 'api/types/Photo';
import { capitalizeFirstLetter } from 'common/capitalize-word';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { mainStore } from 'stores/MainStore';
import { sortKindPriority, sortNumbers } from 'utils/sorting';

interface CourierProps {
  courier: CourierWithRel;
  tasks?: TaskWithRel[] | null;
}

export default observer(({ courier, tasks }: CourierProps) => {
  const { t } = useTranslation();
  if (Object.keys(courier.courier).length === 0 || !courier.user.contact) {
    return null;
  }

  const handlePhoneCopy = () => copyPhoneNumber(courier.user.contact.phone);

  const copyPhoneNumber = async (phone: string) => {
    try {
      await navigator.clipboard.writeText(phone);
    } catch (error) {
      error && console.error(error);
      mainStore.pushAlert('error', t('errorPhrases:failedCopyPhone'));
    }
  };

  return (
    <div className="order-details__item item _courier">
      <div className="item__title title">
        <span className="title__sub">{t('common:courier')}</span>
        <span className="title__main">{courier.user.contact.name}</span>
        <div className="title__icon icon icon-chevron-up" />
      </div>
      <div className="item__content content">
        <div className="content__header">
          <div className="phone">
            <span className="phone__value">{courier.user.contact.phone}</span>
            <div className="phone__copy icon icon-copy" onClick={handlePhoneCopy} />
            <span className="phone__status">
              {capitalizeFirstLetter(courier.courier.status, true)}
            </span>
          </div>
        </div>
        {tasks && tasks.length > 0 && (
          <div className="content__body">
            <div className="order-tasks">
              {tasks
                .sort(({ task: a }, { task: b }) => sortKindPriority(a.kind, b.kind))
                .map((task: TaskWithRel) => (
                  <div key={task.task.id} className="order-task">
                    <div className="order-task__title">{task.task.kind.replaceAll('_', ' ')}</div>
                    {task?.photos && task.photos.length > 0 && (
                      <div className="order-task__photos photos">
                        {task.photos
                          .sort(({ createdAt: a }, { createdAt: b }) => sortNumbers(a, b))
                          .map((photo: Photo) => (
                            <img
                              className="photos__photo"
                              key={photo.id}
                              src={photo.url}
                              onClick={() => mainStore.setSelectedImageUrl(photo.url)}
                              alt=""
                            />
                          ))}
                      </div>
                    )}
                    <div className="order-task__comment">{task.task?.commentary || '–'}</div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
