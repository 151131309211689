import { ResultTableRow } from 'components/Search/PopupAdvanced/store';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import TextWithHighlight from 'components/TextWithHighlight';
import { searchStore } from 'components/Search/store';
import { useTranslation } from 'react-i18next';
import { generateHtmlClasses } from 'utils/htmlClasses';

interface RowProps {
  row: ResultTableRow;
}

export default observer(({ row }: RowProps) => {
  const { t } = useTranslation();
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const handleSelectedToggle = () => setIsSelected((prev) => !prev);

  const { createdAt, deliveredAt, deliveryTime, order, warehouse, courierName, status, eta, sum } =
    row;

  return (
    <div
      className={generateHtmlClasses('result-table__row', { _selected: isSelected })}
      onClick={handleSelectedToggle}
    >
      <div className={generateHtmlClasses('result-table__cell _creation', { _empty: !createdAt })}>
        {createdAt && (
          <>
            {createdAt[0]} {createdAt[1]}
          </>
        )}
      </div>
      <div
        className={generateHtmlClasses('result-table__cell _delivery', { _empty: !deliveredAt })}
      >
        {deliveredAt && (
          <>
            {deliveredAt[0]} {deliveredAt[1]}
          </>
        )}
      </div>
      <div
        className={generateHtmlClasses('result-table__cell _delivery-time', {
          _empty: !deliveryTime,
        })}
      >
        {deliveryTime}
      </div>
      <div className="result-table__cell _order-id">
        <TextWithHighlight
          text={order.id}
          highlightPart={searchStore.advancedSearchValue}
          takeOnlyFirst
          highlightColor={'green'}
        />
        <br />
        <span className="_secondary">
          {order.posInJob}/{order.totalCountInJob} {t('phrases:inJob')}
        </span>
      </div>
      <div className={generateHtmlClasses('result-table__cell _warehouse', { _empty: !warehouse })}>
        {warehouse.name || ''}
        <br />
        <span className="_secondary">{warehouse.barcode}</span>
      </div>
      <div className={generateHtmlClasses('result-table__cell _courier', { _empty: !courierName })}>
        {courierName || ''}
      </div>
      <div className="result-table__cell _status">{status}</div>
      <div className="result-table__cell _eta">
        {eta.min}/{eta.max}
      </div>
      <div className={generateHtmlClasses('result-table__cell _sum', { _empty: !sum })}>{sum}</div>
    </div>
  );
});
