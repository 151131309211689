import { ReactComponent as Unassigned } from 'assets/img/unassigned.svg';
import { ReactComponent as Teamless } from 'assets/img/teamless.svg';
import { ReactComponent as Burger } from 'assets/img/burger.svg';
import { ReactComponent as Teams } from 'assets/img/teams.svg';
import { ReactComponent as Map } from 'assets/img/map.svg';
import { ReactComponent as ArrowLeft } from 'assets/img/arrow-left-v2.svg';
import { ReactComponent as Cross } from 'assets/img/cross.svg';
import { ReactComponent as Search } from 'assets/img/search.svg';
import { ReactComponent as Calendar } from 'assets/img/calendar.svg';
import { ReactComponent as ArrowRight } from 'assets/img/arrow-right.svg';
import { ReactComponent as DoubleArrowRight } from 'assets/img/double-arrow-right.svg';
import { ReactComponent as DoubleArrowLeft } from 'assets/img/double-arrow-left.svg';
import { ReactComponent as Reset } from 'assets/img/cross.svg';
import { ReactComponent as Sort } from 'assets/img/sort.svg';
import { ReactComponent as Pizza } from 'assets/img/pizza.svg';
import { ReactComponent as Clock } from 'assets/img/clock.svg';
import { ReactComponent as Plus } from 'assets/img/plus.svg';

export const UnassignedIcon = Unassigned;
export const TeamlessIcon = Teamless;
export const BurgerIcon = Burger;
export const TeamsIcon = Teams;
export const MapIcon = Map;
export const ArrowLeftIcon = ArrowLeft;
export const CrossIcon = Cross;
export const SearchIcon = Search;
export const CalendarIcon = Calendar;
export const ArrowRightIcon = ArrowRight;
export const DoubleArrowRightIcon = DoubleArrowRight;
export const DoubleArrowLeftIcon = DoubleArrowLeft;
export const ResetIcon = Reset;
export const SortIcon = Sort;
export const PizzaIcon = Pizza;
export const ClockIcon = Clock;
export const PlusIcon = Plus;
