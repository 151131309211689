import { observer } from 'mobx-react-lite';
import React from 'react';
import { DayTime } from '../../stores/DateTimeStore';
import { HOUR_IN_DAY, MIN_IN_HOUR, SEC_IN_MIN } from '../../config';
import Column from './Column';
import { TimeType } from './index';
import { useTranslation } from 'react-i18next';
import { generateHtmlClasses } from 'utils/htmlClasses';

const HOURS = Array.from(Array(HOUR_IN_DAY).keys());
const MINUTES = Array.from(Array(MIN_IN_HOUR).keys());
const SECONDS = Array.from(Array(SEC_IN_MIN).keys());

interface InputPopupProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTime: DayTime | null;
  setSelectedTime: React.Dispatch<React.SetStateAction<DayTime | null>>;
  popupRef: React.RefObject<HTMLDivElement>;
  className: string;
  title?: string;
}

export default observer(
  ({
    isOpen,
    setIsOpen,
    selectedTime,
    setSelectedTime,
    popupRef,
    className,
    title,
  }: InputPopupProps) => {
    const { t } = useTranslation();
    const handleNowClick = () => {
      const today = new Date();
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      setSelectedTime({ hours, minutes, seconds });
      setIsOpen(false);
    };

    const handlePopupClose = () => setIsOpen(false);

    return (
      <div
        className={generateHtmlClasses('input-time__input-popup input-popup', className, {
          _opened: isOpen,
        })}
        ref={popupRef}
      >
        <div className="react-timepicker">
          {title && <div className="react-timepicker__header">{title}</div>}
          <div className="react-timepicker__body">
            <Column
              array={HOURS}
              type={TimeType.HOUR}
              selectedTime={selectedTime}
              setSelectedTime={setSelectedTime}
            />
            <Column
              array={MINUTES}
              type={TimeType.MINUTE}
              selectedTime={selectedTime}
              setSelectedTime={setSelectedTime}
            />
            <Column
              array={SECONDS}
              type={TimeType.SECOND}
              selectedTime={selectedTime}
              setSelectedTime={setSelectedTime}
            />
          </div>
          <div className="react-timepicker__footer">
            <div className="react-timepicker__buttons">
              <button className="button _flat _green" onClick={handleNowClick}>
                {t('common:now')}
              </button>
              <button className="button _primary" onClick={handlePopupClose}>
                {t('common:ok')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  },
);
