import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { generateHtmlClasses } from 'utils/htmlClasses';

interface SwitchProps {
  states: string[];
  currentState: string;
  onChange: (state: string) => void;
  color?: 'gray' | 'green';
}

export default observer(({ states, currentState, onChange, color = 'gray' }: SwitchProps) => {
  const stateRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const [stateHeight, setStateHeight] = useState<number>(0);
  const [stateWidth, setStateWidth] = useState<number>(0);
  const [listWidth, setListWidth] = useState<number>(0);
  const [listGap, setListGap] = useState<number>(0);

  useEffect(() => {
    setStateHeight(stateRef.current?.clientHeight || 0);
    setStateWidth(stateRef.current?.clientWidth || 0);
    setListWidth(listRef.current?.clientWidth || 0);
    setListGap(Math.ceil((listWidth - stateWidth * states.length) / (states.length + 1)));
    //eslint-disable-next-line
  }, [
    stateRef.current?.clientHeight,
    stateRef.current?.clientWidth,
    listRef.current?.clientWidth,
    stateWidth,
  ]);

  const handleStateChange = (state: string) => onChange(state);

  return (
    <div className="switch">
      <div className="switch__list list" ref={listRef}>
        {states.map((state) => (
          <div
            className={generateHtmlClasses('list__state', { _active: currentState === state })}
            key={state}
            onClick={() => handleStateChange(state)}
            ref={stateRef}
            style={{ width: stateWidth }}
          >
            {state}
          </div>
        ))}
        <div
          className={generateHtmlClasses('list__bg', {
            _gray: color === 'gray',
            _green: color === 'green',
          })}
          style={{
            transform: `translateX(${states.indexOf(currentState) * (stateWidth + listGap)}px)`,
            width: stateWidth,
            height: stateHeight,
          }}
        />
      </div>
    </div>
  );
});
