import { ADMIN_V1_API_PREFIX, API_URL_2 } from '../config';
import { IUserWithRoles } from './dto/GetUserByTeamDto';
import { APIResponse, RequestAPI } from './Requests';

interface UserRequestsInterface {
  getBySearchString(searchPhone: string): Promise<APIResponse<IUserWithRoles[]>>;

  getByTeam(teamId: string): Promise<APIResponse<IUserWithRoles[]>>;
}

export const UserRequests: UserRequestsInterface = {
  getBySearchString: (searchStr: string) => {
    const path = searchStr ? `users?s=${searchStr}` : 'users';
    return RequestAPI.get(API_URL_2 + ADMIN_V1_API_PREFIX + path);
  },

  getByTeam: (teamId) => RequestAPI.get(API_URL_2 + ADMIN_V1_API_PREFIX + `users/by-team-id/${teamId}`),
};
