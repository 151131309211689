import { RequestAPI } from './Requests';
import { API_URL_2 } from '../config';

export interface User {
  id: string;
  phone: string;
  login?: string;
  email: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  roles: Role[];
  permissions: Permission[];
}

interface Role {
  id: number;
  code: string;
  name: string;
  service: string;
  description: string;
  createdAt: string;
  permissions: Permission[];
}

interface Permission {
  id: number;
  code: string;
  createdAt: string;
}

interface AuthResponse {
  data: {
    access_token: string;
    refresh_token: string;
  };
}

interface AuthRequestsInterface {
  requestOtpCode(data: { phone: string }): Promise<void>;

  authByEmail(data: { email: string; password: string }): Promise<AuthResponse>;

  otpCodeConfirm(data: { phone: string; code: string }): Promise<AuthResponse>;

  requestAuthUserData(): Promise<{ data: User }>;

  refreshTokens(data: { refresh_token: string }): Promise<AuthResponse>;
}

export const AuthRequests: AuthRequestsInterface = {
  requestOtpCode: (data) => RequestAPI.post(`${API_URL_2}auth/v1/auth/otp/send`, data),

  authByEmail: (data) => RequestAPI.post(`${API_URL_2}auth/v1/auth/sign-in/email`, data),

  otpCodeConfirm: (data) => RequestAPI.post(`${API_URL_2}auth/v1/auth/otp/confirm`, data),

  requestAuthUserData: () => RequestAPI.post(`${API_URL_2}auth/v1/me`),

  refreshTokens: (data) => RequestAPI.post(`${API_URL_2}auth/v1/auth/tokens/refresh`, data),
};
