import { useMemo } from 'react';
import { Order } from '../api/types/Order';
import { dataStore } from '../stores/DataStore';

const useOrderProgress = (order: Order) => useMemo(() => {
  if (dataStore.serverTime <= order.deliverByMin) {
    return 100;
  }
  if (dataStore.serverTime >= order.deliverByMax) {
    return 0;
  }
  return 100 - ((dataStore.serverTime - order.deliverByMin) * 100) /
    (order.deliverByMax - order.deliverByMin);
  //eslint-disable-next-line
}, [order.deliverByMax, order.deliverByMin, dataStore.serverTime]);

export default useOrderProgress;
