import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { authStore } from 'stores/AuthStore';

export default observer(() => {
  const { t } = useTranslation();
  const handleToggle = () => authStore.setIsSummaryExpanded(!authStore.isSummaryExpanded);

  return (
    <div className="summary">
      {authStore.isSummaryExpanded && (
        <div className="summary__body">
          <div className="summary-item">
            <div className="summary-item__title">{t('common:allOrders')}</div>
            <div className="summary-item__value">96</div>
          </div>
          <div className="summary-item">
            <div className="summary-item__title">{t('common:deliveryRate')}</div>
            <div className="summary-item__value">12.4%</div>
          </div>
          <div className="summary-item">
            <div className="summary-item__title">{t('common:cusomerRating')}</div>
            <div className="summary-item__value">2/5</div>
          </div>
        </div>
      )}
      <div className="summary__toggle icon icon-chevron-up" onClick={handleToggle} />
    </div>
  );
});
