import { Courier, VehicleType } from 'api/types/Courier';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import PopupEditCourier from 'components/PopupEditCourier';
import { dataStore } from 'stores/DataStore';
import { mainStore } from 'stores/MainStore';
import InputPhone from 'components/InputPhone';
import InputText from 'components/InputText';
import { useTranslation } from 'react-i18next';
import { generateHtmlClasses } from 'utils/htmlClasses';
import Select, { SelectItem } from 'components/Select';

interface AddCourierFormProps {
  className?: string;
  onDismissClick: () => void;
}

export default observer(({ className, onDismissClick }: AddCourierFormProps) => {
  const { t } = useTranslation();
  const [selectedTeam, setSelectedTeam] = useState<SelectItem>({
    value: '',
    text: '',
    rawText: '',
  });
  const handleChangeTeam = (val: SelectItem) => setSelectedTeam(val);
  const [nameVal, setNameVal] = useState('');
  const [phoneVal, setPhoneVal] = useState('');
  const [phoneValid, setPhoneValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleResetAndDismiss = () => {
    resetForm();
    onDismissClick();
  };
  const handlePhoneChange = (val: string) => setPhoneVal(val);
  const handlePhoneValidate = (flag: boolean) => setPhoneValid(flag);
  const isValid = (): boolean => !(!nameVal.trim() || !phoneValid);

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.currentTarget.value.trimStart();

    value = value.replaceAll('  ', ' ');

    setNameVal(value);
  };

  const resetForm = () => {
    setNameVal('');
    setPhoneVal('');
    setPhoneValid(false);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid()) return;
    setIsLoading(true);
    createCourierAndOpenForm().finally(() => setIsLoading(false));
  };

  const [createdCourier, setCreatedCourier] = useState<Courier | null>(null);
  const [createdCourierName, setCreatedCourierName] = useState<string>('');

  const createCourierAndOpenForm = async () => {
    const courier = await dataStore.createCourier({
      name: nameVal,
      phone: phoneVal,
      teamId: selectedTeam.value,
      vehicle: VehicleType.BICYCLE,
    });
    if (!courier) return;

    resetForm();
    setCreatedCourierName(nameVal);
    setCreatedCourier(courier);
    mainStore.setIsShowPopupEditCourier(true);
  };

  return (
    <React.Fragment>
      <form className={generateHtmlClasses('add-courier-form', className)} onSubmit={handleSubmit}>
        <InputText
          autoFocus
          placeholder={t('forms:addCourier:fullNamePlaceholder')}
          value={nameVal}
          onChange={handleChangeName}
          maxLength={256}
        />
        <InputPhone
          placeholder={t('forms:addCourier:phonePlaceholder')}
          value={phoneVal}
          onChange={handlePhoneChange}
          onValidate={handlePhoneValidate}
        />
        <Select
          className="add-team-form__input"
          initialValue={t('forms:addCourier:team')}
          value={selectedTeam}
          data={dataStore.teamList}
          onChange={handleChangeTeam}
        />
        <div className="add-courier-form__buttons">
          <div className="button _secondary" onClick={handleResetAndDismiss}>
            {t('common:cancel')}
          </div>
          <button className="button _primary" disabled={!isValid() || isLoading}>
            {isLoading ? <span className="spinner" /> : t('forms:addCourier:phonePlaceholder')}
          </button>
        </div>
      </form>
      {createdCourier && (
        <PopupEditCourier
          courierName={createdCourierName}
          courierId={createdCourier.id}
          capacity={createdCourier.capacityPackages || 0}
          vehicleType={createdCourier.vehicleType}
          agency={createdCourier.agency}
          onDismissClick={onDismissClick}
        />
      )}
    </React.Fragment>
  );
});
