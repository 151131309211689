import { GetJobListResponse } from 'api/response/GetJobListResponse';

export enum JobState {
  UNASSIGNED = 'UNASSIGNED',
  ASSIGNED = 'ASSIGNED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
}

export interface Job {
  id: string;
  state: JobState;
  courierId: string;
  createdAt: number;
  updatedAt: number;
  deletedAt: number;
  teamId: string;
  warehouseId: string;
}

export interface SlotJob {
  [key: string]: GetJobListResponse;
}
