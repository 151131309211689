import { API_URL_2, ADMIN_V1_API_PREFIX, WMS_CONNECTOR_API_PREFIX } from 'config';
import {
  WarehouseWithTeamsRel
} from '../proto/build/js/last-mile/dispatcher/public/admin/warehouse_with_teams';
import { RequestAPI, APIResponse } from './Requests';
import { Warehouse } from './types/Warehouse';

interface WarehouseRequestsInterface {
  getInfo(): Promise<APIResponse<Warehouse[]>>;

  getOne(id: string): Promise<APIResponse<WarehouseWithTeamsRel>>;

  getWarehouses(): Promise<APIResponse<Warehouse[]>>;
}

export const WarehouseRequests: WarehouseRequestsInterface = {
  getInfo: () => RequestAPI.get(API_URL_2 + ADMIN_V1_API_PREFIX + 'warehouse/get-all'),

  getOne: (id) => RequestAPI.get(API_URL_2 + ADMIN_V1_API_PREFIX + 'warehouse/id/' + id),

  getWarehouses: () => RequestAPI.get(API_URL_2 + WMS_CONNECTOR_API_PREFIX + 'warehouses'),
};
