import type { CourierAssignTeamDto } from 'api/dto/CourierAssignTeamDto';
import type { CreateCourierDto } from 'api/dto/CreateCourierDto';
import type { ForceOfflineCourierDto } from 'api/dto/ForceOfflineCourierDto';
import type { GetCourierByExternalIdDto } from 'api/dto/GetCourierByExternalIdDto';
import type { UpdateCourierDto } from 'api/dto/UpdateCourierDto';
import type { CourierAssignTeamResponse } from 'api/response/CourierAssignTeamResponse';
import type { ForceOfflineCourierResponse } from 'api/response/ForceOfflineCourierResponse';
import type { Courier } from 'api/types/Courier';
import { RequestAPI, type APIResponse } from './Requests';
import { ADMIN_V1_API_PREFIX, API_URL_2 } from 'config';

interface CourierRequestsInterface {
  getList(): Promise<APIResponse<Courier[]>>;

  forceOffline(data: ForceOfflineCourierDto): Promise<ForceOfflineCourierResponse>;

  create(data: CreateCourierDto): Promise<APIResponse<Courier>>;

  assignTeam(data: CourierAssignTeamDto): Promise<CourierAssignTeamResponse>;

  unassignTeam(data: CourierAssignTeamDto): Promise<void>;

  update(courierId: string, data: UpdateCourierDto): Promise<APIResponse<Courier>>;

  getByExternalId(data: GetCourierByExternalIdDto): Promise<APIResponse<{ courier: Courier }>>;
}

export const CourierRequests: CourierRequestsInterface = {
  getList: () => RequestAPI.get(`${API_URL_2 + ADMIN_V1_API_PREFIX}couriers`),

  forceOffline: (data) =>
    RequestAPI.post(`${API_URL_2 + ADMIN_V1_API_PREFIX}couriers/force-offline`, data),

  create: (data) => RequestAPI.post(`${ADMIN_V1_API_PREFIX}couriers`, data),

  assignTeam: (data) =>
    RequestAPI.post(`${API_URL_2 + ADMIN_V1_API_PREFIX}couriers/assign-team`, data),

  unassignTeam: (data) =>
    RequestAPI.post(`${API_URL_2 + ADMIN_V1_API_PREFIX}couriers/unassign-team`, data),

  update: (courierId, data) =>
    RequestAPI.patch(`${API_URL_2 + ADMIN_V1_API_PREFIX}couriers/${courierId}`, data),

  getByExternalId: (data) =>
    RequestAPI.post(`${API_URL_2 + ADMIN_V1_API_PREFIX}couriers/get-by-ext-id`, data),
};
