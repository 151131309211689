import { observer } from 'mobx-react-lite';
import { settingsStore } from 'components/PopupSettings/Warehouses/store';
import { mainStore } from 'stores/MainStore';
import React, { useEffect } from 'react';
import PopoverAddCourier from './PopoverAddCourier';
import PopoverMenu from './PopoverMenu';
import LogoJiffy from '../assets/img/logo_jiffy_white.svg';
import PopoverFilter from './PopoverFilter';
import PopoverDropdown from './PopoverDropdown';
import PopoverAddTeam from './PopoverAddTeam';
import LanguageSelector from './LanguageSelector/LanguageSelector';
import { authStore } from 'stores/AuthStore';
import { generateHtmlClasses } from 'utils/htmlClasses';

export default observer(() => {
  const handleTogglePopoverDropdown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    mainStore.setIsShowPopoverDropdown(!mainStore.isShowPopoverDropdown);
  };
  const handleOpenModalSettings = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    mainStore.setIsShowPopupSettings(true);
  };
  const handleTogglePopoverMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    mainStore.setIsShownPopoverMenu(!mainStore.isShowPopoverMenu);
  };
  const handleTogglePopoverFilter = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    mainStore.setIsShowPopoverFilter(!mainStore.isShowPopoverFilter);
  };

  useEffect(() => {
    if (!mainStore.isShowPopupSettings && !mainStore.isShowPopoverAddTeam) {
      settingsStore.setSelectedWarehouse(null);
    }
    //eslint-disable-next-line
  }, [mainStore.isShowPopupSettings, mainStore.isShowPopoverAddTeam]);

  return (
    <>
      <div className="header">
        <div className="header-controls">
          <div className="header-controls__menu" onClick={handleTogglePopoverMenu}>
            <img className="header-controls__logo" src={LogoJiffy} alt="" />
            <div
              className={generateHtmlClasses('icon icon-chevron-up', {
                _active: mainStore.isShowPopoverMenu,
              })}
            />
          </div>
          <div className="header-controls__line" />
          <div
            className={generateHtmlClasses('header-controls__item icon icon-settings', {
              _active: mainStore.isShowPopupSettings,
            })}
            onClick={handleOpenModalSettings}
          />
          <div
            className={generateHtmlClasses('header-controls__item icon icon-add', {
              _active: mainStore.isShowPopoverDropdown,
            })}
            onClick={handleTogglePopoverDropdown}
          />
          {authStore.showLangSelector && <LanguageSelector />}
        </div>
        <div
          className={generateHtmlClasses('button-circle _filter icon icon-filter', {
            _active: mainStore.isShowPopoverFilter,
          })}
          onClick={handleTogglePopoverFilter}
        />
      </div>
      <PopoverMenu />
      <PopoverAddCourier />
      <PopoverAddTeam />
      <PopoverDropdown />
      <PopoverFilter />
    </>
  );
});
