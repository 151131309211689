import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { mainStore } from 'stores/MainStore';
import Popup from './Popup';

export default observer(() => {
  const handlePopupDismiss = () => {
    mainStore.setIsShowPopupImageView(false);
    mainStore.setSelectedImageUrl(null);
  }

  useEffect(() => {
    mainStore.setIsShowPopupImageView(!!mainStore.selectedImageUrl?.length);
    //eslint-disable-next-line
  }, [mainStore.selectedImageUrl]);

  return (
    <Popup className='popup-image-view'
           isShow={mainStore.isShowPopupImageView && !!mainStore.selectedImageUrl}
           onBackdropDismiss={handlePopupDismiss}>
      {mainStore.selectedImageUrl?.length &&
       <img className='popup-image-view__image'
            src={mainStore.selectedImageUrl}
            alt='' />}
    </Popup>
  );
});
