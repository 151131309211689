import { OrderWithRel } from 'api/response/GetJobListResponse';
import { TaskState } from 'api/types/Task';
import { useCallback, useEffect, useMemo } from 'react';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import { observer } from 'mobx-react-lite';
import Order from './Order';
import { DynamicFrameName, mainStore } from 'stores/MainStore';
import { generateHtmlClasses } from 'utils/htmlClasses';

type JobProps = {
  orders: OrderWithRel[];
  jobId: string;
  handleToggleExpanding: (flag: boolean) => void;
  isExpanded: boolean;
  index: number;
  isAssigned?: boolean;
  isSlot?: boolean;
  avoidBatching?: boolean;
  isEditable?: boolean;
  source?: DynamicFrameName;
};

export default observer(
  ({
    jobId,
    orders,
    avoidBatching = false,
    handleToggleExpanding,
    isExpanded,
    isEditable,
    isAssigned,
    index: jobIndex,
    isSlot,
    source,
  }: JobProps) => {
    const isJobDragged = useMemo(
      () => jobId === mainStore.draggableId,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [jobId, mainStore.draggableId],
    );
    const smallCardTitle = useMemo(() => {
      let title = '';
      orders.forEach((item, index) => {
        title = `${index > 0 ? `${title}, ` : ''} ${item.order.externalId}`;
      });
      return `${title};`;
    }, [orders]);

    const isDragDisabled = (): boolean => {
      if (isSlot) {
        return isSlot;
      }
      return orders.some(({ tasks }) =>
        tasks.some(({ task }) => {
          return [
            TaskState.ON_THE_WAY,
            TaskState.ON_POINT,
            TaskState.COMPLETED,
            TaskState.FAILED,
          ].includes(task.state as TaskState);
        }),
      );
    };

    const isDropDisabled = (): boolean => {
      if (isEditable) return !isEditable;
      if (orders.length > 1) return true;
      return (
        mainStore.dragItem.group.split('_')[0] === 'jobOrders' &&
        mainStore.dragItem.type === 'order'
      );
    };

    const sortedOrders = orders;

    useEffect(() => {
      document.addEventListener('mouseup', handleMouseUp);
      return () => {
        document.removeEventListener('mouseup', handleMouseUp);
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isJobDragged, jobId]);

    const handleMouseDown = useCallback(
      (event: React.MouseEvent) => {
        const element = event.target as HTMLElement;
        if (mainStore.activeDynamicFrame !== 'unassigned') {
          mainStore.setActiveDynamicFrame(null);
          mainStore.setActiveDynamicFrame('unassigned', source);
        }
        if (
          (element.parentNode?.parentNode as HTMLElement)?.className.includes('job _group') ||
          (
            (element.parentNode?.parentNode as HTMLElement).parentNode as HTMLElement
          ).className.includes('job _group')
        ) {
          return;
        }
        mainStore.setDraggableId(jobId);
        const target = document.querySelector(`[data-rbd-drag-handle-draggable-id="${jobId}"]`);
        target?.classList.add('_isDraggable');
        event.preventDefault();
      },
      [jobId, source],
    );

    const handleMouseUp = useCallback(() => {
      if (isJobDragged) {
        mainStore.setDraggableId('');
        const target = document.querySelector(`[data-rbd-drag-handle-draggable-id="${jobId}"]`);
        target?.classList.remove('_isDraggable');
      }
    }, [isJobDragged, jobId]);

    return (
      <Draggable draggableId={jobId} index={jobIndex} isDragDisabled={isDragDisabled()}>
        {(provided) => {
          const handlers = {
            ...provided.dragHandleProps,
            onMouseDown: isDragDisabled() ? undefined : handleMouseDown,
          };
          return (
            <div
              className={generateHtmlClasses('job', {
                _group: orders.length > 1 && !avoidBatching,
              })}
              {...provided.draggableProps}
              {...handlers}
              ref={provided.innerRef}
            >
              <Droppable
                droppableId={`jobOrders_${jobId}`}
                type="order"
                isDropDisabled={isDropDisabled()}
              >
                {(provided) => (
                  <div ref={provided.innerRef}>
                    {isJobDragged && orders.length > 1 ? (
                      <div className="jobDragCard">{smallCardTitle}</div>
                    ) : (
                      sortedOrders.map(({ order, tasks }, orderIndex) => (
                        <Order
                          isEditable={isEditable}
                          isSlot={isSlot}
                          isAssigned={isAssigned}
                          jobId={jobId}
                          order={order}
                          tasks={tasks}
                          handleToggleExpanding={handleToggleExpanding}
                          isExpanded={isExpanded}
                          key={order.id}
                          isAlone={orders.length === 1}
                          index={orderIndex}
                          source={source}
                        />
                      ))
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          );
        }}
      </Draggable>
    );
  },
);
