import { mainStore } from './stores/MainStore';
import {
  CourierCreateRespV3,
} from 'proto/build/js/last-mile/dispatcher/public/admin/v3/courier_create';
import {
  JobAssignCourierResp,
} from './proto/build/js/last-mile/dispatcher/public/admin/job_assign_courier';
import {
  JobUnassignCourierResp,
} from './proto/build/js/last-mile/dispatcher/public/admin/job_unassign_courier';
import {
  JobsGetListRespV2,
} from './proto/build/js/last-mile/dispatcher/public/admin/v2/jobs_get_list';
import {
  JiffyMessage,
  JiffyMessageBasic,
  JiffyMessageBody,
} from './proto/build/js/message/message';

export const listenerOnClick = () => {
  document.addEventListener('click', (e) => {
    if (!e.target) return;
    if ((e.target as HTMLElement).closest('.popover')) return;
    mainStore.hideAllPopovers();
  });
};

window.protoDecode = (data: any): any => {
  if (!data || data.body?.type === undefined) return;
  try {
    const encoded = JiffyMessage.fromObject(data);
    switch (data.body.type) {
      case JiffyMessageBody.Type.ERROR:
        return JiffyMessageBasic.decode(encoded.body.data).message;
      case JiffyMessageBody.Type.DISPATCHER__RESPONSE__ADMIN__JOBS_GET_LIST_V2:
        return JobsGetListRespV2.decode(encoded.body.data);
      case JiffyMessageBody.Type.DISPATCHER__RESPONSE__ADMIN__JOB_ASSIGN_COURIER:
        return JobAssignCourierResp.decode(encoded.body.data);
      case JiffyMessageBody.Type.DISPATCHER__RESPONSE__ADMIN__JOB_UNASSIGN_COURIER:
        return JobUnassignCourierResp.decode(encoded.body.data);
      case JiffyMessageBody.Type.DISPATCHER__RESPONSE__ADMIN__COURIER_CREATE_V3:
        return CourierCreateRespV3.decode(encoded.body.data);
    }
  } catch (error) {
    error && console.error(error);
  }
};
