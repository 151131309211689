import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import PopupDeleteMember from '../../../components/PopupDeleteMember';
import PopupAddMember from '../../../components/PopupAddMember';
import { mainStore } from 'stores/MainStore';
import { dataStore } from 'stores/DataStore';

import Header from '../Header';
import { settingsStore } from './store';
import UserCard from './UserCard';
import MobileEmptyBlock from 'components/MobileEmptyBlock';
import { useTranslation } from 'react-i18next';
import InputText from 'components/InputText';

const EditableTeamName = ({
  name,
  teamId,
  warehouseId,
}: {
  name: string;
  teamId: string;
  warehouseId?: string;
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [nameVal, setNameVal] = useState(name);
  const toggleClick = () => {
    setIsEditing((val) => !val);
  };
  const handleChangeInput = (e) => {
    setNameVal(e.currentTarget.value);
  };
  const handleResetValue = () => {
    setIsEditing(false);
    setNameVal(name);
  };
  const handleSaveValue = () => {
    const newName = nameVal.trim();
    dataStore
      .editTeam(teamId, {
        name: newName,
      })
      .then((suc) => {
        setIsEditing(false);
        if (warehouseId) {
          settingsStore.fetchWarehouseWithTeams(warehouseId);
        }
        settingsStore.changeSelectedTeamName(newName);
      })
      .catch((err) => {
        setIsEditing(false);
        setNameVal(name);
      });
  };
  return !isEditing ? (
    <div style={{ flexDirection: 'row', display: 'flex' }}>
      <div onClick={toggleClick} className="team-name-title" style={{ cursor: 'pointer' }}>
        {nameVal}
      </div>
      <div
        className="frame-dynamic-title__edit-btn icon icon-pen"
        onClick={toggleClick}
        style={{ marginLeft: 10, fontSize: 16, cursor: 'pointer' }}
      />
    </div>
  ) : (
    <div style={{ flexDirection: 'row', display: 'flex' }}>
      <InputText
        className="form__input"
        placeholder={name}
        value={nameVal}
        onChange={handleChangeInput}
        noClearButton
      />
      <div
        className="input-text__clear icon icon-close"
        onClick={handleResetValue}
        style={{ marginLeft: 10, fontSize: 16, cursor: 'pointer' }}
      />
      <div
        className="input-text__clear icon icon-check"
        onClick={handleSaveValue}
        style={{ marginLeft: 10, fontSize: 20, cursor: 'pointer' }}
      />
    </div>
  );
};

export default observer(() => {
  if (!settingsStore.selectedTeam) return null;

  const { t } = useTranslation();
  const [userIdToDelete, setUserIdToDelete] = useState<string>('');

  const handleBackClick = () => settingsStore.setActiveFrame('home');
  const handlePopupAddMemberShow = () => mainStore.setIsShowPopupAddMember(true);
  const handleUserDelete = (userId: string) => setUserIdToDelete(userId);
  const handleTeamMembersChange = () => {
    if (settingsStore.selectedTeam) {
      return settingsStore.fetchTeamWithUsers(settingsStore.selectedTeam.team);
    }
  };

  const offlineUsersLength = settingsStore.calculateOfflineCouriers(
    settingsStore.selectedTeam.users,
  );
  const allUsersLength = settingsStore.selectedTeam.users.length;

  return (
    <div>
      <Header
        title={
          <EditableTeamName
            name={settingsStore.selectedTeam.team.name}
            teamId={settingsStore.selectedTeam.team.id}
            warehouseId={settingsStore.selectedWarehouse?.warehouse.id}
          />
        }
        onBackClick={handleBackClick}
      />
      <div className="settings-frame__body body">
        <div className="body__item item">
          <div className="item__top top">
            <span className="top__label">
              {allUsersLength - offlineUsersLength}/{allUsersLength} {t('phrases:onDuty')}
            </span>
            <div className="top__button button">
              <div className="button__icon icon icon-plus" />
              <div className="button__text" onClick={handlePopupAddMemberShow}>
                {t('phrases:addNewMember')}
              </div>
            </div>
          </div>
          <div className="item__user-cards cards user-cards">
            {settingsStore.selectedTeam.users.map((user) => (
              <UserCard key={user.id} user={user} onUserDeleteClick={handleUserDelete} />
            ))}
            <MobileEmptyBlock />
          </div>
        </div>
      </div>
      <PopupAddMember
        teamId={settingsStore.selectedTeam.team.id}
        onComplete={handleTeamMembersChange}
      />
      <PopupDeleteMember
        userId={userIdToDelete}
        teamId={settingsStore.selectedTeam.team.id}
        onComplete={handleTeamMembersChange}
      />
    </div>
  );
});
