import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { SENTRY_DSN } from './config';
import { BrowserRouter } from 'react-router-dom';
import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Translations from './translations.json';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  environment:
    process.env.NODE_ENV === 'development'
      ? 'development'
      : process.env.REACT_APP_ENV || 'development',
  tracesSampleRate: 1.0,
  maxValueLength: 5000,
  enabled: process.env.NODE_ENV !== 'development',
  release: process.env.REACT_APP_RELEASE
    ? `dispatcher-admin@${process.env.REACT_APP_RELEASE}`
    : undefined,
  beforeBreadcrumb(breadcrumb) {
    if (breadcrumb.category === 'sentry.transaction') return null;
    return breadcrumb;
  },
});

i18n.use(initReactI18next).init({
  resources: Translations as Resource,
  lng: 'ar',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
    defaultVariables: {
      // companyName: company.config.name,
      // companyEmail: company.config.email,
      // ageRestricted: company.config.ageRestricted,
    },
  },
});
const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>,
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
