import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { frameDetailStore } from 'components/FrameDetail/store';
import Popup from './Popup';
import { mainStore } from 'stores/MainStore';
import { dataStore } from 'stores/DataStore';
import { useTranslation } from 'react-i18next';

export default observer(() => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const handlePopupDismiss = () => mainStore.setIsShowPopupComplete(false);
  const handleComplete = () => {
    if (!mainStore.selectedOrder.id) return;
    setIsLoading(true);
    dataStore.orderForceComplete(mainStore.selectedOrder.externalId).catch(() => void 0).finally(() => {
      setIsLoading(false);
      mainStore.setIsShowPopupComplete(false);
    });
  };
  const handleFail = () => {
    if (!mainStore.selectedOrder.id) return;
    setIsLoading(true);
    dataStore.orderForceFail(mainStore.selectedOrder.externalId).catch(() => void 0).finally(() => {
      setIsLoading(false);
      mainStore.setIsShowPopupComplete(false);
    });
  };

  const returnTaskExists = !!frameDetailStore.tasks?.RETURN;

  return (
    <Popup
      className="popup-complete"
      isShow={mainStore.isShowPopupComplete && !!mainStore.selectedOrder.id}
      onBackdropDismiss={handlePopupDismiss}
    >
      <div className="popup-complete__sign">?</div>
      <div className="popup-complete__text">{t('phrases:areYouSureCompleteOrder')}</div>
      <div className="popup-complete__buttons">
        <button
          className="button _secondary _block"
          onClick={handleComplete}
          disabled={isLoading}
        >
          {isLoading ? <span className="spinner" /> : t('phrases:yesCompelete')}
        </button>
        <button
          className="button _red _block"
          onClick={handleFail}
          disabled={isLoading || returnTaskExists}
        >
          {isLoading ? <span className="spinner" /> : t('phrases:failOrder')}
        </button>
      </div>
    </Popup>
  );
});
