import * as Sentry from '@sentry/react';
import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { REQUEST_TIMEOUT, API_URL } from 'config';
import { JiffyMessage, JiffyMessageBasic, JiffyMessageBody } from 'proto/build/js/message/message';
import { authStore } from 'stores/AuthStore';

export interface JiffyMessageType<T extends JiffyMessageBody.Type> extends JiffyMessage {
  body: JiffyMessageBodyType<T>;
}

export interface JiffyMessageBodyType<T extends JiffyMessageBody.Type> extends JiffyMessageBody {
  type: T;
}

export interface APIResponse<T> {
  data: T;
  pagination?: {
    size: number;
    current: number;
    total: number;
  };
}

interface RequestAPIMethods {
  get(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse['data']>;

  post(
    url: string,
    data?: Record<string, any>,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse['data']>;

  put(
    url: string,
    data?: Record<string, any>,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse['data']>;

  patch(
    url: string,
    data?: Record<string, any>,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse['data']>;

  delete(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse['data']>;
}

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: REQUEST_TIMEOUT,
});

const addToken = (config?: AxiosRequestConfig): AxiosRequestConfig | undefined => {
  if (!authStore.token) return config;
  return Object.assign(config || {}, { headers: { Authorization: `Bearer ${authStore.token}` } });
};

const requestWrapper = async (request: () => Promise<AxiosResponse>): Promise<AxiosResponse> => {
  let count = 2;
  while (count > 0) {
    count--;
    try {
      return await request();
    } catch (error) {
      const { response, code, message } = error as AxiosError<any>;
      if (!response && (code === 'ECONNABORTED' || message === 'Network Error')) {
        if (count) {
          const attempt = count;
          await new Promise((resolve) => setTimeout(resolve, (2 - attempt) * 1000));
          continue;
        } else return Promise.reject(error);
      }
      const { status, config } = response || {};
      if (status === 401) {
        if ((config?.url as string)?.includes('auth/v1/auth/sign-in/email')) {
          return Promise.reject(error);
        }

        if ((config?.url as string)?.includes('auth/v1/auth/tokens/refresh')) {
          return Promise.reject(error);
        }
        if (!authStore.tokensAreRefreshing) {
          try {
            authStore.setTokensAreRefreshing(true);
            const {
              data: { access_token, refresh_token },
            } = await authStore.requestNewTokens();
            authStore.setToken(access_token);
            authStore.setRefreshToken(refresh_token);
            authStore.setTokensAreRefreshing(false);
            return request();
          } catch (e) {
            authStore.setTokensAreRefreshing(false);
            authStore.logout();
            return Promise.reject(error);
          }
        } else {
          return new Promise((resolve) => {
            const interval = setInterval(() => {
              if (!authStore.tokensAreRefreshing) {
                clearInterval(interval);
                resolve(request());
              }
            }, 1000);
          });
        }
      }
      return Promise.reject(error);
    }
  }
  return Promise.reject();
};

export const RequestAPI: RequestAPIMethods = {
  get: (url, config) => requestWrapper(() => axiosInstance.get(url, addToken(config))),

  post: (url, data, config) =>
    requestWrapper(() => axiosInstance.post(url, data, addToken(config))),

  put: (url, data, config) => requestWrapper(() => axiosInstance.put(url, data, addToken(config))),

  patch: (url, data, config) =>
    requestWrapper(() => axiosInstance.patch(url, data, addToken(config))),

  delete: (url, config) => requestWrapper(() => axiosInstance.delete(url, addToken(config))),
};

const loggingErrorResponse = ({ data, status, config }: AxiosResponse) => {
  if (status === 401) return;
  Sentry.withScope((scope) => {
    scope.setExtras({
      request: {
        url: (config.baseURL || '') + config.url,
        method: config.method,
        request: config.data,
      },
      response: data,
    });
    Sentry.captureMessage(
      `[${status}] ${config.method?.toUpperCase()} ${config.url}`,
      status === 500 ? 'warning' : 'info',
    );
  });
};

axiosInstance.interceptors.response.use(
  ({ data }) => {
    if (!data) return data;
    if (data.body && data.body.data !== undefined && data.body.type) {
      try {
        data = JiffyMessage.fromObject(data);
      } catch (error) {
        error && console.error(error);
        Promise.reject(error);
      }
    }
    return data;
  },
  (error) => {
    if (error && error.response) {
      const {
        response: { data },
      } = error;
      if (data && data.body && data.body.data !== undefined && data.body.type !== undefined) {
        try {
          const { body } = JiffyMessage.fromObject(data);
          error.response.data.message = JiffyMessageBasic.decode(body.data).message || '';
        } catch (error) {
          error && console.error(error);
        }
      }
      loggingErrorResponse(error.response);
    }
    return Promise.reject(error);
  },
);
