import { observer } from 'mobx-react-lite';
import { ReactNode, useState } from 'react';
import React from 'react';
import { generateHtmlClasses } from 'utils/htmlClasses';

interface ExpandableItemProps {
  className?: string;
  children: ReactNode;
  titleRef: React.RefObject<HTMLElement>;
  expanded?: boolean;
}

export default observer(
  ({ children, className, expanded = false, titleRef }: ExpandableItemProps) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(expanded);
    const handleExpandToggle = (event: React.MouseEvent<HTMLDivElement>) => {
      if (
        (event.target as Node).isSameNode(titleRef.current) ||
        titleRef.current?.contains(event.target as Node)
      ) {
        setIsExpanded((prev) => !prev);
      }
    };

    return (
      <div
        className={generateHtmlClasses('order-details__item item _expandable', className, {
          _expanded: isExpanded,
        })}
        onClick={handleExpandToggle}
      >
        {children}
      </div>
    );
  },
);
