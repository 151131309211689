import { observer } from 'mobx-react-lite';
import React, { useState, useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { generateHtmlClasses } from 'utils/htmlClasses';

export type SelectItem = { value: string; text: string; rawText: string };

export const DEFAULT_SELECT_ITEM = {
  value: '',
  text: '',
  rawText: '',
};

type SelectProps = {
  initialValue: string;
  value: SelectItem;
  data: SelectItem[];
  onChange: (value: SelectItem) => void;
  className?: string;
  withSearch?: boolean;
  noSearchIcon?: boolean;
  searchPlaceholder?: string;
  label?: string;
};

export default observer(
  ({
    initialValue,
    value,
    data,
    onChange,
    className,
    withSearch,
    noSearchIcon,
    searchPlaceholder,
    label,
  }: SelectProps) => {
    const { t } = useTranslation();
    const selectRef = useRef<HTMLDivElement | null>(null);

    const [isExpanded, setIsExpanded] = useState(false);
    const [listHeight, setListHeight] = useState<number>(40 * data.length + (data.length - 1));
    const [val, setVal] = useState(value.text || initialValue);
    const [searchVal, setSearchVal] = useState<string>(value.rawText);
    const [filteredData, setFilteredData] = useState<SelectItem[]>(data);

    const handleExpanded = () => setIsExpanded(!isExpanded);

    const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchVal(e.target.value);
    };

    const handleItemChange = (value: SelectItem) => {
      onChange(value);
      if (withSearch) setSearchVal(value.rawText);
    };

    const handleClearSearch = () => {
      setSearchVal('');
      setVal('');
      onChange(DEFAULT_SELECT_ITEM);
    };

    useEffect(() => {
      setVal(value.text || initialValue);
    }, [value, initialValue]);

    useEffect(() => {
      setSearchVal(value.rawText);
    }, [value]);

    useEffect(() => {
      if (!withSearch || !searchVal) {
        setFilteredData(data);
        return;
      }

      const filtered = data.filter((item) =>
        item.text.toLowerCase().includes(searchVal.toLowerCase()),
      );

      setFilteredData(filtered);
      //eslint-disable-next-line
    }, [searchVal, data]);

    useEffect(() => {
      if (!filteredData.length) {
        setListHeight(40);
        return;
      }

      setListHeight(40 * filteredData.length + (filteredData.length - 1));
    }, [filteredData.length]);

    useEffect(() => {
      function handleClickOutside(e: MouseEvent) {
        if (!selectRef.current?.contains(e.target as Node)) {
          setIsExpanded(false);
        }
      }

      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
      //eslint-disable-next-line
    }, [selectRef]);

    return (
      <div className="input-wrapper">
        {label && <div className="input-wrapper__label">{label}</div>}
        <div
          className={generateHtmlClasses('input-select', className, {
            _active: isExpanded,
            '_with-search': withSearch,
          })}
          onClick={handleExpanded}
          ref={selectRef}
        >
          <div className={generateHtmlClasses('input-select__val val', { _active: value.text })}>
            {withSearch ? (
              <div className="input-select__item">
                <div className="search-input">
                  {!noSearchIcon && <div className="search-input__icon icon icon-magnifier" />}
                  <input
                    className="search-input__input"
                    type="text"
                    value={searchVal}
                    onChange={handleSearchValueChange}
                    placeholder={searchPlaceholder}
                  />
                  <div
                    className={generateHtmlClasses('search-input__clear icon icon-close', {
                      _active: searchVal.length,
                    })}
                    onClick={handleClearSearch}
                  />
                </div>
              </div>
            ) : (
              <div className="input-select__item" dangerouslySetInnerHTML={{ __html: val }} />
            )}
            <div className="icon icon-chevron-up input-select__icon" />
          </div>
          <div className="input-select__list list" style={{ height: listHeight }}>
            <Scrollbars>
              {filteredData.length ? (
                filteredData.map((item) => (
                  <div
                    className="input-select__item"
                    key={item.value}
                    onClick={() => handleItemChange(item)}
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                ))
              ) : (
                <div className="input-select__item _no-result">{t('common:noResult')}</div>
              )}
            </Scrollbars>
          </div>
        </div>
      </div>
    );
  },
);
