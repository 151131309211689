import React from 'react';
import { mainStore } from 'stores/MainStore';
import { observer } from 'mobx-react-lite';
import Home from './Home';
import Team from './Team';
import { settingsStore } from './store';
import { generateHtmlClasses } from 'utils/htmlClasses';

export default observer(() => {
  return (
    <div
      className={generateHtmlClasses('settings-frame', 'warehouses', {
        _active: mainStore.activeSettingsFrame === 'warehouses',
      })}
    >
      {settingsStore.activeFrame === 'home' && <Home />}
      {settingsStore.activeFrame === 'team' && <Team />}
    </div>
  );
});
